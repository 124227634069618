import { useState, useEffect, useCallback } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { GroupByData } from "../../../entities/Statistics";
import { useDropdown, useSourceParameter } from "../Statistics";
import { SourceParameter } from "../../../entities/SourceParameters";
import { useFormCriteria, useGroupByValidation } from "./StatisticsForm";
import { TypeGrouping, convertType } from "../../../module/Enum/Grouping/Grouping";
import { DefaultGrouping, DropdownGrouping, convertGrouping } from "../../../module/Enum/Grouping/Grouping";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, FormControl, TextField } from "@mui/material";

export const StatisticsGroupings = observer(() => {
    const { dropdownNotice } = useDropdown();
    const { groupByValidation, setGroupByValidation } = useGroupByValidation();
    const { formCriteria, setFormCriteria, setCriteriaEdit } = useFormCriteria();
    const [groopings, setGroopings] = useState<GroupByData[]>([]);
    const sourceParameters = useSourceParameter();

    const createGroopings = useCallback((): void => {
        const newGroopings: GroupByData[] = [];

        Object.values(dropdownNotice ? DropdownGrouping : DefaultGrouping).forEach((grooping: string) => {
            const newGrooping: GroupByData = {
                type: TypeGrouping.DEFAULT,
                value: grooping,
            };
            newGroopings.push(newGrooping);
        });

        sourceParameters.forEach((parameter: SourceParameter) => {
            const newGrooping: GroupByData = {
                type: TypeGrouping.PARAMETER,
                value: parameter.parameter,
            };
            newGroopings.push(newGrooping);
        });

        setGroopings(newGroopings);
    }, [dropdownNotice, sourceParameters]);

    useEffect(() => {
        createGroopings();
    }, [createGroopings]);

    const changeGroupBy = (event: object, value: GroupByData[]): void => {
        if (value.length <= 4) {
            setFormCriteria({
                ...formCriteria,
                groupBy: value,
            });
        }

        setCriteriaEdit(true);
        setGroupByValidation(false);
    };

    const translateValue = (option: GroupByData): string => {
        let value: string = "";

        if (option.type === TypeGrouping.DEFAULT) {
            value = convertGrouping[option.value];
        } else {
            const parametersCopy: SourceParameter[] = JSON.parse(JSON.stringify(sourceParameters));
            parametersCopy
                .filter((parameter: SourceParameter) => parameter.parameter === option.value)
                .forEach((parameter: SourceParameter) => {
                    value = `${parameter.name} (${parameter.parameter})`;
                });
        }

        return value;
    };

    return (
        <FormControl fullWidth error={groupByValidation}>
            <Autocomplete
                multiple
                fullWidth
                size="small"
                disableClearable
                disableCloseOnSelect
                options={groopings}
                value={formCriteria.groupBy}
                groupBy={(option) => convertType[option.type]}
                getOptionLabel={(option) => translateValue(option)}
                onChange={(event, value) => runInAction(() => changeGroupBy(event, value))}
                isOptionEqualToValue={(option, value) => option.type === value.type && option.value === value.value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="medium"
                        variant="standard"
                        label="Группировки"
                        error={groupByValidation}
                        InputLabelProps={{ shrink: true }}
                        helperText={groupByValidation && "Поле не может быть пустым"}
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            size="small"
                            checked={selected}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        />
                        {translateValue(option)}
                    </li>
                )}
            />
        </FormControl>
    );
});
