import { LandingTitle, LandingUrl } from ".";
import { useUserRoles } from "../../../hooks/useUserRoles";
import { LandingAllowedUsers } from "./LandingAllowedUsers";

export const LandingEditBody = () => {
    const { isAdministrator, isTeamLead } = useUserRoles();

    return (
        <div className="modal-body modal-overflow">
            <LandingTitle />
            <LandingUrl />
            {(isAdministrator() || isTeamLead()) && <LandingAllowedUsers />}
        </div>
    );
};
