import { useContext, createContext } from "react";
import { useValidation, errorText, ValidationHook } from "./useValidation.hook";
import { Email, Password, ForgotPassword, SignInButton } from ".";
import "../../../style/authorization.css";

const ValidationContext = createContext<ValidationHook>({} as ValidationHook);

export const SignIn = () => {
    const { validation, changeValidation, checkValidation } = useValidation();

    return (
        <ValidationContext.Provider value={{ validation, changeValidation, checkValidation }}>
            <div className="authorization">
                <div className="authorization__title">
                    <h4>Добро пожаловать</h4>
                </div>
                <div className="mt-4">
                    <Email />
                </div>
                <div className="mt-4">
                    <Password />
                </div>
                {validation.wrongData && <div className="sing-in__wrong-data">{errorText.wrongData}</div>}
                <div className="authorization__footer mt-4">
                    <div className="sing-in__forgot-password">
                        <ForgotPassword />
                    </div>
                    <SignInButton />
                </div>
            </div>
        </ValidationContext.Provider>
    );
};

export const useSingInValidation = (): ValidationHook => {
    return useContext(ValidationContext);
};
