import { useState } from "react";
import { observer } from "mobx-react";
import { useDomainsIds } from "../Domains";
import { useStores } from "../../../stores";
import { WarningModal } from ".";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const DomainDeleteButton = observer(() => {
    const { domainsIds, setDomainsIds } = useDomainsIds();
    const { domainsData, removeDomain } = useStores().domainsStore;
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const conditionsForDisabled: boolean = domainsIds.length === 0 || domainsIds.length === domainsData.items.length;

    const openWarningModal = (): void => setOpenWarning(true);

    const closeWarningModal = (): void => setOpenWarning(false);

    const deleteDomain = (): void => {
        domainsIds.forEach((id: number) => {
            removeDomain(id);
        });
        setDomainsIds([]);
        closeWarningModal();
    };

    return (
        <>
            <Button
                size="small"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                disabled={conditionsForDisabled}
                onClick={openWarningModal}
            >
                Удалить
            </Button>

            {openWarning && <WarningModal deleteDomain={deleteDomain} closeWarningModal={closeWarningModal} />}
        </>
    );
});
