import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useCondition } from "../";
import { useRoutingRule } from "../../";
import { useStores } from "../../../../../../stores";
import { useValidations } from "../../../../../../validation";
import { CampaignValidation } from "../../../../../../validation/CampaignEditValidation";
import { Campaign, Condition, RoutingRule } from "../../../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const ConditionParameter = observer(() => {
    const { rule, ruleIndex } = useRoutingRule();
    const { condition, conditionIndex } = useCondition();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldParameterValues: string = `values${ruleIndex}${conditionIndex}`;
    const fieldParameterName: string = `${condition.type}${ruleIndex}${conditionIndex}`;

    const changeParameterName = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.name = event.target.value;
        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setParameterNameValidation();
    };

    const changeParameterValues = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.values = [event.target.value];
        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setParameterValuesValidation();
    };

    const setParameterNameValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions[fieldParameterName] = false;

        changeValidation(newValidation);
    };

    const setParameterValuesValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions[fieldParameterValues] = false;

        changeValidation(newValidation);
    };

    return (
        <div className="d-flex">
            <div className="d-flex align-items-center w-100 mr-3">
                <small className="text-muted text-nowrap mr-3">Параметр</small>
                <TextField
                    fullWidth
                    variant="standard"
                    value={condition.name}
                    error={validation.routingRuleConditions[fieldParameterName]}
                    helperText={validation.routingRuleConditions[fieldParameterName] && "Поле не может быть пустым"}
                    onChange={action(changeParameterName)}
                />
            </div>
            <div className="d-flex align-items-center w-100">
                <small className="text-muted text-nowrap mr-3">Значения</small>
                <TextField
                    fullWidth
                    variant="standard"
                    value={condition.values}
                    error={validation.routingRuleConditions[fieldParameterValues]}
                    helperText={validation.routingRuleConditions[fieldParameterValues] && "Поле не может быть пустым"}
                    onChange={action(changeParameterValues)}
                />
            </div>
        </div>
    );
});
