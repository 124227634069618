import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { PasswordResetData } from "../../entities/Auth";
import { usePasswordResetValidation } from "./PasswordReset";
import { errorText, AuthValidation } from "./useValidation.hook";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField, InputAdornment, IconButton } from "@mui/material";

export const PasswordRepeat = observer(() => {
    const [showPass, setShowPass] = useState<boolean>(false);
    const { validation, changeValidation } = usePasswordResetValidation();
    const { passwordResetData, changePasswordResetData } = useStores().authStore;

    const handleChange = (): void => setShowPass(!showPass);

    const changePassword = (event: ChangeEvent<HTMLInputElement>): void => {
        const dataCopy: PasswordResetData = JSON.parse(JSON.stringify(passwordResetData));

        dataCopy.passwordRepeat = event.target.value.replace(/\s/g, "");

        checkPasswordEquality(dataCopy);
        changePasswordResetData(dataCopy);
    };

    const checkPasswordEquality = (data: PasswordResetData): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));

        data.password !== data.passwordRepeat
            ? (newValidation.passwordsDoNotMatch = true)
            : (newValidation.passwordsDoNotMatch = false);

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <TextField
                fullWidth
                placeholder="Повторите пароль"
                type={showPass ? "text" : "password"}
                error={validation.passwordsDoNotMatch}
                value={passwordResetData.passwordRepeat}
                helperText={validation.passwordsDoNotMatch && errorText.passwordsDoNotMatch}
                onChange={action(changePassword)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleChange}>
                                {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        padding: 0,
                    },
                }}
            />
        </div>
    );
});
