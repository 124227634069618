import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Filter } from "../../../../entities/Report";
import { FilterField, FilterExpression, FilterValue, FilterDeleteButton, FilterAddButton } from "./";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography, AccordionActions, AccordionDetails, AccordionSummary } from "@mui/material";

export const ReportFilter = observer(() => {
    const { filter } = useStores().reportStore.reportRequest;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-light border-bottom border-secondary">
                <Typography>Фильтры</Typography>
            </AccordionSummary>
            <AccordionDetails className="d-flex flex-column">
                {filter.map((filterItem: Filter, filterIndex: number) => (
                    <div key={filterIndex} className="d-flex align-items-end mt-2">
                        <div className="col pl-0">
                            <FilterField filterItem={filterItem} />
                        </div>

                        <div className="col">
                            <FilterExpression filterIndex={filterIndex} filterItem={filterItem} />
                        </div>

                        <div className="col">
                            <FilterValue filterIndex={filterIndex} filterItem={filterItem} />
                        </div>

                        <div>
                            <FilterDeleteButton filterIndex={filterIndex} />
                        </div>
                    </div>
                ))}
            </AccordionDetails>
            <AccordionActions>
                <FilterAddButton />
            </AccordionActions>
        </Accordion>
    );
});
