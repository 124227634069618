interface ConvertField {
    id: string;
    name: string;
    [key: string]: string;
}

export enum SortField {
    ID = "id",
    NAME = "name",
}

export const convertField: ConvertField = {
    id: "#",
    name: "Наименование",
};
