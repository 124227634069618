import { Campaign } from "../../../entities/Campaigns";
import { Statistics } from "../../Statistics";

interface Props {
    item: Campaign;
}

export const Dropdown = ({ item }: Props) => (
    <tr key={item.id}>
        <td colSpan={7}>
            <div className="table__statistics">
                <Statistics dropdownNotice={true} campaignId={item.id} campaignTitle={item.title} />
            </div>
        </td>
    </tr>
);
