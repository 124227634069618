import { AxiosResponse } from "axios";
import { Source, SourceRequest } from "../../entities/SourceEdit";
import { SourceParameters } from "../../entities/SourceParameters";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { SourcesCriteriaRequest, SourcesData } from "../../entities/Sources";

interface SourcesApiInterface {
    deleteSource(sourceId: number): Promise<AxiosResponse>;
    getSource(sourceId: string | number): Promise<AxiosResponse<Source>>;
    postSource(newSource: SourceRequest): Promise<AxiosResponse<Source>>;
    getSources(criteria: SourcesCriteriaRequest): Promise<AxiosResponse<SourcesData>>;
    putSource(sourceId: number, newSource: SourceRequest): Promise<AxiosResponse<Source>>;
    getSourceParameters(): Promise<AxiosResponse<SourceParameters>>;
    getCampaignSourceParameters(campaignId: string | number): Promise<AxiosResponse<SourceParameters>>;
}

export const SourcesApi: SourcesApiInterface = {
    getSources: async (criteria: SourcesCriteriaRequest): Promise<AxiosResponse<SourcesData>> =>
        await httpClient.get(`/api/v1/source`, { params: criteria }),

    getSource: async (sourceId: string | number): Promise<AxiosResponse<Source>> =>
        await httpClient.get(`/api/v1/source/${sourceId}`),

    postSource: async (newSource: Source): Promise<AxiosResponse<Source>> =>
        await httpClient.post(`/api/v1/source`, newSource),

    putSource: async (sourceId: number, newSource: Source): Promise<AxiosResponse<Source>> =>
        await httpClient.put(`/api/v1/source/${sourceId}`, newSource),

    deleteSource: async (sourceId: number): Promise<AxiosResponse> =>
        await httpClient.delete(`/api/v1/source/${sourceId}`),

    getSourceParameters: async (): Promise<AxiosResponse<SourceParameters>> =>
        await httpClient.get<SourceParameters>(`/api/v1/source/parameters`),

    getCampaignSourceParameters: async (campaignId: number | string): Promise<AxiosResponse<SourceParameters>> =>
        await httpClient.get<SourceParameters>(`/api/v1/source/parameters/${campaignId}`),
};
