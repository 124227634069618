import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { LandingsCriteria } from "../../../entities/Landings";
import { TextField } from "@mui/material";

export const LandingSearch = observer(() => {
    const [searchValue, setSearchValue] = useState<string>("");
    const { criteria, changeCriteria } = useStores().landingsStore;

    const changeSearchValue = (event: ChangeEvent<{ value: string }>): void => {
        const newCriteria: LandingsCriteria = JSON.parse(JSON.stringify(criteria));

        setTimeout(() => {
            event.target.value.length >= 3 ? (newCriteria.search = event.target.value) : (newCriteria.search = "");
            changeCriteria(newCriteria);
        }, 0);

        setSearchValue(event.target.value);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            value={searchValue}
            label="Поиск лендинга..."
            InputLabelProps={{ shrink: true }}
            placeholder="Введите минимум 3 символа"
            onChange={action(changeSearchValue)}
        />
    );
});
