import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { PostbackSchedule } from "./PostbackSchedule";
import { LimitType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { PostbackLimitCheckBox, PostbackLimitValue, PostbackLimitType, PostbackLimitPercent } from ".";

export const CampaignPostbackLimits = observer(() => {
    const [checkUnlimited, setСheckUnlimited] = useState<boolean>(false);
    const { limitType, limitValue } = useStores().campaignEditStore.campaign.postbackSettings;

    useEffect(() => {
        switch (true) {
            case limitType === LimitType.PERCENT && limitValue === "100":
                setСheckUnlimited(true);
                break;

            case limitValue === "-1":
                setСheckUnlimited(true);
                break;

            default:
                setСheckUnlimited(false);
                break;
        }
    }, [limitType, limitValue]);

    return (
        <div>
            <h5>Глобальный лимит постбэков</h5>

            <div className="d-flex justify-content-between mt-3">
                <PostbackLimitCheckBox checkUnlimited={checkUnlimited} />

                <div className="postback-limit-type">
                    <PostbackLimitType checkUnlimited={checkUnlimited} />
                </div>
            </div>

            {limitValue !== "-1" && (
                <div className="d-flex mt-3">
                    <div className="container-fluid pl-0 pr-0">
                        <PostbackLimitValue />
                    </div>

                    {limitType !== LimitType.PERCENT && (
                        <div className="container-fluid pr-0">
                            <PostbackLimitPercent />
                        </div>
                    )}
                </div>
            )}

            {limitType === LimitType.SCHEDULED_HOUR && <PostbackSchedule />}
        </div>
    );
});
