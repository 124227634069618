import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { SourceParameter } from "../../../../entities/SourceEdit";
import { ParameterAddButton, ParameterName, ParameterParameter, ParameterSourceMacros, ParameterDeleteButton } from ".";

export const SourceParameters = observer(() => {
    const { parameters } = useStores().sourceEditStore.source;

    return (
        <div className="mt-5">
            <h5>Конвертация параметров</h5>
            {parameters.map((parameter: SourceParameter, index: number) => (
                <div key={index} className="d-flex align-items-start mt-3">
                    <ParameterName parameter={parameter} parameterIndex={index} />
                    <ParameterParameter parameter={parameter} parameterIndex={index} />
                    <ParameterSourceMacros parameter={parameter} parameterIndex={index} />
                    <ParameterDeleteButton parameterIndex={index} />
                </div>
            ))}
            <ParameterAddButton />
        </div>
    );
});
