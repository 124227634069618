import { useState } from "react";
import { WarningModal } from "./";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSelectedOffers } from "../Offers";
import { OfferItem } from "../../../entities/Offers";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const OfferDeleteButton = observer(() => {
    const [open, setOpen] = useState<boolean>(false);
    const { removeOffer } = useStores().offersStore;
    const { selectedOffers, setSelectedOffers } = useSelectedOffers();

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    const deleteOffer = (): void => {
        selectedOffers.forEach((offer: OfferItem) => {
            removeOffer(offer.id);
        });
        setSelectedOffers([]);
        closeWarningModal();
    };

    return (
        <>
            <Button
                size="small"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                disabled={selectedOffers.length === 0}
                onClick={openWarningModal}
            >
                Удалить
            </Button>

            {open && <WarningModal deleteOffer={deleteOffer} closeWarningModal={closeWarningModal} />}
        </>
    );
});
