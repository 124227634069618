import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSelectedUsers } from "../Users";
import { AlertModal, WarningModal } from ".";
import { User } from "../../../entities/Users";
import { AxiosResponse, AxiosError } from "axios";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const UserDeleteButton = observer(() => {
    const { removeUser } = useStores().usersStore;
    const { selectedUsers, setSelectedUsers } = useSelectedUsers();
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);

    const openAlertModal = (): void => setOpenAlert(true);

    const closeAlertModal = (): void => setOpenAlert(false);

    const openWarningModal = (): void => setOpenWarning(true);

    const closeWarningModal = (): void => setOpenWarning(false);

    const deleteUser = (): void => {
        let usersCopy: User[] = JSON.parse(JSON.stringify(selectedUsers));

        usersCopy.forEach((user: User) => {
            removeUser(user.id)
                .then((response: AxiosResponse | void) => {
                    usersCopy = usersCopy.filter((userCopy: User) => userCopy.id !== user.id);
                    setSelectedUsers(usersCopy);
                })
                .catch((error: AxiosError) => {
                    switch (error.response?.status) {
                        case 409:
                            openAlertModal();
                            break;
                    }
                });
        });

        closeWarningModal();
    };

    return (
        <>
            <Button
                size="small"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                disabled={selectedUsers.length === 0}
                onClick={openWarningModal}
            >
                Удалить
            </Button>

            {openAlert && <AlertModal closeAlertModal={closeAlertModal} />}
            {openWarning && <WarningModal deleteUser={deleteUser} closeWarningModal={closeWarningModal} />}
        </>
    );
});
