import { Range } from "react-date-range";
import { DateRange } from "../../DateRange";
import { DateFilters } from "../../../module/Enum/DateFilters";
import { useDateFilter, useFormCriteria } from "./StatisticsForm";

export const StatisticsDate = () => {
    const { setDateFilter } = useDateFilter();
    const { formCriteria, setFormCriteria, setCriteriaEdit } = useFormCriteria();

    const changeDate = (range: Range[]): void => {
        setCriteriaEdit(true);

        range.forEach((value: Range): void => {
            setDateFilter(value.key as DateFilters);

            setFormCriteria({
                ...formCriteria,
                dateFrom: value.startDate as Date,
                dateTo: value.endDate as Date,
            });
        });
    };

    return <DateRange startDate={formCriteria.dateFrom} endDate={formCriteria.dateTo} changeDate={changeDate} />;
};
