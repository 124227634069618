import { action } from "mobx";
import { observer } from "mobx-react";
import { useUrlParams } from "./";
import { useDefaultRule } from "../";
import { useStores } from "../../../../../stores";
import { Campaign, PercentageRoutingRule, RuleDestinationTypeUrl } from "../../../../../entities/CampaignEdit";
import { Button } from "@mui/material";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";

interface Props {
    parameterIndex: number;
}

export const UrlParamDeleteButton = observer(({ parameterIndex }: Props) => {
    const { rule, ruleIndex } = useDefaultRule();
    const { urlParams, setUrlParams } = useUrlParams();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const [url] = (rule.destination as RuleDestinationTypeUrl).url
        ? (rule.destination as RuleDestinationTypeUrl).url.split("?")
        : [];

    const deleteUrlParam = (): void => {
        const newUrlParams: string[] = JSON.parse(JSON.stringify(urlParams));
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        newUrlParams.splice(parameterIndex, 1);
        (newRule.destination as RuleDestinationTypeUrl).url =
            newUrlParams.length > 0 ? [url, newUrlParams.join("&")].join("?") : url;
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        setUrlParams(newUrlParams);
        changeCampaign(newCampaign);
    };

    return (
        <Button color="error" onClick={action(deleteUrlParam)}>
            <DeleteSweepOutlinedIcon />
        </Button>
    );
});
