import { MouseEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { Campaign } from "../../../../../entities/CampaignEdit";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const hoursArray: number[] = new Array(24).fill(1).map((number: number, index: number) => index);

export const ScheduleHours = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const changeScheduleHours = (event: MouseEvent<HTMLElement>, newArray: number[]): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.postbackSettings.schedule = newArray;

        changeCampaign(newCampaign);
    };

    return (
        <ToggleButtonGroup
            className="shedule__group"
            value={campaign.postbackSettings.schedule}
            onChange={action(changeScheduleHours)}
        >
            {hoursArray.map((hour: number) => (
                <ToggleButton className="shedule__hour" key={hour} value={hour}>
                    {hour < 10 ? String(hour).padStart(2, "0") : hour}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
});
