import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { DefaultSort, ParameterSort } from "../../../../entities/Report";
import { SortField, SortDirectionField, SortDeleteButton, SortAddButton } from "./";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography, AccordionActions, AccordionDetails, AccordionSummary } from "@mui/material";

export const ReportSort = observer(() => {
    const { sort } = useStores().reportStore.reportRequest;

    return (
        <Accordion className="mb-4">
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="bg-light border-bottom border-secondary">
                <Typography>Сортировка</Typography>
            </AccordionSummary>
            <AccordionDetails className="d-flex flex-column">
                <div>
                    {sort.map((sortItem: DefaultSort | ParameterSort, sortIndex: number) => (
                        <div key={sortIndex} className="d-flex align-items-end mt-2">
                            <div className="col pl-0">
                                <SortField sortItem={sortItem} />
                            </div>

                            <div className="col">
                                <SortDirectionField sortIndex={sortIndex} sortItem={sortItem} />
                            </div>
                            <div>
                                <SortDeleteButton sortIndex={sortIndex} />
                            </div>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
            <AccordionActions>
                <SortAddButton />
            </AccordionActions>
        </Accordion>
    );
});
