import { AxiosResponse } from "axios";
import { TeamsData } from "../entities/Teams";
import { TeamsApi } from "../services/api/TeamsApi";
import { makeAutoObservable, runInAction } from "mobx";

export interface TeamsStoreInterface {
    teamsData: TeamsData;
    findTeams(): Promise<TeamsData>;
    removeTeams(teamId: number): Promise<AxiosResponse>;
}

const { getTeams, deleteTeam } = TeamsApi;

export class TeamsStore implements TeamsStoreInterface {
    public teamsData: TeamsData = {
        items: [],
    };

    constructor() {
        makeAutoObservable(this);
    }

    public findTeams = async (): Promise<TeamsData> => {
        await getTeams().then((response: AxiosResponse<TeamsData>) => {
            runInAction(() => {
                this.teamsData = response.data;
            });
        });
        return this.teamsData;
    };

    public removeTeams = async (teamId: number): Promise<AxiosResponse> => {
        return await deleteTeam(teamId).then((response: AxiosResponse) => {
            return runInAction(() => {
                this.findTeams();
                return response;
            });
        });
    };
}
