import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Campaign, RoutingRule } from "../../../../entities/CampaignEdit";
import { DestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Button } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export const RuleAddButton = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const addRule = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = {
            destination: {
                type: DestinationType.OFFER,
                offerId: -1,
                landingId: null,
            },
            conditions: [],
        };

        newCampaign.routingRules.push(newRule);

        changeCampaign(newCampaign);
    };

    return (
        <Button variant="outlined" color="success" startIcon={<AddBoxOutlinedIcon />} onClick={action(addRule)}>
            Добавить правило
        </Button>
    );
});
