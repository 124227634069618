import { TeamEditSaveButton, TeamEditSaveAndCloseButton } from ".";

interface Props {
    closeTeamEdit(): void;
}

export const TeamEditFooter = ({ closeTeamEdit }: Props) => (
    <div className="modal-footer">
        <TeamEditSaveButton />
        <TeamEditSaveAndCloseButton closeTeamEdit={closeTeamEdit} />
    </div>
);
