import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useReportData } from "../";
import { ReportData } from "../../../entities/Report";

interface Props {
    calculationCvr(cvr: number, deltaCvr: number): any;
    calculationCount(value: number, deltaValue: number): any;
    calculationToFixed(value: number, deltaValue: number): any;
}

export const TableFooter = observer(({ calculationCvr, calculationCount, calculationToFixed }: Props) => {
    const { groupBy } = useStores().reportStore.reportRequest;
    const { summary } = useReportData().reportData as ReportData;

    return (
        <tfoot>
            <tr>
                <td colSpan={groupBy.length}>Итог</td>
                <td>
                    {summary.clickCount} {calculationCount(summary.clickCount, summary.deltaClickCount)}
                </td>
                <td>
                    {summary.leadCount} {calculationCount(summary.leadCount, summary.deltaLeadCount)}
                </td>
                <td>
                    {summary.postbackCount} {calculationCount(summary.postbackCount, summary.deltaPostbackCount)}
                </td>
                <td>
                    {summary.landingClickCount}{" "}
                    {calculationCount(summary.landingClickCount, summary.deltaLandingClickCount)}
                </td>
                <td>
                    {summary.revenue.toFixed(2)} {calculationToFixed(summary.revenue, summary.deltaRevenue)}
                </td>
                <td>
                    {summary.cost.toFixed(2)} {calculationToFixed(summary.cost, summary.deltaCost)}
                </td>
                <td>
                    {summary.cvr.toFixed(2)}% {calculationCvr(summary.cvr, summary.deltaCvr)}
                </td>
            </tr>
        </tfoot>
    );
});
