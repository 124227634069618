import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useCondition } from "../";
import { useRoutingRule } from "../../";
import { useStores } from "../../../../../../stores";
import { useValidations } from "../../../../../../validation";
import { CampaignValidation } from "../../../../../../validation/CampaignEditValidation";
import { Campaign, Condition, RoutingRule } from "../../../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const ConditionIp = observer(() => {
    const { rule, ruleIndex } = useRoutingRule();
    const { condition, conditionIndex } = useCondition();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldIpTo: string = `ipTo${ruleIndex}${conditionIndex}`;
    const fieldIpFrom: string = `ipFrom${ruleIndex}${conditionIndex}`;

    const changeIpFrom = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.ipFrom = event.target.value;
        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setConditionIpFromValidation();
    };

    const changeIpTo = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.ipTo = event.target.value;
        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setConditionIpToValidation();
    };

    const setConditionIpFromValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions[fieldIpFrom] = false;

        changeValidation(newValidation);
    };

    const setConditionIpToValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions[fieldIpTo] = false;

        changeValidation(newValidation);
    };

    return (
        <div className="d-flex">
            <div className="d-flex align-items-center w-100 mr-3">
                <small className="text-muted text-nowrap mr-3">c</small>
                <TextField
                    fullWidth
                    variant="standard"
                    value={condition.ipFrom}
                    error={validation.routingRuleConditions[fieldIpFrom]}
                    helperText={validation.routingRuleConditions[fieldIpFrom] && "Поле не может быть пустым"}
                    onChange={action(changeIpFrom)}
                />
            </div>
            <div className="d-flex align-items-center w-100">
                <small className="text-muted text-nowrap mr-3">по</small>
                <TextField
                    fullWidth
                    variant="standard"
                    value={condition.ipTo}
                    error={validation.routingRuleConditions[fieldIpTo]}
                    helperText={validation.routingRuleConditions[fieldIpTo] && "Поле не может быть пустым"}
                    onChange={action(changeIpTo)}
                />
            </div>
        </div>
    );
});
