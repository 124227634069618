import { useState, useEffect, ChangeEvent } from "react";
import { observer } from "mobx-react";
import { LandingSwitch, LandingSelect } from "./";
import { useStores } from "../../../../../stores";
import { useRoutingRule } from "../CampaignRoutingRules";
import { useValidations } from "../../../../../validation";
import { CampaignValidation } from "../../../../../validation/CampaignEditValidation";
import { DestinationType } from "../../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Campaign, RoutingRule, RuleDestinationTypeOffer } from "../../../../../entities/CampaignEdit";

export const RuleLanding = observer(() => {
    const [open, setOpen] = useState<boolean>(false);
    const { rule, ruleIndex } = useRoutingRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `routingRuleLanding${ruleIndex}`;

    useEffect(() => {
        if (rule.destination.type === DestinationType.OFFER) {
            rule.destination.landingId === null ? setOpen(false) : setOpen(true);
        }
    }, [rule.destination]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const checked: boolean = event.target.checked;

        setOpen(checked);
        changeLandingId(checked);
        setRoutingRuleLandingValidation();
    };

    const changeLandingId = (checked: boolean): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));

        (newRule.destination as RuleDestinationTypeOffer).landingId = checked ? -1 : null;
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
    };

    const setRoutingRuleLandingValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));
        newValidation.routingRules[fieldName] = false;
        changeValidation(newValidation);
    };

    return (
        <div className="d-flex mt-2 mb-2">
            <LandingSwitch open={open} handleChange={handleChange} />
            {open && <LandingSelect />}
        </div>
    );
});
