import { AxiosResponse } from "axios";
import { OffersApi } from "../services/api/OffersApi";
import { makeAutoObservable, runInAction } from "mobx";
import { Offer, NewOffer, OfferRequest } from "../entities/OfferEdit";

export interface OfferEditStoreInterface {
    offer: NewOffer | Offer;
    isChangedData: boolean;
    cleanUpOfferEdit(): void;
    saveOffer(): Promise<Offer>;
    changeOffer(newOffer: Offer): void;
    findOffer(offerId: string | number): Promise<Offer>;
    cloneOffer(offerId: string | number): Promise<NewOffer>;
}

const DEFAULT_OFFER: NewOffer = {
    title: "",
    urlPattern: "",
    payout: 0,
};

const { getOffer, postOffer, putOffer } = OffersApi;

export class OfferEditStore implements OfferEditStoreInterface {
    public offer: NewOffer | Offer = DEFAULT_OFFER;
    private initialOffer: NewOffer | Offer = DEFAULT_OFFER;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return JSON.stringify(this.offer) === JSON.stringify(this.initialOffer);
    }

    public changeOffer = (newOffer: Offer): void => {
        this.offer = newOffer;
    };

    public cleanUpOfferEdit = (): void => {
        this.offer = DEFAULT_OFFER;
        this.initialOffer = DEFAULT_OFFER;
    };

    public findOffer = async (offerId: string | number): Promise<Offer> => {
        await getOffer(offerId).then((response: AxiosResponse<Offer>) => {
            runInAction(() => {
                this.offer = response.data;
                this.initialOffer = response.data;
            });
        });
        return this.offer as Offer;
    };

    public cloneOffer = async (offerId: string | number): Promise<NewOffer> => {
        await getOffer(offerId).then((response: AxiosResponse<Offer>) => {
            runInAction(() => {
                const newOffer: NewOffer = {
                    ...response.data,
                    title: response.data.title + " (clone)",
                };
                delete newOffer.id;
                this.offer = newOffer;
            });
        });
        return this.offer;
    };

    public saveOffer = async (): Promise<Offer> => {
        return this.offer.id === undefined ? this.createOffer() : this.editOffer();
    };

    private createOffer = async (): Promise<Offer> => {
        return await postOffer(this.createOfferRequest()).then((response: AxiosResponse<Offer>) => {
            return runInAction(() => {
                this.offer = response.data;
                this.initialOffer = response.data;

                return response.data;
            });
        });
    };

    private editOffer = async (): Promise<Offer> => {
        return await putOffer(this.offer.id as number, this.createOfferRequest()).then(
            (response: AxiosResponse<Offer>) => {
                return runInAction(() => {
                    this.offer = response.data;
                    this.initialOffer = response.data;

                    return response.data;
                });
            }
        );
    };

    private createOfferRequest = (): OfferRequest => {
        const offerRequest: OfferRequest = {
            title: this.offer.title,
            urlPattern: this.offer.urlPattern,
            payout: this.offer.payout,
        };

        return offerRequest;
    };
}
