import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { SourceParameter } from "../../../../entities/SourceParameters";
import { DefaultSort, ParameterSort } from "../../../../entities/Report";
import { convertField } from "../../../../module/Enum/Statistics/SortField";
import { TextField } from "@mui/material";

interface Props {
    sortItem: DefaultSort | ParameterSort;
}

export const SortField = observer(({ sortItem }: Props) => {
    const { items } = useStores().sourceParametersStore.sourceParameters;
    const subParametersFilter: string[] = items
        .filter((parameter: SourceParameter) => parameter.parameter === (sortItem as ParameterSort).parameter)
        .map((parameter: SourceParameter) => parameter.name);

    return (
        <TextField
            fullWidth
            label="Поле"
            variant="standard"
            className="pointer-events"
            value={convertField[(sortItem as DefaultSort).field] || subParametersFilter}
        />
    );
});
