import { action } from "mobx";
import { observer } from "mobx-react";
import { Modal, Button } from "@mui/material";

interface Props {
    deleteCampaign(): void;
    closeWarningModal(): void;
}

export const WarningModal = observer(({ deleteCampaign, closeWarningModal }: Props) => (
    <div className="modal fade">
        <Modal open onClose={closeWarningModal}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Предупреждение</h5>
                    </div>
                    <div className="modal-body">Вы действительно хотите удалить кампанию?</div>
                    <div className="modal-footer">
                        <Button variant="outlined" onClick={closeWarningModal}>
                            Нет
                        </Button>
                        <Button variant="contained" onClick={action(deleteCampaign)}>
                            Да
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
));
