import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Routes } from "./routes";
import { Layout } from "./components/layout";
import { RootStoreContext, useStores } from "./stores";
import { getToken } from "./module/AxiosRequest/AxiosRequest";
import { RootValidationContext, useValidations } from "./validation";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export const Tracker = observer(() => {
    const [loading, setLoading] = useState<boolean>(true);
    const { findCurrentUser } = useStores().currentUserStore;
    const { isAuthorized, setIsAuthorized } = useStores().authStore;

    useEffect(() => {
        const token: string | null = localStorage.getItem("jwtToken");
        getToken(token !== null ? token : "");
    }, []);

    useEffect(() => {
        findCurrentUser()
            .then((response) => {
                setIsAuthorized(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [findCurrentUser, setIsAuthorized]);

    const loadTracker = (): JSX.Element => {
        if (loading) {
            return LinearIndeterminate();
        } else {
            return renderTracker(isAuthorized);
        }
    };

    return (
        <RootStoreContext.Provider value={useStores()}>
            <RootValidationContext.Provider value={useValidations()}>{loadTracker()}</RootValidationContext.Provider>
        </RootStoreContext.Provider>
    );
});

const renderTracker = (isAuthorized: boolean): JSX.Element => {
    return (
        <>
            {isAuthorized && <Layout />}
            <Routes />
        </>
    );
};

const LinearIndeterminate = (): JSX.Element => {
    return (
        <Box sx={{ width: "100%" }}>
            <LinearProgress />
        </Box>
    );
};
