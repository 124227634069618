import { AxiosResponse } from "axios";
import { Domain } from "../entities/DomainEdit";
import { makeAutoObservable, runInAction } from "mobx";
import { DomainsApi } from "../services/api/DomainsApi";

export interface DomainEditStoreInterface {
    domain: Domain;
    isChangedData: boolean;
    setDefaultDomain(): void;
    saveDomain(): Promise<Domain>;
    changeDomain(newDomain: Domain): void;
    findDomain(domainId: string): Promise<Domain>;
}

const DEFAULT_DOMAIN: Domain = {
    domain: "",
    isDefault: false,
};

const { getDomain, postDomain, putDomain } = DomainsApi;

export class DomainEditStore implements DomainEditStoreInterface {
    public domain: Domain = DEFAULT_DOMAIN;
    private initialDomain: Domain = DEFAULT_DOMAIN;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return JSON.stringify(this.domain) === JSON.stringify(this.initialDomain);
    }

    public setDefaultDomain = (): void => {
        this.domain = DEFAULT_DOMAIN;
        this.initialDomain = DEFAULT_DOMAIN;
    };

    public changeDomain = (newDomain: Domain): void => {
        this.domain = newDomain;
    };

    public findDomain = async (domainId: string): Promise<Domain> => {
        await getDomain(domainId).then((response: AxiosResponse<Domain>) => {
            runInAction(() => {
                this.domain = response.data;
                this.initialDomain = response.data;
            });
        });
        return this.domain;
    };

    public saveDomain = async (): Promise<Domain> => {
        return this.domain.id === undefined ? this.createDomain() : this.editDomain(this.domain.id);
    };

    private createDomain = async (): Promise<Domain> => {
        return await postDomain(this.domain).then((response: AxiosResponse<Domain>) => {
            return runInAction(() => {
                this.domain = response.data;
                this.initialDomain = response.data;

                return response.data;
            });
        });
    };

    private editDomain = async (domainId: number): Promise<Domain> => {
        return await putDomain(domainId, this.domain).then((response: AxiosResponse<Domain>) => {
            return runInAction(() => {
                this.domain = response.data;
                this.initialDomain = response.data;

                return response.data;
            });
        });
    };
}
