import { action } from "mobx";
import { observer } from "mobx-react";
import { useDefaultRule } from "./";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Campaign } from "../../../../entities/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

export const RuleDeleteButton = observer(() => {
    const { ruleIndex } = useDefaultRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const deleteRule = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.percentageRoutingRules.splice(ruleIndex, 1);

        deleteValidation();
        changeCampaign(newCampaign);
    };

    const deleteValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.percentageRoutingRules = {};

        changeValidation(newValidation);
    };

    return (
        <Button color="error" onClick={action(deleteRule)}>
            <DeleteForeverOutlinedIcon />
        </Button>
    );
});
