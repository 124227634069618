import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../../stores/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TextField, InputAdornment } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

export const Links = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { campaignLinks } = useStores().campaignLinksStore;

    const copyText = (): void => {
        enqueueSnackbar("Ссылка скопирована!", { variant: "success", autoHideDuration: 1500 });
    };

    return (
        <>
            <strong>Ссылка для источника</strong>
            <TextField
                disabled
                fullWidth
                multiline
                variant="standard"
                value={campaignLinks.click}
                helperText={!campaignLinks.click && "Для получения ссылки необходимо сохранить кампанию"}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" className="btn" onClick={copyText}>
                            <CopyToClipboard text={campaignLinks.click}>
                                <FileCopyOutlinedIcon />
                            </CopyToClipboard>
                        </InputAdornment>
                    ),
                    style: {
                        fontSize: 14,
                    },
                }}
            />

            <strong>Postback URL</strong>
            <TextField
                disabled
                fullWidth
                multiline
                variant="standard"
                value={campaignLinks.postback}
                helperText={!campaignLinks.postback && "Для получения ссылки необходимо сохранить кампанию"}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" className="btn" onClick={copyText}>
                            <CopyToClipboard text={campaignLinks.postback}>
                                <FileCopyOutlinedIcon />
                            </CopyToClipboard>
                        </InputAdornment>
                    ),
                    style: {
                        fontSize: 14,
                    },
                }}
            />

            <strong>Click URL</strong>
            <TextField
                disabled
                fullWidth
                multiline
                variant="standard"
                value={campaignLinks.landing}
                helperText={!campaignLinks.landing && "Для получения ссылки необходимо сохранить кампанию"}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start" className="btn" onClick={copyText}>
                            <CopyToClipboard text={campaignLinks.landing}>
                                <FileCopyOutlinedIcon />
                            </CopyToClipboard>
                        </InputAdornment>
                    ),
                    style: {
                        fontSize: 14,
                    },
                }}
            />
        </>
    );
});
