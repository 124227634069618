import { makeAutoObservable } from "mobx";
import { Team } from "../entities/TeamEdit";

export interface TeamEditValidationInterface {
    validation: TeamValidation;
    setDefualtValidation(): void;
    checkValidation(team: Team): boolean;
    changeValidation(newValidation: TeamValidation): void;
}

export interface TeamValidation {
    title: boolean;
}

const DEFAULT_VALIDATION: TeamValidation = {
    title: false,
};

export class TeamEditValidation implements TeamEditValidationInterface {
    public validation: TeamValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: TeamValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (team: Team): boolean => {
        !team.title ? (this.validation.title = true) : (this.validation.title = false);

        return Object.values(this.validation).includes(true);
    };
}
