import { useState, useEffect, SyntheticEvent } from "react";
import { useDefaultRule } from "./";
import { observer } from "mobx-react";
import { runInAction, action } from "mobx";
import { useStores } from "../../../../stores";
import { OfferEdit } from "../../../OfferEdit";
import { useOffers } from "../../useOffers.hook";
import { Offer } from "../../../../entities/OfferEdit";
import { useValidations } from "../../../../validation";
import { OffersCriteria, OfferItem } from "../../../../entities/Offers";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { convertDestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Campaign, PercentageRoutingRule, RuleDestinationTypeOffer } from "../../../../entities/CampaignEdit";
import { Autocomplete, FormControl, InputAdornment, IconButton, TextField, Tooltip } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export const RuleOffer = observer(() => {
    const [inputValue, setInputValue] = useState<string>("");
    const [offer, setOffer] = useState<OfferItem | null>(null);
    const [openOfferModal, setOpenOfferModal] = useState<boolean>(false);
    const [allowedСhangeСriteria, setAllowedСhangeСriteria] = useState<boolean>(false);
    const { rule, ruleIndex } = useDefaultRule();
    const { criteria, offersList, setCriteria } = useOffers();
    const { findOffer, cleanUpOfferEdit } = useStores().offerEditStore;
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `percentageRuleOffer${ruleIndex}`;
    const offerId: number = (rule.destination as RuleDestinationTypeOffer).offerId;

    useEffect(() => {
        if (offerId !== -1) {
            findOffer(offerId).then((reponse: Offer) => {
                const newOffer: OfferItem = {
                    id: reponse.id,
                    title: reponse.title,
                    userId: reponse.userId,
                    urlPattern: reponse.urlPattern,
                };

                setOffer(newOffer);
                cleanUpOfferEdit();
                setAllowedСhangeСriteria(true);
            });
        } else {
            setOffer(null);
            setAllowedСhangeСriteria(true);
        }

        return cleanUpOfferEdit;
    }, [offerId, findOffer, cleanUpOfferEdit]);

    const openOfferEdit = (): void => setOpenOfferModal(true);

    const closeOfferEdit = (): void => setOpenOfferModal(false);

    const changeRuleOffer = (event: SyntheticEvent<Element, Event>, value: OfferItem | null): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        (newRule.destination as RuleDestinationTypeOffer).offerId = value !== null ? value.id : -1;
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setPercentageRuleOfferValidation();
    };

    const changeInputValue = (event: SyntheticEvent<Element, Event>, newInputValue: string | undefined): void => {
        const newCriretia: OffersCriteria = JSON.parse(JSON.stringify(criteria));
        const inputValue: string = newInputValue !== undefined ? newInputValue : "";
        const inputValueLength: boolean = inputValue.length < 1 || inputValue.length > 2;
        const checkSelectedItem: boolean = event !== null && typeof (event.target as any).value === "number";

        if (allowedСhangeСriteria && inputValueLength && !checkSelectedItem) {
            newCriretia.search = inputValue;
            setCriteria(newCriretia);
        }

        setInputValue(inputValue);
        setPercentageRuleOfferValidation();
    };

    const setNewOffer = (offerId: number): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        (newRule.destination as RuleDestinationTypeOffer).offerId = offerId;
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        setAllowedСhangeСriteria(false);
        changeCampaign(newCampaign);
        setPercentageRuleOfferValidation();
    };

    const setPercentageRuleOfferValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));
        newValidation.percentageRoutingRules[fieldName] = false;
        changeValidation(newValidation);
    };

    return (
        <FormControl fullWidth className="rule-offer" error={validation.percentageRoutingRules[fieldName]}>
            <Autocomplete
                fullWidth
                value={offer}
                id="offer-search"
                options={offersList}
                inputValue={inputValue}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onInputChange={action(changeInputValue)}
                onChange={(event, value) => runInAction(() => changeRuleOffer(event, value))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        variant="outlined"
                        placeholder="Введите минимум 3 символа"
                        error={validation.percentageRoutingRules[fieldName]}
                        label={convertDestinationType[rule.destination.type]}
                        helperText={validation.percentageRoutingRules[fieldName] && "Необходимо выбрать оффер"}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <div>
                                    <div className="MuiIcon">{params.InputProps.endAdornment}</div>
                                    <InputAdornment position="end">
                                        <Tooltip title="Создать новый оффер">
                                            <IconButton size="small" onClick={openOfferEdit}>
                                                <AddBoxOutlinedIcon
                                                    color={
                                                        validation.percentageRoutingRules[fieldName]
                                                            ? "error"
                                                            : "success"
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                </div>
                            ),
                        }}
                    />
                )}
            />

            {openOfferModal && (
                <OfferEdit campaignEditNotice={true} setNewOffer={setNewOffer} closeOfferEdit={closeOfferEdit} />
            )}
        </FormControl>
    );
});
