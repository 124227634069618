import { useStatisticsContext } from "../Statistics";

export const TableFooter = () => {
    const { groupBy } = useStatisticsContext().criteria;
    const { summary } = useStatisticsContext().statisticsData;

    return (
        <tfoot>
            <tr>
                <td colSpan={groupBy.length}>Итог</td>
                <td>{summary.clickCount}</td>
                <td>{summary.leadCount}</td>
                <td>{summary.postbackCount}</td>
                <td>{summary.landingClickCount}</td>
                <td>{summary.revenue.toFixed(2)}</td>
                <td>{summary.cost.toFixed(2)}</td>
                <td>{summary.cvr.toFixed(2)}%</td>
            </tr>
        </tfoot>
    );
};
