import { AxiosResponse } from "axios";
import { Team } from "../../entities/TeamEdit";
import { TeamsData } from "../../entities/Teams";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";

interface TeamsApiInterface {
    getTeams(): Promise<AxiosResponse<TeamsData>>;
    deleteTeam(teamId: number): Promise<AxiosResponse>;
    getTeam(teamId: string): Promise<AxiosResponse<Team>>;
    postTeam(newTeam: Team): Promise<AxiosResponse<Team>>;
    putTeam(teamId: number, newTeam: Team): Promise<AxiosResponse<Team>>;
}

export const TeamsApi: TeamsApiInterface = {
    getTeams: async (): Promise<AxiosResponse<TeamsData>> => await httpClient.get<TeamsData>(`/api/v1/team`),

    getTeam: async (teamId: string): Promise<AxiosResponse<Team>> =>
        await httpClient.get<Team>(`/api/v1/team/${teamId}`),

    postTeam: async (newTeam: Team): Promise<AxiosResponse<Team>> =>
        await httpClient.post<Team>(`/api/v1/team`, newTeam),

    putTeam: async (teamId: number, newTeam: Team): Promise<AxiosResponse<Team>> =>
        await httpClient.put<Team>(`/api/v1/team/${teamId}`, newTeam),

    deleteTeam: async (teamId: number): Promise<AxiosResponse> => await httpClient.delete(`/api/v1/team/${teamId}`),
};
