import { action } from "mobx";
import { observer } from "mobx-react";
import { useCondition } from "./";
import { useRoutingRule } from "../";
import { useStores } from "../../../../../stores";
import { useValidations } from "../../../../../validation";
import { Campaign, RoutingRule } from "../../../../../entities/CampaignEdit";
import { CampaignValidation } from "../../../../../validation/CampaignEditValidation";
import { Button } from "@mui/material";
import DeleteSweepOutlinedIcon from "@mui/icons-material/DeleteSweepOutlined";

export const ConditionDeleteButton = observer(() => {
    const { conditionIndex } = useCondition();
    const { rule, ruleIndex } = useRoutingRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const deleteCondition = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));

        newRule.conditions.splice(conditionIndex, 1);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        deleteValidation();
        changeCampaign(newCampaign);
    };

    const deleteValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions = {};

        changeValidation(newValidation);
    };

    return (
        <Button color="error" onClick={action(deleteCondition)}>
            <DeleteSweepOutlinedIcon />
        </Button>
    );
});
