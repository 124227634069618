import { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { User } from "../../../../entities/UserEdit";
import { UserDeleteButton, UserAddButton } from "./";

export const LandingAllowedUsers = observer(() => {
    const [userList, setUserList] = useState<User[]>([]);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const { findUser } = useStores().userEditStore;
    const {
        landingIsFound,
        landing: { allowedUserIds },
    } = useStores().landingEditStore;

    const loadUsersByIds = useCallback(
        async (userIds: number[]) => {
            const result: User[] = [];

            for (const userId of userIds) {
                const user: User = await findUser(userId);
                result.push(user);
            }

            setUserList(result);
        },
        [findUser]
    );

    useEffect(() => {
        if (landingIsFound && !isInitialized) {
            setIsInitialized(true);
            loadUsersByIds(allowedUserIds);
        }
    }, [landingIsFound, isInitialized, allowedUserIds, loadUsersByIds]);

    const addUser = (userId: number): void => {
        findUser(userId).then((response: User) => {
            const userListCopy: User[] = [...userList];
            userListCopy.push(response);
            setUserList(userListCopy);
        });
    };

    const deleteUser = (userId: number): void => {
        const userListCopy: User[] = userList.filter((user: User) => user.id !== userId);
        setUserList(userListCopy);
    };

    return (
        <div className="d-flex flex-wrap align-items-center mt-4">
            <h5 className="mr-4">Доступно для пользователей:</h5>

            {userList.map((user: User) => (
                <div key={user.id} className="user user_color">
                    <div className="mr-2">
                        {user.fullName.lastName} {user.fullName.firstName}
                    </div>
                    <div>
                        <UserDeleteButton userId={user.id} deleteUser={deleteUser} />
                    </div>
                </div>
            ))}

            <div>
                <UserAddButton addUser={addUser} />
            </div>
        </div>
    );
});
