import { useEffect, useState } from "react";
import { useStatisticsContext } from "../Statistics";
import { DefaultGrouping } from "../../../module/Enum/Grouping/Grouping";
import { StatisticsItem, GroupParameter } from "../../../entities/Statistics";

interface MappingData {
    [key: string]: number;
}

interface TreeData {
    [key: string]: TreeItem;
}

interface TreeItem {
    id: string;
    parentId: string;
    groupValues: string[];
    clickCount: number;
    leadCount: number;
    postbackCount: number;
    landingClickCount: number;
    cvr: number;
    revenue: number;
    cost: number;
    children: TreeItem[];
}

interface NewStatisticsItem {
    groupValues: string[];
    clickCount: number;
    leadCount: number;
    postbackCount: number;
    landingClickCount: number;
    cvr: number;
    revenue: number;
    cost: number;
}

export const TableBody = () => {
    const { groupBy } = useStatisticsContext().criteria;
    const { items } = useStatisticsContext().statisticsData;
    const [newStatisticsData, setNewStatisticsData] = useState<NewStatisticsItem[]>([]);

    useEffect(() => {
        const newData: TreeData = {};

        const createKey = (item: StatisticsItem, index: number): string => {
            return item.groupParameters
                .map((groupParameterItem: GroupParameter, gIndex: number) =>
                    index >= gIndex ? "key_" + groupParameterItem.value : ""
                )
                .filter((key: string) => key !== "")
                .join("&_&");
        };

        const createNewData = (item: StatisticsItem): void => {
            item.groupParameters.forEach((groupParameter: GroupParameter, index: number): void => {
                if (!newData[createKey(item, index)]) {
                    newData[createKey(item, index)] = {
                        id: createKey(item, index),
                        parentId: createKey(item, index - 1),
                        groupValues: item.groupParameters.map(
                            (groupParameter: GroupParameter, gIndex: number): string => {
                                let transformValue: string = "";

                                switch (groupParameter.field) {
                                    case DefaultGrouping.HOUR:
                                        transformValue = groupParameter.value + ":00";
                                        break;

                                    case DefaultGrouping.DATE:
                                        transformValue = groupParameter.value.split("-").reverse().join(".");
                                        break;

                                    default:
                                        transformValue = groupParameter.value;
                                        break;
                                }

                                return gIndex === index ? transformValue : "";
                            }
                        ),
                        clickCount: item.clickCount,
                        leadCount: item.leadCount,
                        postbackCount: item.postbackCount,
                        landingClickCount: item.landingClickCount,
                        cvr: item.cvr,
                        revenue: item.revenue,
                        cost: item.cost,
                        children: [],
                    };
                } else {
                    newData[createKey(item, index)].clickCount += item.clickCount;
                    newData[createKey(item, index)].leadCount += item.leadCount;
                    newData[createKey(item, index)].postbackCount += item.postbackCount;
                    newData[createKey(item, index)].revenue += item.revenue;
                    newData[createKey(item, index)].cost += item.cost;
                }
                newData[createKey(item, index)].cvr =
                    newData[createKey(item, index)].clickCount > 0
                        ? (newData[createKey(item, index)].leadCount / newData[createKey(item, index)].clickCount) * 100
                        : newData[createKey(item, index)].cvr;
            });
        };

        items.forEach((item: StatisticsItem): void => {
            createNewData(item);
        });

        const mappingId: MappingData = Object.values(newData).reduce(
            (object: MappingData, item: TreeItem, index: number): MappingData => {
                object[item.id] = index;
                return object;
            },
            {}
        );

        const createTree = (): TreeItem[] => {
            const roots: TreeItem[] = [];
            const list: TreeItem[] = Object.values(newData);

            for (let i: number = 0; i < list.length; i += 1) {
                let node: TreeItem = list[i];
                node.parentId !== "" ? list[mappingId[node.parentId]].children.push(node) : roots.push(node);
            }

            return roots;
        };

        const createResultData = (): NewStatisticsItem[] => {
            const result: NewStatisticsItem[] = [];

            const extractChildren = (item: TreeItem) => {
                const newItem: NewStatisticsItem = {
                    groupValues: item.groupValues,
                    clickCount: item.clickCount,
                    leadCount: item.leadCount,
                    postbackCount: item.postbackCount,
                    landingClickCount: item.landingClickCount,
                    cvr: item.cvr,
                    revenue: item.revenue,
                    cost: item.cost,
                };

                result.push(newItem);

                if (item.children.length > 0) item.children.map((child: TreeItem) => extractChildren(child));
            };

            createTree().map((item: TreeItem) => extractChildren(item));

            return result;
        };

        setNewStatisticsData(createResultData());
    }, [items]);

    return (
        <tbody>
            {newStatisticsData.map((item: NewStatisticsItem, index: number) => (
                <tr
                    key={index}
                    className={
                        groupBy.length > 1 && item.groupValues[0] !== "" ? "table-active table__text-weight" : ""
                    }
                >
                    {item.groupValues.map((value: string, gIndex: number) => (
                        <td key={gIndex}>{value}</td>
                    ))}
                    <td>{item.clickCount}</td>
                    <td>{item.leadCount}</td>
                    <td>{item.postbackCount}</td>
                    <td>{item.landingClickCount}</td>
                    <td>{item.revenue.toFixed(2)}</td>
                    <td>{item.cost.toFixed(2)}</td>
                    <td>{item.cvr.toFixed(2)}%</td>
                </tr>
            ))}
        </tbody>
    );
};
