import { action } from "mobx";
import { format } from "date-fns";
import { observer } from "mobx-react";
import { Range } from "react-date-range";
import { useStores } from "../../../stores";
import { DateRange } from "../../DateRange";
import { ReportRequest } from "../../../entities/Report";

export const ReportDate = observer(() => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;

    const changeDate = (range: Range[]): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));

        range.forEach((value: Range) => {
            newReportRequest.period = {
                from: format(value.startDate as Date, "yyyy-MM-dd"),
                to: format(value.endDate as Date, "yyyy-MM-dd"),
            };
        });

        changeReportRequest(newReportRequest);
    };

    return (
        <DateRange
            startDate={reportRequest.period.from}
            endDate={reportRequest.period.to}
            changeDate={action(changeDate)}
        />
    );
});
