import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { OffersCriteria } from "../../../entities/Offers";
import { SortField } from "../../../module/Enum/Offers/SortField";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
    closeOfferEdit(): void;
    setNewOffer?(offerId: number): void;
}

interface ParamsProps {
    mode: Mode;
}

export const OfferEditSaveAndCloseButton = observer(({ setNewOffer, closeOfferEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { enqueueSnackbar } = useSnackbar();
    const { offer, saveOffer } = useStores().offerEditStore;
    const { criteria, changeCriteria } = useStores().offersStore;
    const { checkValidation } = useValidations().offerEditValidation;

    const filterOffersTable = (): void => {
        const newCriteria: OffersCriteria = { ...criteria };
        newCriteria.page = 1;
        newCriteria.sort = {
            direction: SortDirection.DESC,
            field: SortField.ID,
        };
        changeCriteria(newCriteria);
    };

    const saveAndClose = (): void => {
        checkValidation(offer)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveOffer().then((response) => {
                  if (response) {
                      if (setNewOffer) {
                          setNewOffer(response.id as number);
                      } else {
                          if (mode === Mode.ADD) filterOffersTable();
                      }
                      closeOfferEdit();
                      enqueueSnackbar("Оффер сохранен", { variant: "success", autoHideDuration: 1500 });
                  }
              });
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(saveAndClose)}>
            Сохранить и выйти
        </Button>
    );
});
