import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useCondition } from "../";
import { useRoutingRule } from "../../";
import { useStores } from "../../../../../../stores";
import { useValidations } from "../../../../../../validation";
import { CampaignValidation } from "../../../../../../validation/CampaignEditValidation";
import { Campaign, Condition, RoutingRule } from "../../../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const ConditionUserAgent = observer(() => {
    const { rule, ruleIndex } = useRoutingRule();
    const { condition, conditionIndex } = useCondition();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `${condition.type}${ruleIndex}${conditionIndex}`;

    const changeConditionUserAgent = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.userAgent = event.target.value;
        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setConditionUserAgentValidation();
    };

    const setConditionUserAgentValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions[fieldName] = false;

        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            variant="standard"
            value={condition.userAgent}
            error={validation.routingRuleConditions[fieldName]}
            helperText={validation.routingRuleConditions[fieldName] && "Поле не может быть пустым"}
            onChange={action(changeConditionUserAgent)}
        />
    );
});
