import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Campaign } from "../../../../entities/CampaignEdit";
import { LimitType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { TextField } from "@mui/material";

export const PostbackLimitValue = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const changePostbackLimitValue = (event: ChangeEvent<HTMLInputElement>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        switch (true) {
            case newCampaign.postbackSettings.limitType === LimitType.PERCENT:
                if (Number(event.target.value) <= 100) newCampaign.postbackSettings.limitValue = event.target.value;
                break;

            case !/\D/.test(event.target.value):
                newCampaign.postbackSettings.limitValue = event.target.value;
                break;

            case event.target.value === "":
                newCampaign.postbackSettings.limitValue = "0";
                break;
        }

        changeCampaign(newCampaign);
        setPostbackSettingsValidation();
    };

    const setPostbackSettingsValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.postbackSettings = false;

        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            value={+campaign.postbackSettings.limitValue}
            label={campaign.postbackSettings.limitType !== LimitType.PERCENT ? "Лимит" : "Лимит (%)"}
            onChange={action(changePostbackLimitValue)}
        />
    );
});
