import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useUrlParams } from "./";
import { useDefaultRule } from "../";
import { useStores } from "../../../../../stores";
import { Campaign, PercentageRoutingRule, RuleDestinationTypeUrl } from "../../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

interface Props {
    parameter: string;
    parameterIndex: number;
}

export const UrlParamField = observer(({ parameter, parameterIndex }: Props) => {
    const { rule, ruleIndex } = useDefaultRule();
    const { urlParams, setUrlParams } = useUrlParams();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const [url] = (rule.destination as RuleDestinationTypeUrl).url
        ? (rule.destination as RuleDestinationTypeUrl).url.split("?")
        : [];

    const changeUrlParams = (event: ChangeEvent<{ value: string }>): void => {
        const value: string = event.target.value;
        const newUrlParams: string[] = JSON.parse(JSON.stringify(urlParams));
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        if (value.length > 0) newUrlParams.splice(parameterIndex, 1, value);
        (newRule.destination as RuleDestinationTypeUrl).url = [url, newUrlParams.join("&")].join("?");
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        setUrlParams(newUrlParams);
        changeCampaign(newCampaign);
    };

    return <TextField fullWidth value={parameter} variant="standard" onChange={action(changeUrlParams)} />;
});
