import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useLandingsIds } from "../Landings";
import { Landing } from "../../../entities/Landings";
import { Checkbox } from "@mui/material";

export const TableBody = observer(() => {
    const { landingsIds, setLandingsIds } = useLandingsIds();
    const { items } = useStores().landingsStore.landingsData;

    const handleChangeId = (id: number): void => {
        let landingsIdsCopy: number[] = Object.assign([], landingsIds);

        landingsIdsCopy.includes(id)
            ? (landingsIdsCopy = landingsIdsCopy.filter((item: number) => item !== id))
            : landingsIdsCopy.push(id);

        setLandingsIds(landingsIdsCopy);
    };

    return (
        <tbody>
            {items.map((item: Landing) => (
                <React.Fragment key={item.id}>
                    <tr onClick={() => handleChangeId(item.id)}>
                        <td className="align-middle">
                            <Checkbox size="small" checked={landingsIds.includes(item.id)} />
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">{item.title}</td>
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
});
