import { observer } from "mobx-react";
import { useSelectedUsers } from "../Users";
import { useStores } from "../../../stores";
import { User } from "../../../entities/Users";
import { Team } from "../../../entities/Teams";
import { Modal, Button } from "@mui/material";

interface Props {
    closeAlertModal(): void;
}

export const AlertModal = observer(({ closeAlertModal }: Props) => {
    const { selectedUsers } = useSelectedUsers();
    const { items } = useStores().teamsStore.teamsData;

    const createAlertText = (): JSX.Element =>
        selectedUsers.length > 1 ? (
            <div>
                <div>Что ж ты делаешь!</div>
                <div>Нельзя удалять тимлидов команд. </div>
                <div>
                    P.S. Для удаления можно сделать данных тимлидов обычными юзерами, а другого пользователя команд
                    {items.map((team: Team) =>
                        selectedUsers.map((user: User) =>
                            team.id === user.teamId ? <strong> «{team.title}» </strong> : null
                        )
                    )}
                    назначить на роль тимлида
                </div>
            </div>
        ) : (
            <div>
                Что ж ты делаешь! <br />
                Нельзя удалять тимлида команды. <br />
                P.S. Для удаления можно сделать данного тимлида обычным юзером, а другого пользователя команды
                {items.map((team: Team) =>
                    selectedUsers.map((user: User) =>
                        team.id === user.teamId ? <strong> «{team.title}» </strong> : null
                    )
                )}
                назначить на роль тимлида
            </div>
        );

    return (
        <div className="modal fade">
            <Modal open>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">{createAlertText()}</div>

                        <div className="modal-footer">
                            <Button variant="contained" onClick={closeAlertModal}>
                                Ок
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
