import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { LandingsCriteria } from "../../entities/Landings";
import { Pagination, FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";

export const LandingsPagination = observer(() => {
    const { landingsData, criteria, changeCriteria } = useStores().landingsStore;

    const changePage = (event: object, currentPage: number): void => {
        const criteriaCopy: LandingsCriteria = JSON.parse(JSON.stringify(criteria));

        criteriaCopy.page = currentPage;

        changeCriteria(criteriaCopy);
    };

    const changeItemsPerPage = (event: SelectChangeEvent<number>): void => {
        const criteriaCopy: LandingsCriteria = JSON.parse(JSON.stringify(criteria));

        criteriaCopy.page = 1;
        criteriaCopy.itemsPerPage = event.target.value as number;

        changeCriteria(criteriaCopy);
    };

    return (
        <div className="form-row align-items-center pb-4">
            <Pagination
                shape="rounded"
                page={criteria.page}
                className="pagination mr-2"
                count={Math.ceil(landingsData.totalItems / criteria.itemsPerPage) || 1}
                onChange={action(changePage)}
            />

            <label className="mr-3">Кол-во строк</label>

            <FormControl variant="standard">
                <Select value={criteria.itemsPerPage} onChange={action(changeItemsPerPage)}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
});
