import { useState, useEffect } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from "..";
import { useUrlParamsState } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../stores";
import { Mode } from "../../../../../module/Enum/Mode";
import {
    Source,
    SourceParameter,
    SourcePostback,
    SourcePostbackUrlParameter,
} from "../../../../../entities/SourceEdit";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface Props {
    parameterIndex: number;
    parameter: SourcePostbackUrlParameter;
}

interface ParamsProps {
    mode: Mode;
}

export const ParameterValueSelect = observer(({ parameter, parameterIndex }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { urlParams, setUrlParams } = useUrlParamsState();
    const { source, changeSource } = useStores().sourceEditStore;
    const [sourceParameter, setSourceParameter] = useState<string>("");
    const [url] = postback.urlPattern.split("?");

    useEffect(() => {
        const value = "{track.params.";
        const newSourceParameter = parameter.value.slice(value.length, parameter.value.length - 1);
        setSourceParameter(newSourceParameter);
    }, [source.parameters, parameter.value]);

    const changeUrlValue = (event: SelectChangeEvent<string>): void => {
        const value: string = event.target.value as string;
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));
        const newUrlParams: SourcePostbackUrlParameter[] = JSON.parse(JSON.stringify(urlParams));
        const newUrlParam: SourcePostbackUrlParameter = {
            ...parameter,
            value: `{track.params.${value}}`,
        };

        newUrlParams.splice(parameterIndex, 1, newUrlParam);
        newPostback.urlPattern = [
            url,
            newUrlParams.map((param) => [param.parameter, param.value].join("=")).join("&"),
        ].join("?");
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setUrlParams(newUrlParams);
    };

    return (
        <div className="container-fluid">
            <FormControl fullWidth disabled={mode === Mode.VIEW}>
                <InputLabel>Имя параметра</InputLabel>
                <Select label="Имя параметра" value={sourceParameter} onChange={action(changeUrlValue)}>
                    {source.parameters.map((parameter: SourceParameter) => (
                        <MenuItem key={parameter.parameter} value={parameter.parameter}>
                            {parameter.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
});
