import { Mode } from "../../module/Enum/Mode";
import { Users } from "../../components/Users";
import { UserEdit } from "../../components/UserEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    userId: string;
}

export const UsersRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, userId } = useParams<ParamsProps>();

    const closeUserEdit = (): void => {
        history.push("/spa/users");
    };

    switch (mode) {
        case Mode.ADD:
            content = <UserEdit mode={mode} closeUserEdit={closeUserEdit} />;
            break;

        case Mode.EDIT:
            content = <UserEdit mode={mode} userId={userId} closeUserEdit={closeUserEdit} />;
            break;

        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeUserEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Users />
            {content}
        </>
    );
};
