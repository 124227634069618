import { useSelectedOffers } from "../";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

export const OfferCloneButton = () => {
    const { url } = useRouteMatch();
    const { selectedOffers } = useSelectedOffers();
    const [id] = selectedOffers.map((offer) => offer.id);

    return (
        <div className="mr-2">
            <Link to={`${url}/clone/${id}`} className={selectedOffers.length !== 1 ? "link_disabled" : "link"}>
                <Button
                    size="small"
                    variant="contained"
                    startIcon={<FileCopyIcon />}
                    disabled={selectedOffers.length !== 1}
                >
                    Клонировать
                </Button>
            </Link>
        </div>
    );
};
