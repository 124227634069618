import { ChangeEvent } from "react";
import { action } from "mobx";
import { useDefaultRule } from "./";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { Campaign, PercentageRoutingRule } from "../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const RulePercent = observer(() => {
    const { rule, ruleIndex } = useDefaultRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `percentageRulePercent${ruleIndex}`;

    const changeRulePercent = (event: ChangeEvent<{ value: unknown }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        if (Number(event.target.value) <= 100) newRule.percent = Number(event.target.value);
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setPercentageRulePercentValidation();
    };

    const setPercentageRulePercentValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.percentageRoutingRules[fieldName] = false;

        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            value={rule.percent}
            label="Доля трафика (%)"
            error={validation.percentageRoutingRules[fieldName]}
            helperText={validation.percentageRoutingRules[fieldName] && "Доля трафика не может быть равна 0"}
            onChange={action(changeRulePercent)}
        />
    );
});
