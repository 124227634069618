import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { Switch } from "@mui/material";

interface Props {
    open: boolean;
    handleChange(event: ChangeEvent<HTMLInputElement>): void;
}

export const LandingSwitch = observer(({ open, handleChange }: Props) => (
    <div className="d-flex align-items-center mt-2">
        <div className="mr-2">Лендинг</div>
        <div>
            <Switch
                size="small"
                checked={open}
                onChange={action(handleChange)}
                inputProps={{ "aria-label": "controlled" }}
            />
        </div>
    </div>
));
