import { useReportData, usePagination } from "./";
import { Pagination, Select, MenuItem, FormControl, SelectChangeEvent } from "@mui/material";

export const ReportPagination = () => {
    const { reportData } = useReportData();
    const { pagination, setPagination } = usePagination();

    const changePage = (event: object, selectedPage: number): void => {
        setPagination({
            ...pagination,
            currentPage: selectedPage,
        });
    };

    const changeItemsPerPage = (event: SelectChangeEvent<number>): void => {
        setPagination({
            currentPage: 1,
            itemsPerPage: event.target.value as number,
        });
    };

    return (
        <div className="d-flex align-items-center">
            <Pagination
                shape="rounded"
                page={pagination.currentPage}
                className="pagination mr-2 mb-4"
                count={Math.ceil(reportData !== undefined ? reportData.items.length / pagination.itemsPerPage : 1)}
                onChange={changePage}
            />

            <label className="mr-3 mb-4">Кол-во строк</label>

            <FormControl variant="standard" className="mb-4">
                <Select value={pagination.itemsPerPage} onChange={changeItemsPerPage}>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={250}>250</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};
