import { useState } from "react";
import { PasswordResetInitializeData } from "../../../entities/Auth";

interface ValidationText {
    email: string;
    nonExistentMail: string;
}

export interface ValidationHook {
    validation: AuthValidation;
    changeValidation(validation: AuthValidation): void;
    checkValidation(data: PasswordResetInitializeData): boolean;
}

export interface AuthValidation {
    email: boolean;
    nonExistentMail: boolean;
}

export const errorText: ValidationText = {
    email: "Почта должна быть вида «example@mail.com»",
    nonExistentMail: "Неправильно указана почта",
};

export const useValidation = (): ValidationHook => {
    const [validation, setValidation] = useState<AuthValidation>({
        email: false,
        nonExistentMail: false,
    });

    const changeValidation = (validation: AuthValidation): void => {
        setValidation(validation);
    };

    const checkValidation = (data: PasswordResetInitializeData): boolean => {
        const regEmail: RegExp = /\S+@\S+\.\S+/;
        const validationCopy: AuthValidation = JSON.parse(JSON.stringify(validation));

        !regEmail.test(data.email) ? (validationCopy.email = true) : (validationCopy.email = false);

        setValidation(validationCopy);

        return Object.values(validationCopy).includes(true);
    };

    return {
        validation,
        checkValidation,
        changeValidation,
    };
};
