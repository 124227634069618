import { useState, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { SourcePostback } from "../../../../entities/SourceEdit";
import { PostbackParameters } from "./PostbackParameters";
import {
    PostbackGoalName,
    PostbackUrlPattern,
    PostbackIsActive,
    PostbackParametersShowButton,
    PostbackDeleteButton,
    PostbackAddButton,
} from ".";

interface PostbackContextInterface {
    postback: SourcePostback;
    postbackIndex: number;
}

interface IndicesStateContextInterface {
    postbackIndices: number[];
    setPostbackIndices: Dispatch<SetStateAction<number[]>>;
}

const PostbackContext = createContext<PostbackContextInterface>({} as PostbackContextInterface);
const IndicesStateContext = createContext<IndicesStateContextInterface>({} as IndicesStateContextInterface);

export const SourcePostbacks = observer(() => {
    const { postbacks } = useStores().sourceEditStore.source;
    const [postbackIndices, setPostbackIndices] = useState<number[]>([]);

    return (
        <IndicesStateContext.Provider value={{ postbackIndices, setPostbackIndices }}>
            <div className="mt-5">
                <h5>Проброс постбэков на цели</h5>
                {postbacks.map((postback: SourcePostback, postbackIndex: number) => (
                    <PostbackContext.Provider value={{ postback, postbackIndex }} key={postbackIndex}>
                        <div className="d-flex align-items-start mt-3">
                            <PostbackGoalName />
                            <PostbackUrlPattern />
                            <PostbackIsActive />
                            <PostbackParametersShowButton />
                            <PostbackDeleteButton />
                        </div>
                        {postbackIndices.includes(postbackIndex) && <PostbackParameters />}
                    </PostbackContext.Provider>
                ))}
                <PostbackAddButton />
            </div>
        </IndicesStateContext.Provider>
    );
});

export const usePostback = (): PostbackContextInterface => {
    return useContext(PostbackContext);
};

export const useIndicesState = (): IndicesStateContextInterface => {
    return useContext(IndicesStateContext);
};
