import { useRouteMatch, Link } from "react-router-dom";
import { Fab, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
    campaignId: number;
}

export const CampaingEditButton = ({ campaignId }: Props) => {
    const { url } = useRouteMatch();

    return (
        <div className="mr-2">
            <Link to={`${url}/edit/${campaignId}`} className="link">
                <Tooltip title="Изменить" placement="bottom">
                    <Fab size="small" color="primary">
                        <EditIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            </Link>
        </div>
    );
};
