import { useState, useEffect, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { Offer } from "../../../entities/OfferEdit";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const OfferPayout = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { offer, changeOffer } = useStores().offerEditStore;
    const [payoutValue, setPayoutValue] = useState<string>("0");

    useEffect(() => {
        setPayoutValue(String(offer.payout));
    }, [offer.payout]);

    const changePayout = (event: ChangeEvent<{ value: string }>): void => {
        const newOffer: Offer = JSON.parse(JSON.stringify(offer));
        const reg: RegExp = new RegExp(/^\d*\.?\d*$/);
        const stringArray: string[] = event.target.value.split("");

        if (reg.test(event.target.value)) {
            if (stringArray[0] === "0" && stringArray[1] !== "." && stringArray.length > 1) {
                stringArray.splice(0, 1);
            }
            setPayoutValue(stringArray.join(""));
            newOffer.payout = Number(event.target.value);
        }
        if (!event.target.value) setPayoutValue(String(newOffer.payout));

        changeOffer(newOffer);
    };

    return (
        <div className="field mt-5">
            <h5>Payout</h5>
            <TextField
                fullWidth
                value={payoutValue}
                onChange={action(changePayout)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
