import { useState } from "react";
import { useSnackbar } from "notistack";
import { FilterSaveModal } from "./FilterSaveModal";
import { useGroupByValidation } from "../../StatisticsForm";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export const FilterSaveButton = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { checkValidation } = useGroupByValidation();
    const [open, setOpen] = useState<boolean>(false);

    const openSaveModal = (): void => setOpen(true);

    const closeSaveModal = (): void => setOpen(false);

    const checkCriteria = (): void => {
        if (checkValidation()) {
            enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 2000 });
        } else {
            openSaveModal();
        }
    };

    return (
        <div>
            <Button
                size="small"
                variant="contained"
                className="text-nowrap"
                startIcon={<SaveIcon />}
                onClick={checkCriteria}
            >
                Сохранить фильтр
            </Button>
            {open && <FilterSaveModal closeSaveModal={closeSaveModal} />}
        </div>
    );
};
