import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Campaign } from "../../../../entities/CampaignEdit";
import { LimitType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { FormControlLabel, Checkbox } from "@mui/material";

interface Props {
    checkUnlimited: boolean;
}

export const PostbackLimitCheckBox = observer(({ checkUnlimited }: Props) => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const changePostbackLimitValue = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        if (newCampaign.postbackSettings.limitType === LimitType.PERCENT) {
            newCampaign.postbackSettings.limitValue === "100"
                ? (newCampaign.postbackSettings.limitValue = "0")
                : (newCampaign.postbackSettings.limitValue = "100");
        } else {
            if (newCampaign.postbackSettings.limitValue === "-1") {
                newCampaign.postbackSettings.limitValue = "0";
                newCampaign.postbackSettings.probabilityPercent = 100;
            } else {
                newCampaign.postbackSettings.limitValue = "-1";
                newCampaign.postbackSettings.probabilityPercent = 100;
            }
        }

        changeCampaign(newCampaign);
        setPostbackSettingsValidation();
    };

    const setPostbackSettingsValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.postbackSettings = false;

        changeValidation(newValidation);
    };

    return (
        <FormControlLabel
            label="Безлимитная отправка постбэков"
            control={<Checkbox checked={checkUnlimited} onChange={action(changePostbackLimitValue)} />}
        />
    );
});
