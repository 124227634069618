import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

export const DomainAddButton = () => {
    const { url } = useRouteMatch();

    return (
        <div className="mr-2">
            <Link to={`${url}/add`} className="link">
                <Button size="small" color="success" variant="contained" startIcon={<AddBoxIcon />}>
                    Добавить
                </Button>
            </Link>
        </div>
    );
};
