import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { LandingsPagination } from ".";
import { useStores } from "../../stores";
import { LandingsForm } from "./LandingsForm";
import { LandingsTable } from "./LandingsTable";
import { ActionButtons } from "./ActionButtons";

interface LandingsIdsContextInterface {
    landingsIds: number[];
    setLandingsIds: Dispatch<SetStateAction<number[]>>;
}

export const Landings = observer(() => {
    const [landingsIds, setLandingsIds] = useState<number[]>([]);
    const { criteria, findLandings, setDefaultCriteria } = useStores().landingsStore;

    useEffect(() => {
        findLandings();
    }, [criteria, findLandings]);

    useEffect(() => {
        setLandingsIds([]);
    }, [criteria]);

    useEffect(() => {
        return setDefaultCriteria;
    }, [setDefaultCriteria]);

    return (
        <LandingsIdsContext.Provider value={{ landingsIds, setLandingsIds }}>
            <div className="container-fluid">
                <div className="ml-4">
                    <h1>Лендинги</h1>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <LandingsForm />
                    <ActionButtons />
                </div>
                <LandingsTable />
                <LandingsPagination />
            </div>
        </LandingsIdsContext.Provider>
    );
});

const LandingsIdsContext = createContext<LandingsIdsContextInterface>({} as LandingsIdsContextInterface);

export const useLandingsIds = (): LandingsIdsContextInterface => {
    return useContext(LandingsIdsContext);
};
