import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { Landing } from "../../../entities/LandingEdit";
import { LandingValidation } from "../../../validation/LandingEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const LandingTitle = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { landing, changeLanding } = useStores().landingEditStore;
    const { validation, changeValidation } = useValidations().landingEditValidation;

    const changeTitle = (event: ChangeEvent<HTMLInputElement>): void => {
        const newLanding: Landing = JSON.parse(JSON.stringify(landing));

        newLanding.title = event.target.value;

        setTitleValidation();
        changeLanding(newLanding);
    };

    const setTitleValidation = (): void => {
        const newValidation: LandingValidation = JSON.parse(JSON.stringify(validation));

        newValidation.title = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Наименование</h5>
            <TextField
                fullWidth
                value={landing.title}
                error={validation.title}
                helperText={validation.title && "Поле не может быть пустым"}
                onChange={action(changeTitle)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
