import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useMode } from "../UserEdit";
import { useStores } from "../../../stores";
import { Mode } from "../../../module/Enum/Mode";
import { User } from "../../../entities/UserEdit";
import { useValidations } from "../../../validation";
import { Roles, convertRolesName } from "../../../module/Enum/Roles/Roles";
import { UserValidation } from "../../../validation/UserEditValidation";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, FormControl, FormHelperText, TextField } from "@mui/material";

export const UserRoles = observer(() => {
    const { mode } = useMode();
    const { user, changeUser } = useStores().userEditStore;
    const { validation, changeValidation } = useValidations().userEditValidation;

    const changeRoles = (event: object, value: Roles[]): void => {
        const newUser: User = JSON.parse(JSON.stringify(user));

        newUser.roles = value;

        changeUser(newUser);
        setRolesValidation();
    };

    const setRolesValidation = (): void => {
        const newValidation: UserValidation = JSON.parse(JSON.stringify(validation));

        newValidation.roles = false;

        changeValidation(newValidation);
    };
    return (
        <div className="field">
            <h5>Роли</h5>
            <FormControl fullWidth error={validation.roles}>
                <Autocomplete
                    multiple
                    limitTags={3}
                    disableCloseOnSelect
                    value={user.roles.slice()}
                    options={Object.values(Roles)}
                    disabled={mode === Mode.CURRENT}
                    getOptionLabel={(option) => convertRolesName[option]}
                    onChange={(event, value) => runInAction(() => changeRoles(event, value))}
                    renderInput={(params) => <TextField {...params} variant="outlined" error={validation.roles} />}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                size="small"
                                checked={selected}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            />
                            {convertRolesName[option]}
                        </li>
                    )}
                />
                {validation.roles && <FormHelperText>Поле не может быть пустым</FormHelperText>}
            </FormControl>
        </div>
    );
});
