import { useState, useEffect } from "react";
import { Warning } from "./";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { DateFilters } from "../../../../module/Enum/DateFilters";
import { Filter, FilterData } from "../../../../entities/Filters";
import { StatisticsCriteria } from "../../../../entities/Statistics";
import { FilterType } from "../../../../module/Enum/Filters/Filters";
import { useDropdown, useStatisticsContext } from "../../Statistics";
import { CampaignSearchItem } from "../../../../entities/CampaignSearch";
import { useFormCriteria, useSelectedСampaigns } from "../../StatisticsForm";
import { addDays, startOfISOWeek, endOfISOWeek, addWeeks, startOfMonth, endOfMonth, addMonths } from "date-fns";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Select, InputLabel, FormControl, MenuItem, IconButton } from "@mui/material";

interface Props {
    selectedDateFilter: DateFilters | undefined;
    dateFrom: Date;
    dateTo: Date;
}

interface DateRange {
    dateFrom: Date;
    dateTo: Date;
}

export const FilterList = observer(() => {
    const { setCriteria } = useStatisticsContext();
    const { campaignId, dropdownNotice } = useDropdown();
    const { setSelectedСampaigns } = useSelectedСampaigns();
    const { criteriaEdit, setCriteriaEdit } = useFormCriteria();
    const { filtersData, findFilters } = useStores().filtersStore;
    const [open, setOpen] = useState<boolean>(false);
    const [filterItem, setFilterItem] = useState<Filter>();
    const [filterTitle, setFilterTitle] = useState<string>("none");

    useEffect(() => {
        criteriaEdit && setFilterTitle("none");
    }, [criteriaEdit]);

    useEffect(() => {
        findFilters(dropdownNotice ? FilterType.DROPDOWN_STATISTICS : FilterType.STATISTICS);
    }, [dropdownNotice, findFilters]);

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    const changeFilter = (filter: Filter): void => {
        setFilterTitle(filter.title);
        changeCriteria(filter);
    };

    const changeCriteria = (filter: Filter): void => {
        const newCriteria: StatisticsCriteria = (filter.data as FilterData).filter;
        const { dateFrom, dateTo } = transformFilterDates({
            selectedDateFilter: (filter.data as FilterData).selectedDateFilter,
            dateFrom: newCriteria.dateFrom,
            dateTo: newCriteria.dateTo,
        });

        newCriteria.dateFrom = dateFrom;
        newCriteria.dateTo = dateTo;
        if (campaignId !== undefined && dropdownNotice) newCriteria.campaignIds = [campaignId];

        setCriteriaEdit(false);
        setCriteria(newCriteria);
        setSelectedСampaigns((filter.data as FilterData).selectedСampaigns as CampaignSearchItem[]);
    };

    return (
        <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="myFilter">Мои фильтры</InputLabel>
            <Select id="myFilter" value={filterTitle}>
                <MenuItem value="none" className="filter-item_select" onClick={() => setFilterTitle("none")}>
                    Не выбран
                </MenuItem>
                {filtersData.items.map((filter: Filter) => (
                    <MenuItem
                        key={filter.id}
                        value={filter.title}
                        className="d-flex align-items-center filter-item_select mt-2"
                        onClick={() => setFilterItem(filter)}
                    >
                        <div className="w-100" onClick={() => changeFilter(filter)}>
                            {filter.title}
                        </div>
                        {filterTitle !== filter.title && (
                            <IconButton className="p-0" onClick={openWarningModal}>
                                <HighlightOffIcon color="error" />
                            </IconButton>
                        )}
                    </MenuItem>
                ))}
            </Select>
            {open && <Warning filterItem={filterItem} closeWarningModal={closeWarningModal} />}
        </FormControl>
    );
});

function transformFilterDates({ selectedDateFilter, dateFrom, dateTo }: Props): DateRange {
    switch (selectedDateFilter) {
        case DateFilters.TODAY:
            return {
                dateFrom: new Date(),
                dateTo: new Date(),
            };

        case DateFilters.YESTERDAY:
            return {
                dateFrom: addDays(new Date(), -1),
                dateTo: addDays(new Date(), -1),
            };

        case DateFilters.THIS_WEEK:
            return {
                dateFrom: startOfISOWeek(new Date()),
                dateTo: endOfISOWeek(new Date()),
            };

        case DateFilters.LAST_WEEK:
            return {
                dateFrom: startOfISOWeek(addWeeks(new Date(), -1)),
                dateTo: endOfISOWeek(addWeeks(new Date(), -1)),
            };

        case DateFilters.LAST_SEVEN_DAYS:
            return {
                dateFrom: addDays(new Date(), -7),
                dateTo: new Date(),
            };

        case DateFilters.THIS_MONTH:
            return {
                dateFrom: startOfMonth(new Date()),
                dateTo: endOfMonth(new Date()),
            };

        case DateFilters.LAST_MONTH:
            return {
                dateFrom: startOfMonth(addMonths(new Date(), -1)),
                dateTo: endOfMonth(addMonths(new Date(), -1)),
            };

        case DateFilters.LAST_THIRTY_DAYS:
            return {
                dateFrom: addDays(new Date(), -30),
                dateTo: new Date(),
            };

        default:
            return {
                dateFrom: new Date(dateFrom),
                dateTo: new Date(dateTo),
            };
    }
}
