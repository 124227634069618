import { SyntheticEvent } from "react";
import { CampaignSearchInput } from "../../CampaignSearch";
import { useFormCriteria, useSelectedСampaigns } from "./StatisticsForm";
import { CampaignSearchItem } from "../../../entities/CampaignSearch";

export const CampaignSearch = () => {
    const { selectedСampaigns, setSelectedСampaigns } = useSelectedСampaigns();
    const { formCriteria, setFormCriteria, setCriteriaEdit } = useFormCriteria();

    const changeCampaignIds = (event: SyntheticEvent<Element, Event>, value: (string | CampaignSearchItem)[]): void => {
        setCriteriaEdit(true);
        setSelectedСampaigns(value as CampaignSearchItem[]);
        setFormCriteria({
            ...formCriteria,
            campaignIds: (value as CampaignSearchItem[]).map((item: CampaignSearchItem) => item.id),
        });
    };

    return <CampaignSearchInput selectedСampaigns={selectedСampaigns} onChangeCampaignIds={changeCampaignIds} />;
};
