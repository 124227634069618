import { action } from "mobx";
import { observer } from "mobx-react";
import { useDefaultRule } from "./";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { Campaign, PercentageRoutingRule } from "../../../../entities/CampaignEdit";
import {
    DestinationType,
    PaymentModelType,
    convertDestinationType,
} from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { FormControl, InputLabel, Select, MenuItem, TextField, SelectChangeEvent } from "@mui/material";

export const RuleDirection = observer(() => {
    const { rule, ruleIndex } = useDefaultRule();
    const { campaign, paymentModel, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const changeRuleType = (event: SelectChangeEvent<DestinationType>): void => {
        const destinationType = event.target.value as DestinationType;
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule =
            destinationType === DestinationType.URL
                ? {
                      ...rule,
                      destination: {
                          type: DestinationType.URL,
                          url: "",
                      },
                  }
                : {
                      ...rule,
                      destination: {
                          type: DestinationType.OFFER,
                          offerId: -1,
                          landingId: null,
                      },
                  };

        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        deleteValidation();
        changeCampaign(newCampaign);
    };

    const deleteValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.percentageRoutingRules = {};

        changeValidation(newValidation);
    };

    return paymentModel.type === PaymentModelType.NONE ? (
        <FormControl fullWidth>
            <InputLabel>Направление трафика</InputLabel>
            <Select label="Направление трафика" value={rule.destination.type} onChange={action(changeRuleType)}>
                {Object.values(DestinationType).map((value: string) => (
                    <MenuItem key={value} value={value}>
                        {convertDestinationType[value]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    ) : (
        <TextField
            fullWidth
            className="pointer-events"
            label="Направление трафика"
            value={convertDestinationType[rule.destination.type]}
        />
    );
});
