import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { PaymentModelType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { PaymentModelValue } from "./PaymentModelValue";
import { PaymentModelRadio, PaymentModelInfo } from "./";

export const CampaignPaymentModel = observer(() => {
    const { paymentModel } = useStores().campaignEditStore;

    return (
        <div className="d-flex mt-5">
            <div className="payment-model-radio">
                <PaymentModelRadio />
            </div>

            {paymentModel.type !== PaymentModelType.NONE && (
                <div className="payment-model-value">
                    <PaymentModelValue />
                </div>
            )}

            <div>
                <PaymentModelInfo />
            </div>
        </div>
    );
});
