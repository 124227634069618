import { observer } from "mobx-react";
import { Modal, Button } from "@mui/material";

interface Props {
    closeAlertModal(): void;
}

export const AlertModal = observer(({ closeAlertModal }: Props) => (
    <div className="modal fade">
        <Modal open>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="d-flex justify-content-between align-items-center modal-body">
                        <div>Нельзя удалить команду, в которой есть пользователи</div>
                        <div>
                            <Button variant="contained" onClick={closeAlertModal}>
                                Ок
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
));
