import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { FilterTitle, SaveButton, CanсelButton } from "./";
import { FilterRequest } from "../../../../../entities/Filters";
import { FilterType } from "../../../../../module/Enum/Filters/Filters";
import { SortField } from "../../../../../module/Enum/Statistics/SortField";
import { TypeGrouping } from "../../../../../module/Enum/Grouping/Grouping";
import { SortDirection } from "../../../../../module/Enum/Sort/SortDirection";
import { StatisticsCriteria, GroupByData } from "../../../../../entities/Statistics";
import { useDropdown, useStatisticsContext } from "../../../../Statistics/Statistics";
import { useFormCriteria, useDateFilter, useSelectedСampaigns } from "../../../../Statistics/StatisticsForm";
import { Modal } from "@mui/material";

interface Props {
    closeSaveModal(): void;
}

export const FilterSaveModal = observer(({ closeSaveModal }: Props) => {
    const { dateFilter } = useDateFilter();
    const { dropdownNotice } = useDropdown();
    const { formCriteria } = useFormCriteria();
    const { criteria } = useStatisticsContext();
    const { selectedСampaigns } = useSelectedСampaigns();
    const { setDefaultFilter, changeFilter } = useStores().filtersStore;

    useEffect(() => {
        const newCriteria: StatisticsCriteria = { ...criteria, ...formCriteria };

        newCriteria.page = 1;
        newCriteria.groupBy = newCriteria.groupBy.filter((group: GroupByData) => group.value !== "none");
        newCriteria.sort =
            newCriteria.groupBy[0].type === TypeGrouping.DEFAULT
                ? {
                      type: TypeGrouping.DEFAULT,
                      field: newCriteria.groupBy[0].value as SortField,
                      direction: SortDirection.ASC,
                  }
                : {
                      type: TypeGrouping.PARAMETER,
                      parameter: newCriteria.groupBy[0].value as string,
                      direction: SortDirection.ASC,
                  };

        const newFilter: FilterRequest = {
            title: "",
            data: {
                type: dropdownNotice ? FilterType.DROPDOWN_STATISTICS : FilterType.STATISTICS,
                filter: newCriteria,
                selectedDateFilter: dateFilter,
                selectedСampaigns: selectedСampaigns,
            },
        };

        changeFilter(newFilter);
    }, [dropdownNotice, criteria, formCriteria, selectedСampaigns, dateFilter, changeFilter]);

    useEffect(() => {
        return setDefaultFilter;
    }, [setDefaultFilter]);

    return (
        <div className="modal fade">
            <Modal open>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Введите название фильтра</h5>
                        </div>
                        <div className="modal-body">
                            <FilterTitle />
                        </div>
                        <div className="modal-footer">
                            <CanсelButton closeSaveModal={closeSaveModal} />
                            <SaveButton closeSaveModal={closeSaveModal} />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
