import { useLandingsIds } from "../Landings";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const LandingViewButton = () => {
    const { url } = useRouteMatch();
    const { landingsIds } = useLandingsIds();
    const [id] = landingsIds;

    return (
        <div className="mr-2">
            <Link to={`${url}/view/${id}`} className={landingsIds.length !== 1 ? "link_disabled" : "link"}>
                <Button
                    size="small"
                    variant="contained"
                    startIcon={<VisibilityIcon />}
                    disabled={landingsIds.length !== 1}
                >
                    Посмотреть
                </Button>
            </Link>
        </div>
    );
};
