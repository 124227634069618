import { action } from "mobx";
import { observer } from "mobx-react";
import { SourceParameter } from "../../../entities/SourceParameters";
import { TypeGrouping } from "../../../module/Enum/Grouping/Grouping";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { useStatisticsContext, useSourceParameter } from "../Statistics";
import { StatisticsCriteria, SortData, GroupByData } from "../../../entities/Statistics";
import { DefaultField, SortField, convertField } from "../../../module/Enum/Statistics/SortField";

export const TableHead = observer(() => {
    const sourceParameters = useSourceParameter();
    const { criteria, setCriteria } = useStatisticsContext();

    const sortTable = (field: SortField | string, type: TypeGrouping): void => {
        const newCriteria: StatisticsCriteria = { ...criteria };
        const newSortData: SortData = {
            type: TypeGrouping.DEFAULT,
            direction: SortDirection.ASC,
        };

        newSortData.type = type;

        type === TypeGrouping.DEFAULT
            ? (newSortData.field = field as SortField)
            : (newSortData.parameter = field as string);

        newSortData.direction =
            (newCriteria.sort.field === field || newCriteria.sort.parameter === field) &&
            newCriteria.sort.type === type &&
            newCriteria.sort.direction === SortDirection.ASC
                ? SortDirection.DESC
                : SortDirection.ASC;

        newCriteria.sort = newSortData;

        setCriteria(newCriteria);
    };

    const createArrow = (field: SortField | string, type: TypeGrouping): any => {
        const { sort } = criteria;

        switch (true) {
            case (sort.field === field || sort.parameter === field) &&
                sort.type === type &&
                sort.direction === SortDirection.ASC:
                return (
                    <div className="arrow">
                        <span className="up" />
                    </div>
                );
            case (sort.field === field || sort.parameter === field) &&
                sort.type === type &&
                sort.direction === SortDirection.DESC:
                return (
                    <div className="arrow">
                        <span className="down" />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <thead>
            <tr>
                {criteria.groupBy.map((item: GroupByData, index: number) => (
                    <th key={index} className="cursor-hover" onClick={action(() => sortTable(item.value, item.type))}>
                        <div className="table__text-position">
                            {item.type === TypeGrouping.DEFAULT
                                ? convertField[item.value]
                                : sourceParameters
                                      .filter((parameter: SourceParameter) => parameter.parameter === item.value)
                                      .map((parameter: SourceParameter) => parameter.name)}
                        </div>
                        {createArrow(item.value, item.type)}
                    </th>
                ))}

                {Object.values(DefaultField).map((field: DefaultField, index: number) => (
                    <th
                        key={index}
                        className="cursor-hover"
                        onClick={action(() => sortTable(field, TypeGrouping.DEFAULT))}
                    >
                        <div className="table__text-position">{convertField[field]}</div>
                        {createArrow(field, TypeGrouping.DEFAULT)}
                    </th>
                ))}
            </tr>
        </thead>
    );
});
