import { Mode } from "../../module/Enum/Mode";
import { Domains } from "../../components/Domains";
import { UserEdit } from "../../components/UserEdit";
import { DomainEdit } from "../../components/DomainEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    domainId: string;
}

export const DomainsRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, domainId } = useParams<ParamsProps>();

    const closeDomainEdit = (): void => {
        history.push("/spa/domains");
    };

    switch (mode) {
        case Mode.ADD:
            content = <DomainEdit closeDomainEdit={closeDomainEdit} />;
            break;

        case Mode.EDIT:
            content = <DomainEdit domainId={domainId} closeDomainEdit={closeDomainEdit} />;
            break;

        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeDomainEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Domains />
            {content}
        </>
    );
};
