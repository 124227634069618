import { useState } from "react";
import { PasswordResetData } from "../../entities/Auth";

interface ValidationText {
    password: string;
    passwordsDoNotMatch: string;
}

export interface ValidationHook {
    validation: AuthValidation;
    checkValidation(data: PasswordResetData): boolean;
    changeValidation(validation: AuthValidation): void;
}

export interface AuthValidation {
    password: boolean;
    passwordsDoNotMatch: boolean;
}

export const errorText: ValidationText = {
    password: "Поле не может быть пустым",
    passwordsDoNotMatch: "Пароли не совпадают",
};

export const useValidation = (): ValidationHook => {
    const [validation, setValidation] = useState<AuthValidation>({
        password: false,
        passwordsDoNotMatch: false,
    });

    const changeValidation = (validation: AuthValidation): void => {
        setValidation(validation);
    };

    const checkValidation = (data: PasswordResetData): boolean => {
        const validationCopy: AuthValidation = JSON.parse(JSON.stringify(validation));

        !data.password ? (validationCopy.password = true) : (validationCopy.password = false);
        data.password !== data.passwordRepeat
            ? (validationCopy.passwordsDoNotMatch = true)
            : (validationCopy.passwordsDoNotMatch = false);

        setValidation(validationCopy);
        return Object.values(validationCopy).includes(true);
    };

    return {
        validation,
        checkValidation,
        changeValidation,
    };
};
