import { format } from "date-fns";
import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { CampaignsApi } from "../services/api/CampaignsApi";
import { SortField } from "../module/Enum/Campaigns/SortField";
import { SortDirection } from "../module/Enum/Sort/SortDirection";
import { CampaignData, CampaignCriteria, GetCampaignsRequest } from "../entities/Campaigns";

export interface CampaignsStoreInterface {
    campaignData: CampaignData;
    criteria: CampaignCriteria;
    setSortDESC(): void;
    setDefaultCriteria(): void;
    findCampaigns(): Promise<CampaignData>;
    removeCampaign(campaignId: number): Promise<{}>;
    changeCriteria(newCriteria: CampaignCriteria): void;
}

const DEFAULT_CAMPAIGNS_CRITERIA: CampaignCriteria = {
    title: "",
    dateFrom: new Date(),
    dateTo: new Date(),
    page: 1,
    itemsPerPage: 10,
    onlyWithClick: 0,
    sort: {
        direction: SortDirection.DESC,
        field: SortField.CLICK_COUNT,
    },
};

const { getCampaigns, deleteCampaign } = CampaignsApi;

export class CampaignsStore implements CampaignsStoreInterface {
    public criteria: CampaignCriteria = DEFAULT_CAMPAIGNS_CRITERIA;
    public campaignData: CampaignData = {
        items: [],
        summary: {
            clickCount: 0,
            leadCount: 0,
            cvr: 0,
        },
        totalItems: 0,
    };

    constructor() {
        makeAutoObservable(this);
    }

    public setDefaultCriteria = (): void => {
        this.criteria = DEFAULT_CAMPAIGNS_CRITERIA;
    };

    public changeCriteria = (newCriteria: CampaignCriteria): void => {
        this.criteria = newCriteria;
    };

    public setSortDESC = (): void => {
        this.criteria = {
            ...this.criteria,
            page: 1,
            sort: {
                direction: SortDirection.DESC,
                field: SortField.ID,
            },
        };
    };

    public findCampaigns = async (): Promise<CampaignData> => {
        await getCampaigns(this.createGetCampaignsRequest()).then((response: AxiosResponse<CampaignData>) => {
            runInAction(() => {
                this.campaignData = response.data;
            });
        });
        return this.campaignData;
    };

    public removeCampaign = async (campaignId: number): Promise<{}> => {
        return deleteCampaign(campaignId).then((response: AxiosResponse<{}>) => {
            return runInAction(() => {
                this.findCampaigns();
                return response;
            });
        });
    };

    private createGetCampaignsRequest = (): GetCampaignsRequest => {
        const urlParameters: GetCampaignsRequest = {
            title: this.criteria.title,
            dateFrom: format(this.criteria.dateFrom, "dd.MM.yyyy"),
            dateTo: format(this.criteria.dateTo, "dd.MM.yyyy"),
            page: this.criteria.page,
            itemsPerPage: this.criteria.itemsPerPage,
            onlyWithClick: this.criteria.onlyWithClick,
            "sort[direction]": this.criteria.sort.direction,
            "sort[field]": this.criteria.sort.field,
        };
        return urlParameters;
    };
}
