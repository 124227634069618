import { AxiosResponse } from "axios";
import { OffersApi } from "../services/api/OffersApi";
import { makeAutoObservable, runInAction } from "mobx";
import { SortField } from "../module/Enum/Offers/SortField";
import { SortDirection } from "../module/Enum/Sort/SortDirection";
import { OffersCriteriaRequest, OffersCriteria, OffersData } from "../entities/Offers";

export interface OffersStoreInterface {
    offersData: OffersData;
    criteria: OffersCriteria;
    cleanUpOffers(): void;
    removeOffer(offerId: number): Promise<any>;
    changeCriteria(newCriteria: OffersCriteria): void;
    findOffers(criteria: OffersCriteria): Promise<OffersData>;
}

export const DEFAULT_OFFERS_DATA: OffersData = {
    items: [],
    totalItems: 1,
};

export const DEFAULT_OFFERS_CRITERIA: OffersCriteria = {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sort: {
        field: SortField.ID,
        direction: SortDirection.ASC,
    },
};

const { getOffers, deleteOffer } = OffersApi;

export class OffersStore implements OffersStoreInterface {
    public offersData: OffersData = DEFAULT_OFFERS_DATA;
    public criteria: OffersCriteria = DEFAULT_OFFERS_CRITERIA;

    constructor() {
        makeAutoObservable(this);
    }

    public changeCriteria = (newCriteria: OffersCriteria): void => {
        this.criteria = newCriteria;
    };

    public cleanUpOffers = (): void => {
        this.offersData = DEFAULT_OFFERS_DATA;
        this.criteria = DEFAULT_OFFERS_CRITERIA;
    };

    public findOffers = async (criteria: OffersCriteria): Promise<OffersData> => {
        await getOffers(this.createOffersCriteriaRequest(criteria)).then((response: AxiosResponse<OffersData>) => {
            runInAction(() => {
                this.offersData = response.data;
            });
        });
        return this.offersData;
    };

    public removeOffer = async (offerId: number): Promise<any> => {
        return await deleteOffer(offerId).then((response: AxiosResponse) => {
            return runInAction(() => {
                this.findOffers(this.criteria);
                return response;
            });
        });
    };

    private createOffersCriteriaRequest = (criteria: OffersCriteria): OffersCriteriaRequest => {
        const urlParameters: OffersCriteriaRequest = {
            search: criteria.search,
            page: criteria.page,
            itemsPerPage: criteria.itemsPerPage,
            "sort[field]": criteria.sort.field,
            "sort[direction]": criteria.sort.direction,
        };
        return urlParameters;
    };
}
