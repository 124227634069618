import { useState } from "react";
import { WarningModal } from "./";
import { useStores } from "../../../stores";
import { Fab, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    campaignId: number;
}

export const CampaignDeleteButton = ({ campaignId }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const { removeCampaign } = useStores().campaignsStore;

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    const deleteCampaign = (): void => {
        removeCampaign(campaignId);
        closeWarningModal();
    };

    return (
        <>
            <Tooltip title="Удалить" placement="bottom">
                <Fab size="small" color="error" onClick={openWarningModal}>
                    <DeleteIcon fontSize="small" />
                </Fab>
            </Tooltip>

            {open && <WarningModal deleteCampaign={deleteCampaign} closeWarningModal={closeWarningModal} />}
        </>
    );
};
