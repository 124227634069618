import { observer } from "mobx-react";
import { useStores } from "../stores";
import { AuthRoutes } from "./AuthRoutes";
import { TrackerRoutes } from "./TrackerRoutes";

export const Routes = observer(() => {
    const { isAuthorized } = useStores().authStore;

    return isAuthorized ? <TrackerRoutes /> : <AuthRoutes />;
});
