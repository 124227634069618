import { useState } from "react";
import { AuthData } from "../../../entities/Auth";

interface ValidationText {
    email: string;
    password: string;
    wrongData: string;
}

export interface ValidationHook {
    validation: AuthValidation;
    checkValidation(data: AuthData): boolean;
    changeValidation(validation: AuthValidation): void;
}

export interface AuthValidation {
    email: boolean;
    password: boolean;
    wrongData: boolean;
}

export const errorText: ValidationText = {
    email: "Почта должна быть вида «example@mail.com»",
    password: "Поле не может быть пустым",
    wrongData: "Неправильно указана почта или пароль",
};

export const useValidation = (): ValidationHook => {
    const [validation, setValidation] = useState<AuthValidation>({
        email: false,
        password: false,
        wrongData: false,
    });

    const changeValidation = (validation: AuthValidation): void => {
        setValidation(validation);
    };

    const checkValidation = (data: AuthData): boolean => {
        const regEmail: RegExp = /\S+@\S+\.\S+/;
        const validationCopy: AuthValidation = JSON.parse(JSON.stringify(validation));

        !regEmail.test(data.email) ? (validationCopy.email = true) : (validationCopy.email = false);
        !data.password ? (validationCopy.password = true) : (validationCopy.password = false);

        setValidation(validationCopy);

        return Object.values(validationCopy).includes(true);
    };

    return {
        validation,
        checkValidation,
        changeValidation,
    };
};
