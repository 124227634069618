import { Mode } from "../../module/Enum/Mode";
import { Teams } from "../../components/Teams";
import { TeamEdit } from "../../components/TeamEdit";
import { UserEdit } from "../../components/UserEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    teamId: string;
}

export const TeamsRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, teamId } = useParams<ParamsProps>();

    const closeTeamEdit = (): void => {
        history.push("/spa/teams");
    };

    switch (mode) {
        case Mode.ADD:
            content = <TeamEdit closeTeamEdit={closeTeamEdit} />;
            break;

        case Mode.EDIT:
            content = <TeamEdit teamId={teamId} closeTeamEdit={closeTeamEdit} />;
            break;

        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeTeamEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Teams />
            {content}
        </>
    );
};
