import { useState } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useReportData, useError } from "../";
import { AxiosResponse, AxiosError } from "axios";
import { useValidations } from "../../../validation";
import { ReportData, ReportId } from "../../../entities/Report";
import { Button, Snackbar } from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CircularProgress from "@mui/material/CircularProgress";

export const ReportCreateButton = observer(() => {
    let timerId: ReturnType<typeof setTimeout>;
    const { setError } = useError();
    const { setReportData } = useReportData();
    const { enqueueSnackbar } = useSnackbar();
    const { checkValidation } = useValidations().reportValidation;
    const { reportRequest, findReport, findReportId } = useStores().reportStore;
    const [loading, setLoading] = useState<boolean>(false);
    const progress: JSX.Element = <CircularProgress size="1.8rem" color="inherit" className="mr-2" disableShrink />;

    const getReport = (id: string): void => {
        findReport(id)
            .then((response: AxiosResponse<ReportData>) => {
                switch (response.status) {
                    case 200:
                        setLoading(false);
                        clearTimeout(timerId);
                        setReportData(response.data);
                        break;
                    case 202:
                        timerId = setTimeout(() => getReport(id), 1000);
                        break;
                }
            })
            .catch((error: AxiosError) => {
                switch (error.response?.status) {
                    case 500:
                        setError(true);
                        setLoading(false);
                        clearTimeout(timerId);
                        break;
                }
            });
    };

    const getReportId = (): void => {
        findReportId().then((response: ReportId): void => {
            getReport(response.reportId);
        });
    };

    const createReport = (): void => {
        if (checkValidation(reportRequest)) {
            enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 2000 });
        } else {
            getReportId();
            setError(false);
            setLoading(true);
            setReportData(undefined);
        }
    };

    return (
        <div>
            <Button
                className="ml-4"
                variant="contained"
                disabled={loading}
                startIcon={<AssignmentIcon />}
                onClick={action(createReport)}
            >
                Создать отчёт
            </Button>

            <Snackbar
                open={loading}
                action={progress}
                color="inherit"
                className="pointer-events"
                message="Создание отчета..."
            />
        </div>
    );
});
