import { observer } from "mobx-react";
import { useCondition } from "../";
import { RoutingConditionType } from "../../../../../../module/Enum/CampaignEdit/CampaignEdit";
import {
    ConditionOS,
    ConditionIp,
    ConditionDevices,
    ConditionReferrer,
    ConditionUserAgent,
    ConditionParameter,
    ConditionCountries,
} from ".";

export const ConditionField = observer(() => {
    const { condition } = useCondition();

    const createFiled = (): any => {
        switch (condition.type) {
            case RoutingConditionType.REFERRER:
                return <ConditionReferrer />;

            case RoutingConditionType.USER_AGEN:
                return <ConditionUserAgent />;

            case RoutingConditionType.IP:
                return <ConditionIp />;

            case RoutingConditionType.PARAMETER:
                return <ConditionParameter />;

            case RoutingConditionType.DEVICES:
                return <ConditionDevices />;

            case RoutingConditionType.OPERATION_SYSTEM:
                return <ConditionOS />;

            case RoutingConditionType.GEO:
                return <ConditionCountries />;
        }
    };

    return <>{createFiled()}</>;
});
