import { MouseEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useCondition } from "./";
import { useRoutingRule } from "../";
import { useStores } from "../../../../../stores";
import { useValidations } from "../../../../../validation";
import { CampaignValidation } from "../../../../../validation/CampaignEditValidation";
import { Campaign, Condition, RoutingRule } from "../../../../../entities/CampaignEdit";
import { RoutingConditionExpression } from "../../../../../module/Enum/CampaignEdit/CampaignEdit";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

export const ConditionExpression = observer(() => {
    const { rule, ruleIndex } = useRoutingRule();
    const { condition, conditionIndex } = useCondition();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `expression${ruleIndex}${conditionIndex}`;

    const changeConditionExpression = (
        event: MouseEvent<HTMLElement>,
        newAlignment: RoutingConditionExpression | null
    ): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.expression = newAlignment as RoutingConditionExpression;

        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setConditionExpressionValidation();
    };

    const setConditionExpressionValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRules[fieldName] = false;

        changeValidation(newValidation);
    };

    return (
        <ToggleButtonGroup
            exclusive
            size="small"
            value={condition.expression}
            onChange={action((event, newAlignment) => changeConditionExpression(event, newAlignment))}
        >
            <ToggleButton
                size="small"
                color="success"
                className="p-0 pr-2 pl-2"
                value={RoutingConditionExpression.EQUAL}
            >
                <small>Включить</small>
            </ToggleButton>
            <ToggleButton
                size="small"
                color="error"
                className="p-0 pr-2 pl-2"
                value={RoutingConditionExpression.NOT_EQUAL}
            >
                <small>Исключить</small>
            </ToggleButton>
        </ToggleButtonGroup>
    );
});
