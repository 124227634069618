import { ChangeEvent } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { TextField } from "@mui/material";

export const FilterTitle = observer(() => {
    const { filter, changeFilter } = useStores().filtersStore;

    const changeFilterTitle = (event: ChangeEvent<{ value: string }>): void => {
        changeFilter({
            ...filter,
            title: event.target.value,
        });
    };

    return (
        <div>
            <h6>Название фильтра</h6>
            <TextField fullWidth variant="standard" value={filter.title} onChange={changeFilterTitle} />
        </div>
    );
});
