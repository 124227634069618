import { AxiosResponse } from "axios";
import { Offer, OfferRequest } from "../../entities/OfferEdit";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { OffersCriteriaRequest, OffersData } from "../../entities/Offers";

interface OffersApiInterface {
    deleteOffer(offerId: number): Promise<AxiosResponse>;
    postOffer(offer: OfferRequest): Promise<AxiosResponse<Offer>>;
    getOffer(offerId: string | number): Promise<AxiosResponse<Offer>>;
    putOffer(offerId: number, offer: OfferRequest): Promise<AxiosResponse<Offer>>;
    getOffers(criteria: OffersCriteriaRequest): Promise<AxiosResponse<OffersData>>;
}

export const OffersApi: OffersApiInterface = {
    getOffers: async (criteria: OffersCriteriaRequest): Promise<AxiosResponse<OffersData>> =>
        await httpClient.get<OffersData>("/api/v1/offer", { params: criteria }),

    getOffer: async (offerId: string | number): Promise<AxiosResponse<Offer>> =>
        await httpClient.get<Offer>(`/api/v1/offer/${offerId}`),

    postOffer: async (offer: OfferRequest): Promise<AxiosResponse<Offer>> =>
        await httpClient.post<Offer>("/api/v1/offer", offer),

    putOffer: async (offerId: number, offer: OfferRequest): Promise<AxiosResponse<Offer>> =>
        await httpClient.put<Offer>(`/api/v1/offer/${offerId}`, offer),

    deleteOffer: async (offerId: number): Promise<AxiosResponse> => await httpClient.delete(`/api/v1/offer/${offerId}`),
};
