import { useState, useEffect, useRef } from "react";
import { DateFilters, convertDateFilters } from "../../module/Enum/DateFilters";
import { Range, DateRangePicker, OnDateRangeChangeProps } from "react-date-range";
import { format, addDays, startOfISOWeek, endOfISOWeek, addWeeks, startOfMonth, endOfMonth, addMonths } from "date-fns";
import ru from "date-fns/locale/ru";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { TextField, InputAdornment, IconButton, Button } from "@mui/material";
import "../../style/dateRange.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface Props {
    delta?: boolean;
    startDate: Date | string;
    endDate: Date | string;
    changeDate(range: Range[]): void;
}

export const DateRange = ({ delta, startDate, endDate, changeDate }: Props) => {
    const dateRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [dates, setDates] = useState<Range[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [previousDates, setPreviousDates] = useState<Range[]>([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);

    useEffect(() => {
        const checkOutsideClick = (event: any): void => {
            if (dateRef.current !== null && !dateRef.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("click", checkOutsideClick);
        return () => {
            document.removeEventListener("click", checkOutsideClick);
        };
    }, [dateRef]);

    const handleOpen = (): void => setOpen(!open);

    const changeDateRange = (range: OnDateRangeChangeProps): void => {
        Object.values(range).forEach((value: Range) => {
            setDates([value]);
        });
    };

    const saveDate = (): void => {
        changeDate(dates);
        setPreviousDates(dates);
        setOpen(false);
    };

    const returnPreviousDate = (): void => {
        setDates(previousDates);
        setOpen(false);
    };

    return (
        <div ref={dateRef}>
            <div onClick={handleOpen}>
                <TextField
                    fullWidth
                    variant="standard"
                    label={delta ? "Диапазон дат (Дельта)" : "Диапазон дат"}
                    value={`${transformDate(startDate)} - ${transformDate(endDate)}`}
                    inputProps={{
                        style: {
                            cursor: "pointer",
                        },
                        readOnly: true,
                    }}
                    InputProps={{
                        style: {
                            marginRight: "1rem", // for Safary and Firefox
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <DateRangeIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            {open && (
                <div className="calendar">
                    <DateRangePicker
                        direction="horizontal"
                        monthDisplayFormat="MMM"
                        weekdayDisplayFormat="EEEEEE"
                        dateDisplayFormat="dd.MM.yyyy"
                        months={2}
                        locale={ru}
                        ranges={dates}
                        editableDateInputs={true}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        onChange={changeDateRange}
                        staticRanges={Object.values(DateFilters).map((filter: DateFilters) => ({
                            label: convertDateFilters[filter],
                            range: () => createDateRange(filter),
                            isSelected() {
                                return false;
                            },
                        }))}
                    />
                    <div className="modal-footer">
                        <Button size="small" variant="outlined" onClick={returnPreviousDate}>
                            Отменить
                        </Button>
                        <Button size="small" variant="contained" onClick={saveDate}>
                            Применить
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

const transformDate = (date: Date | string): string =>
    date instanceof Date ? format(date, "dd.MM.yyyy") : date.split("-").reverse().join(".");

const createDateRange = (filter: DateFilters): Range => {
    switch (filter) {
        case DateFilters.YESTERDAY:
            return {
                key: filter,
                startDate: addDays(new Date(), -1),
                endDate: addDays(new Date(), -1),
            };
        case DateFilters.THIS_WEEK:
            return {
                key: filter,
                startDate: startOfISOWeek(new Date()),
                endDate: endOfISOWeek(new Date()),
            };
        case DateFilters.LAST_WEEK:
            return {
                key: filter,
                startDate: startOfISOWeek(addWeeks(new Date(), -1)),
                endDate: endOfISOWeek(addWeeks(new Date(), -1)),
            };
        case DateFilters.LAST_SEVEN_DAYS:
            return {
                key: filter,
                startDate: addDays(new Date(), -7),
                endDate: new Date(),
            };
        case DateFilters.THIS_MONTH:
            return {
                key: filter,
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
            };
        case DateFilters.LAST_MONTH:
            return {
                key: filter,
                startDate: startOfMonth(addMonths(new Date(), -1)),
                endDate: endOfMonth(addMonths(new Date(), -1)),
            };
        case DateFilters.LAST_THIRTY_DAYS:
            return {
                key: filter,
                startDate: addDays(new Date(), -30),
                endDate: new Date(),
            };
        default:
            return {
                key: DateFilters.TODAY,
                startDate: new Date(),
                endDate: new Date(),
            };
    }
};
