import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Campaign, Goal } from "../../../../entities/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { TextField } from "@mui/material";

interface Props {
    goal: Goal;
    goalIndex: number;
}

export const GoalName = observer(({ goal, goalIndex }: Props) => {
    const errorText: string = createErrorText(goal.name);
    const fieldName: string = createFieldName(goal.name, goalIndex);
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const changeGoalName = (event: ChangeEvent<HTMLInputElement>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newGoal: Goal = JSON.parse(JSON.stringify(goal));

        newGoal.name = event.target.value;
        newCampaign.goals.splice(goalIndex, 1, newGoal);

        setGoalNameValidation();
        changeCampaign(newCampaign);
    };

    const setGoalNameValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));
        newValidation.goals[fieldName] = false;
        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            label="Имя цели"
            value={goal.name}
            error={validation.goals[fieldName]}
            helperText={validation.goals[fieldName] && errorText}
            onChange={action(changeGoalName)}
            InputLabelProps={{ shrink: true }}
        />
    );
});

const createFieldName = (name: string, goalIndex: number): string => {
    return !name ? `goalNameLength${goalIndex}` : `goalName${goalIndex}`;
};

const createErrorText = (name: string): string => {
    return !name ? `Поле не может быть пустым` : `Поле должно быть уникальным`;
};
