import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { PaymentModel } from "../../../../entities/CampaignEdit";
import {
    DestinationType,
    PaymentModelType,
    convertPaymentModelType,
} from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Warning } from "./";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";

export const PaymentModelRadio = observer(() => {
    const [open, setOpen] = useState<boolean>(false);
    const [event, setEvent] = useState<ChangeEvent<HTMLInputElement> | null>(null);
    const { campaign, paymentModel, changePaymentModel } = useStores().campaignEditStore;
    const defaultRulesCheck: boolean = campaign.percentageRoutingRules
        .map((rule) => rule.destination.type)
        .includes(DestinationType.URL);
    const routingRulesCheck: boolean = campaign.routingRules
        .map((rule) => rule.destination.type)
        .includes(DestinationType.URL);

    const openWarningModal = (event: ChangeEvent<HTMLInputElement>): void => {
        setOpen(true);
        setEvent(event);
    };

    const closeWarningModal = (): void => setOpen(false);

    const changePaymentModelType = (event: ChangeEvent<HTMLInputElement>): void => {
        const modelType = event.target.value as PaymentModelType;
        let newPaymentModel: PaymentModel = JSON.parse(JSON.stringify(paymentModel));

        switch (modelType) {
            case PaymentModelType.CPA:
                newPaymentModel = {
                    type: modelType,
                    cost: 0,
                };
                break;
            case PaymentModelType.REV_SHARE:
                newPaymentModel = {
                    type: modelType,
                    costPercentage: 0,
                };
                break;
            default:
                newPaymentModel = {
                    type: modelType,
                };
                break;
        }

        changePaymentModel(newPaymentModel);
    };

    return (
        <div>
            <h5>Модель затрат</h5>
            <RadioGroup
                value={paymentModel.type}
                onChange={defaultRulesCheck || routingRulesCheck ? openWarningModal : action(changePaymentModelType)}
            >
                {Object.values(PaymentModelType).map((type: string, index: number) => (
                    <FormControlLabel
                        key={index}
                        value={type}
                        className="mb-0"
                        control={<Radio />}
                        label={convertPaymentModelType[type]}
                    />
                ))}
            </RadioGroup>
            {open && (
                <Warning
                    event={event}
                    closeWarningModal={closeWarningModal}
                    changePaymentModelType={changePaymentModelType}
                />
            )}
        </div>
    );
});
