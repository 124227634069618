import { createContext, useContext } from "react";
import { ReportValidation, ReportValidationInterface } from "./ReportValidation";
import { TeamEditValidation, TeamEditValidationInterface } from "./TeamEditValidation";
import { UserEditValidation, UserEditValidationInterface } from "./UserEditValidation";
import { OfferEditValidation, OfferEditValidationInterface } from "./OfferEditValidation";
import { DomainEditValidation, DomainEditValidationInterface } from "./DomainEditValidation";
import { SourceEditValidation, SourceEditValidationInterface } from "./SourceEditValidation";
import { LandingEditValidation, LandingEditValidationInterface } from "./LandingEditValidation";
import { CampaignEditValidation, CampaignEditValidationInterface } from "./CampaignEditValidation";

interface RootValidationInterface {
    reportValidation: ReportValidationInterface;
    teamEditValidation: TeamEditValidationInterface;
    userEditValidation: UserEditValidationInterface;
    offerEditValidation: OfferEditValidationInterface;
    domainEditValidation: DomainEditValidationInterface;
    sourceEditValidation: SourceEditValidationInterface;
    landingEditValidation: LandingEditValidationInterface;
    campaignEditValidation: CampaignEditValidationInterface;
}

const RootValidation: RootValidationInterface = {
    reportValidation: new ReportValidation(),
    teamEditValidation: new TeamEditValidation(),
    userEditValidation: new UserEditValidation(),
    offerEditValidation: new OfferEditValidation(),
    domainEditValidation: new DomainEditValidation(),
    sourceEditValidation: new SourceEditValidation(),
    landingEditValidation: new LandingEditValidation(),
    campaignEditValidation: new CampaignEditValidation(),
};

export const RootValidationContext = createContext<RootValidationInterface>(RootValidation);

export const useValidations = (): RootValidationInterface => {
    return useContext(RootValidationContext);
};
