import { makeAutoObservable } from "mobx";
import { Offer, NewOffer } from "../entities/OfferEdit";

export interface OfferEditValidationInterface {
    validation: OfferValidation;
    setDefualtValidation(): void;
    checkValidation(offer: NewOffer | Offer): boolean;
    changeValidation(newValidation: OfferValidation): void;
}

export interface OfferValidation {
    title: boolean;
    urlPattern: boolean;
}

const DEFAULT_VALIDATION: OfferValidation = {
    title: false,
    urlPattern: false,
};

export class OfferEditValidation implements OfferEditValidationInterface {
    public validation: OfferValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: OfferValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (offer: NewOffer | Offer): boolean => {
        const regUrl: RegExp = /^(ftp|http|https):\/\/[^ "]+$/;

        !offer.title ? (this.validation.title = true) : (this.validation.title = false);
        !regUrl.test(offer.urlPattern) ? (this.validation.urlPattern = true) : (this.validation.urlPattern = false);

        return Object.values(this.validation).includes(true);
    };
}
