import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../stores";
import { useHistory, useParams } from "react-router-dom";
import { usePasswordResetValidation } from "./PasswordReset";
import { Button } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CircularProgress from "@mui/material/CircularProgress";

interface ParamsProps {
    token: string;
}

export const ResetButton = observer(() => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { token } = useParams<ParamsProps>();
    const { checkValidation } = usePasswordResetValidation();
    const { passwordResetData, resetPassword } = useStores().authStore;
    const progress: JSX.Element = <CircularProgress size="1.8rem" color="inherit" className="mr-2" disableShrink />;

    const changePath = (): void => {
        history.push(`/spa/auth`);
    };

    const reset = (): void => {
        checkValidation(passwordResetData)
            ? enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 1500 })
            : resetPassword(token)
                  .then((response) => {
                      enqueueSnackbar("Пароль успешно сброшен!", { variant: "success", autoHideDuration: 1000 });
                      setTimeout(
                          () =>
                              enqueueSnackbar("Переадресация", {
                                  action: progress,
                                  variant: "warning",
                                  autoHideDuration: 2000,
                              }),
                          1000
                      );
                      setTimeout(changePath, 3000);
                  })
                  .catch((error) => {
                      enqueueSnackbar("Упс..", { variant: "error", autoHideDuration: 1500 });
                  });
    };

    return (
        <Button variant="contained" startIcon={<RestartAltIcon />} onClick={action(reset)}>
            Сбросить
        </Button>
    );
});
