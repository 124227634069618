import { observer } from "mobx-react";
import { useSelectedOffers } from "../";
import { useStores } from "../../../stores";
import { useUserRoles } from "../../../hooks/useUserRoles";
import { OfferAddButton, OfferEditButton, OfferViewButton, OfferCloneButton, OfferDeleteButton } from "./";
import "../../../style/actionButtons.css";

export const ActionButtons = observer(() => {
    const { selectedOffers } = useSelectedOffers();
    const { id } = useStores().currentUserStore.currentUser;
    const { isMediaBuyer } = useUserRoles();

    const displayEditButton = (): JSX.Element => {
        if (selectedOffers.length === 1 && isMediaBuyer()) {
            const [userId] = selectedOffers.map((item) => item.userId);

            const isUserIdEqual = (): boolean => {
                return id === userId;
            };

            return isUserIdEqual() ? <OfferEditButton /> : <OfferViewButton />;
        } else {
            return <OfferEditButton />;
        }
    };

    const displayDeleteButton = (): JSX.Element | null => {
        return isMediaBuyer() ? null : <OfferDeleteButton />;
    };

    return (
        <div className="d-flex ml-4 pt-3">
            <OfferAddButton />
            {displayEditButton()}
            <OfferCloneButton />
            {displayDeleteButton()}
        </div>
    );
});
