import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";
import { Source, SourcePostback } from "../../../../entities/SourceEdit";
import { Checkbox, FormControlLabel } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const PostbackIsActive = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { source, changeSource } = useStores().sourceEditStore;

    const changeIsActive = (): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));

        newPostback.isActive = !newPostback.isActive;
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
    };

    return (
        <FormControlLabel
            disabled={mode === Mode.VIEW}
            label={postback.isActive ? "Активен" : "Не активен"}
            className={
                postback.isActive ? "postback-active postback-active_succes" : "postback-active postback-active_error"
            }
            control={
                <Checkbox
                    size="small"
                    checked={postback.isActive}
                    indeterminate={!postback.isActive}
                    color={postback.isActive ? "success" : "error"}
                />
            }
            onChange={action(changeIsActive)}
        />
    );
});
