export enum SortField {
    ID = "id",
    TITLE = "title",
}

interface ConvertField {
    id: string;
    title: string;
    [key: string]: string;
}

export const convertField: ConvertField = {
    id: "#",
    title: "Наименование",
};
