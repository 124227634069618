import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useLandingsIds } from "../Landings";
import { Landing, LandingsCriteria } from "../../../entities/Landings";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { SortField, convertField } from "../../../module/Enum/Landings/SortField";
import { Checkbox } from "@mui/material";

export const TableHead = observer(() => {
    const { landingsIds, setLandingsIds } = useLandingsIds();
    const { landingsData, criteria, changeCriteria } = useStores().landingsStore;

    const handleChangeAllId = (): void => {
        const landingsIdsCopy: number[] = Object.assign([], landingsIds);
        const booleanArray: boolean[] = [];

        landingsData.items.forEach((item: Landing) => {
            booleanArray.push(landingsIdsCopy.includes(item.id));
            if (!landingsIdsCopy.includes(item.id)) landingsIdsCopy.push(item.id);
        });
        !booleanArray.includes(false) ? setLandingsIds([]) : setLandingsIds(landingsIdsCopy);
    };

    const sortTable = (field: SortField): void => {
        const newCriteria: LandingsCriteria = JSON.parse(JSON.stringify(criteria));

        newCriteria.sort.field = field;
        newCriteria.sort.direction =
            criteria.sort.field === field && criteria.sort.direction === SortDirection.ASC
                ? SortDirection.DESC
                : SortDirection.ASC;

        changeCriteria(newCriteria);
    };

    const createArrow = (field: SortField): any => {
        switch (true) {
            case criteria.sort.field === field && criteria.sort.direction === SortDirection.ASC:
                return (
                    <div className="arrow">
                        <span className="up" />
                    </div>
                );
            case criteria.sort.field === field && criteria.sort.direction === SortDirection.DESC:
                return (
                    <div className="arrow">
                        <span className="down" />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <thead>
            <tr>
                <th className="table__th-width pb-1" onClick={handleChangeAllId}>
                    <Checkbox
                        size="small"
                        checked={landingsData.items.length !== 0 && landingsIds.length === landingsData.items.length}
                    />
                </th>
                {Object.values(SortField).map((value: SortField, index: number) => (
                    <th
                        key={value}
                        className={index === 0 ? "table__th-width cursor_pointer" : "cursor_pointer"}
                        onClick={action(() => sortTable(value))}
                    >
                        <div className="table__text-position">{convertField[value]}</div>
                        {createArrow(value)}
                    </th>
                ))}
            </tr>
        </thead>
    );
});
