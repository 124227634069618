import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../module/Enum/Mode";
import { OfferEditBody } from "./OfferEditBody";
import { useValidations } from "../../validation";
import { OfferEditHeader } from "./OfferEditHeader";
import { OfferEditFooter } from "./OfferEditFooter";
import { Modal } from "@mui/material";
import "../../style/offerEdit.css";

interface Props {
    offerId?: string | undefined;
    campaignEditNotice?: boolean;
    setNewOffer?(offerId: number): void;
    closeOfferEdit(): void;
}

interface ParamsProps {
    mode: Mode;
}

export const OfferEdit = observer(({ offerId, campaignEditNotice, setNewOffer, closeOfferEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { setDefualtValidation } = useValidations().offerEditValidation;
    const { findOffer, cloneOffer, cleanUpOfferEdit } = useStores().offerEditStore;

    useEffect(() => {
        switch (mode) {
            case Mode.EDIT:
                offerId && findOffer(offerId);
                break;
            case Mode.VIEW:
                offerId && findOffer(offerId);
                break;
            case Mode.CLONE:
                offerId && cloneOffer(offerId);
                break;
            default:
                cleanUpOfferEdit();
                break;
        }
    }, [offerId, mode, findOffer, cloneOffer, cleanUpOfferEdit]);

    useEffect(() => {
        return () => {
            cleanUpOfferEdit();
            setDefualtValidation();
        };
    }, [cleanUpOfferEdit, setDefualtValidation]);

    return (
        <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true" autoComplete="off">
            <Modal open>
                <div
                    role="document"
                    className={
                        campaignEditNotice
                            ? "modal-dialog modal-dialog-centered campaign-modal_width"
                            : "modal-dialog modal-dialog-centered modal_width"
                    }
                >
                    <div className="modal-content">
                        <OfferEditHeader closeOfferEdit={closeOfferEdit} />
                        <OfferEditBody />
                        {mode !== Mode.VIEW && (
                            <OfferEditFooter setNewOffer={setNewOffer} closeOfferEdit={closeOfferEdit} />
                        )}
                    </div>
                </div>
            </Modal>
        </form>
    );
});
