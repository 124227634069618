import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useHistory } from "react-router-dom";
import { useValidations } from "../../../validation";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export const DomainEditSaveButton = observer(() => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { findDomains } = useStores().domainsStore;
    const { domain, saveDomain } = useStores().domainEditStore;
    const { checkValidation } = useValidations().domainEditValidation;

    const changePath = (id: number | undefined): void => {
        id && history.push(`/spa/domains/edit/${id}`);
    };

    const save = (): void => {
        checkValidation(domain)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveDomain().then((response) => {
                  if (response) {
                      findDomains();
                      changePath(response.id);
                      enqueueSnackbar("Домен сохранен", { variant: "success", autoHideDuration: 1500 });
                  }
              });
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(save)}>
            Сохранить
        </Button>
    );
});
