import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { Offer } from "../../../entities/OfferEdit";
import { useValidations } from "../../../validation";
import { OfferValidation } from "../../../validation/OfferEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const OfferUrlPattern = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { offer, changeOffer } = useStores().offerEditStore;
    const { validation, changeValidation } = useValidations().offerEditValidation;

    const changeUrlPattern = (event: ChangeEvent<HTMLInputElement>): void => {
        const newOffer: Offer = JSON.parse(JSON.stringify(offer));

        newOffer.urlPattern = event.target.value;

        changeOffer(newOffer);
        setUrlPatternValidation();
    };

    const setUrlPatternValidation = (): void => {
        const newValidation: OfferValidation = JSON.parse(JSON.stringify(validation));

        newValidation.urlPattern = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field mt-5">
            <h5>Url</h5>
            <TextField
                fullWidth
                multiline
                minRows={5}
                value={offer.urlPattern}
                error={validation.urlPattern}
                helperText={validation.urlPattern && "Url должно быть вида https://example.com"}
                onChange={action(changeUrlPattern)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
