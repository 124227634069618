import { Switch, Route, Redirect } from "react-router-dom";
import { Authorization } from "../components/Authorization";
import { PasswordReset } from "../components/PasswordReset";

export const AuthRoutes = () => (
    <Switch>
        <Route path="/spa/auth" component={Authorization} />
        <Route path="/spa/reset-password/:token?" component={PasswordReset} />
        <Route path="*" render={() => <Redirect to="/spa/auth" />} />
    </Switch>
);
