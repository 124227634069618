import { SourceSaveButton, SourceSaveAndCloseButton } from "./";

interface Props {
    closeSourceEdit(): void;
}

export const SourceEditFooter = ({ closeSourceEdit }: Props) => (
    <div className="modal-footer">
        <SourceSaveButton />
        <SourceSaveAndCloseButton closeSourceEdit={closeSourceEdit} />
    </div>
);
