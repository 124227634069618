import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";
import { useValidations } from "../../../../validation";
import { Source, SourceParameter } from "../../../../entities/SourceEdit";
import { SourceValidation } from "../../../../validation/SourceEditValidation";
import { TextField } from "@mui/material";

interface Props {
    parameterIndex: number;
    parameter: SourceParameter;
}

interface ParamsProps {
    mode: Mode;
}

export const ParameterName = observer(({ parameter, parameterIndex }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { source, changeSource } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().sourceEditValidation;
    const errorText: string = "Поле не может быть пустым";
    const fieldName: string = `parameterName${parameterIndex}`;

    const changeParameterName = (event: ChangeEvent<{ value: string; name: string }>): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newParameter: SourceParameter = JSON.parse(JSON.stringify(parameter));

        newParameter.name = event.target.value;
        newSource.parameters.splice(parameterIndex, 1, newParameter);

        changeSource(newSource);
        setParameterNameValidation(event.target.name);
    };

    const setParameterNameValidation = (name: string): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.parameters[name] = false;
        changeValidation(newValidation);
    };

    return (
        <div className="container-fluid pl-0">
            <TextField
                fullWidth
                variant="outlined"
                autoComplete="off"
                label="Наименование"
                name={fieldName}
                value={parameter.name}
                error={validation.parameters[fieldName]}
                helperText={validation.parameters[fieldName] && errorText}
                onChange={action(changeParameterName)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
