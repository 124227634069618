import { useState, MouseEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReportRequest, Filter } from "../../../../entities/Report";
import { Expression } from "../../../../module/Enum/Expression";
import { FilterField } from "../../../../module/Enum/Report/FilterField";
import { convertField } from "../../../../module/Enum/Statistics/SortField";
import { Menu, Button, MenuItem } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export const FilterAddButton = observer(() => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;
    const [anchorElFilter, setAnchorElFilter] = useState<null | HTMLElement>(null);

    const openMenu = (event: MouseEvent<HTMLButtonElement>): void => setAnchorElFilter(event.currentTarget);

    const closeMenu = (): void => setAnchorElFilter(null);

    const addFilter = (field: FilterField): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));
        const newFilterField: Filter = {
            field: field,
            expression: Expression.MORE,
            value: 100,
        };

        newReportRequest.filter.push(newFilterField);

        changeReportRequest(newReportRequest);
        closeMenu();
    };

    return (
        <div>
            <Button
                size="small"
                color="success"
                id="filter-button"
                variant="outlined"
                aria-haspopup="true"
                aria-controls="filter-menu"
                startIcon={<AddBoxOutlinedIcon />}
                aria-expanded={Boolean(anchorElFilter) ? "true" : undefined}
                onClick={openMenu}
            >
                Добавить
            </Button>
            <Menu
                keepMounted
                id="filter-menu"
                anchorEl={anchorElFilter}
                open={Boolean(anchorElFilter)}
                MenuListProps={{
                    "aria-labelledby": "filter-button",
                }}
                onClose={closeMenu}
            >
                {Object.values(FilterField).map((field: FilterField, index: number) => (
                    <MenuItem key={index} value={convertField[field]} onClick={action(() => addFilter(field))}>
                        {convertField[field]}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});
