import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSnackbar } from "notistack";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";

export const CampaignEditTitle = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { campaign } = useStores().campaignEditStore;

    return campaign.id === undefined ? (
        <h4 className="modal-title">Новая кампания</h4>
    ) : (
        <div className="d-flex">
            <h4 className="modal-title mr-3">Кампания «{campaign.title}»</h4>
            <CopyToClipboard text={campaign.title}>
                <Button color="inherit" onClick={() => enqueueSnackbar("Название скопировано", { variant: "success" })}>
                    <FileCopyOutlinedIcon fontSize="small" />
                </Button>
            </CopyToClipboard>
        </div>
    );
});
