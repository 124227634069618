import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReportRequest, DefaultSort, ParameterSort } from "../../../../entities/Report";
import { SortDirection, convertDirection } from "../../../../module/Enum/Sort/SortDirection";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface Props {
    sortIndex: number;
    sortItem: DefaultSort | ParameterSort;
}

export const SortDirectionField = observer(({ sortIndex, sortItem }: Props) => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;

    const changeSortDirection = (event: SelectChangeEvent<SortDirection>): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));
        const newSortItem: DefaultSort | ParameterSort = JSON.parse(JSON.stringify(sortItem));

        newSortItem.direction = event.target.value as SortDirection;
        newReportRequest.sort.splice(sortIndex, 1, newSortItem);

        changeReportRequest(newReportRequest);
    };

    return (
        <FormControl fullWidth variant="standard">
            <InputLabel>Сортировка</InputLabel>
            <Select label="Сортировка" value={sortItem.direction} onChange={action(changeSortDirection)}>
                {Object.values(SortDirection).map((direction: SortDirection, index: number) => (
                    <MenuItem key={index} value={direction}>
                        {convertDirection[direction]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
