import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Campaign } from "../../../../entities/CampaignEdit";
import { LimitType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface Props {
    checkUnlimited: boolean;
}

interface ConvertName {
    [key: string]: string;
}

const convertName: ConvertName = {
    scheduledHour: "В час",
    day: "В сутки",
    percent: "В процентах",
};

const hoursArray: number[] = new Array(24).fill(1).map((number: number, index: number) => index);

export const PostbackLimitType = observer(({ checkUnlimited }: Props) => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const changePostbackLimitType = (event: SelectChangeEvent<LimitType>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        switch (event.target.value) {
            case LimitType.DAY:
                newCampaign.postbackSettings = {
                    limitType: event.target.value as LimitType,
                    limitValue: checkUnlimited ? "-1" : "0",
                    probabilityPercent: 100,
                };
                break;

            case LimitType.PERCENT:
                newCampaign.postbackSettings = {
                    limitType: event.target.value as LimitType,
                    limitValue: checkUnlimited ? "100" : "0",
                };
                newCampaign.goals = newCampaign.goals.map((goal) => {
                    goal.limitValue = "100";
                    return goal;
                });
                break;

            case LimitType.SCHEDULED_HOUR:
                newCampaign.postbackSettings = {
                    limitType: event.target.value as LimitType,
                    limitValue: checkUnlimited ? "-1" : "0",
                    schedule: hoursArray,
                    probabilityPercent: 100,
                };
                break;
        }

        changeCampaign(newCampaign);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>Тип</InputLabel>
            <Select label="Тип" value={campaign.postbackSettings.limitType} onChange={action(changePostbackLimitType)}>
                {Object.values(LimitType).map((value: string) => (
                    <MenuItem key={value} value={value}>
                        {convertName[value]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
