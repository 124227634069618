import { CampaignEditTitle, CampaignEditCloseButton } from "./";

interface Props {
    closeCampaignEdit(): void;
}

export const CampaignEditHeader = ({ closeCampaignEdit }: Props) => (
    <div className="modal-header">
        <CampaignEditTitle />
        <CampaignEditCloseButton closeCampaignEdit={closeCampaignEdit} />
    </div>
);
