import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useFormCriteria } from "./CampaignsForm";
import { CampaignCriteria } from "../../../entities/Campaigns";
import { Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const CampaignsFilterButton = observer(() => {
    const { formCriteria } = useFormCriteria();
    const { criteria, changeCriteria } = useStores().campaignsStore;

    const filterTable = (): void => {
        const newCriteria: CampaignCriteria = JSON.parse(JSON.stringify(criteria));

        newCriteria.dateFrom = formCriteria.dateFrom;
        newCriteria.dateTo = formCriteria.dateTo;
        newCriteria.onlyWithClick = formCriteria.onlyWithClick;
        newCriteria.page = 1;

        changeCriteria(newCriteria);
    };

    return (
        <Button variant="contained" startIcon={<FilterAltIcon />} onClick={action(filterTable)}>
            Фильтровать
        </Button>
    );
});
