import { useState } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { ReportRequest } from "../../../entities/Report";
import { CampaignSearchItem } from "../../../entities/CampaignSearch";
import { CampaignSearchInput } from "../../CampaignSearch/CampaignSearch";

export const CampaignSearch = observer(() => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;
    const [selectedСampaigns, setSelectedСampaigns] = useState<CampaignSearchItem[]>([]);

    const changeCampaignIds = (event: object, value: CampaignSearchItem[]): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));

        newReportRequest.campaignIds = value.map((item: CampaignSearchItem) => item.id);

        setSelectedСampaigns(value);
        changeReportRequest(newReportRequest);
    };

    return (
        <CampaignSearchInput selectedСampaigns={selectedСampaigns} onChangeCampaignIds={action(changeCampaignIds)} />
    );
});
