import { UserSearch } from ".";

export const UsersForm = () => (
    <form className="pt-4 pb-4">
        <div className="d-flex align-items-center ml-4">
            <div className="w-25">
                <UserSearch />
            </div>
        </div>
    </form>
);
