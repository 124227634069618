import { AxiosResponse } from "axios";
import { UsersApi } from "../services/api/UsersApi";
import { makeAutoObservable, runInAction } from "mobx";
import { UsersData, UsersCriteria } from "../entities/Users";

export interface UsersStoreInterface {
    usersData: UsersData;
    criteria: UsersCriteria;
    setDefaultCriteria(): void;
    findUsers(): Promise<UsersData>;
    changeCriteria(newCriteria: UsersCriteria): void;
    removeUser(userId: number): Promise<AxiosResponse>;
}

const DEFAULT_USERS_CRITERIA: UsersCriteria = {
    search: "",
    page: 1,
    itemsPerPage: 10,
};

const { getUsers, deleteUser } = UsersApi;

export class UsersStore implements UsersStoreInterface {
    public criteria: UsersCriteria = DEFAULT_USERS_CRITERIA;

    public usersData: UsersData = {
        items: [],
        totalItems: 0,
    };

    constructor() {
        makeAutoObservable(this);
    }

    public setDefaultCriteria = (): void => {
        this.criteria = DEFAULT_USERS_CRITERIA;
    };

    public changeCriteria = (newCriteria: UsersCriteria): void => {
        this.criteria = newCriteria;
    };

    public findUsers = async (): Promise<UsersData> => {
        await getUsers(this.criteria).then((response: AxiosResponse<UsersData>) => {
            runInAction(() => {
                this.usersData = response.data;
            });
        });
        return this.usersData;
    };

    public removeUser = async (userId: number): Promise<AxiosResponse> => {
        return await deleteUser(userId).then((response: AxiosResponse) => {
            return runInAction(() => {
                this.findUsers();
                return response;
            });
        });
    };
}
