import { Mode } from "../../module/Enum/Mode";
import { Sources } from "../../components/Sources";
import { UserEdit } from "../../components/UserEdit";
import { SourceEdit } from "../../components/SourceEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    sourceId: string;
}

export const SourcesRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, sourceId } = useParams<ParamsProps>();

    const closeSourceEdit = (): void => {
        history.push("/spa/sources");
    };

    switch (mode) {
        case Mode.ADD:
            content = <SourceEdit sourceId={sourceId} closeSourceEdit={closeSourceEdit} />;
            break;
        case Mode.EDIT:
            content = <SourceEdit sourceId={sourceId} closeSourceEdit={closeSourceEdit} />;
            break;
        case Mode.VIEW:
            content = <SourceEdit sourceId={sourceId} closeSourceEdit={closeSourceEdit} />;
            break;
        case Mode.CLONE:
            content = <SourceEdit sourceId={sourceId} closeSourceEdit={closeSourceEdit} />;
            break;
        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeSourceEdit} />;
            break;
        default:
            content = null;
            break;
    }

    return (
        <>
            <Sources />
            {content}
        </>
    );
};
