import { format } from "date-fns";
import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { StatisticsApi } from "../services/api/StatisticsApi";
import { SortField } from "../module/Enum/Statistics/SortField";
import { SortDirection } from "../module/Enum/Sort/SortDirection";
import { TypeGrouping, DefaultGrouping } from "../module/Enum/Grouping/Grouping";
import { GroupByData, StatisticsData, StatisticsCriteria, СonvertedStatisticsCriteria } from "../entities/Statistics";

export interface StatisticsStoreInterface {
    statisticsData: StatisticsData;
    findStatistics(criteria: StatisticsCriteria): Promise<StatisticsData>;
}

export const DEFAULT_STATISTICS_CRITERIA: StatisticsCriteria = {
    campaignIds: [],
    dateFrom: new Date(),
    dateTo: new Date(),
    groupBy: [
        {
            type: TypeGrouping.DEFAULT,
            value: DefaultGrouping.DATE,
        },
    ],
    page: 1,
    itemsPerPage: 10,
    sort: {
        type: TypeGrouping.DEFAULT,
        field: SortField.DATE,
        direction: SortDirection.ASC,
    },
};

export const DEFAULT_STATISTICS_DATA: StatisticsData = {
    items: [],
    summary: {
        clickCount: 0,
        leadCount: 0,
        postbackCount: 0,
        landingClickCount: 0,
        cvr: 0,
        revenue: 0,
        cost: 0,
    },
    totalItems: 0,
};

const { getStatistics } = StatisticsApi;

export class StatisticsStore implements StatisticsStoreInterface {
    public statisticsData: StatisticsData = DEFAULT_STATISTICS_DATA;

    constructor() {
        makeAutoObservable(this);
    }

    public findStatistics = async (criteria: StatisticsCriteria): Promise<StatisticsData> => {
        await getStatistics(this.createCriteriaForStatisticsRequest(criteria)).then(
            (response: AxiosResponse<StatisticsData>) => {
                runInAction(() => {
                    this.statisticsData = response.data;
                });
            }
        );
        return this.statisticsData;
    };

    private createCriteriaForStatisticsRequest = (criteria: StatisticsCriteria): СonvertedStatisticsCriteria => {
        const urlParameters: СonvertedStatisticsCriteria | any = {
            campaignIds: criteria.campaignIds,
            dateFrom: format(criteria.dateFrom, "yyyy-MM-dd"),
            dateTo: format(criteria.dateTo, "yyyy-MM-dd"),
            page: criteria.page,
            itemsPerPage: criteria.itemsPerPage,
        };

        urlParameters["sort[type]"] = criteria.sort.type;
        criteria.sort.type === TypeGrouping.DEFAULT
            ? (urlParameters["sort[field]"] = criteria.sort.field)
            : (urlParameters["sort[parameter]"] = criteria.sort.parameter);
        urlParameters["sort[direction]"] = criteria.sort.direction;

        criteria.groupBy
            .filter((group: GroupByData) => group.value !== "none")
            .forEach((group: GroupByData, index: number) => {
                urlParameters[`groupBy[${index}][type]`] = group.type;
                urlParameters[`groupBy[${index}][value]`] = group.value;
            });

        return urlParameters;
    };
}
