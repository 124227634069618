import { createContext, useContext } from "react";
import { AuthStore, AuthStoreInterface } from "./AuthStore";
import { TeamsStore, TeamsStoreInterface } from "./TeamsStore";
import { UsersStore, UsersStoreInterface } from "./UsersStore";
import { OffersStore, OffersStoreInterface } from "./OffersStore";
import { ReportStore, ReportStoreInterface } from "./ReportStore";
import { FiltersStore, FiltersStoreInterface } from "./FiltersStore";
import { SourcesStore, SourcesStoreInterface } from "./SourcesStore";
import { DomainsStore, DomainsStroreInterface } from "./DomainsStrore";
import { LandingsStore, LandingsStoreInterface } from "./LandingsStore";
import { TimezoneStore, TimezoneStoreInterface } from "./TimezoneStore";
import { TeamEditStore, TeamEditStoreInterface } from "./TeamEditStore";
import { UserEditStore, UserEditStoreInterface } from "./UserEditStore";
import { CampaignsStore, CampaignsStoreInterface } from "./CampaignsStore";
import { OfferEditStore, OfferEditStoreInterface } from "./OfferEditStore";
import { CountriesStore, CountriesStoreInterface } from "./CountriesStore";
import { DomainEditStore, DomainEditStoreInterface } from "./DomainEditStore";
import { StatisticsStore, StatisticsStoreInterface } from "./StatisticsStore";
import { SourceEditStore, SourceEditStoreInterface } from "./SourceEditStore";
import { CurrentUserStore, CurrentUserStoreInterface } from "./CurrentUserStore";
import { LandingEditStore, LandingEditStoreInterface } from "./LandingEditStore";
import { CampaignEditStore, CampaignEditStoreInterface } from "./CampaignEditStore";
import { CampaignLinksStore, CampaignLinksStoreInterface } from "./CampaignLinksStrore";
import { CampaignSearchStore, CampaignSearchStoreInterface } from "./CampaignSearchStore";
import { SourceParametersStore, SourceParametersStoreInterface } from "./SourceParametersStore";

export interface RootStoreInterface {
    authStore: AuthStoreInterface;
    teamsStore: TeamsStoreInterface;
    usersStore: UsersStoreInterface;
    offersStore: OffersStoreInterface;
    reportStore: ReportStoreInterface;
    filtersStore: FiltersStoreInterface;
    sourcesStore: SourcesStoreInterface;
    domainsStore: DomainsStroreInterface;
    landingsStore: LandingsStoreInterface;
    timezoneStore: TimezoneStoreInterface;
    teamEditStore: TeamEditStoreInterface;
    userEditStore: UserEditStoreInterface;
    campaignsStore: CampaignsStoreInterface;
    offerEditStore: OfferEditStoreInterface;
    countriesStore: CountriesStoreInterface;
    domainEditStore: DomainEditStoreInterface;
    statisticsStore: StatisticsStoreInterface;
    sourceEditStore: SourceEditStoreInterface;
    currentUserStore: CurrentUserStoreInterface;
    landingEditStore: LandingEditStoreInterface;
    campaignEditStore: CampaignEditStoreInterface;
    campaignLinksStore: CampaignLinksStoreInterface;
    campaignSearchStore: CampaignSearchStoreInterface;
    sourceParametersStore: SourceParametersStoreInterface;
}

export const RootStore: RootStoreInterface = {
    authStore: new AuthStore(),
    teamsStore: new TeamsStore(),
    usersStore: new UsersStore(),
    offersStore: new OffersStore(),
    reportStore: new ReportStore(),
    filtersStore: new FiltersStore(),
    sourcesStore: new SourcesStore(),
    domainsStore: new DomainsStore(),
    landingsStore: new LandingsStore(),
    timezoneStore: new TimezoneStore(),
    teamEditStore: new TeamEditStore(),
    userEditStore: new UserEditStore(),
    campaignsStore: new CampaignsStore(),
    offerEditStore: new OfferEditStore(),
    countriesStore: new CountriesStore(),
    domainEditStore: new DomainEditStore(),
    statisticsStore: new StatisticsStore(),
    sourceEditStore: new SourceEditStore(),
    currentUserStore: new CurrentUserStore(),
    landingEditStore: new LandingEditStore(),
    campaignEditStore: new CampaignEditStore(),
    campaignLinksStore: new CampaignLinksStore(),
    campaignSearchStore: new CampaignSearchStore(),
    sourceParametersStore: new SourceParametersStore(),
};

export const RootStoreContext = createContext<RootStoreInterface>(RootStore);

export const useStores = (): RootStoreInterface => {
    return useContext(RootStoreContext);
};
