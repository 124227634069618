import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useValidations } from "../../validation";
import { ReportData } from "../../entities/Report";
import { ReportForm } from "./ReportForm";
import { ReportTable } from "./ReportTable";
import { ReportPagination, ErrorMessage } from "./";
import "../../style/delta.css";

interface ErrorContextInterface {
    error: boolean;
    setError: Dispatch<SetStateAction<boolean>>;
}

interface ReportDataContextInterface {
    reportData: ReportData | undefined;
    setReportData: Dispatch<SetStateAction<ReportData | undefined>>;
}

interface PaginationContextInterface {
    pagination: Pagination;
    setPagination: Dispatch<SetStateAction<Pagination>>;
}

interface Pagination {
    currentPage: number;
    itemsPerPage: number;
}

export const Report = observer(() => {
    const { setDefaultReportRequest } = useStores().reportStore;
    const { setDefualtValidation } = useValidations().reportValidation;
    const { findSourceParameters, cleanUpSourceParameters } = useStores().sourceParametersStore;
    const [error, setError] = useState<boolean>(false);
    const [reportData, setReportData] = useState<ReportData | undefined>(undefined);
    const [pagination, setPagination] = useState<Pagination>({
        currentPage: 1,
        itemsPerPage: 100,
    });

    useEffect(() => {
        findSourceParameters();
    }, [findSourceParameters]);

    useEffect(() => {
        return () => {
            setDefualtValidation();
            setDefaultReportRequest();
            cleanUpSourceParameters();
        };
    }, [setDefualtValidation, setDefaultReportRequest, cleanUpSourceParameters]);

    return (
        <ReportDataContext.Provider value={{ reportData, setReportData }}>
            <PaginationContext.Provider value={{ pagination, setPagination }}>
                <ErrorContext.Provider value={{ error, setError }}>
                    <div className="container-fluid">
                        <div className="row align-items-center ml-4">
                            <h1 className="mr-2">Дельта</h1>
                        </div>
                    </div>
                    <div className="container-fluid bg-light">
                        <ReportForm />
                        {reportData !== undefined && <ReportTable />}
                        {reportData !== undefined && <ReportPagination />}
                        {error && <ErrorMessage />}
                    </div>
                </ErrorContext.Provider>
            </PaginationContext.Provider>
        </ReportDataContext.Provider>
    );
});

const ErrorContext = createContext<ErrorContextInterface>({} as ErrorContextInterface);
const ReportDataContext = createContext<ReportDataContextInterface>({} as ReportDataContextInterface);
const PaginationContext = createContext<PaginationContextInterface>({} as PaginationContextInterface);

export const useError = (): ErrorContextInterface => {
    return useContext(ErrorContext);
};

export const useReportData = (): ReportDataContextInterface => {
    return useContext(ReportDataContext);
};

export const usePagination = (): PaginationContextInterface => {
    return useContext(PaginationContext);
};
