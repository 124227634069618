import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useHistory } from "react-router-dom";
import { useValidations } from "../../../validation";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export const LandingEditSaveButton = observer(() => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { landing, saveLanding } = useStores().landingEditStore;
    const { checkValidation } = useValidations().landingEditValidation;

    const changePath = (id: number | undefined): void => {
        id && history.push(`/spa/landings/edit/${id}`);
    };

    const save = (): void => {
        checkValidation(landing)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveLanding()
                  .then((response) => {
                      if (response) {
                          changePath(response.id);
                          enqueueSnackbar("Лендинг сохранен", { variant: "success", autoHideDuration: 1500 });
                      }
                  })
                  .catch((error) => {
                      if (error.response?.status === 403) {
                          enqueueSnackbar("У вас нет доступа к редактированию", {
                              variant: "error",
                              autoHideDuration: 1500,
                          });
                      }
                  });
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(save)}>
            Сохранить
        </Button>
    );
});
