import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../module/Enum/Mode";
import { useValidations } from "../../validation";
import { SourceEditBody } from "./SourceEditBody";
import { SourceEditHeader } from "./SourceEditHeader";
import { SourceEditFooter } from "./SourceEditFooter";
import { Modal } from "@mui/material";
import "../../style/sourceEdit.css";

interface Props {
    sourceId: string | undefined;
    closeSourceEdit(): void;
}

interface ParamsProps {
    mode: Mode;
}

export const SourceEdit = observer(({ sourceId, closeSourceEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { setDefualtValidation } = useValidations().sourceEditValidation;
    const { findSource, cloneSource, cleanUpSourceEdit } = useStores().sourceEditStore;

    useEffect(() => {
        switch (mode) {
            case Mode.EDIT:
                sourceId && findSource(sourceId);
                break;
            case Mode.VIEW:
                sourceId && findSource(sourceId);
                break;
            case Mode.CLONE:
                sourceId && cloneSource(sourceId);
                break;
            default:
                cleanUpSourceEdit();
                break;
        }
    }, [sourceId, mode, findSource, cloneSource, cleanUpSourceEdit]);

    useEffect(() => {
        return () => {
            cleanUpSourceEdit();
            setDefualtValidation();
        };
    }, [cleanUpSourceEdit, setDefualtValidation]);

    return (
        <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true" autoComplete="off">
            <Modal open>
                <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <SourceEditHeader closeSourceEdit={closeSourceEdit} />
                        <SourceEditBody />
                        {mode !== Mode.VIEW && <SourceEditFooter closeSourceEdit={closeSourceEdit} />}
                    </div>
                </div>
            </Modal>
        </form>
    );
});
