import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { Source } from "../../../entities/SourceEdit";
import { SourceValidation } from "../../../validation/SourceEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const SourceGlobalPostbackUrlPattern = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { source, changeSource } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().sourceEditValidation;

    const changeGlobalPostbackUrlPattern = (event: ChangeEvent<HTMLInputElement>): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        newSource.globalPostbackUrlPattern = event.target.value.replace(/%20/g, "").replace(/\s+/g, "");
        changeSource(newSource);
        setGlobalPostbackUrlPatternValidation();
    };

    const setGlobalPostbackUrlPatternValidation = (): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.globalPostbackUrlPattern = false;
        changeValidation(newValidation);
    };

    return (
        <div className="field mt-3">
            <h5>Глобал постбэк url</h5>
            <TextField
                fullWidth
                autoComplete="off"
                name="globalPostbackUrlPattern"
                value={source.globalPostbackUrlPattern}
                error={validation.globalPostbackUrlPattern}
                helperText={validation.globalPostbackUrlPattern && "Url должно быть вида https://example.com"}
                onChange={action(changeGlobalPostbackUrlPattern)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
