import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { SourcesApi } from "../services/api/SourcesApi";
import { Source, NewSource, SourceRequest } from "../entities/SourceEdit";

export interface SourceEditStoreInterface {
    isChangedData: boolean;
    source: NewSource | Source;
    cleanUpSourceEdit(): void;
    saveSource(): Promise<Source>;
    changeSource(newSource: Source): void;
    findSource(sourceId: string | number): Promise<Source>;
    cloneSource(sourceId: string | number): Promise<NewSource>;
}

const DEFAULT_SOURCE: NewSource | Source = {
    title: "",
    parameters: [],
    globalPostbackUrlPattern: "",
    postbacks: [],
    teamIds: [],
    color: "",
};

const { getSource, postSource, putSource } = SourcesApi;

export class SourceEditStore implements SourceEditStoreInterface {
    public source: NewSource | Source = DEFAULT_SOURCE;
    private initialSource: NewSource | Source = DEFAULT_SOURCE;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return JSON.stringify(this.source) === JSON.stringify(this.initialSource);
    }

    public changeSource = (newSource: Source): void => {
        this.source = newSource;
    };

    public cleanUpSourceEdit = (): void => {
        this.source = DEFAULT_SOURCE;
        this.initialSource = DEFAULT_SOURCE;
    };

    public findSource = async (sourceId: string | number): Promise<Source> => {
        await getSource(sourceId).then((response: AxiosResponse<Source>) => {
            runInAction(() => {
                this.source = response.data;
                this.initialSource = response.data;
            });
        });
        return this.source as Source;
    };

    public cloneSource = async (sourceId: string | number): Promise<NewSource> => {
        await getSource(sourceId).then((response: AxiosResponse<Source>) => {
            runInAction(() => {
                if (response) {
                    const newSource: NewSource = {
                        ...response.data,
                        title: response.data.title + " (clone)",
                    };
                    delete newSource.id;
                    this.source = newSource;
                }
            });
        });
        return this.source;
    };

    public saveSource = async (): Promise<Source> => {
        return this.source.id === undefined ? this.createSource() : this.editSource(this.source.id);
    };

    private createSource = async (): Promise<Source> => {
        return await postSource(this.createSourceRequest()).then((response: AxiosResponse<Source>) => {
            return runInAction(() => {
                this.source = response.data;
                this.initialSource = response.data;

                return response.data;
            });
        });
    };

    private editSource = async (sourceId: number): Promise<Source> => {
        return await putSource(sourceId, this.createSourceRequest()).then((response: AxiosResponse<Source>) => {
            return runInAction(() => {
                this.source = response.data;
                this.initialSource = response.data;

                return response.data;
            });
        });
    };

    private createSourceRequest = (): SourceRequest => {
        const sourceRequest: SourceRequest = {
            title: this.source.title,
            parameters: this.source.parameters,
            globalPostbackUrlPattern: this.source.globalPostbackUrlPattern,
            postbacks: this.source.postbacks,
            teamIds: this.source.teamIds,
            color: this.source.color,
        };

        return sourceRequest;
    };
}
