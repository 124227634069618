import { useEffect } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { User } from "../../../entities/UserEdit";
import { Timezone } from "../../../entities/Timezone";
import { FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";

export const UserTimezone = observer(() => {
    const { user, changeUser } = useStores().userEditStore;
    const { timezoneData, findTimezone } = useStores().timezoneStore;

    useEffect(() => {
        findTimezone();
    }, [findTimezone]);

    const changeTimezone = (event: SelectChangeEvent): void => {
        const newUser: User = JSON.parse(JSON.stringify(user));

        newUser.dateTimezone = event.target.value;

        changeUser(newUser);
    };

    return (
        <div className="field">
            <h5>Таймзона</h5>
            <FormControl fullWidth>
                <Select value={user.dateTimezone} onChange={action(changeTimezone)}>
                    {timezoneData.items.map((item: Timezone) => (
                        <MenuItem key={item.timezone} value={item.timezone}>
                            {item.timezone}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
});
