import { CampaignTitle, CampaignSource } from ".";

export const CampaignInformation = () => (
    <div>
        <h5>Общие данные</h5>
        <div className="mt-4">
            <CampaignTitle />
        </div>
        <div className="mt-4">
            <CampaignSource />
        </div>
    </div>
);
