import { action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";
import { Source, SourceParameter } from "../../../../entities/SourceEdit";
import { Button } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

interface ParamsProps {
    mode: Mode;
}

export const ParameterAddButton = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { source, changeSource } = useStores().sourceEditStore;

    const addParameter = () => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newParameter: SourceParameter = {
            name: "",
            parameter: "",
            sourceMacros: "",
        };

        newSource.parameters.push(newParameter);

        changeSource(newSource);
    };

    return (
        <Button
            color="success"
            className="mt-3"
            variant="outlined"
            disabled={mode === Mode.VIEW}
            startIcon={<AddBoxOutlinedIcon />}
            onClick={action(addParameter)}
        >
            Добавить параметр
        </Button>
    );
});
