import { useTeamsIds } from "../Teams";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const TeamEditButton = () => {
    const { url } = useRouteMatch();
    const { teamsIds } = useTeamsIds();
    const [id] = teamsIds;

    return (
        <div className="mr-2">
            <Link to={`${url}/edit/${id}`} className={teamsIds.length !== 1 ? "link_disabled" : "link"}>
                <Button size="small" variant="contained" startIcon={<EditIcon />} disabled={teamsIds.length !== 1}>
                    Изменить
                </Button>
            </Link>
        </div>
    );
};
