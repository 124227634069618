import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { Offer } from "../../../entities/OfferEdit";
import { useValidations } from "../../../validation";
import { OfferValidation } from "../../../validation/OfferEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const OfferTitle = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { offer, changeOffer } = useStores().offerEditStore;
    const { validation, changeValidation } = useValidations().offerEditValidation;

    const changeTitle = (event: ChangeEvent<HTMLInputElement>): void => {
        const newOffer: Offer = JSON.parse(JSON.stringify(offer));

        newOffer.title = event.target.value;

        setTitleValidation();
        changeOffer(newOffer);
    };

    const setTitleValidation = (): void => {
        const newValidation: OfferValidation = JSON.parse(JSON.stringify(validation));

        newValidation.title = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Наименование оффера</h5>
            <TextField
                fullWidth
                value={offer.title}
                error={validation.title}
                helperText={validation.title && "Поле не может быть пустым"}
                onChange={action(changeTitle)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
