import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { Campaign } from "../../../../../entities/CampaignEdit";
import { Button } from "@mui/material";

export const ScheduleDeleteAllButton = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const deleteAllHours = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.postbackSettings.schedule = [];

        changeCampaign(newCampaign);
    };

    return (
        <Button variant="text" onClick={action(deleteAllHours)}>
            Очистить все
        </Button>
    );
});
