import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { usePostback } from "..";
import { SourcePostbackUrlParameter } from "../../../../../entities/SourceEdit";
import { PostbackUrlParameterType } from "../../../../../module/Enum/SourceEdit/SourceEdit";
import {
    Info,
    ParameterType,
    ParameterAddButton,
    ParameterParameter,
    ParameterValueField,
    ParameterValueSelect,
    ParameterDeleteButton,
} from ".";

interface UrlParamsStateContextInterface {
    urlParams: SourcePostbackUrlParameter[];
    setUrlParams: Dispatch<SetStateAction<SourcePostbackUrlParameter[]>>;
}

export const PostbackParameters = () => {
    const { urlPattern } = usePostback().postback;
    const [url, urlParameters] = urlPattern.split("?");
    const [urlParams, setUrlParams] = useState<SourcePostbackUrlParameter[]>([]);

    useEffect(() => {
        if (url && urlParameters) {
            const newUrlParams: SourcePostbackUrlParameter[] = [];

            urlParameters.split("&").forEach((param: string) => {
                const [urlParameter, urlValue] = param.split("=");
                const newUrlParam: SourcePostbackUrlParameter = {
                    type: PostbackUrlParameterType.VALUE,
                    parameter: urlParameter,
                    value: urlValue,
                };
                if (urlValue)
                    switch (true) {
                        case urlValue.includes("{track_id}"):
                            newUrlParam.type = PostbackUrlParameterType.ID_CLICK_TRACK;
                            break;
                        case urlValue.includes("{timestamp}"):
                            newUrlParam.type = PostbackUrlParameterType.CURRENT_TIME;
                            break;
                        case urlValue.includes("{track.params."):
                            newUrlParam.type = PostbackUrlParameterType.SOURCE_PARAMS_FORWARDING;
                            break;
                        case urlValue.includes("{") && urlValue.includes("}"):
                            newUrlParam.type = PostbackUrlParameterType.GET_PARAMETER;
                            break;
                        default:
                            newUrlParam.type = PostbackUrlParameterType.VALUE;
                            break;
                    }
                newUrlParams.push(newUrlParam);
                setUrlParams(newUrlParams);
            });
        } else {
            setUrlParams([]);
        }
    }, [url, urlParameters]);

    return (
        <UrlParamsStateContext.Provider value={{ urlParams, setUrlParams }}>
            <div className="mt-4">
                <h5>Параметры</h5>
                <Info />
                {urlParams.map(
                    (parameter: SourcePostbackUrlParameter, index: number) =>
                        parameter && (
                            <div key={index} className="d-flex align-items-start mt-4">
                                <ParameterType parameter={parameter} parameterIndex={index} />
                                <ParameterParameter parameter={parameter} parameterIndex={index} />
                                {parameter.type === PostbackUrlParameterType.SOURCE_PARAMS_FORWARDING ? (
                                    <ParameterValueSelect parameter={parameter} parameterIndex={index} />
                                ) : (
                                    <ParameterValueField parameter={parameter} parameterIndex={index} />
                                )}
                                <ParameterDeleteButton parameterIndex={index} />
                            </div>
                        )
                )}

                <ParameterAddButton />
            </div>
        </UrlParamsStateContext.Provider>
    );
};

const UrlParamsStateContext = createContext<UrlParamsStateContextInterface>({} as UrlParamsStateContextInterface);

export const useUrlParamsState = (): UrlParamsStateContextInterface => {
    return useContext(UrlParamsStateContext);
};
