import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useMode } from "../UserEdit";
import { useStores } from "../../../stores";
import { Mode } from "../../../module/Enum/Mode";
import { User } from "../../../entities/UserEdit";
import { useValidations } from "../../../validation";
import { UserValidation } from "../../../validation/UserEditValidation";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { TextField, Tooltip, InputAdornment, IconButton } from "@mui/material";

export const UserPassword = observer(() => {
    const [showPass, setShowPass] = useState<boolean>(false);
    const { mode } = useMode();
    const { user, changeUser } = useStores().userEditStore;
    const { validation, changeValidation } = useValidations().userEditValidation;

    const handleChange = (): void => setShowPass(!showPass);

    const changePassword = (event: ChangeEvent<HTMLInputElement>): void => {
        const newUser: User = JSON.parse(JSON.stringify(user));

        newUser.password = event.target.value.replace(/\s/g, "");
        if (!event.target.value) delete newUser.password;

        changeUser(newUser);
        setPasswordValidation();
    };

    const setPasswordValidation = (): void => {
        const newValidation: UserValidation = JSON.parse(JSON.stringify(validation));

        newValidation.password = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <div className="d-flex align-items-center">
                <h5 className="mr-2">Пароль</h5>
                {mode !== Mode.ADD && (
                    <Tooltip title="Необязательное поле" placement="right" className="mb-1">
                        <HelpOutlineOutlinedIcon fontSize="small" className="shedule__tooltip" />
                    </Tooltip>
                )}
            </div>
            <TextField
                fullWidth
                value={user.password}
                autoComplete="new-password"
                error={validation.password}
                type={showPass ? "text" : "password"}
                helperText={validation.password && "Поле не может быть пустым"}
                onChange={action(changePassword)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleChange}>
                                {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        padding: 0,
                    },
                }}
            />
        </div>
    );
});
