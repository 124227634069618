import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useCondition } from "../";
import { useRoutingRule } from "../../";
import { useStores } from "../../../../../../stores";
import { useValidations } from "../../../../../../validation";
import { CampaignValidation } from "../../../../../../validation/CampaignEditValidation";
import { Campaign, Condition, RoutingRule } from "../../../../../../entities/CampaignEdit";
import { OperationSystems } from "../../../../../../module/Enum/CampaignEdit/CampaignEdit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, FormControl, FormHelperText, TextField } from "@mui/material";

export const ConditionOS = observer(() => {
    const { rule, ruleIndex } = useRoutingRule();
    const { condition, conditionIndex } = useCondition();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `${condition.type}${ruleIndex}${conditionIndex}`;

    const changeConditionOS = (event: object, value: OperationSystems[]): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newCondition: Condition = Object.assign({}, condition);

        newCondition.operationSystems = value;
        newRule.conditions.splice(conditionIndex, 1, newCondition);
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setConditionOSValidation();
    };

    const setConditionOSValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRuleConditions[fieldName] = false;

        changeValidation(newValidation);
    };

    return (
        <FormControl fullWidth error={validation.routingRuleConditions[fieldName]}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                size="small"
                limitTags={3}
                value={condition.operationSystems?.slice()}
                getOptionLabel={(option) => option}
                options={Object.values(OperationSystems)}
                onChange={(event, value) => runInAction(() => changeConditionOS(event, value))}
                renderInput={(params) => (
                    <TextField {...params} variant="standard" error={validation.routingRuleConditions[fieldName]} />
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            size="small"
                            checked={selected}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        />
                        {option}
                    </li>
                )}
            />
            {validation.routingRuleConditions[fieldName] && <FormHelperText>Поле не может быть пустым</FormHelperText>}
        </FormControl>
    );
});
