import { AxiosResponse } from "axios";
import { TimezoneData } from "../../entities/Timezone";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";

interface TimezoneApiInterface {
    getTimezone(): Promise<AxiosResponse<TimezoneData>>;
}

export const TimezoneApi: TimezoneApiInterface = {
    getTimezone: async (): Promise<AxiosResponse<TimezoneData>> =>
        await httpClient.get<TimezoneData>(`/api/v1/timezone`),
};
