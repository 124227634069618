export enum TypeGrouping {
    DEFAULT = "default",
    PARAMETER = "parameter",
}

export enum DefaultGrouping {
    DATE = "date",
    HOUR = "hour",
    GOAL = "goal",
    LANDING = "landing",
    CAMPAIGN = "campaign",
    MANAGER = "manager",
    SOURCE = "source",
    OFFER = "offer",
    URL = "url",
    BROWSER_FAMILY = "browserFamily",
    OPERATION_SYSTEM_FAMILY = "operationSystemFamily",
    DEVICE = "device",
    COUNTRY = "country",
}

export enum DropdownGrouping {
    DATE = "date",
    HOUR = "hour",
    GOAL = "goal",
    LANDING = "landing",
    MANAGER = "manager",
    SOURCE = "source",
    OFFER = "offer",
    URL = "url",
    BROWSER_FAMILY = "browserFamily",
    OPERATION_SYSTEM_FAMILY = "operationSystemFamily",
    DEVICE = "device",
    COUNTRY = "country",
}

interface ConvertValue {
    [key: string]: string;
}

export const convertType: ConvertValue = {
    default: "Основная",
    parameter: "Дополнительная",
};

export const convertGrouping: ConvertValue = {
    date: "По датам",
    hour: "По часам",
    goal: "По целям",
    landing: "По лендингам",
    campaign: "По кампаниям",
    manager: "По менеджерам",
    source: "По источникам",
    offer: "По офферам",
    url: "По url",
    browserFamily: "По браузерам",
    operationSystemFamily: "По ОС",
    device: "По устройствам",
    country: "По странам",
};
