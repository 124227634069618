import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { OffersPagination } from "./";
import { useStores } from "../../stores";
import { OffersForm } from "./OffersForm";
import { OffersTable } from "./OffersTable";
import { ActionButtons } from "./ActionButtons";
import { OfferItem } from "../../entities/Offers";

interface SelectedOffersContextInterface {
    selectedOffers: OfferItem[];
    setSelectedOffers: Dispatch<SetStateAction<OfferItem[]>>;
}

export const Offers = observer(() => {
    const [selectedOffers, setSelectedOffers] = useState<OfferItem[]>([]);
    const { criteria, findOffers, cleanUpOffers } = useStores().offersStore;

    useEffect(() => {
        findOffers(criteria);
    }, [criteria, findOffers]);

    useEffect(() => {
        setSelectedOffers([]);
    }, [criteria]);

    useEffect(() => {
        return cleanUpOffers;
    }, [cleanUpOffers]);

    return (
        <SelectedOffersContext.Provider value={{ selectedOffers, setSelectedOffers }}>
            <div className="container-fluid">
                <div className="ml-4">
                    <h1>Офферы</h1>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <OffersForm />
                    <ActionButtons />
                </div>
                <OffersTable />
                <OffersPagination />
            </div>
        </SelectedOffersContext.Provider>
    );
});

const SelectedOffersContext = createContext<SelectedOffersContextInterface>({} as SelectedOffersContextInterface);

export const useSelectedOffers = (): SelectedOffersContextInterface => {
    return useContext(SelectedOffersContext);
};
