import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReportRequest, Filter } from "../../../../entities/Report";
import { TextField } from "@mui/material";

interface Props {
    filterIndex: number;
    filterItem: Filter;
}

export const FilterValue = observer(({ filterIndex, filterItem }: Props) => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;

    const changeFilterValue = (event: ChangeEvent<HTMLInputElement>): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));
        const newFilterItem: Filter = JSON.parse(JSON.stringify(filterItem));
        const reg: RegExp = new RegExp(/^\d*\.?\d*$/);

        if (reg.test(event.target.value)) newFilterItem.value = Number(event.target.value);
        newReportRequest.filter.splice(filterIndex, 1, newFilterItem);

        changeReportRequest(newReportRequest);
    };

    return (
        <TextField
            fullWidth
            label="Значение"
            variant="standard"
            value={Number(filterItem.value)}
            onChange={action(changeFilterValue)}
        />
    );
});
