import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { Campaign } from "../../../entities/CampaignEdit";
import { CampaignLinks } from "../../../entities/CampaignLinks";
import { useClipboard, ClipboardAPI } from "use-clipboard-copy";
import { Fab, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

interface Props {
    campaignId: number;
}

export const SourceLinkCopyButton = observer(({ campaignId }: Props) => {
    const clipboard: ClipboardAPI = useClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const { findCampaign, cleanUpCampaignEdit } = useStores().campaignEditStore;
    const { findCampaignLinks, changeLinksCriteria, cleanUpCampaignLinks } = useStores().campaignLinksStore;

    const cleanUp = (): void => {
        cleanUpCampaignEdit();
        cleanUpCampaignLinks();
    };

    const copyLink = (link: string): void => {
        cleanUp();
        clipboard.copy(link);
        enqueueSnackbar("Ссылка скопирована!", { variant: "success", autoHideDuration: 1500 });
    };

    const copySourceLink = (): void => {
        findCampaign(campaignId).then((response: Campaign) => {
            const domainName: string = "domainId";
            const domainId: string = String(response.domainId);
            changeLinksCriteria(domainName, domainId);
            findCampaignLinks(response.id as number).then((response: CampaignLinks) => {
                copyLink(response.click);
            });
        });
    };

    return (
        <div className="mr-2">
            <Tooltip title="Скопировать ссылку для источника" placement="bottom">
                <Fab size="small" color="warning" onClick={action(copySourceLink)}>
                    <LinkIcon fontSize="small" />
                </Fab>
            </Tooltip>
        </div>
    );
});
