import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSingInValidation } from "./SignIn";
import { AuthData } from "../../../entities/Auth";
import { errorText, AuthValidation } from "./useValidation.hook";
import { TextField } from "@mui/material";

export const Email = observer(() => {
    const { authData, changeAuthData } = useStores().authStore;
    const { validation, changeValidation } = useSingInValidation();

    const changeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
        const dataCopy: AuthData = JSON.parse(JSON.stringify(authData));

        dataCopy.email = event.target.value.replace(/^\s/, "");

        changeAuthData(dataCopy);
        setEmailValidation();
    };

    const setEmailValidation = (): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));
        newValidation.email = false;
        newValidation.wrongData = false;
        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <TextField
                fullWidth
                placeholder="Почта"
                value={authData.email}
                error={validation.email || validation.wrongData}
                helperText={validation.email && errorText.email}
                onChange={action(changeEmail)}
            />
        </div>
    );
});
