import { useState, useEffect, SyntheticEvent } from "react";
import { observer } from "mobx-react";
import { action, runInAction } from "mobx";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Source } from "../../../../entities/SourceEdit";
import { Campaign } from "../../../../entities/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { SourcesCriteria, SourcesData, SourceItem } from "../../../../entities/Sources";
import { Autocomplete, FormControl, TextField } from "@mui/material";

export const CampaignSource = observer(() => {
    const [inputValue, setInputValue] = useState<string>("");
    const [source, setSource] = useState<SourceItem | null>(null);
    const [sourceList, setSourceList] = useState<SourceItem[]>([]);
    const [allowedСhangeСriteria, setAllowedСhangeСriteria] = useState<boolean>(false);
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { findSource, cleanUpSourceEdit } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const { criteria, changeCriteria, findSources, cleanUpSources } = useStores().sourcesStore;

    useEffect(() => {
        if (campaign.sourceId !== -1) {
            findSource(campaign.sourceId).then((reponse: Source) => {
                const newSource: SourceItem = {
                    id: reponse.id,
                    title: reponse.title,
                    teamIds: reponse.teamIds,
                    color: reponse.color,
                    userName: "",
                };

                setSource(newSource);
                setAllowedСhangeСriteria(true);
            });
        } else {
            setSource(null);
            setAllowedСhangeСriteria(true);
        }
    }, [campaign.sourceId, findSource]);

    useEffect(() => {
        findSources().then((response: SourcesData) => {
            if (campaign.sourceId !== -1) {
                const newSourceList: SourceItem[] = response.items.filter(
                    (item: SourceItem) => item.id !== campaign.sourceId
                );
                setSourceList(newSourceList);
            } else {
                setSourceList(response.items);
            }
        });
    }, [campaign.sourceId, criteria, findSources]);

    useEffect(() => {
        return () => {
            cleanUpSources();
            cleanUpSourceEdit();
        };
    }, [cleanUpSources, cleanUpSourceEdit]);

    const changeCampaignSourceId = (event: SyntheticEvent<Element, Event>, value: SourceItem | null): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.sourceId = value !== null ? value.id : -1;

        setSourceIdValidation();
        changeCampaign(newCampaign);
    };

    const changeInputValue = (event: SyntheticEvent<Element, Event>, newInputValue: string | undefined): void => {
        const newCriretia: SourcesCriteria = JSON.parse(JSON.stringify(criteria));
        const inputValue: string = newInputValue !== undefined ? newInputValue : "";
        const inputValueLength: boolean = inputValue.length < 1 || inputValue.length > 2;
        const checkSelectedItem: boolean = event !== null && typeof (event.target as any).value === "number";

        if (allowedСhangeСriteria && inputValueLength && !checkSelectedItem) {
            newCriretia.search = inputValue;
            changeCriteria(newCriretia);
        }

        setSourceIdValidation();
        setInputValue(inputValue);
    };

    const setSourceIdValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));
        newValidation.sourceId = false;
        changeValidation(newValidation);
    };

    return (
        <FormControl fullWidth className="source-id" error={validation.sourceId}>
            <Autocomplete
                fullWidth
                value={source}
                id="source-search"
                options={sourceList}
                inputValue={inputValue}
                getOptionLabel={(option) => option.title}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onInputChange={action(changeInputValue)}
                onChange={(event, value) => runInAction(() => changeCampaignSourceId(event, value))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label="Источник"
                        variant="outlined"
                        error={validation.sourceId}
                        placeholder="Введите минимум 3 символа"
                        helperText={validation.sourceId && "Необходимо выбрать источник"}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </FormControl>
    );
});
