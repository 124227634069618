import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useValidations } from "../../validation";
import { TeamEditBody } from "./TeamEditBody";
import { TeamEditHeader } from "./TeamEditHeader";
import { TeamEditFooter } from "./TeamEditFooter";
import { Modal } from "@mui/material";
import "../../style/offerEdit.css";

interface Props {
    teamId?: string | undefined;
    closeTeamEdit(): void;
}

export const TeamEdit = observer(({ teamId, closeTeamEdit }: Props) => {
    const { setDefaultTeam, findTeam } = useStores().teamEditStore;
    const { setDefualtValidation } = useValidations().teamEditValidation;

    useEffect(() => {
        teamId === undefined ? setDefaultTeam() : findTeam(teamId);
    }, [teamId, findTeam, setDefaultTeam]);

    useEffect(() => {
        return () => {
            setDefaultTeam();
            setDefualtValidation();
        };
    }, [setDefaultTeam, setDefualtValidation]);

    return (
        <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true" autoComplete="off">
            <Modal open>
                <div role="document" className="modal-dialog modal-dialog-centered modal_width">
                    <div className="modal-content">
                        <TeamEditHeader closeTeamEdit={closeTeamEdit} />
                        <TeamEditBody />
                        <TeamEditFooter closeTeamEdit={closeTeamEdit} />
                    </div>
                </div>
            </Modal>
        </form>
    );
});
