export enum Expression {
    EQUALS = "equals",
    NOT_EQUALS = "notEquals",
    LESS = "less",
    MORE = "more",
    MORE_OR_EQUALS = "moreOrEquals",
    LESS_OR_EQUALS = "lessOrEquals",
}

export const convertExpressionName: any = {
    equals: "Равно",
    notEquals: "Не равно",
    less: "Меньше",
    more: "Больше",
    moreOrEquals: "Больше либо равно",
    lessOrEquals: "Меньше либо равно",
};
