import { useState, useEffect, useCallback } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useValidations } from "../../../validation";
import { Validation } from "../../../validation/ReportValidation";
import { SourceParameter } from "../../../entities/SourceParameters";
import { ReportRequest, DefaultGroupBy, ParameterGroupBy } from "../../../entities/Report";
import { TypeGrouping, DefaultGrouping, convertType, convertGrouping } from "../../../module/Enum/Grouping/Grouping";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, FormControl, TextField } from "@mui/material";

export const ReportGroupings = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { items } = useStores().sourceParametersStore.sourceParameters;
    const { reportRequest, changeReportRequest } = useStores().reportStore;
    const { validation, changeValidation } = useValidations().reportValidation;
    const [groopings, setGroopings] = useState<(DefaultGroupBy | ParameterGroupBy)[]>([]);

    const createGroopings = useCallback((): void => {
        const newGroopings: (DefaultGroupBy | ParameterGroupBy)[] = [];

        Object.values(DefaultGrouping).forEach((grooping: string) => {
            const newGrooping: DefaultGroupBy = {
                type: TypeGrouping.DEFAULT,
                field: grooping,
            };
            newGroopings.push(newGrooping);
        });

        items.forEach((parameter: SourceParameter) => {
            const newGrooping: ParameterGroupBy = {
                type: TypeGrouping.PARAMETER,
                parameter: parameter.parameter,
            };
            newGroopings.push(newGrooping);
        });

        setGroopings(newGroopings);
    }, [items]);

    useEffect(() => {
        createGroopings();
    }, [createGroopings]);

    const changeGroupBy = (event: object, value: (DefaultGroupBy | ParameterGroupBy)[]): void => {
        const reportRequestCopy: ReportRequest = JSON.parse(JSON.stringify(reportRequest));

        if (value.length <= 4) {
            reportRequestCopy.groupBy = value;
            reportRequestCopy.sort = [];
        }

        showWarning();
        setGroupByValidation();
        changeReportRequest(reportRequestCopy);
    };

    const setGroupByValidation = (): void => {
        const newValidation: Validation = JSON.parse(JSON.stringify(validation));

        newValidation.groupBy = false;

        changeValidation(newValidation);
    };

    const showWarning = (): void => {
        if (reportRequest.sort.length > 0)
            enqueueSnackbar("Сортировка сброшена", { variant: "warning", autoHideDuration: 2000 });
    };

    const translateValue = (option: DefaultGroupBy | ParameterGroupBy): string => {
        let value: string = "";

        if (option.type === TypeGrouping.DEFAULT) {
            value = convertGrouping[option.field];
        } else {
            const parametersCopy: SourceParameter[] = JSON.parse(JSON.stringify(items));
            parametersCopy
                .filter((parameter: SourceParameter) => parameter.parameter === option.parameter)
                .forEach((parameter: SourceParameter) => {
                    value = `${parameter.name} (${parameter.parameter})`;
                });
        }

        return value;
    };

    return (
        <FormControl fullWidth error={validation.groupBy}>
            <Autocomplete
                multiple
                fullWidth
                size="small"
                limitTags={2}
                disableClearable
                disableCloseOnSelect
                options={groopings}
                value={reportRequest.groupBy}
                groupBy={(option) => convertType[option.type]}
                getOptionLabel={(option) => translateValue(option)}
                onChange={(event, value) => runInAction(() => changeGroupBy(event, value))}
                isOptionEqualToValue={(option, value) =>
                    option.type === value.type &&
                    (option as DefaultGroupBy).field === (value as DefaultGroupBy).field &&
                    (option as ParameterGroupBy).parameter === (value as ParameterGroupBy).parameter
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="medium"
                        variant="standard"
                        label="Группировки"
                        error={validation.groupBy}
                        InputLabelProps={{ shrink: true }}
                        helperText={validation.groupBy && "Поле не может быть пустым"}
                    />
                )}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            size="small"
                            checked={selected}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        />
                        {translateValue(option)}
                    </li>
                )}
            />
        </FormControl>
    );
});
