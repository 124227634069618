import { Tooltip } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

export const ScheduleTitle = () => (
    <div className="d-flex align-items-end">
        <h5 className="mr-2 m-0">Расписание постбэков</h5>
        <Tooltip
            placement="right"
            title={
                <p className="shedule__tooltip-text">
                    "По умолчанию все часы активны, отправка постбэков будет происходить в соответствии с настроенным
                    лимитом. При отправке постбэков используется время UTC."
                </p>
            }
        >
            <HelpOutlineOutlinedIcon fontSize="small" className="shedule__tooltip" />
        </Tooltip>
    </div>
);
