import { Range } from "react-date-range";
import { DateRange } from "../../DateRange";
import { useFormCriteria, FormCriteria } from "./CampaignsForm";

export const CampaignsDate = () => {
    const { formCriteria, setFormCriteria } = useFormCriteria();

    const changeDate = (range: Range[]): void => {
        const newCriteria: FormCriteria = JSON.parse(JSON.stringify(formCriteria));

        range.forEach((value: Range) => {
            newCriteria.dateFrom = value.startDate as Date;
            newCriteria.dateTo = value.endDate as Date;
        });

        setFormCriteria(newCriteria);
    };

    return <DateRange startDate={formCriteria.dateFrom} endDate={formCriteria.dateTo} changeDate={changeDate} />;
};
