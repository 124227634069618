import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { TeamsTable } from "./TeamsTable";
import { ActionButtons } from "./ActionButtons";

interface TeamsIdsContextInterface {
    teamsIds: number[];
    setTeamsIds: Dispatch<SetStateAction<number[]>>;
}

export const Teams = observer(() => {
    const { findTeams } = useStores().teamsStore;
    const [teamsIds, setTeamsIds] = useState<number[]>([]);

    useEffect(() => {
        findTeams();
    }, [findTeams]);

    useEffect(() => {
        setTeamsIds([]);
    }, []);

    return (
        <TeamsIdsContext.Provider value={{ teamsIds, setTeamsIds }}>
            <div className="container-fluid">
                <div className="ml-4">
                    <h1>Команды</h1>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <ActionButtons />
                </div>
                <TeamsTable />
            </div>
        </TeamsIdsContext.Provider>
    );
});

const TeamsIdsContext = createContext<TeamsIdsContextInterface>({} as TeamsIdsContextInterface);

export const useTeamsIds = (): TeamsIdsContextInterface => {
    return useContext(TeamsIdsContext);
};
