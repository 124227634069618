export enum SortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

interface ConvertDirection {
    [key: string]: string;
}

export const convertDirection: ConvertDirection = {
    ASC: "По возрастанию",
    DESC: "По убыванию",
};
