import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { DestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Campaign, RoutingRule, PercentageRoutingRule } from "../../../../entities/CampaignEdit";
import { Modal, Button } from "@mui/material";

interface WarningProps {
    event: ChangeEvent<HTMLInputElement> | null;
    closeWarningModal(): void;
    changePaymentModelType(event: ChangeEvent<HTMLInputElement>): void;
}

export const Warning = observer(({ event, closeWarningModal, changePaymentModelType }: WarningProps) => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const deleteDestinationUrl = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.percentageRoutingRules = newCampaign.percentageRoutingRules.filter(
            (rule: PercentageRoutingRule) => rule.destination.type === DestinationType.OFFER
        );

        newCampaign.routingRules = newCampaign.routingRules.filter(
            (rule: RoutingRule) => rule.destination.type === DestinationType.OFFER
        );

        closeWarningModal();
        changeCampaign(newCampaign);
        event && changePaymentModelType(event);
    };

    return (
        <div className="modal fade">
            <Modal open onClose={closeWarningModal}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">О нет! 😢</h5>
                        </div>
                        <div className="modal-body">
                            Выбранная модель затрат работает только с офферами! <br />
                            Правила с направлением трафика на Url будут удалены. <br />
                            Продолжить?
                        </div>
                        <div className="modal-footer">
                            <Button variant="contained" onClick={action(deleteDestinationUrl)}>
                                Да
                            </Button>
                            <Button color="inherit" onClick={closeWarningModal}>
                                Нет
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
