import { useState, useEffect, SyntheticEvent } from "react";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Landing } from "../../../../entities/LandingEdit";
import { User, UsersCriteria } from "../../../../entities/Users";
import { Autocomplete, TextField } from "@mui/material";
import { Modal, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    addUser(userId: number): void;
    closeSearchUserModal(): void;
}

export const SearchUserModal = observer(({ addUser, closeSearchUserModal }: Props) => {
    const [error, setError] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const [userList, setUserList] = useState<User[]>([]);
    const [inputValue, setInputValue] = useState<string>("");
    const { landing, changeLanding } = useStores().landingEditStore;
    const { criteria, findUsers, changeCriteria, setDefaultCriteria } = useStores().usersStore;

    useEffect(() => {
        findUsers().then((response) => {
            let newUserList: User[] = response.items;
            landing.allowedUserIds.forEach((userId: number) => {
                newUserList = newUserList.filter((item: User) => item.id !== userId);
            });
            setUserList(newUserList);
        });
    }, [criteria, landing.allowedUserIds, findUsers]);

    useEffect(() => {
        return setDefaultCriteria;
    }, [setDefaultCriteria]);

    const addAllowedUser = (): void => {
        const landingCopy: Landing = JSON.parse(JSON.stringify(landing));

        if (user !== null) {
            landingCopy.allowedUserIds.push(user.id);
            addUser(user.id);
            changeLanding(landingCopy);
            closeSearchUserModal();
        } else {
            setError(true);
        }
    };

    const changeUser = (event: SyntheticEvent<Element, Event>, newValue: User | null): void => {
        setUser(newValue);
    };

    const changeInputValue = (event: SyntheticEvent<Element, Event>, newInputValue: string | undefined): void => {
        const newCriretia: UsersCriteria = JSON.parse(JSON.stringify(criteria));
        const inputValue: string = newInputValue !== undefined ? newInputValue : "";

        if (inputValue.length < 1 || inputValue.length > 2) {
            newCriretia.search = inputValue;
            changeCriteria(newCriretia);
        }
        setError(false);
        setInputValue(inputValue);
    };

    return (
        <div className="modal fade">
            <Modal open onClose={closeSearchUserModal}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Добавить пользователя</h5>
                            <div>
                                <IconButton onClick={closeSearchUserModal}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className="modal-body">
                            <Autocomplete
                                id="user-search"
                                value={user}
                                options={userList}
                                inputValue={inputValue}
                                getOptionLabel={(option) => `${option.fullName.lastName} ${option.fullName.firstName}`}
                                onChange={(event, value) => runInAction(() => changeUser(event, value))}
                                onInputChange={changeInputValue}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={error}
                                        variant="outlined"
                                        label="Поиск пользователя"
                                        placeholder="Введите минимум 3 символа"
                                        helperText={error && "Поле не может быть пустым"}
                                    />
                                )}
                            />
                        </div>
                        <div className="modal-footer">
                            <Button variant="contained" onClick={action(addAllowedUser)}>
                                Добавить
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
