import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { CampaignsPagination } from "./";
import { CampaignsForm } from "./CampaignsForm";
import { CampaignsTable } from "./CampaignsTable";
import { CampaignAddButton } from "./ActionButtons";
import "../../style/campaigns.css";

export const Campaigns = observer(() => {
    const { criteria, findCampaigns, setDefaultCriteria } = useStores().campaignsStore;

    useEffect(() => {
        findCampaigns();
    }, [criteria, findCampaigns]);

    useEffect(() => {
        return setDefaultCriteria;
    }, [setDefaultCriteria]);

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center ml-4">
                    <h1 className="mr-2">Кампании</h1> <CampaignAddButton />
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <CampaignsForm />
                </div>
                <CampaignsTable />
                <CampaignsPagination />
            </div>
        </>
    );
});
