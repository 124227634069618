import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from "..";
import { useUrlParamsState } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../stores";
import { Mode } from "../../../../../module/Enum/Mode";
import { PostbackUrlParameterType } from "../../../../../module/Enum/SourceEdit/SourceEdit";
import { Source, SourcePostback, SourcePostbackUrlParameter } from "../../../../../entities/SourceEdit";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface Props {
    parameterIndex: number;
    parameter: SourcePostbackUrlParameter;
}

interface ParamsProps {
    mode: Mode;
}

export const ParameterType = observer(({ parameter, parameterIndex }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { urlParams, setUrlParams } = useUrlParamsState();
    const { source, changeSource } = useStores().sourceEditStore;
    const [url] = postback.urlPattern.split("?");

    const changeParameterType = (event: SelectChangeEvent<PostbackUrlParameterType>) => {
        const value = event.target.value as PostbackUrlParameterType;
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));
        const newUrlParams: SourcePostbackUrlParameter[] = JSON.parse(JSON.stringify(urlParams));
        const newUrlParam: SourcePostbackUrlParameter = JSON.parse(JSON.stringify(parameter));

        switch (value) {
            case PostbackUrlParameterType.GET_PARAMETER:
                newUrlParam.value = "{}";
                break;
            case PostbackUrlParameterType.SOURCE_PARAMS_FORWARDING:
                newUrlParam.value = `{track.params.${source.parameters[0].parameter}}`;
                break;
            case PostbackUrlParameterType.ID_CLICK_TRACK:
                newUrlParam.value = "{track_id}";
                break;
            case PostbackUrlParameterType.CURRENT_TIME:
                newUrlParam.value = "{timestamp}";
                break;
            case PostbackUrlParameterType.VALUE:
                newUrlParam.value = "";
                break;
        }

        newUrlParams.splice(parameterIndex, 1, newUrlParam);
        newPostback.urlPattern = [
            url,
            newUrlParams.map((param) => [param.parameter, param.value].join("=")).join("&"),
        ].join("?");
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setUrlParams(newUrlParams);
    };

    return (
        <div className="container-fluid pl-0">
            <FormControl fullWidth disabled={mode === Mode.VIEW}>
                <InputLabel>Тип</InputLabel>
                <Select label="Тип" value={parameter.type} onChange={action(changeParameterType)}>
                    {Object.values(PostbackUrlParameterType).map((value: string) => (
                        <MenuItem
                            key={value}
                            value={value}
                            disabled={
                                !source.parameters.length && value === PostbackUrlParameterType.SOURCE_PARAMS_FORWARDING
                            }
                        >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
});
