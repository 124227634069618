import { useState, useEffect, SyntheticEvent } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Team } from "../../../entities/TeamEdit";
import { User, UsersCriteria } from "../../../entities/Users";
import { Autocomplete, TextField } from "@mui/material";

export const TeamLead = observer(() => {
    const [value, setValue] = useState<User | null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const { team, changeTeam } = useStores().teamEditStore;
    const { findUser, setDefaultUser } = useStores().userEditStore;
    const { criteria, usersData, findUsers, changeCriteria, setDefaultCriteria } = useStores().usersStore;

    useEffect(() => {
        findUsers();
    }, [criteria, findUsers]);

    useEffect(() => {
        team.teamLeadId === null || team.teamLeadId === undefined
            ? setValue(null)
            : findUser(String(team.teamLeadId)).then((response) => {
                  setValue(response as User);
              });
    }, [team.teamLeadId, findUser]);

    useEffect(() => {
        return () => {
            setDefaultUser();
            setDefaultCriteria();
        };
    }, [setDefaultUser, setDefaultCriteria]);

    const changeTeamLead = (event: SyntheticEvent<Element, Event>, newValue: User | null): void => {
        const newTeam: Team = JSON.parse(JSON.stringify(team));
        newTeam.teamLeadId = newValue !== null ? (newValue?.id as number) : null;
        changeTeam(newTeam);
    };

    const changeInputValue = (event: SyntheticEvent<Element, Event>, newInputValue: string | undefined): void => {
        const newCriretia: UsersCriteria = JSON.parse(JSON.stringify(criteria));
        const inputValue: string = newInputValue !== undefined ? newInputValue : "";

        if (inputValue.length < 1 || inputValue.length > 2) {
            newCriretia.search = inputValue;
            changeCriteria(newCriretia);
        }

        setInputValue(inputValue);
    };

    return (
        <div className="field mt-5">
            <h5>Лидер команды</h5>
            <Autocomplete
                id="team-lead"
                value={value}
                inputValue={inputValue}
                options={usersData.items}
                getOptionLabel={(option) => `${option.fullName.lastName} ${option.fullName.firstName}`}
                onChange={(event, value) => runInAction(() => changeTeamLead(event, value))}
                onInputChange={changeInputValue}
                renderInput={(params) => <TextField {...params} placeholder="Введите минимум 3 символа" />}
            />
        </div>
    );
});
