import { makeAutoObservable } from "mobx";
import { NewSource, Source, SourceParameter, SourcePostback } from "../entities/SourceEdit";

export interface SourceEditValidationInterface {
    validation: SourceValidation;
    setDefualtValidation(): void;
    checkValidation(source: NewSource | Source): boolean;
    changeValidation(newValidation: SourceValidation): void;
}

export interface SourceValidation {
    title: boolean;
    globalPostbackUrlPattern: boolean;
    parameters: {
        [key: string]: boolean;
    };
    postbacks: {
        [key: string]: boolean;
    };
}

const DEFAULT_VALIDATION: SourceValidation = {
    title: false,
    globalPostbackUrlPattern: false,
    parameters: {},
    postbacks: {},
};

export class SourceEditValidation implements SourceEditValidationInterface {
    public validation: SourceValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: SourceValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (source: NewSource | Source): boolean => {
        const regUrl: RegExp = /^(ftp|http|https):\/\/[^ "]+$/;

        !source.title ? (this.validation.title = true) : (this.validation.title = false);
        !regUrl.test(source.globalPostbackUrlPattern)
            ? (this.validation.globalPostbackUrlPattern = true)
            : (this.validation.globalPostbackUrlPattern = false);
        source.parameters.forEach((parameter: SourceParameter, index: number): void => {
            !parameter.name
                ? (this.validation.parameters[`parameterName${index}`] = true)
                : (this.validation.parameters[`parameterName${index}`] = false);
            !parameter.parameter
                ? (this.validation.parameters[`parameterParameter${index}`] = true)
                : (this.validation.parameters[`parameterParameter${index}`] = false);
            !parameter.sourceMacros
                ? (this.validation.parameters[`parameterSourceMacros${index}`] = true)
                : (this.validation.parameters[`parameterSourceMacros${index}`] = false);
        });
        source.postbacks.forEach((postback: SourcePostback, index: number) => {
            !postback.goalName
                ? (this.validation.postbacks[`postbackGoalName${index}`] = true)
                : (this.validation.postbacks[`postbackGoalName${index}`] = false);
            !regUrl.test(postback.urlPattern)
                ? (this.validation.postbacks[`postbackUrlPattern${index}`] = true)
                : (this.validation.postbacks[`postbackUrlPattern${index}`] = false);
        });

        return Object.values(this.validation)
            .map((value: boolean | {}) => {
                return typeof value === "boolean" ? value : Object.values(value).includes(true);
            })
            .includes(true);
    };
}
