import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { CampaignCriteria, SortData } from "../../../entities/Campaigns";
import { SortField, convertFieldName } from "../../../module/Enum/Campaigns/SortField";

export const TableHead = observer(() => {
    const { criteria, changeCriteria } = useStores().campaignsStore;

    const sortTable = (field: SortField): void => {
        const newCriteria: CampaignCriteria = { ...criteria };
        const newSortData: SortData = {
            field: SortField.ID,
            direction: SortDirection.ASC,
        };

        newSortData.field = field;
        newSortData.direction =
            newCriteria.sort.field === field && newCriteria.sort.direction === SortDirection.ASC
                ? SortDirection.DESC
                : SortDirection.ASC;
        newCriteria.sort = newSortData;

        changeCriteria(newCriteria);
    };

    const createArrow = (field: SortField): any => {
        switch (true) {
            case criteria.sort.field === field && criteria.sort.direction === SortDirection.ASC:
                return (
                    <div className="arrow">
                        <span className="up" />
                    </div>
                );
            case criteria.sort.field === field && criteria.sort.direction === SortDirection.DESC:
                return (
                    <div className="arrow">
                        <span className="down" />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <thead>
            <tr>
                <th className="text-center">Статистика</th>

                {Object.values(SortField).map((value: SortField, index: number): any => {
                    return (
                        <th
                            key={value}
                            className={index === 0 ? "table__th-width cursor-hover" : "cursor-hover"}
                            onClick={action(() => sortTable(value))}
                        >
                            <div className="table__text-position">{convertFieldName[value]}</div>
                            {createArrow(value)}
                        </th>
                    );
                })}

                <th className="text-center">Кнопки действий</th>
            </tr>
        </thead>
    );
});
