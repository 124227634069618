import { useState, useEffect, useCallback } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";
import { Campaign } from "../../../../entities/CampaignEdit";
import { DomainsData, Domain } from "../../../../entities/Domains";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const Domains = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { domainsData, findDomains } = useStores().domainsStore;
    const { changeLinksCriteria } = useStores().campaignLinksStore;
    const { campaign, changeCampaign, updateInitialCampaign } = useStores().campaignEditStore;
    const [domainsIsFound, setDomainsIsFound] = useState<boolean>(false);

    const changeDomainId = useCallback(
        (domainId: number): void => {
            const campaignCopy: Campaign = JSON.parse(JSON.stringify(campaign));
            campaignCopy.domainId = Number(domainId);
            changeCampaign(campaignCopy);
        },
        [campaign, changeCampaign]
    );

    const getDomais = useCallback((): void => {
        const isCreatePage = (): boolean => {
            return mode === Mode.ADD;
        };

        if (!domainsIsFound)
            findDomains().then((response: DomainsData) => {
                setDomainsIsFound(true);
                if (isCreatePage()) {
                    response.items
                        .filter((domain: Domain) => domain.isDefault)
                        .forEach((domain: Domain) => {
                            changeDomainId(domain.id);
                            updateInitialCampaign();
                        });
                }
            });
    }, [mode, domainsIsFound, findDomains, changeDomainId, updateInitialCampaign]);

    useEffect(() => {
        getDomais();
    }, [getDomais]);

    useEffect(() => {
        const isEditPage = (): boolean => {
            return mode === Mode.EDIT;
        };

        if (isEditPage()) {
            changeLinksCriteria("domainId", String(campaign.domainId));
        }
    }, [mode, campaign.domainId, changeLinksCriteria]);

    const changeDomain = (event: SelectChangeEvent): void => {
        const domainId: string = event.target.value;
        changeDomainId(Number(domainId));
    };

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel>Домен</InputLabel>
            <Select label="Домен" name="domainId" value={String(campaign.domainId)} onChange={action(changeDomain)}>
                {domainsData.items.map((item: Domain) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.domain}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
