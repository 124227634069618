import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { SourcesApi } from "../services/api/SourcesApi";
import { SortField } from "../module/Enum/Sources/SortField";
import { SortDirection } from "../module/Enum/Sort/SortDirection";
import { SourcesCriteriaRequest, SourcesCriteria, SourcesData } from "../entities/Sources";

export interface SourcesStoreInterface {
    sourcesData: SourcesData;
    criteria: SourcesCriteria;
    cleanUpSources(): void;
    findSources(): Promise<SourcesData>;
    changeCriteria(newCriteria: SourcesCriteria): void;
    removeSource(sourceId: number): Promise<AxiosResponse<SourcesData>>;
}

const DEFAULT_SOURCES_DATA: SourcesData = {
    items: [],
    totalItems: 1,
};

const DEFAULT_SOURCES_CRITERIA: SourcesCriteria = {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sort: {
        field: SortField.ID,
        direction: SortDirection.ASC,
    },
};

const { getSources, deleteSource } = SourcesApi;

export class SourcesStore implements SourcesStoreInterface {
    public sourcesData: SourcesData = DEFAULT_SOURCES_DATA;
    public criteria: SourcesCriteria = DEFAULT_SOURCES_CRITERIA;

    constructor() {
        makeAutoObservable(this);
    }

    public changeCriteria = (newCriteria: SourcesCriteria): void => {
        this.criteria = newCriteria;
    };

    public cleanUpSources = (): void => {
        this.sourcesData = DEFAULT_SOURCES_DATA;
        this.criteria = DEFAULT_SOURCES_CRITERIA;
    };

    public findSources = async (): Promise<SourcesData> => {
        await getSources(this.createSourcesCriteriaRequest()).then((response: AxiosResponse<SourcesData>) => {
            runInAction(() => {
                this.sourcesData = response.data;
            });
        });
        return this.sourcesData;
    };

    public removeSource = async (sourceId: number): Promise<AxiosResponse> => {
        return await deleteSource(sourceId).then((response: AxiosResponse) => {
            return runInAction(() => {
                this.findSources();
                return response;
            });
        });
    };

    private createSourcesCriteriaRequest = (): SourcesCriteriaRequest => {
        const sourcesCriteria: SourcesCriteriaRequest = {
            search: this.criteria.search,
            page: this.criteria.page,
            itemsPerPage: this.criteria.itemsPerPage,
            "sort[field]": this.criteria.sort.field,
            "sort[direction]": this.criteria.sort.direction,
        };
        return sourcesCriteria;
    };
}
