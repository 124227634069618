import { useState, useEffect, useRef, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useDefaultRule } from "./";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { convertDestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Campaign, PercentageRoutingRule, RuleDestinationTypeUrl } from "../../../../entities/CampaignEdit";
import { UrlParamsEdit } from "./UrlParamEdit";
import { TextField } from "@mui/material";

export const RuleUrl = observer(() => {
    const urlRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const { rule, ruleIndex } = useDefaultRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `percentageRuleUrl${ruleIndex}`;

    useEffect(() => {
        const onClick = (event: any): void => {
            if (urlRef.current && !urlRef.current.contains(event.target)) {
                handleClose();
            }
        };
        document.addEventListener("mousedown", onClick);
        return () => document.removeEventListener("mousedown", onClick);
    }, []);

    const handleOpen = (): void => setOpen(true);

    const handleClose = (): void => setOpen(false);

    const changeRuleUrl = (event: ChangeEvent<{ value: string }>): void => {
        const newString: string = event.target.value.replace(/%20/g, "").replace(/\s+/g, "");
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        (newRule.destination as RuleDestinationTypeUrl).url = newString;
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setPercentageRuleUrlValidation();
    };

    const setPercentageRuleUrlValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.percentageRoutingRules[fieldName] = false;

        changeValidation(newValidation);
    };

    return (
        <div ref={urlRef} onClick={handleOpen}>
            <TextField
                fullWidth
                multiline
                variant="standard"
                maxRows={open ? 5 : 1}
                error={validation.percentageRoutingRules[fieldName]}
                label={convertDestinationType[rule.destination.type]}
                value={(rule.destination as RuleDestinationTypeUrl).url}
                helperText={validation.percentageRoutingRules[fieldName] && "Url должно быть вида https://example.com"}
                onChange={action(changeRuleUrl)}
            />
            <UrlParamsEdit />
        </div>
    );
});
