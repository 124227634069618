import { useState } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { AlertModal } from "./AlertModal";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
    closeTeamEdit(): void;
}

interface ParamsProps {
    mode: Mode;
}

export const TeamEditSaveAndCloseButton = observer(({ closeTeamEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { enqueueSnackbar } = useSnackbar();
    const { findTeams } = useStores().teamsStore;
    const { team, saveTeam } = useStores().teamEditStore;
    const { checkValidation } = useValidations().teamEditValidation;
    const [openAlert, setOpenopenAlert] = useState<boolean>(false);

    const openAlertModal = (): void => setOpenopenAlert(true);

    const closeAlertModal = (): void => setOpenopenAlert(false);

    const saveAndClose = (): void => {
        checkValidation(team)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveTeam()
                  .then((response) => {
                      if (response) {
                          if (mode === Mode.ADD) findTeams();
                          closeTeamEdit();
                          enqueueSnackbar("Команда сохранена", { variant: "success", autoHideDuration: 1500 });
                      }
                  })
                  .catch((error) => {
                      if (error.response?.status === 409) {
                          openAlertModal();
                      }
                  });
    };

    return (
        <>
            <Button variant="contained" startIcon={<SaveIcon />} onClick={action(saveAndClose)}>
                Сохранить и выйти
            </Button>

            {openAlert && <AlertModal closeAlertModal={closeAlertModal} />}
        </>
    );
});
