import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReportRequest, Filter } from "../../../../entities/Report";
import { Expression, convertExpressionName } from "../../../../module/Enum/Expression";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface Props {
    filterIndex: number;
    filterItem: Filter;
}

export const FilterExpression = observer(({ filterIndex, filterItem }: Props) => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;

    const changeFilterExpression = (event: SelectChangeEvent<Expression>): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));
        const newFilterItem: Filter = JSON.parse(JSON.stringify(filterItem));

        newFilterItem.expression = event.target.value as Expression;
        newReportRequest.filter.splice(filterIndex, 1, newFilterItem);

        changeReportRequest(newReportRequest);
    };

    return (
        <FormControl fullWidth variant="standard">
            <InputLabel>Выражение</InputLabel>
            <Select value={filterItem.expression} onChange={action(changeFilterExpression)}>
                {Object.values(Expression).map((expression: Expression, index: number) => (
                    <MenuItem key={index} value={expression}>
                        {convertExpressionName[expression]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});
