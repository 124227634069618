import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { WarningModalOnUnsavedData } from "../../WarningModalOnUnsavedData";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    closeLandingEdit(): void;
}

export const LandingEditCloseButton = observer(({ closeLandingEdit }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const { isChangedData } = useStores().landingEditStore;

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    return (
        <div>
            <IconButton onClick={isChangedData ? closeLandingEdit : openWarningModal}>
                <CloseIcon />
            </IconButton>

            {open && (
                <WarningModalOnUnsavedData closeModalEdit={closeLandingEdit} closeWarningModal={closeWarningModal} />
            )}
        </div>
    );
});
