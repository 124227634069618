import { useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { StatisticsPagination } from ".";
import { StatisticsForm } from "./StatisticsForm";
import { StatisticsTable } from "./StatisticsTable";
import { useStatistics } from "./useStatistics.hook";
import { useSourceParameters } from "./useSourceParameters.hook";
import { SourceParameter } from "../../entities/SourceParameters";
import { StatisticsCriteria, StatisticsData } from "../../entities/Statistics";
import "../../style/statistics.css";

interface Props {
    campaignId?: number;
    campaignTitle?: string;
    dropdownNotice?: boolean;
}

interface DropdownContextInterface {
    campaignId?: number;
    dropdownNotice?: boolean;
}

interface StatisticsContextInterface {
    criteria: StatisticsCriteria;
    statisticsData: StatisticsData;
    setCriteria: Dispatch<SetStateAction<StatisticsCriteria>>;
}

export const Statistics = observer(({ campaignId, campaignTitle, dropdownNotice }: Props) => {
    const sourceParameters = useSourceParameters(campaignId, dropdownNotice);
    const { criteria, setCriteria, statisticsData } = useStatistics(campaignId, dropdownNotice);

    return (
        <SourceParameterContext.Provider value={sourceParameters}>
            <DropdownContext.Provider value={{ campaignId, dropdownNotice }}>
                <StatisticsCriteriaContext.Provider value={{ criteria, statisticsData, setCriteria }}>
                    <div className="container-fluid">
                        <div className="row align-items-center ml-4">
                            {dropdownNotice ? (
                                <h3 className="mr-2">Статистика кампании «{campaignTitle}»</h3>
                            ) : (
                                <h1 className="mr-2">Статистика</h1>
                            )}
                        </div>
                    </div>
                    <div className="container-fluid bg-light">
                        <StatisticsForm />
                        <StatisticsTable />
                        <StatisticsPagination />
                    </div>
                </StatisticsCriteriaContext.Provider>
            </DropdownContext.Provider>
        </SourceParameterContext.Provider>
    );
});

const SourceParameterContext = createContext<SourceParameter[]>([]);
const DropdownContext = createContext<DropdownContextInterface>({} as DropdownContextInterface);
const StatisticsCriteriaContext = createContext<StatisticsContextInterface>({} as StatisticsContextInterface);

export const useSourceParameter = (): SourceParameter[] => {
    return useContext(SourceParameterContext);
};

export const useDropdown = (): DropdownContextInterface => {
    return useContext(DropdownContext);
};

export const useStatisticsContext = (): StatisticsContextInterface => {
    return useContext(StatisticsCriteriaContext);
};
