import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { LandingsApi } from "../services/api/LandingsApi";
import { SortField } from "../module/Enum/Landings/SortField";
import { SortDirection } from "../module/Enum/Sort/SortDirection";
import { LandingsCriteriaRequest, LandingsCriteria, LandingsData } from "../entities/Landings";

export interface LandingsStoreInterface {
    criteria: LandingsCriteria;
    landingsData: LandingsData;
    setDefaultCriteria(): void;
    findLandings(): Promise<LandingsData>;
    removeLanding(landingId: number): Promise<any>;
    changeCriteria(newCriteria: LandingsCriteria): void;
}

const DEFAULT_LANDINGS_CRITERIA: LandingsCriteria = {
    search: "",
    page: 1,
    itemsPerPage: 10,
    sort: {
        field: SortField.ID,
        direction: SortDirection.ASC,
    },
};

const { getLandings, deleteLanding } = LandingsApi;

export class LandingsStore implements LandingsStoreInterface {
    public criteria: LandingsCriteria = DEFAULT_LANDINGS_CRITERIA;

    public landingsData: LandingsData = {
        items: [],
        totalItems: 0,
    };

    constructor() {
        makeAutoObservable(this);
    }

    public setDefaultCriteria = (): void => {
        this.criteria = DEFAULT_LANDINGS_CRITERIA;
    };

    public changeCriteria = (newCriteria: LandingsCriteria): void => {
        this.criteria = newCriteria;
    };

    public findLandings = async (): Promise<LandingsData> => {
        await getLandings(this.createLandingsCriteriaRequest()).then((response: AxiosResponse<LandingsData>) => {
            runInAction(() => {
                this.landingsData = response.data;
            });
        });
        return this.landingsData;
    };

    public removeLanding = async (landingId: number): Promise<any> => {
        return await deleteLanding(landingId).then((response: AxiosResponse) => {
            return runInAction(() => {
                this.findLandings();
                return response;
            });
        });
    };

    private createLandingsCriteriaRequest = (): LandingsCriteriaRequest => {
        const urlParameters: LandingsCriteriaRequest = {
            search: this.criteria.search,
            page: this.criteria.page,
            itemsPerPage: this.criteria.itemsPerPage,
            "sort[field]": this.criteria.sort.field,
            "sort[direction]": this.criteria.sort.direction,
        };
        return urlParameters;
    };
}
