import { useState } from "react";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { AlertModal, WarningModal } from "./";
import { useSelectedSources } from "../Sources";
import { SourceItem } from "../../../entities/Sources";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const SourceDeleteButton = observer(() => {
    const { removeSource } = useStores().sourcesStore;
    const { selectedSources, setSelectedSources } = useSelectedSources();
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);

    const openAlertModal = (): void => setOpenAlert(true);

    const closeAlertModal = (): void => setOpenAlert(false);

    const openWarningModal = (): void => setOpenWarning(true);

    const closeWarningModal = (): void => setOpenWarning(false);

    const deleteSource = (): void => {
        selectedSources.forEach((item: SourceItem) => {
            removeSource(item.id).catch((error: AxiosError) => {
                switch (error.response?.status) {
                    case 409:
                        openAlertModal();
                        break;
                }
            });
        });
        closeWarningModal();
        setSelectedSources([]);
    };

    return (
        <>
            <Button
                size="small"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                disabled={selectedSources.length === 0}
                onClick={openWarningModal}
            >
                Удалить
            </Button>

            {openAlert && <AlertModal closeAlertModal={closeAlertModal} />}
            {openWarning && <WarningModal deleteSource={deleteSource} closeWarningModal={closeWarningModal} />}
        </>
    );
});
