import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { Landing } from "../../../entities/LandingEdit";
import { LandingValidation } from "../../../validation/LandingEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const LandingUrl = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { landing, changeLanding } = useStores().landingEditStore;
    const { validation, changeValidation } = useValidations().landingEditValidation;

    const changeUrl = (event: ChangeEvent<HTMLInputElement>): void => {
        const newLanding: Landing = JSON.parse(JSON.stringify(landing));

        newLanding.url = event.target.value;

        setUrlValidation();
        changeLanding(newLanding);
    };

    const setUrlValidation = (): void => {
        const newValidation: LandingValidation = JSON.parse(JSON.stringify(validation));

        newValidation.url = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field mt-5">
            <h5>Url</h5>
            <TextField
                fullWidth
                multiline
                minRows={3}
                value={landing.url}
                error={validation.url}
                helperText={validation.url && "Url должен быть вида https://example.com"}
                onChange={action(changeUrl)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
