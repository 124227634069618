import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Campaign } from "../../../../entities/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface Props {
    goalIndex: number;
}

export const GoalDeleteButton = observer(({ goalIndex }: Props) => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const deleteGoal = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.goals.splice(goalIndex, 1);

        deleteValidation();
        changeCampaign(newCampaign);
    };

    const deleteValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.goals = {};

        changeValidation(newValidation);
    };

    return (
        <Button color="error" onClick={action(deleteGoal)}>
            <DeleteForeverOutlinedIcon />
        </Button>
    );
});
