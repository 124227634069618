import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { Source } from "../../../entities/SourceEdit";
import { SourceValidation } from "../../../validation/SourceEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const SourceTitle = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { source, changeSource } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().sourceEditValidation;

    const changeTitle = (event: ChangeEvent<HTMLInputElement>): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        newSource.title = event.target.value;
        changeSource(newSource);
        setTitleValidation();
    };

    const setTitleValidation = (): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.title = false;
        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Наименование источника</h5>
            <TextField
                fullWidth
                name="title"
                autoComplete="off"
                value={source.title}
                error={validation.title}
                helperText={validation.title && "Поле не может быть пустым"}
                onChange={action(changeTitle)}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
