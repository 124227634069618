import { useEffect, useContext, createContext } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Mode } from "../../module/Enum/Mode";
import { UserEditBody } from "./UserEditBody";
import { UserEditHeader } from "./UserEditHeader";
import { UserEditFooter } from "./UserEditFooter";
import { useValidations } from "../../validation";
import { Modal } from "@mui/material";
import "../../style/userEdit.css";

interface Props {
    mode: Mode;
    userId?: string;
    closeUserEdit(): void;
}

interface ModeContextInterface {
    mode: Mode;
}

export const UserEdit = observer(({ mode, userId, closeUserEdit }: Props) => {
    const { currentUser } = useStores().currentUserStore;
    const { setDefualtValidation } = useValidations().userEditValidation;
    const { findUser, setDefaultUser, changeUser, changeInitialUser } = useStores().userEditStore;

    useEffect(() => {
        switch (mode) {
            case Mode.EDIT:
                userId !== undefined && findUser(userId);
                break;
            case Mode.CURRENT:
                changeUser(currentUser);
                changeInitialUser(currentUser);
                break;
        }
    }, [mode, userId, currentUser, findUser, changeUser, changeInitialUser]);

    useEffect(() => {
        return () => {
            setDefaultUser();
            setDefualtValidation();
        };
    }, [setDefaultUser, setDefualtValidation]);

    return (
        <ModeContext.Provider value={{ mode }}>
            <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true" autoComplete="off">
                <Modal open>
                    <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                        <div className="modal-content">
                            <UserEditHeader closeUserEdit={closeUserEdit} />
                            <UserEditBody />
                            <UserEditFooter />
                        </div>
                    </div>
                </Modal>
            </form>
        </ModeContext.Provider>
    );
});

const ModeContext = createContext<ModeContextInterface>({} as ModeContextInterface);

export const useMode = (): ModeContextInterface => {
    return useContext(ModeContext);
};
