import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { User } from "../../../entities/UserEdit";
import { useValidations } from "../../../validation";
import { UserValidation } from "../../../validation/UserEditValidation";
import { TextField } from "@mui/material";

export const UserEmail = observer(() => {
    const { user, changeUser } = useStores().userEditStore;
    const { validation, changeValidation } = useValidations().userEditValidation;

    const changeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
        const newUser: User = JSON.parse(JSON.stringify(user));

        newUser.email = event.target.value.replace(/^\s/, "");

        changeUser(newUser);
        setEmailValidation();
    };

    const setEmailValidation = (): void => {
        const newValidation: UserValidation = JSON.parse(JSON.stringify(validation));

        newValidation.email = false;
        newValidation.doubleEmail = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Почта</h5>
            <TextField
                fullWidth
                autoComplete="off"
                value={user.email}
                error={validation.email || validation.doubleEmail}
                helperText={
                    (validation.doubleEmail && "Этот email уже используется") ||
                    (validation.email && "Почта должна быть вида «example@mail.com»")
                }
                onChange={action(changeEmail)}
            />
        </div>
    );
});
