import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { WarningModalOnUnsavedData } from "../../WarningModalOnUnsavedData";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    closeOfferEdit(): void;
}

export const OfferEditCloseButton = observer(({ closeOfferEdit }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const { isChangedData } = useStores().offerEditStore;

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    return (
        <div>
            <IconButton onClick={!isChangedData ? openWarningModal : closeOfferEdit}>
                <CloseIcon />
            </IconButton>

            {open && (
                <WarningModalOnUnsavedData closeModalEdit={closeOfferEdit} closeWarningModal={closeWarningModal} />
            )}
        </div>
    );
});
