import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSelectedOffers } from "../Offers";
import { OfferItem, OffersCriteria } from "../../../entities/Offers";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { SortField, convertField } from "../../../module/Enum/Offers/SortField";
import { Checkbox } from "@mui/material";

export const TableHead = observer(() => {
    const { selectedOffers, setSelectedOffers } = useSelectedOffers();
    const { offersData, criteria, changeCriteria } = useStores().offersStore;

    const handleChangeAllId = (): void => {
        const booleanArray: boolean[] = [];
        const selectedOffersCopy: OfferItem[] = JSON.parse(JSON.stringify(selectedOffers));

        offersData.items.forEach((item: OfferItem) => {
            const isOfferExist = (): boolean => {
                return selectedOffersCopy.map((offer: OfferItem) => offer.id).includes(item.id);
            };

            booleanArray.push(isOfferExist());
            if (!isOfferExist()) selectedOffersCopy.push(item);
        });
        booleanArray.includes(false) ? setSelectedOffers(selectedOffersCopy) : setSelectedOffers([]);
    };

    const sortTable = (field: SortField): void => {
        const newCriteria: OffersCriteria = JSON.parse(JSON.stringify(criteria));

        const isSameField = (): boolean => {
            return criteria.sort.field === field;
        };

        const isSameDirection = (): boolean => {
            return criteria.sort.direction === SortDirection.ASC;
        };

        newCriteria.sort.field = field;
        newCriteria.sort.direction = isSameField() && isSameDirection() ? SortDirection.DESC : SortDirection.ASC;

        changeCriteria(newCriteria);
    };

    const createArrow = (field: SortField): any => {
        const isSameField = (): boolean => {
            return criteria.sort.field === field;
        };

        const isDirectionASC = (): boolean => {
            return criteria.sort.direction === SortDirection.ASC;
        };

        const isDirectionDESC = (): boolean => {
            return criteria.sort.direction === SortDirection.DESC;
        };

        switch (true) {
            case isSameField() && isDirectionASC():
                return (
                    <div className="arrow">
                        <span className="up" />
                    </div>
                );
            case isSameField() && isDirectionDESC():
                return (
                    <div className="arrow">
                        <span className="down" />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <thead>
            <tr>
                <th className="table__th-width pb-1" onClick={handleChangeAllId}>
                    <Checkbox
                        size="small"
                        checked={offersData.items.length !== 0 && selectedOffers.length === offersData.items.length}
                    />
                </th>
                {Object.values(SortField).map((value: SortField, index: number) => (
                    <th
                        key={value}
                        className={index === 0 ? "table__th-width cursor_pointer" : "cursor_pointer"}
                        onClick={action(() => sortTable(value))}
                    >
                        <div className="table__text-position">{convertField[value]}</div>
                        {createArrow(value)}
                    </th>
                ))}
            </tr>
        </thead>
    );
});
