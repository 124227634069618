import React from "react";
import { observer } from "mobx-react";
import { useTeamsIds } from "../Teams";
import { useStores } from "../../../stores";
import { Team } from "../../../entities/Teams";
import { Checkbox } from "@mui/material";

export const TableBody = observer(() => {
    const { teamsIds, setTeamsIds } = useTeamsIds();
    const { items } = useStores().teamsStore.teamsData;

    const handleChangeId = (id: number): void => {
        let teamsIdsCopy: number[] = JSON.parse(JSON.stringify(teamsIds));

        teamsIdsCopy.includes(id)
            ? (teamsIdsCopy = teamsIdsCopy.filter((item: number) => item !== id))
            : teamsIdsCopy.push(id);

        setTeamsIds(teamsIdsCopy);
    };

    return (
        <tbody>
            {items.map((item: Team) => (
                <React.Fragment key={item.id}>
                    <tr onClick={() => handleChangeId(item.id)}>
                        <td className="align-middle">
                            <Checkbox size="small" checked={teamsIds.includes(item.id)} />
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">{item.title}</td>
                        <td className="align-middle">
                            {item.leader?.fullName.lastName || "Не"} {item.leader?.fullName.firstName || "указан"}
                        </td>
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
});
