import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useValidations } from "../../validation";
import { DomainEditBody } from "./DomainEditBody";
import { DomainEditHeader } from "./DomainEditHeader";
import { DomainEditFooter } from "./DomainEditFooter";
import { Modal } from "@mui/material";

interface Props {
    domainId?: string;
    closeDomainEdit(): void;
}

export const DomainEdit = observer(({ domainId, closeDomainEdit }: Props) => {
    const { findDomain, setDefaultDomain } = useStores().domainEditStore;
    const { setDefualtValidation } = useValidations().domainEditValidation;

    useEffect(() => {
        if (domainId !== undefined) findDomain(domainId);
    }, [domainId, findDomain]);

    useEffect(() => {
        return () => {
            setDefaultDomain();
            setDefualtValidation();
        };
    }, [setDefaultDomain, setDefualtValidation]);

    return (
        <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true" autoComplete="off">
            <Modal open>
                <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <DomainEditHeader closeDomainEdit={closeDomainEdit} />
                        <DomainEditBody />
                        <DomainEditFooter />
                    </div>
                </div>
            </Modal>
        </form>
    );
});
