import { useState, useEffect, useRef, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { usePostback, useIndicesState } from ".";
import { Mode } from "../../../../module/Enum/Mode";
import { useValidations } from "../../../../validation";
import { Source, SourcePostback } from "../../../../entities/SourceEdit";
import { SourceValidation } from "../../../../validation/SourceEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const PostbackUrlPattern = observer(() => {
    const urlRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const { mode } = useParams<ParamsProps>();
    const { postbackIndices } = useIndicesState();
    const { postback, postbackIndex } = usePostback();
    const { source, changeSource } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().sourceEditValidation;
    const fieldName: string = `postbackUrlPattern${postbackIndex}`;
    const errorText: string = "Url должно быть вида https://example.com";
    const urlHeight: string = open || postbackIndices.includes(postbackIndex) ? "15rem" : "1rem";

    useEffect(() => {
        const onClick = (event: any): void => {
            if (urlRef.current && !urlRef.current.contains(event.target)) {
                handleClose();
            }
        };
        document.addEventListener("mousedown", onClick);
        return () => document.removeEventListener("mousedown", onClick);
    }, []);

    const handleOpen = (): void => setOpen(true);

    const handleClose = (): void => setOpen(false);

    const changePostbackUrlPattern = (event: ChangeEvent<{ value: string; name: string }>): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));

        newPostback.urlPattern = event.target.value.replace(/%20/g, "").replace(/\s+/g, "");
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setPostbackUrlPatternValidation(event.target.name);
    };

    const setPostbackUrlPatternValidation = (name: string): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.postbacks[name] = false;
        changeValidation(newValidation);
    };

    return (
        <div ref={urlRef} className="container-fluid postback-url col-6" onClick={handleOpen}>
            <TextField
                fullWidth
                multiline
                label="Url"
                autoComplete="off"
                className="p-0"
                name={fieldName}
                value={postback.urlPattern}
                error={validation.postbacks[fieldName]}
                helperText={validation.postbacks[fieldName] && errorText}
                onChange={action(changePostbackUrlPattern)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: mode === Mode.VIEW }}
                inputProps={{ style: { maxHeight: `${urlHeight}` } }}
            />
        </div>
    );
});
