import { OfferEditSaveButton, OfferEditSaveAndCloseButton } from "./";

interface Props {
    closeOfferEdit(): void;
    setNewOffer?(offerId: number): void;
}

export const OfferEditFooter = ({ setNewOffer, closeOfferEdit }: Props) => (
    <div className="modal-footer">
        <OfferEditSaveButton setNewOffer={setNewOffer} />
        <OfferEditSaveAndCloseButton setNewOffer={setNewOffer} closeOfferEdit={closeOfferEdit} />
    </div>
);
