import { AxiosResponse } from "axios";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { Landing, LandingRequest } from "../../entities/LandingEdit";
import { LandingsCriteriaRequest, LandingsData } from "../../entities/Landings";

interface LandingsApiInterface {
    deleteLanding(landingId: number): Promise<AxiosResponse>;
    postLanding(landing: LandingRequest): Promise<AxiosResponse<Landing>>;
    getLanding(landingId: string | number): Promise<AxiosResponse<Landing>>;
    getLandings(criteria: LandingsCriteriaRequest): Promise<AxiosResponse<LandingsData>>;
    putLanding(landingId: number, landing: LandingRequest): Promise<AxiosResponse<Landing>>;
}

export const LandingsApi: LandingsApiInterface = {
    getLandings: async (criteria: LandingsCriteriaRequest): Promise<AxiosResponse<LandingsData>> =>
        await httpClient.get<LandingsData>("/api/v1/landing", { params: criteria }),

    getLanding: async (landingId: string | number): Promise<AxiosResponse<Landing>> =>
        await httpClient.get<Landing>(`/api/v1/landing/${landingId}`),

    postLanding: async (landing: LandingRequest): Promise<AxiosResponse<Landing>> =>
        await httpClient.post<Landing>("/api/v1/landing", landing),

    putLanding: async (landingId: number, landing: LandingRequest): Promise<AxiosResponse<Landing>> =>
        await httpClient.put<Landing>(`/api/v1/landing/${landingId}`, landing),

    deleteLanding: async (landingId: number): Promise<AxiosResponse> =>
        await httpClient.delete(`/api/v1/landing/${landingId}`),
};
