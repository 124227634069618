import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { CampaignsApi } from "../services/api/CampaignsApi";
import { Campaign, PaymentModel } from "../entities/CampaignEdit";
import { LimitType, PaymentModelType } from "../module/Enum/CampaignEdit/CampaignEdit";

export interface CampaignEditStoreInterface {
    campaign: Campaign;
    paymentModel: PaymentModel;
    isChangedData: boolean;
    cleanUpCampaignEdit(): void;
    updateInitialCampaign(): void;
    saveCampaign(): Promise<Campaign>;
    changeCampaign(newCampaign: Campaign): void;
    changePaymentModel(newPaymentModel: PaymentModel): void;
    findPaymentModel(campaignId: string): Promise<PaymentModel>;
    editPaymentModel(campaignId: number): Promise<PaymentModel>;
    findCampaign(campaignId: number | string): Promise<Campaign>;
    cloneCampaign(campaignId: number | string): Promise<Campaign>;
}

const DEFAULT_CAMPAIGN: Campaign = {
    title: "",
    sourceId: -1,
    domainId: -1,
    goals: [],
    postbackSettings: {
        limitType: LimitType.DAY,
        limitValue: "-1",
        probabilityPercent: 100,
    },
    routingRules: [],
    percentageRoutingRules: [],
};

const DEFAULT_PAYMENT_MODEL: PaymentModel = {
    type: PaymentModelType.NONE,
};

const { getCampaign, postCampaign, putCampaign, getPaymentModel, putPaymentModel } = CampaignsApi;

export class CampaignEditStore implements CampaignEditStoreInterface {
    public campaign: Campaign = DEFAULT_CAMPAIGN;
    public paymentModel: PaymentModel = DEFAULT_PAYMENT_MODEL;
    private initialCampaign: Campaign = DEFAULT_CAMPAIGN;
    private initialPaymentModel: PaymentModel = DEFAULT_PAYMENT_MODEL;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return (
            JSON.stringify(this.campaign) === JSON.stringify(this.initialCampaign) &&
            JSON.stringify(this.paymentModel) === JSON.stringify(this.initialPaymentModel)
        );
    }

    public changeCampaign = (newCampaign: Campaign): void => {
        this.campaign = newCampaign;
    };

    public updateInitialCampaign = (): void => {
        this.initialCampaign = this.campaign;
    };

    public changePaymentModel = (newPaymentModel: PaymentModel): void => {
        this.paymentModel = newPaymentModel;
    };

    public cleanUpCampaignEdit = (): void => {
        this.campaign = DEFAULT_CAMPAIGN;
        this.initialCampaign = DEFAULT_CAMPAIGN;
        this.paymentModel = DEFAULT_PAYMENT_MODEL;
        this.initialPaymentModel = DEFAULT_PAYMENT_MODEL;
    };

    public findCampaign = async (campaignId: number | string): Promise<Campaign> => {
        await getCampaign(campaignId).then((response: AxiosResponse<Campaign>) => {
            runInAction(() => {
                this.campaign = response.data;
                this.initialCampaign = response.data;
            });
        });
        return this.campaign;
    };

    public cloneCampaign = async (campaignId: number | string): Promise<Campaign> => {
        await getCampaign(campaignId).then((response: AxiosResponse<Campaign>) => {
            runInAction(() => {
                const newCampaign: Campaign = {
                    ...response.data,
                    title: response.data.title + " (clone)",
                };
                delete newCampaign.id;
                this.campaign = newCampaign;
            });
        });
        return this.campaign;
    };

    public saveCampaign = async (): Promise<Campaign> =>
        this.campaign.id === undefined ? this.createCampaign() : this.editCampaign(this.campaign.id);

    private createCampaign = async (): Promise<Campaign> => {
        return await postCampaign(this.campaign).then((response: AxiosResponse<Campaign>) => {
            return runInAction(() => {
                this.campaign = response.data;
                this.initialCampaign = response.data;

                return response.data;
            });
        });
    };

    private editCampaign = async (campaignId: number): Promise<Campaign> => {
        return await putCampaign(campaignId, this.campaign).then((response: AxiosResponse<Campaign>) => {
            return runInAction(() => {
                this.campaign = response.data;
                this.initialCampaign = response.data;

                return response.data;
            });
        });
    };

    public findPaymentModel = async (campaignId: string): Promise<PaymentModel> => {
        await getPaymentModel(campaignId).then((response: AxiosResponse<PaymentModel>) => {
            runInAction(() => {
                this.paymentModel = response.data;
                this.initialPaymentModel = response.data;
            });
        });
        return this.paymentModel;
    };

    public editPaymentModel = async (campaignId: number): Promise<PaymentModel> => {
        await putPaymentModel(campaignId, this.paymentModel).then((response: AxiosResponse<PaymentModel>) => {
            runInAction(() => {
                this.paymentModel = response.data;
                this.initialPaymentModel = response.data;
            });
        });
        return this.paymentModel;
    };
}
