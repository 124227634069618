import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from "..";
import { useUrlParamsState } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../stores";
import { Mode } from "../../../../../module/Enum/Mode";
import { PostbackUrlParameterType } from "../../../../../module/Enum/SourceEdit/SourceEdit";
import { Source, SourcePostback, SourcePostbackUrlParameter } from "../../../../../entities/SourceEdit";
import { Button } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

interface ParamsProps {
    mode: Mode;
}

export const ParameterAddButton = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { urlParams, setUrlParams } = useUrlParamsState();
    const { source, changeSource } = useStores().sourceEditStore;
    const [url] = postback.urlPattern.split("?");

    const addParameter = (): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));
        const newUrlParams: SourcePostbackUrlParameter[] = JSON.parse(JSON.stringify(urlParams));
        const newUrlParam: SourcePostbackUrlParameter = {
            type: PostbackUrlParameterType.VALUE,
            parameter: "",
            value: "",
        };

        if (url) {
            newUrlParams.push(newUrlParam);
            newPostback.urlPattern = [
                url,
                newUrlParams.map((param) => [param.parameter, param.value].join("=")).join("&"),
            ].join("?");
        }
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setUrlParams(newUrlParams);
    };

    return (
        <div className="text-right mt-4 mb-4">
            <Button
                color="success"
                variant="outlined"
                startIcon={<AddBoxOutlinedIcon />}
                disabled={!url || mode === Mode.VIEW}
                onClick={action(addParameter)}
            >
                Добавить параметр
            </Button>
        </div>
    );
});
