import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useValidations } from "../../validation";
import { CampaignEditBody } from "./CampaignEditBody";
import { CampaignEditHeader } from "./CampaignEditHeader";
import { CampaignEditFooter } from "./CampaignEditFooter";
import { Modal } from "@mui/material";
import "../../style/campaignEdit.css";

interface Props {
    mode: string;
    campaignId?: string;
    closeCampaignEdit(): void;
}

export const CampaignEdit = observer(({ mode, campaignId, closeCampaignEdit }: Props) => {
    const { setDefualtValidation } = useValidations().campaignEditValidation;
    const { findCampaign, cloneCampaign, findPaymentModel, cleanUpCampaignEdit } = useStores().campaignEditStore;

    useEffect(() => {
        switch (mode) {
            case "edit":
                campaignId && findCampaign(campaignId);
                campaignId && findPaymentModel(campaignId);
                break;
            case "clone":
                campaignId && cloneCampaign(campaignId);
                campaignId && findPaymentModel(campaignId);
                break;
        }
    }, [mode, campaignId, findCampaign, cloneCampaign, findPaymentModel]);

    useEffect(() => {
        return () => {
            cleanUpCampaignEdit();
            setDefualtValidation();
        };
    }, [setDefualtValidation, cleanUpCampaignEdit]);

    return (
        <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <Modal open>
                <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <CampaignEditHeader closeCampaignEdit={closeCampaignEdit} />
                        <CampaignEditBody />
                        <CampaignEditFooter closeCampaignEdit={closeCampaignEdit} />
                    </div>
                </div>
            </Modal>
        </form>
    );
});
