import { useEffect } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useMode } from "../UserEdit";
import { useStores } from "../../../stores";
import { Team } from "../../../entities/Teams";
import { Mode } from "../../../module/Enum/Mode";
import { User } from "../../../entities/UserEdit";
import { useValidations } from "../../../validation";
import { useUserRoles } from "../../../hooks/useUserRoles";
import { DEFAULT_USER } from "../../../stores/UserEditStore";
import { UserValidation } from "../../../validation/UserEditValidation";
import { FormControl, Select, MenuItem, SelectChangeEvent, FormHelperText } from "@mui/material";

export const UserTeam = observer(() => {
    const { mode } = useMode();
    const { isTeamLead } = useUserRoles();
    const { currentUser } = useStores().currentUserStore;
    const { user, changeUser } = useStores().userEditStore;
    const { teamsData, findTeams } = useStores().teamsStore;
    const { validation, changeValidation } = useValidations().userEditValidation;

    useEffect(() => {
        findTeams();
    }, [findTeams]);

    useEffect(() => {
        if (isTeamLead() && mode === Mode.ADD) changeUser({ ...DEFAULT_USER, teamId: currentUser.teamId });
    }, [mode, currentUser.teamId, isTeamLead, changeUser]);

    const changeTeam = (event: SelectChangeEvent<number>): void => {
        const newUser: User = JSON.parse(JSON.stringify(user));

        newUser.teamId = event.target.value as number;

        changeUser(newUser);
        setTeamValidation();
    };

    const setTeamValidation = (): void => {
        const newValidation: UserValidation = JSON.parse(JSON.stringify(validation));

        newValidation.teamId = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Команда</h5>
            <FormControl fullWidth error={validation.teamId}>
                <Select
                    value={user.teamId}
                    disabled={mode === Mode.CURRENT || isTeamLead()}
                    onChange={action(changeTeam)}
                >
                    <MenuItem value={-1}>Не выбрана</MenuItem>
                    {teamsData.items.map((item: Team) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.title}
                        </MenuItem>
                    ))}
                </Select>
                {validation.teamId && <FormHelperText>Необходимо выбрать команду</FormHelperText>}
            </FormControl>
        </div>
    );
});
