import React from "react";
import { observer } from "mobx-react";
import { useDomainsIds } from "../Domains";
import { useStores } from "../../../stores";
import { Domain } from "../../../entities/Domains";
import { Checkbox } from "@mui/material";

export const TableBody = observer(() => {
    const { domainsIds, setDomainsIds } = useDomainsIds();
    const { items } = useStores().domainsStore.domainsData;

    const handleChangeId = (id: number): void => {
        let domainsIdsCopy: number[] = JSON.parse(JSON.stringify(domainsIds));

        domainsIdsCopy.includes(id)
            ? (domainsIdsCopy = domainsIdsCopy.filter((item: number) => item !== id))
            : domainsIdsCopy.push(id);

        setDomainsIds(domainsIdsCopy);
    };

    return (
        <tbody>
            {items.map((item: Domain) => (
                <React.Fragment key={item.id}>
                    <tr onClick={() => handleChangeId(item.id)}>
                        <td className="align-middle">
                            <Checkbox size="small" checked={domainsIds.includes(item.id)} />
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">{item.domain}</td>
                        <td className="align-middle">{item.isDefault ? "Да" : "Нет"}</td>
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
});
