import { AxiosResponse } from "axios";
import { UsersApi } from "../services/api/UsersApi";
import { makeAutoObservable, runInAction } from "mobx";
import { User, NewUser, UserRequest } from "../entities/UserEdit";

export interface UserEditStoreInterface {
    user: NewUser | User;
    isNewUser: boolean;
    isChangedData: boolean;
    setDefaultUser(): void;
    saveUser(): Promise<User>;
    changeUser(newUser: NewUser | User): void;
    changeInitialUser(newUser: NewUser | User): void;
    findUser(userId: string | number): Promise<User>;
}

export const DEFAULT_USER: NewUser = {
    teamId: -1,
    roles: [],
    email: "",
    fullName: {
        firstName: "",
        lastName: "",
    },
    password: "",
    dateTimezone: "Europe/Moscow",
};

const { getUser, postUser, putUser } = UsersApi;

export class UserEditStore implements UserEditStoreInterface {
    public user: NewUser | User = DEFAULT_USER;
    private initialUser: NewUser | User = DEFAULT_USER;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return JSON.stringify(this.user) === JSON.stringify(this.initialUser);
    }

    get isNewUser(): boolean {
        return this.user.id === undefined;
    }

    public setDefaultUser = (): void => {
        this.user = DEFAULT_USER;
        this.initialUser = DEFAULT_USER;
    };

    public changeUser = (newUser: NewUser | User): void => {
        this.user = newUser;
    };

    public changeInitialUser = (newUser: NewUser | User): void => {
        this.initialUser = newUser;
    };

    public findUser = async (userId: string | number): Promise<User> => {
        await getUser(userId).then((response: AxiosResponse<User>) => {
            runInAction(() => {
                this.user = response.data;
                this.initialUser = response.data;
            });
        });
        return this.user as User;
    };

    public saveUser = async (): Promise<User> => {
        return this.isNewUser ? this.createUser() : this.editUser(this.user.id as number);
    };

    private createUser = async (): Promise<User> => {
        return await postUser(this.createUserRequest()).then((response: AxiosResponse<User>) => {
            return runInAction(() => {
                this.user = response.data;
                this.initialUser = response.data;
                return response.data;
            });
        });
    };

    private editUser = async (userId: number): Promise<User> => {
        return await putUser(userId, this.createUserRequest()).then((response: AxiosResponse<User>) => {
            return runInAction(() => {
                this.user = response.data;
                this.initialUser = response.data;
                return response.data;
            });
        });
    };

    private createUserRequest = (): UserRequest => {
        const newUser: User = JSON.parse(JSON.stringify(this.user));
        if (!newUser.password) delete newUser.password;
        return newUser;
    };
}
