import React, { useState } from "react";
import { Dropdown } from "./";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { ActionButtons } from "../ActionButtons";
import { Campaign } from "../../../entities/Campaigns";
import { IconButton, Tooltip } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";

export const TableBody = observer(() => {
    const { items } = useStores().campaignsStore.campaignData;
    const [dropdownIds, setDropdownIds] = useState<number[]>([]);

    const showStatistics = (id: number): void => {
        const copyCampaignIds: number[] = Object.assign([], dropdownIds);
        const index: number = copyCampaignIds.indexOf(id);
        index > -1 ? copyCampaignIds.splice(index, 1) : copyCampaignIds.push(id);
        setDropdownIds(copyCampaignIds);
    };

    return (
        <tbody>
            {items.map((item: Campaign) => (
                <React.Fragment key={item.id}>
                    <tr>
                        <td className="align-middle text-center" onClick={() => showStatistics(item.id)}>
                            <IconButton>
                                <Tooltip title="Показать статистику" placement="bottom">
                                    <AssessmentOutlinedIcon />
                                </Tooltip>
                            </IconButton>
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">
                            <div className="d-flex">
                                <div className="table__campaign">
                                    <WorkOutlineOutlinedIcon className="mr-2" />
                                    {item.title}
                                </div>
                                <div className="table__fullname">
                                    <PersonOutlinedIcon className="mr-2" />
                                    {item.manager.fullName}
                                </div>
                            </div>
                            <div className="table__source">
                                <LanguageIcon className="mr-2" />
                                {item.source.title}
                            </div>
                        </td>
                        <td className="align-middle">{item.clickCount}</td>
                        <td className="align-middle">{item.leadCount}</td>
                        <td className="align-middle">{item.cvr.toFixed(2)} %</td>

                        <td className="align-middle">
                            <ActionButtons campaignId={item.id} />
                        </td>
                    </tr>
                    {dropdownIds.includes(item.id) && <Dropdown item={item} />}
                </React.Fragment>
            ))}
        </tbody>
    );
});
