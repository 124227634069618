import { observer } from "mobx-react";
import { useStores } from "../../../stores";

export const TableFooter = observer(() => {
    const { summary } = useStores().campaignsStore.campaignData;

    return (
        <tfoot>
            <tr>
                <td colSpan={3}>Итог</td>
                <td>{summary.clickCount}</td>
                <td>{summary.leadCount}</td>
                <td>{summary.cvr.toFixed(2)} %</td>
            </tr>
        </tfoot>
    );
});
