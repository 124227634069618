import { makeAutoObservable } from "mobx";
import { DestinationType, LimitType, RoutingConditionType } from "../module/Enum/CampaignEdit/CampaignEdit";
import {
    Goal,
    Campaign,
    Condition,
    RoutingRule,
    PostbackSettings,
    PercentageRoutingRule,
} from "../entities/CampaignEdit";

export interface CampaignEditValidationInterface {
    validation: CampaignValidation;
    setDefualtValidation(): void;
    checkValidation(campaign: Campaign): boolean;
    changeValidation(newValidation: CampaignValidation): void;
}

export interface CampaignValidation {
    title: boolean;
    sourceId: boolean;
    postbackSettings: boolean;
    percentageRoutingRulesLength: boolean;
    goals: {
        [key: string]: boolean;
    };
    routingRules: {
        [key: string]: boolean;
    };
    routingRuleConditions: {
        [key: string]: boolean;
    };
    percentageRoutingRules: {
        [key: string]: boolean;
    };
}

const DEFAULT_VALIDATION: CampaignValidation = {
    title: false,
    sourceId: false,
    postbackSettings: false,
    percentageRoutingRulesLength: false,
    goals: {},
    routingRules: {},
    routingRuleConditions: {},
    percentageRoutingRules: {},
};

export class CampaignEditValidation implements CampaignEditValidationInterface {
    public validation: CampaignValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: CampaignValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (campaign: Campaign): boolean => {
        this.checkTitle(campaign.title);
        this.checkGoals(campaign.goals);
        this.checkSourceId(campaign.sourceId);
        this.checkRoutingRules(campaign.routingRules);
        this.checkPercentageRoutingRules(campaign.percentageRoutingRules);
        this.checkPostbackSettings(campaign.postbackSettings, campaign.goals);

        return Object.values(this.validation)
            .map((value: boolean | {}) => {
                return typeof value === "boolean" ? value : Object.values(value).includes(true);
            })
            .includes(true);
    };

    private checkTitle = (title: string): void => {
        const isTitleRightLength = (): boolean => {
            const minLength = 5;
            return title.length < minLength;
        };
        isTitleRightLength() ? (this.validation.title = true) : (this.validation.title = false);
    };

    private checkSourceId = (sourceId: number): void => {
        const isSourceIdExists = (): boolean => {
            return sourceId === -1;
        };
        isSourceIdExists() ? (this.validation.sourceId = true) : (this.validation.sourceId = false);
    };

    private checkGoals = (goals: Goal[]): void => {
        const isGoalsMatchesFound = (indices: number[]): boolean => {
            return indices.length > 1;
        };

        const repetitiveGoals: { [key: string]: Array<number> } = goals
            .map((goal: Goal) => goal.name)
            .reduce((prev: { [key: string]: Array<number> }, current: string, index: number) => {
                const transformCurrent: string = current.toLowerCase();

                if (!current) {
                    this.validation.goals[`goalNameLength${index}`] = true;
                } else {
                    this.validation.goals[`goalNameLength${index}`] = false;
                    prev[transformCurrent] ? prev[transformCurrent].push(index) : (prev[transformCurrent] = [index]);
                }

                return prev;
            }, {});

        Object.values(repetitiveGoals).forEach((indices: number[]) => {
            if (isGoalsMatchesFound(indices)) {
                indices.forEach((index: number) => (this.validation.goals[`goalName${index}`] = true));
            } else {
                this.validation.goals[`goalName${indices}`] = false;
            }
        });
    };

    private checkRoutingRules = (routingRules: RoutingRule[]): void => {
        const isUrl = (url: string): boolean => {
            const regUrl: RegExp = /^(ftp|http|https):\/\/[^ "]+$/;
            return regUrl.test(url);
        };

        routingRules.forEach((rule: RoutingRule, index: number): void => {
            if (rule.destination.type === DestinationType.URL) {
                isUrl(rule.destination.url)
                    ? (this.validation.routingRules[`routingRuleUrl${index}`] = false)
                    : (this.validation.routingRules[`routingRuleUrl${index}`] = true);
            } else {
                rule.destination.offerId === -1
                    ? (this.validation.routingRules[`routingRuleOffer${index}`] = true)
                    : (this.validation.routingRules[`routingRuleOffer${index}`] = false);

                rule.destination.landingId === -1
                    ? (this.validation.routingRules[`routingRuleLanding${index}`] = true)
                    : (this.validation.routingRules[`routingRuleLanding${index}`] = false);
            }

            rule.conditions.forEach((condition: Condition, gIndex: number): void => {
                !condition.expression
                    ? (this.validation.routingRuleConditions[`expression${index}${gIndex}`] = true)
                    : (this.validation.routingRuleConditions[`expression${index}${gIndex}`] = false);

                switch (condition.type) {
                    case RoutingConditionType.GEO:
                        condition.countries?.length === 0
                            ? (this.validation.routingRuleConditions[`geo${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`geo${index}${gIndex}`] = false);
                        break;
                    case RoutingConditionType.OPERATION_SYSTEM:
                        condition.operationSystems?.length === 0
                            ? (this.validation.routingRuleConditions[`operationSystems${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`operationSystems${index}${gIndex}`] = false);
                        break;
                    case RoutingConditionType.DEVICES:
                        condition.devices?.length === 0
                            ? (this.validation.routingRuleConditions[`devices${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`devices${index}${gIndex}`] = false);
                        break;
                    case RoutingConditionType.PARAMETER:
                        !condition.name
                            ? (this.validation.routingRuleConditions[`parameter${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`parameter${index}${gIndex}`] = false);
                        !condition.values?.length || !condition.values
                            ? (this.validation.routingRuleConditions[`values${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`values${index}${gIndex}`] = false);
                        break;
                    case RoutingConditionType.IP:
                        !condition.ipFrom
                            ? (this.validation.routingRuleConditions[`ipFrom${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`ipFrom${index}${gIndex}`] = false);
                        !condition.ipTo
                            ? (this.validation.routingRuleConditions[`ipTo${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`ipTo${index}${gIndex}`] = false);
                        break;
                    case RoutingConditionType.USER_AGEN:
                        !condition.userAgent
                            ? (this.validation.routingRuleConditions[`userAgent${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`userAgent${index}${gIndex}`] = false);
                        break;
                    case RoutingConditionType.REFERRER:
                        !condition.referrer
                            ? (this.validation.routingRuleConditions[`referrer${index}${gIndex}`] = true)
                            : (this.validation.routingRuleConditions[`referrer${index}${gIndex}`] = false);
                        break;
                }
            });
        });
    };

    private checkPostbackSettings = (postbackSettings: PostbackSettings, goals: Goal[]): void => {
        const postbackLimits: number = postbackSettings ? +postbackSettings.limitValue : 0;

        const isLimitTypeNotEqualPercent = (): boolean => {
            return postbackSettings.limitType !== LimitType.PERCENT;
        };

        const isPostbackLimitsExists = (): boolean => {
            return postbackLimits !== -1;
        };

        const isSumGoalsLimitsMoreThanPostbackLimits = (): boolean => {
            const sumGoalsLimits: number = goals ? calculationSumGoalsLimits(goals) : 0;
            return sumGoalsLimits > postbackLimits;
        };

        const calculationSumGoalsLimits = (goals: Goal[]): number => {
            return goals
                .map((goal: Goal) => +goal.limitValue)
                .reduce((prev: number, current: number) => prev + current, 0);
        };

        isLimitTypeNotEqualPercent() && isPostbackLimitsExists() && isSumGoalsLimitsMoreThanPostbackLimits()
            ? (this.validation.postbackSettings = true)
            : (this.validation.postbackSettings = false);
    };

    private checkPercentageRoutingRules = (percentageRoutingRules: PercentageRoutingRule[]): void => {
        const isUrl = (url: string): boolean => {
            const regUrl: RegExp = /^(ftp|http|https):\/\/[^ "]+$/;
            return regUrl.test(url);
        };

        const isOnlyPercentageRoutingRule = (): boolean => {
            return percentageRoutingRules.length === 1;
        };

        const isPercentageRoutingRulesExists = (): boolean => {
            return !percentageRoutingRules.length;
        };

        isPercentageRoutingRulesExists()
            ? (this.validation.percentageRoutingRulesLength = true)
            : (this.validation.percentageRoutingRulesLength = false);

        percentageRoutingRules.forEach((rule: PercentageRoutingRule, index: number): void => {
            if (rule.destination.type === DestinationType.URL) {
                isUrl(rule.destination.url)
                    ? (this.validation.percentageRoutingRules[`percentageRuleUrl${index}`] = false)
                    : (this.validation.percentageRoutingRules[`percentageRuleUrl${index}`] = true);
            } else {
                rule.destination.offerId === -1
                    ? (this.validation.percentageRoutingRules[`percentageRuleOffer${index}`] = true)
                    : (this.validation.percentageRoutingRules[`percentageRuleOffer${index}`] = false);

                rule.destination.landingId === -1
                    ? (this.validation.percentageRoutingRules[`percentageRuleLanding${index}`] = true)
                    : (this.validation.percentageRoutingRules[`percentageRuleLanding${index}`] = false);
            }

            !rule.percent && isOnlyPercentageRoutingRule()
                ? (this.validation.percentageRoutingRules[`percentageRulePercent${index}`] = true)
                : (this.validation.percentageRoutingRules[`percentageRulePercent${index}`] = false);
        });
    };
}

export const campaignEditValidation = new CampaignEditValidation();
