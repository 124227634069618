import { LandingEditSaveButton, LandingEditSaveAndCloseButton } from ".";

interface Props {
    closeLandingEdit(): void;
}

export const LandingEditFooter = ({ closeLandingEdit }: Props) => (
    <div className="modal-footer">
        <LandingEditSaveButton />
        <LandingEditSaveAndCloseButton closeLandingEdit={closeLandingEdit} />
    </div>
);
