import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useHistory } from "react-router-dom";
import { useValidations } from "../../../validation";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
    setNewOffer?(offerId: number): void;
}

export const OfferEditSaveButton = observer(({ setNewOffer }: Props) => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { offer, saveOffer } = useStores().offerEditStore;
    const { checkValidation } = useValidations().offerEditValidation;

    const changePath = (id: number | undefined): void => {
        id && history.push(`/spa/offers/edit/${id}`);
    };

    const save = (): void => {
        checkValidation(offer)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveOffer().then((response) => {
                  if (response) {
                      setNewOffer ? setNewOffer(response.id as number) : changePath(response.id);
                      enqueueSnackbar("Оффер сохранен", { variant: "success", autoHideDuration: 1500 });
                  }
              });
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(save)}>
            Сохранить
        </Button>
    );
});
