export enum Roles {
    ROLE_USER = "ROLE_USER",
    ROLE_MEDIA_BUYER = "ROLE_MEDIA_BUYER",
    ROLE_TEAMLEAD = "ROLE_TEAMLEAD",
    ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR",
}

interface RolesName {
    [key: string]: string;
}

export const convertRolesName: RolesName = {
    ROLE_USER: "Пользователь",
    ROLE_MEDIA_BUYER: "Медиа байер",
    ROLE_TEAMLEAD: "Тимлид",
    ROLE_ADMINISTRATOR: "Администратор",
};
