import { useContext, createContext } from "react";
import { observer } from "mobx-react";
import { useRoutingRule } from "../";
import { ConditionField } from "./ConditionField";
import { Condition } from "../../../../../entities/CampaignEdit";
import { ConditionTitle, ConditionExpression, ConditionDeleteButton } from ".";

interface ConditionContextInterface {
    condition: Condition;
    conditionIndex: number;
}

const ConditionContext = createContext<ConditionContextInterface>({} as ConditionContextInterface);

export const ConditionsTable = observer(() => {
    const { rule } = useRoutingRule();

    return (
        <>
            {rule.conditions.length > 0 && (
                <table className="table table-sm table-responsive-md ml-3 mt-3">
                    <tbody>
                        {rule.conditions.map((condition: Condition, conditionIndex: number) => (
                            <ConditionContext.Provider key={conditionIndex} value={{ condition, conditionIndex }}>
                                <tr>
                                    <td className="table__type text-nowrap position-relative">
                                        <ConditionTitle />
                                    </td>
                                    <td>
                                        <ConditionExpression />
                                    </td>
                                    <td className="col table__field">
                                        <ConditionField />
                                    </td>
                                    <td>
                                        <ConditionDeleteButton />
                                    </td>
                                </tr>
                            </ConditionContext.Provider>
                        ))}
                    </tbody>
                </table>
            )}
        </>
    );
});

export const useCondition = (): ConditionContextInterface => {
    return useContext(ConditionContext);
};
