import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { DomainsTable } from "./DomainsTable";
import { ActionButtons } from "./ActionButtons";

interface DomainsIdsContextInterface {
    domainsIds: number[];
    setDomainsIds: Dispatch<SetStateAction<number[]>>;
}

export const Domains = observer(() => {
    const { findDomains } = useStores().domainsStore;
    const [domainsIds, setDomainsIds] = useState<number[]>([]);

    useEffect(() => {
        findDomains();
    }, [findDomains]);

    useEffect(() => {
        setDomainsIds([]);
    }, []);

    return (
        <DomainsIdsContext.Provider value={{ domainsIds, setDomainsIds }}>
            <div className="container-fluid">
                <div className="ml-4">
                    <h1>Домены</h1>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <ActionButtons />
                </div>
                <DomainsTable />
            </div>
        </DomainsIdsContext.Provider>
    );
});

const DomainsIdsContext = createContext<DomainsIdsContextInterface>({} as DomainsIdsContextInterface);

export const useDomainsIds = (): DomainsIdsContextInterface => {
    return useContext(DomainsIdsContext);
};
