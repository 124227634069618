import { useState, MouseEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useRoutingRule } from "./";
import { useStores } from "../../../../stores";
import { Campaign, RoutingRule } from "../../../../entities/CampaignEdit";
import {
    convertConditionType,
    RoutingConditionType,
    RoutingConditionExpression,
} from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Button, Menu, MenuItem } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export const ConditionAddButton = observer(() => {
    const { rule, ruleIndex } = useRoutingRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const addCondition = (event: any): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const conditionType = event.target.attributes.value.value as RoutingConditionType;

        switch (conditionType) {
            case RoutingConditionType.GEO:
                newRule.conditions.push({
                    type: RoutingConditionType.GEO,
                    expression: RoutingConditionExpression.EQUAL,
                    countries: [],
                });
                break;
            case RoutingConditionType.OPERATION_SYSTEM:
                newRule.conditions.push({
                    type: RoutingConditionType.OPERATION_SYSTEM,
                    expression: RoutingConditionExpression.EQUAL,
                    operationSystems: [],
                });
                break;
            case RoutingConditionType.DEVICES:
                newRule.conditions.push({
                    type: RoutingConditionType.DEVICES,
                    expression: RoutingConditionExpression.EQUAL,
                    devices: [],
                });
                break;
            case RoutingConditionType.PARAMETER:
                newRule.conditions.push({
                    type: RoutingConditionType.PARAMETER,
                    expression: RoutingConditionExpression.EQUAL,
                    name: "",
                    values: [],
                });
                break;
            case RoutingConditionType.IP:
                newRule.conditions.push({
                    type: RoutingConditionType.IP,
                    expression: RoutingConditionExpression.EQUAL,
                    ipFrom: "",
                    ipTo: "",
                });
                break;
            case RoutingConditionType.USER_AGEN:
                newRule.conditions.push({
                    type: RoutingConditionType.USER_AGEN,
                    expression: RoutingConditionExpression.EQUAL,
                    userAgent: "",
                });
                break;
            case RoutingConditionType.REFERRER:
                newRule.conditions.push({
                    type: RoutingConditionType.REFERRER,
                    expression: RoutingConditionExpression.EQUAL,
                    referrer: "",
                });
                break;
        }

        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        handleClose();
        changeCampaign(newCampaign);
    };

    return (
        <div className="ml-4 mr-4">
            <Button color="success" variant="outlined" startIcon={<AddBoxOutlinedIcon />} onClick={handleClick}>
                Условие
            </Button>
            <Menu keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {Object.values(RoutingConditionType).map((value: string) => (
                    <MenuItem key={value} value={value} onClick={action(addCondition)}>
                        {convertConditionType[value]}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
});
