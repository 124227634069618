import { Navbar, CurrentUserDropdown } from "./";

export const Layout = () => (
    <div className="mb-4">
        <nav className=" navbar navbar-expand navbar-light bg-light">
            <div className="collapse navbar-collapse layout-width">
                <Navbar />
                <CurrentUserDropdown />
            </div>
        </nav>
    </div>
);
