import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores/index";
import { useValidations } from "../../../../validation";
import { Campaign } from "../../../../entities/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { TextField } from "@mui/material";

export const CampaignTitle = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const changeCampaignTitle = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.title = event.target.value;

        setTitleValidation();
        changeCampaign(newCampaign);
    };

    const setTitleValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.title = false;

        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            variant="outlined"
            label="Наименование кампании"
            value={campaign.title}
            error={validation.title}
            helperText={validation.title && "Наименование не может содержать меньше 5 символов"}
            onChange={action(changeCampaignTitle)}
            InputLabelProps={{
                shrink: true,
            }}
        />
    );
});
