import { AxiosResponse } from "axios";
import { TimezoneData } from "../entities/Timezone";
import { makeAutoObservable, runInAction } from "mobx";
import { TimezoneApi } from "../services/api/TimezoneApi";

export interface TimezoneStoreInterface {
    timezoneData: TimezoneData;
    findTimezone(): Promise<TimezoneData>;
}

const { getTimezone } = TimezoneApi;

export class TimezoneStore implements TimezoneStoreInterface {
    public timezoneData: TimezoneData = {
        items: [],
    };

    constructor() {
        makeAutoObservable(this);
    }

    public findTimezone = async (): Promise<TimezoneData> => {
        await getTimezone().then((response: AxiosResponse<TimezoneData>) => {
            runInAction(() => {
                this.timezoneData = response.data;
            });
        });
        return this.timezoneData;
    };
}
