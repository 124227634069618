import { AxiosResponse } from "axios";
import { AuthApi } from "../services/api/AuthApi";
import { makeAutoObservable, runInAction } from "mobx";
import { getToken } from "../module/AxiosRequest/AxiosRequest";
import {
    Token,
    AuthData,
    PasswordResetData,
    PasswordResetRequest,
    PasswordResetInitializeData,
} from "../entities/Auth";

export interface AuthStoreInterface {
    isAuthorized: boolean;
    authData: AuthData;
    forgotPassword: boolean;
    passwordResetData: PasswordResetData;
    passwordResetInitializeData: PasswordResetInitializeData;
    cleanUpAuth(): void;
    logout(): Promise<AxiosResponse>;
    changeAuthData(data: AuthData): void;
    setIsAuthorized(value: boolean): void;
    signIn(): Promise<AxiosResponse<Token>>;
    setForgotPassword(value: boolean): void;
    initializePasswordReset(): Promise<AxiosResponse<{}>>;
    changePasswordResetData(data: PasswordResetData): void;
    resetPassword(token: string): Promise<AxiosResponse<{}>>;
    changePasswordResetInitializeData(data: PasswordResetInitializeData): void;
}

const DEFAULT_AUTH_DATA: AuthData = {
    email: "",
    password: "",
};

const DEFAULT_PASSWORD_RESET_DATA: PasswordResetData = {
    password: "",
    passwordRepeat: "",
};

const DEFAULT_PASSWORD_RESET_INITIALIZE_DATA: PasswordResetInitializeData = {
    email: "",
};

const { getLogout, postSignIn, postPasswordReset, postPasswordResetInitialize } = AuthApi;

export class AuthStore implements AuthStoreInterface {
    public forgotPassword: boolean = false;
    public authData: AuthData = DEFAULT_AUTH_DATA;
    public passwordResetData: PasswordResetData = DEFAULT_PASSWORD_RESET_DATA;
    public passwordResetInitializeData: PasswordResetInitializeData = DEFAULT_PASSWORD_RESET_INITIALIZE_DATA;
    private authorized: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isAuthorized(): boolean {
        return this.authorized;
    }

    public setIsAuthorized = (value: boolean): void => {
        this.authorized = value;
    };

    public setForgotPassword = (value: boolean): void => {
        this.forgotPassword = value;
    };

    public changeAuthData = (data: AuthData): void => {
        this.authData = data;
    };

    public changePasswordResetData = (data: PasswordResetData): void => {
        this.passwordResetData = data;
    };

    public changePasswordResetInitializeData = (data: PasswordResetInitializeData): void => {
        this.passwordResetInitializeData = data;
    };

    public cleanUpAuth = (): void => {
        this.forgotPassword = false;
        this.authData = DEFAULT_AUTH_DATA;
        this.passwordResetData = DEFAULT_PASSWORD_RESET_DATA;
        this.passwordResetInitializeData = DEFAULT_PASSWORD_RESET_INITIALIZE_DATA;
    };

    public logout = async (): Promise<AxiosResponse> => {
        this.setIsAuthorized(false);

        return await getLogout().then((response) => {
            return runInAction(() => {
                getToken("");
                this.setIsAuthorized(false);
                localStorage.removeItem("jwtToken");
                return response;
            });
        });
    };

    public signIn = async (): Promise<AxiosResponse<Token>> => {
        return await postSignIn(this.authData).then((response: AxiosResponse<Token>) => {
            return runInAction(() => {
                getToken(response.data.jwtToken);
                localStorage.setItem("jwtToken", response.data.jwtToken);
                this.setIsAuthorized(true);
                return response;
            });
        });
    };

    public initializePasswordReset = async (): Promise<AxiosResponse<{}>> => {
        return await postPasswordResetInitialize(this.passwordResetInitializeData);
    };

    public resetPassword = async (token: string): Promise<AxiosResponse<{}>> => {
        return await postPasswordReset(token, this.createPasswordResetRequest());
    };

    private createPasswordResetRequest = (): PasswordResetRequest => {
        const data: PasswordResetRequest = {
            password: this.passwordResetData.password,
        };
        return data;
    };
}
