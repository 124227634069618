import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { CampaignsApi } from "../services/api/CampaignsApi";
import { CampaignSearchCriteria, CampaignSearchData, CampaignSearchItem } from "../entities/CampaignSearch";

export interface CampaignSearchStoreInterface {
    campaignSearchData: CampaignSearchItem[];
    setDefaultData(): void;
    searchCampaign(criteria: CampaignSearchCriteria): Promise<CampaignSearchItem[]>;
}

const { getCampaignSearch } = CampaignsApi;

export class CampaignSearchStore implements CampaignSearchStoreInterface {
    public campaignSearchData: CampaignSearchItem[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public setDefaultData = (): void => {
        this.campaignSearchData = [];
    };

    public searchCampaign = async (criteria: CampaignSearchCriteria): Promise<CampaignSearchItem[]> => {
        await getCampaignSearch(criteria).then((response: AxiosResponse<CampaignSearchData>) => {
            runInAction(() => {
                this.campaignSearchData = response.data.items;
            });
        });
        return this.campaignSearchData;
    };
}
