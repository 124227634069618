import { observer } from "mobx-react";
import { useUserRoles } from "../../../hooks/useUserRoles";
import { UserAddButton, UserEditButton, UserDeleteButton } from ".";
import "../../../style/actionButtons.css";

export const ActionButtons = observer(() => {
    const { isAdministrator } = useUserRoles();

    return (
        <div className="d-flex ml-4 pt-3">
            <UserAddButton />
            <UserEditButton />
            {isAdministrator() && <UserDeleteButton />}
        </div>
    );
});
