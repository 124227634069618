import { useEffect } from "react";
import { observer } from "mobx-react";
import { Domains, Goals, Links } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";

interface ParamsProps {
    mode: Mode;
    campaignId: string;
}

export const CampaignLinks = observer(() => {
    const { mode, campaignId } = useParams<ParamsProps>();
    const { criteria, findCampaignLinks, cleanUpCampaignLinks } = useStores().campaignLinksStore;

    useEffect(() => {
        const isDomainIdExist = (): boolean => {
            return criteria.domainId !== "-1";
        };

        switch (mode) {
            case Mode.EDIT:
                isDomainIdExist() && findCampaignLinks(Number(campaignId));
                break;
            default:
                cleanUpCampaignLinks();
                break;
        }
    }, [mode, campaignId, criteria.domainId, criteria.goalName, findCampaignLinks, cleanUpCampaignLinks]);

    useEffect(() => {
        return cleanUpCampaignLinks;
    }, [cleanUpCampaignLinks]);

    return (
        <div>
            <h5>Ссылки кампании</h5>
            <div className="d-flex align-items-center mt-4">
                <div className="container-fluid pl-0">
                    <Domains />
                </div>
                <div className="container-fluid pr-0">
                    <Goals />
                </div>
            </div>
            <div className="mt-4">
                <Links />
            </div>
        </div>
    );
});
