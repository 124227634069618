export enum DateFilters {
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "thisWeek",
    LAST_WEEK = "lastWeek",
    LAST_SEVEN_DAYS = "lastSevenDays",
    THIS_MONTH = "thisMonth",
    LAST_MONTH = "lastMonth",
    LAST_THIRTY_DAYS = "lastThirtyDays",
}

export const convertDateFilters = {
    today: "Сегодня",
    yesterday: "Вчера",
    thisWeek: "Текущая неделя",
    lastWeek: "Прошлая неделя",
    lastSevenDays: "Последние 7 дней",
    thisMonth: "Текущий месяц",
    lastMonth: "Прошлый месяц",
    lastThirtyDays: "Последние 30 дней",
};
