export enum SortField {
    ID = "id",
    TITLE = "title",
    CLICK_COUNT = "clickCount",
    LEAD_COUNT = "leadCount",
    CVR = "cvr",
}

export const convertFieldName: any = {
    id: "#",
    title: "Наименование",
    clickCount: "Клики",
    leadCount: "Лиды",
    cvr: "CVR",
};
