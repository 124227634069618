import { AxiosResponse } from "axios";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { AuthData, Token, PasswordResetInitializeData, PasswordResetRequest } from "../../entities/Auth";

interface AuthApiInterface {
    getLogout(): Promise<AxiosResponse>;
    postSignIn(authData: AuthData): Promise<AxiosResponse<Token>>;
    postPasswordReset(token: string, data: PasswordResetRequest): Promise<AxiosResponse<{}>>;
    postPasswordResetInitialize(data: PasswordResetInitializeData): Promise<AxiosResponse<{}>>;
}

export const AuthApi: AuthApiInterface = {
    getLogout: async (): Promise<AxiosResponse> => await httpClient.get(`/auth/logout`),

    postSignIn: async (authData: AuthData): Promise<AxiosResponse<Token>> =>
        await httpClient.post<Token>(`/auth/sign-in`, authData),

    postPasswordReset: async (token: string, data: PasswordResetRequest): Promise<AxiosResponse<{}>> =>
        await httpClient.post<AxiosResponse<{}>>(`/auth/reset-password/${token}`, data),

    postPasswordResetInitialize: async (data: PasswordResetInitializeData): Promise<AxiosResponse<{}>> =>
        await httpClient.post<AxiosResponse<{}>>(`/auth/reset-password`, data),
};
