import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { FiltersApi } from "../services/api/FiltersApi";
import { FilterType } from "../module/Enum/Filters/Filters";
import { FiltersData, Filter, FilterData, FilterRequest } from "../entities/Filters";

export interface FiltersStoreInterface {
    filter: FilterRequest;
    filtersData: FiltersData;
    setDefaultFilter(): void;
    changeFilter(newFilter: FilterRequest): void;
    createFilter(): Promise<AxiosResponse<Filter>>;
    findFilters(type: FilterType): Promise<FiltersData>;
    removeFilter(filter: Filter): Promise<AxiosResponse<{}>>;
}

const DEFAULT_FILTER: FilterRequest = {
    title: "",
    data: "",
};

const { getFilters, postFilter, deleteFilter } = FiltersApi;

export class FiltersStore implements FiltersStoreInterface {
    public filter: FilterRequest = DEFAULT_FILTER;
    public filtersData: FiltersData = {
        items: [],
    };

    constructor() {
        makeAutoObservable(this);
    }

    public setDefaultFilter = (): void => {
        this.filter = DEFAULT_FILTER;
    };

    public changeFilter = (newFilter: FilterRequest): void => {
        this.filter = newFilter;
    };

    public findFilters = async (type: FilterType): Promise<FiltersData> => {
        await getFilters().then((response: AxiosResponse<FiltersData>) => {
            runInAction(() => {
                this.filtersData = this.transformData(type, response.data);
            });
        });
        return this.filtersData;
    };

    public createFilter = async (): Promise<AxiosResponse<Filter>> => {
        return await postFilter(this.createPostFilterRequest()).then((response: AxiosResponse<Filter>) => {
            return runInAction(() => {
                this.findFilters((this.filter.data as FilterData).type);
                return response;
            });
        });
    };

    public removeFilter = async (filter: Filter): Promise<AxiosResponse<{}>> => {
        return await deleteFilter(filter.id).then((response: AxiosResponse<{}>) => {
            return runInAction(() => {
                this.findFilters((filter.data as FilterData).type);
                return response;
            });
        });
    };

    private createPostFilterRequest = (): FilterRequest => {
        const newFilter: FilterRequest = { ...this.filter };

        newFilter.data = JSON.stringify(newFilter.data);

        return newFilter;
    };

    private transformData = (type: FilterType, data: FiltersData): FiltersData => {
        const newData: FiltersData = { ...data };

        newData.items = newData.items.map((item: Filter) => {
            const newItem: Filter = { ...item };
            newItem.data = JSON.parse(newItem.data as string);
            return newItem;
        });

        switch (type) {
            case FilterType.STATISTICS:
                newData.items = newData.items.filter(
                    (item: Filter) => (item.data as FilterData).type === FilterType.STATISTICS
                );
                break;
            case FilterType.DROPDOWN_STATISTICS:
                newData.items = newData.items.filter(
                    (item: Filter) => (item.data as FilterData).type === FilterType.DROPDOWN_STATISTICS
                );
                break;
        }

        return newData;
    };
}
