import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Campaign, Goal } from "../../../../entities/CampaignEdit";
import { Button } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export const GoalAddButton = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const addGoal = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newGoal: Goal = {
            name: "",
            limitValue: "0",
            isEnabled: false,
        };

        newCampaign.goals.push(newGoal);

        changeCampaign(newCampaign);
    };

    return (
        <Button color="success" variant="outlined" startIcon={<AddBoxOutlinedIcon />} onClick={action(addGoal)}>
            Добавить цель
        </Button>
    );
});
