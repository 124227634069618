import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography, AccordionDetails, AccordionSummary } from "@mui/material";

export const PaymentModelInfo = () => (
    <Accordion className="payout-model-info">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className="d-flex payout-model-info__title">
                <HelpIcon className="mr-2" />
                Справка по настройке модели затрат
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                Не отслеживать - правила моделей затрат не применяются к кампании.
                <br />
                <br />
                CPA - работает только с направлением трафика на Офферы. В поле Cost $ указывается стоимость лида в
                долларах США. Значение Payout берётся из настроек Оффера или из параметра во входящем постбэке.
                <br />
                <br />
                Доход кампании считается как: payout * кол-во лидов.
                <br />
                Расход кампании считается как: cost $ * кол-во исходящих постбэков.
                <br />
                <br />
                RevShare - работает только с направлением трафика на Офферы. В поле Cost % указывается процент от
                стоимости лида, который выплачивается источнику. Значение Payout берём из параметра во входящих
                постбэках.
                <br />
                <br />
                Доход считается как: кол-во лидов * Payout.
                <br />
                Расход считается как: Payout * (Cost % / 100) * кол-во исходящих постбэков.
            </Typography>
        </AccordionDetails>
    </Accordion>
);
