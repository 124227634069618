import { SourcePostbacks } from "./SourcePostbacks";
import { SourceParameters } from "./SourceParameters";
import { SourceTitle, SourceGlobalPostbackUrlPattern } from "./";

export const SourceEditBody = () => (
    <div className="modal-body modal-overflow">
        <SourceTitle />
        <SourceGlobalPostbackUrlPattern />
        <SourceParameters />
        <SourcePostbacks />
    </div>
);
