import { makeAutoObservable } from "mobx";
import { ReportRequest } from "../entities/Report";

export interface ReportValidationInterface {
    validation: Validation;
    setDefualtValidation(): void;
    changeValidation(newValidation: Validation): void;
    checkValidation(reportRequest: ReportRequest): boolean;
}

export interface Validation {
    groupBy: boolean;
}

const DEFAULT_VALIDATION: Validation = {
    groupBy: false,
};

export class ReportValidation implements ReportValidationInterface {
    public validation: Validation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: Validation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (reportRequest: ReportRequest): boolean => {
        reportRequest.groupBy.length === 0 ? (this.validation.groupBy = true) : (this.validation.groupBy = false);

        return Object.values(this.validation).includes(true);
    };
}
