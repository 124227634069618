import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Landing } from "../../../../entities/LandingEdit";
import { IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    userId: number;
    deleteUser(userId: number): void;
}

export const UserDeleteButton = observer(({ userId, deleteUser }: Props) => {
    const { landing, changeLanding } = useStores().landingEditStore;

    const deleteAllowedUser = (): void => {
        const landingCopy: Landing = JSON.parse(JSON.stringify(landing));
        landingCopy.allowedUserIds = landingCopy.allowedUserIds.filter((id: number) => userId !== id);
        changeLanding(landingCopy);
        deleteUser(userId);
    };

    return (
        <Tooltip title="Удалить пользователя">
            <IconButton className="p-0" color="inherit" size="small" onClick={action(deleteAllowedUser)}>
                <CloseIcon />
            </IconButton>
        </Tooltip>
    );
});
