import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Campaign, Goal } from "../../../../entities/CampaignEdit";
import { LimitType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { TextField, Checkbox, Tooltip, InputAdornment } from "@mui/material";

interface Props {
    goal: Goal;
    goalIndex: number;
}

export const GoalLimitValue = observer(({ goal, goalIndex }: Props) => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const changeGoalLimitValue = (event: ChangeEvent<HTMLInputElement>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newGoal: Goal = JSON.parse(JSON.stringify(goal));

        switch (true) {
            case newCampaign.postbackSettings.limitType === LimitType.PERCENT:
                if (Number(event.target.value) <= 100) newGoal.limitValue = event.target.value;
                break;

            case !/\D/.test(event.target.value):
                newGoal.limitValue = event.target.value;
                break;

            case event.target.value === "":
                newGoal.limitValue = "0";
                break;
        }

        newCampaign.goals.splice(goalIndex, 1, newGoal);

        changeCampaign(newCampaign);
        setPostbackSettingsValidation();
    };

    const handlerGoalLimitValue = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newGoal: Goal = JSON.parse(JSON.stringify(goal));

        newGoal.limitValue === "-1" ? (newGoal.limitValue = "0") : (newGoal.limitValue = "-1");
        newCampaign.goals.splice(goalIndex, 1, newGoal);

        changeCampaign(newCampaign);
        setPostbackSettingsValidation();
    };

    const setPostbackSettingsValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.postbackSettings = false;

        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            error={validation.postbackSettings}
            value={goal.limitValue !== "-1" ? +goal.limitValue : "Безлимит"}
            label={campaign.postbackSettings.limitType !== LimitType.PERCENT ? "Лимит" : "Лимит (%)"}
            onChange={action(changeGoalLimitValue)}
            InputProps={{
                className: "p-0",
                disabled: goal.limitValue === "-1",
                startAdornment: (
                    <InputAdornment position="start">
                        <Tooltip title="Безлимитная отправка постбэков">
                            <Checkbox
                                size="small"
                                checked={goal.limitValue === "-1"}
                                color={validation.postbackSettings ? "error" : "primary"}
                                onClick={action(handlerGoalLimitValue)}
                            />
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        />
    );
});
