import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useRoutingRule } from "./";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { convertDestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Campaign, RoutingRule, RuleDestinationTypeUrl } from "../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const RuleUrl = observer(() => {
    const [open, setOpen] = useState<boolean>(false);
    const { rule, ruleIndex } = useRoutingRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const fieldName: string = `routingRuleUrl${ruleIndex}`;

    const handleOpen = (): void => setOpen(true);

    const handleClose = (): void => setOpen(false);

    const changeRuleUrl = (event: ChangeEvent<{ value: string }>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: RoutingRule = JSON.parse(JSON.stringify(rule));
        const newString: string = event.target.value.replace(/%20/g, "").replace(/\s+/g, "");

        (newRule.destination as RuleDestinationTypeUrl).url = newString;
        newCampaign.routingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
        setRoutingRuleUrlValidation();
    };

    const setRoutingRuleUrlValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.routingRules[fieldName] = false;

        changeValidation(newValidation);
    };

    return (
        <TextField
            fullWidth
            multiline
            variant="standard"
            maxRows={open ? 10 : 1}
            error={validation.routingRules[fieldName]}
            label={convertDestinationType[rule.destination.type]}
            value={(rule.destination as RuleDestinationTypeUrl).url}
            helperText={validation.routingRules[fieldName] && "Url должно быть вида https://example.com"}
            onBlur={handleClose}
            onFocus={handleOpen}
            onChange={action(changeRuleUrl)}
        />
    );
});
