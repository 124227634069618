import { useStatisticsContext } from "./Statistics";
import { Pagination, FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";

export const StatisticsPagination = () => {
    const { criteria, setCriteria, statisticsData } = useStatisticsContext();

    const changePage = (event: object, currentPage: number): void => {
        setCriteria({
            ...criteria,
            page: currentPage,
        });
    };

    const changeItemsPerPage = (event: SelectChangeEvent<number>): void => {
        setCriteria({
            ...criteria,
            page: 1,
            itemsPerPage: Number(event.target.value),
        });
    };

    return (
        <div className="d-flex align-items-center">
            <Pagination
                shape="rounded"
                page={criteria.page}
                className="pagination mr-2 mb-4"
                count={Math.ceil(statisticsData.totalItems / criteria.itemsPerPage) || 1}
                onChange={changePage}
            />

            <label className="mr-3 mb-4">Кол-во строк</label>

            <FormControl variant="standard" className="mb-4">
                <Select value={criteria.itemsPerPage} onChange={changeItemsPerPage}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};
