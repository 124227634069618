import React from "react";
import ReactDOM from "react-dom";
import { Tracker } from "./Tracker";
import { SnackbarProvider } from "notistack";
import { BrowserRouter as Router } from "react-router-dom";
import "./style/index.css";

ReactDOM.render(
    <Router>
        <SnackbarProvider maxSnack={3}>
            <Tracker />
        </SnackbarProvider>
    </Router>,
    document.getElementById("tracker-root")
);
