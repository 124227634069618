import { useState, useEffect } from "react";
import { useStores } from "../../stores";
import { SourceParameters, SourceParameter } from "../../entities/SourceParameters";

export const useSourceParameters = (campaignId?: number, dropdownNotice?: boolean): SourceParameter[] => {
    const [sourceParameters, setSourceParameters] = useState<SourceParameter[]>([]);
    const { findSourceParameters, findCampaignSourceParameters, cleanUpSourceParameters } =
        useStores().sourceParametersStore;

    useEffect(() => {
        dropdownNotice && campaignId !== undefined
            ? findCampaignSourceParameters(campaignId).then((response: SourceParameters) => {
                  setSourceParameters(response.items);
              })
            : findSourceParameters().then((response: SourceParameters) => {
                  setSourceParameters(response.items);
              });
    }, [dropdownNotice, campaignId, findCampaignSourceParameters, findSourceParameters]);

    useEffect(() => {
        return cleanUpSourceParameters;
    }, [cleanUpSourceParameters]);

    return sourceParameters;
};
