import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReportRequest } from "../../../../entities/Report";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface Props {
    filterIndex: number;
}

export const FilterDeleteButton = observer(({ filterIndex }: Props) => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;

    const deleteFilter = (): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));

        newReportRequest.filter.splice(filterIndex, 1);

        changeReportRequest(newReportRequest);
    };

    return (
        <Button color="error" onClick={action(deleteFilter)}>
            <DeleteForeverOutlinedIcon />
        </Button>
    );
});
