import { SourceEditTitle, SourceEditCloseButton } from "./";

interface Props {
    closeSourceEdit(): void;
}

export const SourceEditHeader = ({ closeSourceEdit }: Props) => (
    <div className="modal-header">
        <SourceEditTitle />
        <SourceEditCloseButton closeSourceEdit={closeSourceEdit} />
    </div>
);
