import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { useDefaultRule } from "../";
import { UrlParamField, UrlParamDeleteButton } from ".";
import { RuleDestinationTypeUrl } from "../../../../../entities/CampaignEdit";

interface UrlParamsContextInterface {
    urlParams: string[];
    setUrlParams: Dispatch<SetStateAction<string[]>>;
}

const UrlParamsContext = createContext<UrlParamsContextInterface>({} as UrlParamsContextInterface);

export const UrlParamsEdit = observer(() => {
    const { rule } = useDefaultRule();
    const [urlParams, setUrlParams] = useState<string[]>([]);
    const [url, urlParameters] = (rule.destination as RuleDestinationTypeUrl).url
        ? (rule.destination as RuleDestinationTypeUrl).url.split("?")
        : [];

    useEffect(() => {
        url && urlParameters ? setUrlParams(urlParameters.split("&")) : setUrlParams([]);
    }, [url, urlParameters]);

    return (
        <UrlParamsContext.Provider value={{ urlParams, setUrlParams }}>
            {urlParams.length > 0 &&
                urlParams.map((parameter: string, index: number) => (
                    <div className="d-flex justify-content-around align-items-center">
                        <div className="mr-4">{index + 1}.</div>
                        <UrlParamField parameter={parameter} parameterIndex={index} />
                        <UrlParamDeleteButton parameterIndex={index} />
                    </div>
                ))}
        </UrlParamsContext.Provider>
    );
});

export const useUrlParams = (): UrlParamsContextInterface => {
    return useContext(UrlParamsContext);
};
