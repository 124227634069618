import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { CampaignCriteria } from "../../../entities/Campaigns";
import { SortField } from "../../../module/Enum/Campaigns/SortField";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
    closeCampaignEdit(): void;
}

interface ParamsProps {
    mode: Mode;
}

export const CampaignEditSaveAndCloseButton = observer(({ closeCampaignEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { enqueueSnackbar } = useSnackbar();
    const { criteria, changeCriteria } = useStores().campaignsStore;
    const { checkValidation } = useValidations().campaignEditValidation;
    const { campaign, saveCampaign, editPaymentModel } = useStores().campaignEditStore;

    const filterCampaignsTable = (): void => {
        const newCriteria: CampaignCriteria = { ...criteria };
        newCriteria.page = 1;
        newCriteria.sort = {
            direction: SortDirection.DESC,
            field: SortField.ID,
        };
        changeCriteria(newCriteria);
    };

    const saveAndClose = (): void => {
        checkValidation(campaign)
            ? enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveCampaign().then((response) => {
                  if (response) {
                      if (mode === Mode.ADD) filterCampaignsTable();
                      if (response.id) editPaymentModel(response.id);
                      closeCampaignEdit();
                      enqueueSnackbar("Кампания сохранена", { variant: "success", autoHideDuration: 1500 });
                  }
              });
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(saveAndClose)}>
            Сохранить и выйти
        </Button>
    );
});
