import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Domain } from "../../../entities/DomainEdit";
import { FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";

export const DomainIsDefault = observer(() => {
    const { domain, changeDomain } = useStores().domainEditStore;

    const changeDefaultDomain = (event: SelectChangeEvent): void => {
        const newDomain: Domain = JSON.parse(JSON.stringify(domain));

        newDomain.isDefault = event.target.value === "true" ? true : false;

        changeDomain(newDomain);
    };

    return (
        <div className="field">
            <h5 className="mr-2">Использовать по умолчанию?</h5>
            <FormControl fullWidth>
                <Select value={String(domain.isDefault)} onChange={action(changeDefaultDomain)}>
                    <MenuItem value="true">Да</MenuItem>
                    <MenuItem value="false">Нет</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
});
