import { ReportSort } from "./ReportSort";
import { ReportFilter } from "./ReportFilter";
import { ReportDate, ReportDeltaDate, ReportGroupings, CampaignSearch, ReportCreateButton } from "./";

export const ReportForm = () => (
    <form className="pt-4 pb-4">
        <div className="ml-4 mb-4">
            <div className="d-flex align-items-start">
                <div className="date_with">
                    <ReportDate />
                </div>
                <div className="groopings_width ml-4">
                    <ReportGroupings />
                </div>
                <div className="w-100 ml-4">
                    <ReportSort />
                </div>
            </div>
            <div className="d-flex align-items-start mt-4">
                <div className="date_with">
                    <ReportDeltaDate />
                </div>
                <div className="search_width ml-4">
                    <CampaignSearch />
                </div>

                <div className="w-100 ml-4">
                    <ReportFilter />
                </div>
            </div>
        </div>

        <ReportCreateButton />
    </form>
);
