import { OfferEditTitle, OfferEditCloseButton } from "./";

interface Props {
    closeOfferEdit(): void;
}

export const OfferEditHeader = ({ closeOfferEdit }: Props) => (
    <div className="modal-header">
        <OfferEditTitle />
        <OfferEditCloseButton closeOfferEdit={closeOfferEdit} />
    </div>
);
