import { observer } from "mobx-react";
import { useDomainsIds } from "../Domains";
import { useStores } from "../../../stores";
import { Domain } from "../../../entities/Domains";
import { Checkbox } from "@mui/material";

enum Field {
    ID = "id",
    DOMAIN = "domain",
    IS_DEFAULT = "isDefault",
}

interface ConvertField {
    id: string;
    domain: string;
    isDefault: string;
    [key: string]: string;
}

const convertField: ConvertField = {
    id: "#",
    domain: "Домен",
    isDefault: "По умолчанию",
};

export const TableHead = observer(() => {
    const { domainsIds, setDomainsIds } = useDomainsIds();
    const { items } = useStores().domainsStore.domainsData;

    const handleChangeAllId = (): void => {
        const booleanArray: boolean[] = [];
        const domainsIdsCopy: number[] = JSON.parse(JSON.stringify(domainsIds));

        items.forEach((item: Domain) => {
            booleanArray.push(domainsIdsCopy.includes(item.id));
            if (!domainsIdsCopy.includes(item.id)) domainsIdsCopy.push(item.id);
        });
        !booleanArray.includes(false) ? setDomainsIds([]) : setDomainsIds(domainsIdsCopy);
    };

    return (
        <thead>
            <tr>
                <th className="table__th-width pb-1" onClick={handleChangeAllId}>
                    <Checkbox
                        size="small"
                        color="primary"
                        checked={items.length !== 0 && domainsIds.length === items.length}
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </th>
                {Object.values(Field).map((value: Field, index: number) => (
                    <th key={value} className={index === 0 ? "table__th-width" : ""}>
                        <div className="table__text-position">{convertField[value]}</div>
                    </th>
                ))}
            </tr>
        </thead>
    );
});
