import { AxiosResponse } from "axios";
import { Team } from "../entities/TeamEdit";
import { TeamsApi } from "../services/api/TeamsApi";
import { makeAutoObservable, runInAction } from "mobx";

export interface TeamEditStoreInterface {
    team: Team;
    isChangedData: boolean;
    setDefaultTeam(): void;
    saveTeam(): Promise<Team>;
    changeTeam(newTeam: Team): void;
    findTeam(teamId: string): Promise<Team>;
}

const DEFAULT_TEAM: Team = {
    title: "",
    teamLeadId: null,
};

const { getTeam, postTeam, putTeam } = TeamsApi;

export class TeamEditStore implements TeamEditStoreInterface {
    public team: Team = DEFAULT_TEAM;
    private initialTeam: Team = DEFAULT_TEAM;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return JSON.stringify(this.team) === JSON.stringify(this.initialTeam);
    }

    public setDefaultTeam = (): void => {
        this.team = DEFAULT_TEAM;
        this.initialTeam = DEFAULT_TEAM;
    };

    public changeTeam = (newTeam: Team): void => {
        this.team = newTeam;
    };

    public findTeam = async (teamId: string): Promise<Team> => {
        await getTeam(teamId).then((response: AxiosResponse<Team>) => {
            runInAction(() => {
                this.team = response.data;
                this.initialTeam = response.data;
            });
        });
        return this.team;
    };

    public saveTeam = async (): Promise<Team> => {
        return this.team.id === undefined ? await this.createTeam() : await this.editTeam();
    };

    private createTeam = async (): Promise<Team> => {
        return await postTeam(this.team).then((response: AxiosResponse<Team>) => {
            return runInAction(() => {
                this.team = response.data;
                this.initialTeam = response.data;

                return response.data;
            });
        });
    };

    private editTeam = async (): Promise<Team> => {
        return await putTeam(this.team.id as number, this.team).then((response: AxiosResponse<Team>) => {
            return runInAction(() => {
                this.team = response.data;
                this.initialTeam = response.data;

                return response.data;
            });
        });
    };
}
