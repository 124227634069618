import { Mode } from "../../module/Enum/Mode";
import { Offers } from "../../components/Offers";
import { UserEdit } from "../../components/UserEdit";
import { OfferEdit } from "../../components/OfferEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    offerId: string;
}

export const OffersRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, offerId } = useParams<ParamsProps>();

    const closeOfferEdit = (): void => {
        history.push("/spa/offers");
    };

    switch (mode) {
        case Mode.ADD:
            content = <OfferEdit offerId={offerId} closeOfferEdit={closeOfferEdit} />;
            break;
        case Mode.EDIT:
            content = <OfferEdit offerId={offerId} closeOfferEdit={closeOfferEdit} />;
            break;
        case Mode.VIEW:
            content = <OfferEdit offerId={offerId} closeOfferEdit={closeOfferEdit} />;
            break;
        case Mode.CLONE:
            content = <OfferEdit offerId={offerId} closeOfferEdit={closeOfferEdit} />;
            break;
        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeOfferEdit} />;
            break;
        default:
            content = null;
            break;
    }

    return (
        <>
            <Offers />
            {content}
        </>
    );
};
