import { makeAutoObservable } from "mobx";
import { Landing, NewLanding } from "../entities/LandingEdit";

export interface LandingEditValidationInterface {
    validation: LandingValidation;
    setDefualtValidation(): void;
    checkValidation(landing: NewLanding | Landing): boolean;
    changeValidation(newValidation: LandingValidation): void;
}

export interface LandingValidation {
    title: boolean;
    url: boolean;
}

const DEFAULT_VALIDATION: LandingValidation = {
    title: false,
    url: false,
};

export class LandingEditValidation implements LandingEditValidationInterface {
    public validation: LandingValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: LandingValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (landing: NewLanding | Landing): boolean => {
        const regUrl: RegExp = /^(ftp|http|https):\/\/[^ "]+$/;

        !landing.title ? (this.validation.title = true) : (this.validation.title = false);
        !regUrl.test(landing.url) ? (this.validation.url = true) : (this.validation.url = false);

        return Object.values(this.validation).includes(true);
    };
}
