import { UserName, UserSurname, UserEmail, UserPassword, UserRoles, UserTeam, UserTimezone } from ".";

export const UserEditBody = () => (
    <div className="modal-body modal-overflow">
        <div>
            <UserName />
        </div>
        <div className="mt-4">
            <UserSurname />
        </div>
        <div className="mt-4">
            <UserEmail />
        </div>
        <div className="mt-4">
            <UserPassword />
        </div>
        <div className="mt-4">
            <UserRoles />
        </div>
        <div className="mt-4">
            <UserTeam />
        </div>
        <div className="mt-4">
            <UserTimezone />
        </div>
    </div>
);
