import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { PaymentModel } from "../../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const PaymentModelCost = observer(() => {
    const { paymentModel, changePaymentModel } = useStores().campaignEditStore;
    const [cost, setCost] = useState<string | undefined>(String(paymentModel?.cost));

    const changePaymentModelCost = (event: ChangeEvent<{ value: string }>): void => {
        const newPaymentModel: PaymentModel = JSON.parse(JSON.stringify(paymentModel));
        const reg: RegExp = new RegExp(/^\d*\.?\d*$/);
        const stringArray: string[] = event.target.value.split("");

        if (reg.test(event.target.value)) {
            if (stringArray[0] === "0" && stringArray[1] !== "." && stringArray.length > 1) {
                stringArray.splice(0, 1);
            }
            setCost(stringArray.join(""));
            newPaymentModel.cost = Number(event.target.value);
        }
        if (!event.target.value) setCost(String(newPaymentModel.cost));

        changePaymentModel(newPaymentModel);
    };

    return <TextField fullWidth label="Cost $" value={cost} onChange={action(changePaymentModelCost)} />;
});
