import { observer } from "mobx-react";
import { DomainAddButton, DomainEditButton, DomainDeleteButton } from ".";
import "../../../style/actionButtons.css";

export const ActionButtons = observer(() => (
    <div className="d-flex ml-4 pt-3">
        <DomainAddButton />
        <DomainEditButton />
        <DomainDeleteButton />
    </div>
));
