import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { CampaignSearchCriteria, CampaignSearchItem } from "../../entities/CampaignSearch";
import { Autocomplete, TextField } from "@mui/material";

interface Props {
    selectedСampaigns: CampaignSearchItem[];
    onChangeCampaignIds(event: object, value: (string | CampaignSearchItem)[]): void;
}

export const CampaignSearchInput = observer(({ selectedСampaigns, onChangeCampaignIds }: Props) => {
    const { campaignSearchData, setDefaultData, searchCampaign } = useStores().campaignSearchStore;
    const [criteria, setCriteria] = useState<CampaignSearchCriteria>({
        query: "",
    });

    useEffect(() => {
        if (criteria.query.length > 2) searchCampaign(criteria);
    }, [criteria, searchCampaign]);

    useEffect(() => {
        return setDefaultData;
    }, [setDefaultData]);

    const changeCriteria = (event: object, value: string) => {
        setCriteria({
            query: value,
        });
    };

    return (
        <Autocomplete
            loading
            multiple
            fullWidth
            size="small"
            limitTags={1}
            disableCloseOnSelect
            loadingText="Загрузка..."
            value={selectedСampaigns}
            options={campaignSearchData}
            onChange={onChangeCampaignIds}
            onInputChange={changeCriteria}
            getOptionLabel={(option) => option.title}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            renderInput={(params) => (
                <TextField
                    {...params}
                    type="search"
                    size="medium"
                    variant="standard"
                    label="Поиск кампаний..."
                    InputLabelProps={{ shrink: true }}
                    placeholder={selectedСampaigns.length === 0 ? "Введите минимум 3 символа" : undefined}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.id}. {option.title}
                </li>
            )}
        />
    );
});
