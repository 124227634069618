export enum LimitType {
    SCHEDULED_HOUR = "scheduledHour",
    DAY = "day",
    PERCENT = "percent",
}

export enum PaymentModelType {
    NONE = "none",
    CPA = "cpa",
    REV_SHARE = "revShare",
}

export enum DestinationType {
    URL = "url",
    OFFER = "offer",
}

export enum RoutingConditionExpression {
    EQUAL = "equal",
    NOT_EQUAL = "not_equal",
}

export enum RoutingConditionType {
    GEO = "geo",
    DEVICES = "devices",
    IP = "ip",
    OPERATION_SYSTEM = "operationSystems",
    REFERRER = "referrer",
    USER_AGEN = "userAgent",
    PARAMETER = "parameter",
}

export enum Devices {
    COMPUTER = "Computer",
    TABLET = "Tablet",
    MOBILE = "Mobile",
}

export enum OperationSystems {
    IOS = "iOS",
    ANDROID = "Android",
    WINDOWS = "Windows",
    MAX_OS = "MacOS",
    LINUX = "Linux",
    CHROME_OS = "ChromeOS",
    WINDOWS_PHONE = "WindowsPhone",
    WINDOWS_MOBILE = "WindowsMobile",
}

interface ConvertType {
    [key: string]: string;
}

export const convertPaymentModelType: ConvertType = {
    none: "Не отслеживать",
    cpa: "CPA",
    revShare: "RevShare",
};

export const convertDestinationType: ConvertType = {
    url: "Url",
    offer: "Оффер",
};

export const convertDevicesType: ConvertType = {
    Computer: "Компьютер/ноутбук",
    Tablet: "Планшет",
    Mobile: "Мобильный телефон",
};

export const convertConditionType: ConvertType = {
    geo: "Гео",
    devices: "Устройства",
    ip: "IP-адреса",
    operationSystems: "Операционная система",
    referrer: "Реферрер",
    userAgent: "User-Agent",
    parameter: "Параметр",
};
