import { useFormCriteria, FormCriteria } from "./CampaignsForm";
import { Checkbox, FormControlLabel } from "@mui/material";

export const CampaignsWithClick = () => {
    const { formCriteria, setFormCriteria } = useFormCriteria();

    const changeCampaignsWithClick = (): void => {
        const newCriteria: FormCriteria = { ...formCriteria };

        newCriteria.onlyWithClick = newCriteria.onlyWithClick === 1 ? 0 : 1;

        setFormCriteria(newCriteria);
    };

    return (
        <FormControlLabel
            className="m-0"
            label="С кликами"
            control={
                <Checkbox
                    checked={formCriteria.onlyWithClick === 1 ? true : false}
                    onChange={changeCampaignsWithClick}
                />
            }
        />
    );
};
