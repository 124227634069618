import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSelectedUsers } from "../Users";
import { User } from "../../../entities/Users";
import { Checkbox } from "@mui/material";

export const TableHead = observer(() => {
    const { usersData } = useStores().usersStore;
    const { selectedUsers, setSelectedUsers } = useSelectedUsers();

    const handleChangeAllUsers = (): void => {
        const usersCopy: User[] = JSON.parse(JSON.stringify(selectedUsers));
        const booleanArray: boolean[] = [];

        usersData.items.forEach((item: User) => {
            booleanArray.push(usersCopy.map((user: User) => user.id).includes(item.id));
            if (!usersCopy.map((user: User) => user.id).includes(item.id)) usersCopy.push(item);
        });
        !booleanArray.includes(false) ? setSelectedUsers([]) : setSelectedUsers(usersCopy);
    };

    return (
        <thead>
            <tr>
                <th className="table__th-width pb-1" onClick={handleChangeAllUsers}>
                    <Checkbox
                        size="small"
                        color="primary"
                        checked={usersData.items.length !== 0 && selectedUsers.length === usersData.items.length}
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </th>
                <th className="table__th-width">#</th>
                <th>Имя</th>
                <th>Почта</th>
                <th>Команда</th>
                <th>Роли</th>
            </tr>
        </thead>
    );
});
