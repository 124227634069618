import { useDomainsIds } from "../Domains";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const DomainEditButton = () => {
    const { url } = useRouteMatch();
    const { domainsIds } = useDomainsIds();
    const [id] = domainsIds;

    return (
        <div className="mr-2">
            <Link to={`${url}/edit/${id}`} className={domainsIds.length !== 1 ? "link_disabled" : "link"}>
                <Button size="small" variant="contained" startIcon={<EditIcon />} disabled={domainsIds.length !== 1}>
                    Изменить
                </Button>
            </Link>
        </div>
    );
};
