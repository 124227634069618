import { useContext, createContext } from "react";
import { useValidation, ValidationHook } from "./useValidation.hook";
import { Email, ReturnButton, SendButton } from ".";
import "../../../style/authorization.css";

const ValidationContext = createContext<ValidationHook>({} as ValidationHook);

export const InitializePasswordReset = () => (
    <ValidationContext.Provider value={useValidation()}>
        <div className="authorization">
            <div className="authorization__title">
                <h4>Восстановление пароля</h4>
            </div>
            <div className="mt-4">
                <Email />
            </div>
            <div className="authorization__footer mt-4">
                <ReturnButton />
                <SendButton />
            </div>
        </div>
    </ValidationContext.Provider>
);

export const usePasswordResetValidation = (): ValidationHook => {
    return useContext(ValidationContext);
};
