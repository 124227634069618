import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useLocation, Link } from "react-router-dom";

export const CurrentUserDropdown = observer(() => {
    const location = useLocation();
    const { logout } = useStores().authStore;
    const {
        cleanUpCurrentUser,
        currentUser: { fullName },
    } = useStores().currentUserStore;

    const exit = (): void => {
        logout().then((response) => {
            cleanUpCurrentUser();
        });
    };

    return (
        <div className="nav-link">
            <p className="dropdown-toggle">
                {fullName.firstName} {fullName.lastName}
            </p>

            <div className="dropdown">
                <ul>
                    <li className="dropdown-link">
                        <Link to={`${location.pathname}/current`}>Изменить профиль</Link>
                    </li>
                    <li className="dropdown-link" onClick={action(exit)}>
                        <div>Выйти</div>
                    </li>
                </ul>
            </div>
        </div>
    );
});
