import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from "..";
import { useUrlParamsState } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../stores";
import { Mode } from "../../../../../module/Enum/Mode";
import { Source, SourcePostback, SourcePostbackUrlParameter } from "../../../../../entities/SourceEdit";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface Props {
    parameterIndex: number;
}

interface ParamsProps {
    mode: Mode;
}

export const ParameterDeleteButton = observer(({ parameterIndex }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { urlParams, setUrlParams } = useUrlParamsState();
    const { source, changeSource } = useStores().sourceEditStore;
    const [url] = postback.urlPattern.split("?");

    const deleteParameter = (): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));
        const newUrlParams: SourcePostbackUrlParameter[] = JSON.parse(JSON.stringify(urlParams));

        newUrlParams.splice(parameterIndex, 1);
        newPostback.urlPattern = newUrlParams.length
            ? [url, newUrlParams.map((param) => [param.parameter, param.value].join("=")).join("&")].join("?")
            : url;
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setUrlParams(newUrlParams);
    };

    return (
        <div>
            <Button color="error" disabled={mode === Mode.VIEW} onClick={action(deleteParameter)}>
                <DeleteForeverOutlinedIcon />
            </Button>
        </div>
    );
});
