import {
    ScheduleTitle,
    ScheduleHours,
    ScheduleDescription,
    ScheduleSelectAllButton,
    ScheduleDeleteAllButton,
} from "./";

export const PostbackSchedule = () => (
    <div className="shedule mt-4">
        <ScheduleTitle />
        <div className="mt-2">
            <ScheduleSelectAllButton />
            <ScheduleDeleteAllButton />
        </div>
        <ScheduleHours />
        <ScheduleDescription />
    </div>
);
