import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { PaymentModel } from "../../../../../entities/CampaignEdit";
import { TextField } from "@mui/material";

export const PaymentModelCostPercentage = observer(() => {
    const { paymentModel, changePaymentModel } = useStores().campaignEditStore;
    const [costPercentage, setCostPercentage] = useState<string | undefined>(String(paymentModel?.costPercentage));

    const changePaymentModelCostPercentage = (event: ChangeEvent<{ value: string }>): void => {
        const newPaymentModel: PaymentModel = JSON.parse(JSON.stringify(paymentModel));
        const reg: RegExp = new RegExp(/^\d*\.?\d*$/);
        const stringArray: string[] = event.target.value.split("");

        if (reg.test(event.target.value)) {
            if (stringArray[0] === "0" && stringArray[1] !== "." && stringArray.length > 1) {
                stringArray.splice(0, 1);
            }
            setCostPercentage(stringArray.join(""));
            newPaymentModel.costPercentage = Number(event.target.value);
        }
        if (!event.target.value) setCostPercentage(String(newPaymentModel.costPercentage));

        changePaymentModel(newPaymentModel);
    };

    return (
        <TextField
            fullWidth
            label="Cost %"
            value={costPercentage}
            onChange={action(changePaymentModelCostPercentage)}
        />
    );
});
