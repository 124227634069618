import { CampaignEditSaveButton, CampaignEditSaveAndCloseButton } from "./";

interface Props {
    closeCampaignEdit(): void;
}

export const CampaignEditFooter = ({ closeCampaignEdit }: Props) => (
    <div className="modal-footer">
        <CampaignEditSaveButton />
        <CampaignEditSaveAndCloseButton closeCampaignEdit={closeCampaignEdit} />
    </div>
);
