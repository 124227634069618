import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { PaymentModelCost, PaymentModelCostPercentage } from "./";
import { PaymentModelType, convertPaymentModelType } from "../../../../../module/Enum/CampaignEdit/CampaignEdit";

export const PaymentModelValue = observer(() => {
    const { paymentModel } = useStores().campaignEditStore;

    const createField = (): any => {
        switch (paymentModel.type) {
            case PaymentModelType.CPA:
                return <PaymentModelCost />;
            case PaymentModelType.REV_SHARE:
                return <PaymentModelCostPercentage />;
        }
    };

    return (
        <div>
            <h5>{convertPaymentModelType[paymentModel.type]}</h5>
            <div className="mt-3 mr-5">{createField()}</div>
        </div>
    );
});
