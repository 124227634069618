import { LandingEditTitle, LandingEditCloseButton } from ".";

interface Props {
    closeLandingEdit(): void;
}

export const LandingEditHeader = ({ closeLandingEdit }: Props) => (
    <div className="modal-header">
        <LandingEditTitle />
        <LandingEditCloseButton closeLandingEdit={closeLandingEdit} />
    </div>
);
