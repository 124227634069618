import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { PasswordResetData } from "../../entities/Auth";
import { usePasswordResetValidation } from "./PasswordReset";
import { errorText, AuthValidation } from "./useValidation.hook";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField, InputAdornment, IconButton } from "@mui/material";

export const Password = observer(() => {
    const [showPass, setShowPass] = useState<boolean>(false);
    const { validation, changeValidation } = usePasswordResetValidation();
    const { passwordResetData, changePasswordResetData } = useStores().authStore;

    const handleChange = (): void => setShowPass(!showPass);

    const changePassword = (event: ChangeEvent<HTMLInputElement>): void => {
        const dataCopy: PasswordResetData = JSON.parse(JSON.stringify(passwordResetData));

        dataCopy.password = event.target.value.replace(/\s/g, "");

        setPasswordValidation();
        changePasswordResetData(dataCopy);
    };

    const setPasswordValidation = (): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));
        newValidation.password = false;
        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <TextField
                fullWidth
                placeholder="Пароль"
                error={validation.password}
                value={passwordResetData.password}
                type={showPass ? "text" : "password"}
                helperText={validation.password && errorText.password}
                onChange={action(changePassword)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleChange}>
                                {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        padding: 0,
                    },
                }}
            />
        </div>
    );
});
