import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from "..";
import { useUrlParamsState } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../../stores";
import { Mode } from "../../../../../module/Enum/Mode";
import { PostbackUrlParameterType } from "../../../../../module/Enum/SourceEdit/SourceEdit";
import { Source, SourcePostback, SourcePostbackUrlParameter } from "../../../../../entities/SourceEdit";
import { TextField } from "@mui/material";

interface Props {
    parameterIndex: number;
    parameter: SourcePostbackUrlParameter;
}

interface ParamsProps {
    mode: Mode;
}

export const ParameterValueField = observer(({ parameter, parameterIndex }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { urlParams, setUrlParams } = useUrlParamsState();
    const { source, changeSource } = useStores().sourceEditStore;
    const [url] = postback.urlPattern.split("?");

    const changeUrlValue = (event: ChangeEvent<{ value: string }>): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));
        const newUrlParams: SourcePostbackUrlParameter[] = JSON.parse(JSON.stringify(urlParams));
        const newUrlParam: SourcePostbackUrlParameter = {
            ...parameter,
            value: event.target.value,
        };

        newUrlParams.splice(parameterIndex, 1, newUrlParam);
        newPostback.urlPattern = [
            url,
            newUrlParams.map((param) => [param.parameter, param.value].join("=")).join("&"),
        ].join("?");
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setUrlParams(newUrlParams);
    };

    return (
        <div className="container-fluid">
            <TextField
                fullWidth
                label="Значение"
                variant="outlined"
                value={!parameter.value ? "" : parameter.value}
                disabled={
                    parameter.type === PostbackUrlParameterType.ID_CLICK_TRACK ||
                    parameter.type === PostbackUrlParameterType.CURRENT_TIME
                }
                onChange={action(changeUrlValue)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
