import { format } from "date-fns";
import { AxiosResponse } from "axios";
import { ReportApi } from "../services/api/ReportApi";
import { makeAutoObservable, runInAction } from "mobx";
import { ReportId, ReportData, ReportRequest } from "../entities/Report";
import { TypeGrouping, DefaultGrouping } from "../module/Enum/Grouping/Grouping";

export interface ReportStoreInterface {
    reportRequest: ReportRequest;
    setDefaultReportRequest(): void;
    findReportId(): Promise<ReportId>;
    changeReportRequest(newRequest: ReportRequest): void;
    findReport(id: string): Promise<AxiosResponse<ReportData>>;
}

const { getReport, postReport } = ReportApi;

const DEFAULT_REPORT_REQUEST: ReportRequest = {
    campaignIds: [],
    period: {
        from: format(new Date(), "yyyy-MM-dd"),
        to: format(new Date(), "yyyy-MM-dd"),
    },
    deltaPeriod: {
        from: format(new Date(), "yyyy-MM-dd"),
        to: format(new Date(), "yyyy-MM-dd"),
    },
    groupBy: [
        {
            type: TypeGrouping.DEFAULT,
            field: DefaultGrouping.DATE,
        },
    ],
    sort: [],
    filter: [],
};

export class ReportStore implements ReportStoreInterface {
    public reportRequest: ReportRequest = DEFAULT_REPORT_REQUEST;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefaultReportRequest = (): void => {
        this.reportRequest = DEFAULT_REPORT_REQUEST;
    };

    public changeReportRequest = (newRequest: ReportRequest): void => {
        this.reportRequest = newRequest;
    };

    public findReport = async (id: string): Promise<AxiosResponse<ReportData>> => {
        return await getReport(id).then((response: AxiosResponse<ReportData>) => {
            return runInAction(() => {
                return response;
            });
        });
    };

    public findReportId = async (): Promise<ReportId> => {
        return await postReport(this.reportRequest).then((response: AxiosResponse<ReportId>) => {
            return response.data;
        });
    };
}
