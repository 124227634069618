import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography, AccordionDetails, AccordionSummary } from "@mui/material";

export const Info = () => (
    <Accordion className="postback-info mt-4">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className="d-flex postback-info__title">
                <HelpIcon className="mr-2" />
                Справка по настройке параметров
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Typography>
                Чтобы добавить параметр, необходимо добавить Url <br />
                <strong>Url генерируется при изменении полей в блоке "Параметры" </strong>
                <br />
                <strong>Типы параметров:</strong>
                <br />
                Value — значение используется как есть, без какой-либо модификации <br />
                Get parameter — значение для исходящего постбэка будет взято из GET параметра входящего постбэка <br />
                Source params forwarding — значение будет взято из запроса на переадресацию, в качестве параметра
                указывается строка из параметра источника <br />
                Id click/track — передается track id <br />
                Current time — текущее время
            </Typography>
        </AccordionDetails>
    </Accordion>
);
