import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { LandingsApi } from "../services/api/LandingsApi";
import { Landing, NewLanding, LandingRequest } from "../entities/LandingEdit";

export interface LandingEditStoreInterface {
    isChangedData: boolean;
    landingIsFound: boolean;
    landing: NewLanding | Landing;
    cleanUpLendingEdit(): void;
    saveLanding(): Promise<Landing>;
    changeLanding(newLanding: NewLanding | Landing): void;
    findLanding(landingId: string | number): Promise<Landing>;
}

const DEFAULT_LANDING: NewLanding = {
    title: "",
    url: "",
    allowedUserIds: [],
};

const { getLanding, postLanding, putLanding } = LandingsApi;

export class LandingEditStore implements LandingEditStoreInterface {
    public landingIsFound: boolean = false;
    public landing: NewLanding | Landing = DEFAULT_LANDING;
    private initialLanding: NewLanding | Landing = DEFAULT_LANDING;

    constructor() {
        makeAutoObservable(this);
    }

    get isChangedData(): boolean {
        return JSON.stringify(this.landing) === JSON.stringify(this.initialLanding);
    }

    public changeLanding = (newLanding: NewLanding | Landing): void => {
        this.landing = newLanding;
    };

    public cleanUpLendingEdit = (): void => {
        this.landingIsFound = false;
        this.landing = DEFAULT_LANDING;
        this.initialLanding = DEFAULT_LANDING;
    };

    public findLanding = async (landingId: string | number): Promise<Landing> => {
        await getLanding(landingId).then((response: AxiosResponse<Landing>) => {
            runInAction(() => {
                this.landing = response.data;
                this.initialLanding = response.data;
                this.landingIsFound = true;
            });
        });
        return this.landing as Landing;
    };

    public saveLanding = async (): Promise<Landing> => {
        return this.landing.id === undefined ? this.createLanding() : this.editLanding(this.landing.id);
    };

    private createLanding = async (): Promise<Landing> => {
        return await postLanding(this.createLandingRequest()).then((response: AxiosResponse<Landing>) => {
            return runInAction(() => {
                this.landing = response.data;
                this.initialLanding = response.data;

                return response.data;
            });
        });
    };

    private editLanding = async (landingId: number): Promise<Landing> => {
        return await putLanding(landingId, this.createLandingRequest()).then((response: AxiosResponse<Landing>) => {
            return runInAction(() => {
                this.landing = response.data;
                this.initialLanding = response.data;

                return response.data;
            });
        });
    };

    private createLandingRequest = (): LandingRequest => {
        const landingRequest: LandingRequest = {
            title: this.landing.title,
            url: this.landing.url,
            allowedUserIds: this.landing.allowedUserIds,
        };

        return landingRequest;
    };
}
