import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../../../stores";
import { Button } from "@mui/material";

interface Props {
    closeSaveModal(): void;
}

export const SaveButton = observer(({ closeSaveModal }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { filter, createFilter } = useStores().filtersStore;

    const saveFilter = (): void => {
        createFilter().then((response) => {
            if (response) {
                closeSaveModal();
                enqueueSnackbar("Фильтр сохранен", { variant: "success", autoHideDuration: 1500 });
            }
        });
    };

    return (
        <Button variant="contained" disabled={!filter.title.length} onClick={action(saveFilter)}>
            Сохранить
        </Button>
    );
});
