import { AxiosResponse } from "axios";
import { Domain } from "../../entities/DomainEdit";
import { DomainsData } from "../../entities/Domains";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";

interface DomainsApiInterface {
    getDomains(): Promise<AxiosResponse<DomainsData>>;
    getDomain(domainId: string): Promise<AxiosResponse<Domain>>;
    deleteDomain(domainId: number): Promise<AxiosResponse<null>>;
    postDomain(newDomain: Domain): Promise<AxiosResponse<Domain>>;
    putDomain(domainId: number, newDomain: Domain): Promise<AxiosResponse<Domain>>;
}

export const DomainsApi: DomainsApiInterface = {
    getDomains: async (): Promise<AxiosResponse<DomainsData>> => await httpClient.get<DomainsData>(`/api/v1/domain`),

    getDomain: async (domainId: string): Promise<AxiosResponse<Domain>> =>
        await httpClient.get<Domain>(`/api/v1/domain/${domainId}`),

    postDomain: async (newDomain: Domain): Promise<AxiosResponse<Domain>> =>
        await httpClient.post<Domain>(`/api/v1/domain`, newDomain),

    deleteDomain: async (domainId: number): Promise<AxiosResponse<null>> =>
        await httpClient.delete<null>(`/api/v1/domain/${domainId}`),

    putDomain: async (domainId: number, newDomain: Domain): Promise<AxiosResponse<Domain>> =>
        await httpClient.put<Domain>(`/api/v1/domain/${domainId}`, newDomain),
};
