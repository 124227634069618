import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { WarningModalOnUnsavedData } from "../../WarningModalOnUnsavedData";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    closeTeamEdit(): void;
}

export const TeamEditCloseButton = observer(({ closeTeamEdit }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const { isChangedData } = useStores().teamEditStore;

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    return (
        <div>
            <IconButton onClick={!isChangedData ? openWarningModal : closeTeamEdit}>
                <CloseIcon />
            </IconButton>

            {open && <WarningModalOnUnsavedData closeModalEdit={closeTeamEdit} closeWarningModal={closeWarningModal} />}
        </div>
    );
});
