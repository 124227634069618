import { DomainTitle, DomainIsDefault } from ".";

export const DomainEditBody = () => (
    <div className="modal-body modal-overflow">
        <div className="mt-4">
            <DomainTitle />
        </div>
        <div className="mt-4">
            <DomainIsDefault />
        </div>
    </div>
);
