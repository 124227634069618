import { Mode } from "../../module/Enum/Mode";
import { UserEdit } from "../../components/UserEdit";
import { Statistics } from "../../components/Statistics";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
}

export const StatisticsRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode } = useParams<ParamsProps>();

    const closeOfferEdit = (): void => {
        history.push("/spa/statistics");
    };

    switch (mode) {
        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeOfferEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Statistics />
            {content}
        </>
    );
};
