import { Mode } from "../../module/Enum/Mode";
import { UserEdit } from "../../components/UserEdit";
import { Campaigns } from "../../components/Campaigns";
import { CampaignEdit } from "../../components/CampaignEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    campaignId: string;
}

export const CampaignsRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, campaignId } = useParams<ParamsProps>();

    const closeCampaignEdit = (): void => {
        history.push("/spa/campaigns");
    };

    switch (mode) {
        case Mode.ADD:
            content = <CampaignEdit mode={mode} closeCampaignEdit={closeCampaignEdit} />;
            break;

        case Mode.EDIT:
            content = <CampaignEdit mode={mode} campaignId={campaignId} closeCampaignEdit={closeCampaignEdit} />;
            break;

        case Mode.CLONE:
            content = <CampaignEdit mode={mode} campaignId={campaignId} closeCampaignEdit={closeCampaignEdit} />;
            break;

        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeCampaignEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Campaigns />
            {content}
        </>
    );
};
