import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReportRequest } from "../../../../entities/Report";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface Props {
    sortIndex: number;
}

export const SortDeleteButton = observer(({ sortIndex }: Props) => {
    const { reportRequest, changeReportRequest } = useStores().reportStore;

    const deleteSort = (): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));

        newReportRequest.sort.splice(sortIndex, 1);

        changeReportRequest(newReportRequest);
    };

    return (
        <Button color="error" onClick={action(deleteSort)}>
            <DeleteForeverOutlinedIcon />
        </Button>
    );
});
