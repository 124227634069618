import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { CampaignsDate, CampaignSearch, CampaignsWithClick, CampaignsFilterButton } from "./";

export interface FormCriteria {
    dateFrom: Date;
    dateTo: Date;
    onlyWithClick: number;
}

interface FormCriteriaContextInterface {
    formCriteria: FormCriteria;
    setFormCriteria: Dispatch<SetStateAction<FormCriteria>>;
}

export const CampaignsForm = observer(() => {
    const { criteria } = useStores().campaignsStore;
    const [formCriteria, setFormCriteria] = useState<FormCriteria>({
        dateFrom: new Date(),
        dateTo: new Date(),
        onlyWithClick: 0,
    });

    useEffect(() => {
        const newFormCriteria: FormCriteria = {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            onlyWithClick: JSON.parse(JSON.stringify(criteria.onlyWithClick)),
        };

        setFormCriteria(newFormCriteria);
    }, [criteria.dateFrom, criteria.dateTo, criteria.onlyWithClick]);

    return (
        <FormCriteriaContext.Provider value={{ formCriteria, setFormCriteria }}>
            <form className="pt-4 pb-4">
                <div className="d-flex align-items-center">
                    <div className="ml-4">
                        <CampaignsDate />
                    </div>

                    <div className="w-25 ml-4">
                        <CampaignSearch />
                    </div>

                    <div className="ml-4">
                        <CampaignsWithClick />
                    </div>
                </div>

                <div className="ml-4 mt-4">
                    <CampaignsFilterButton />
                </div>
            </form>
        </FormCriteriaContext.Provider>
    );
});

const FormCriteriaContext = createContext<FormCriteriaContextInterface>({} as FormCriteriaContextInterface);

export const useFormCriteria = (): FormCriteriaContextInterface => {
    return useContext(FormCriteriaContext);
};
