import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { usePostback } from ".";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";
import { useValidations } from "../../../../validation";
import { Source, SourcePostback } from "../../../../entities/SourceEdit";
import { SourceValidation } from "../../../../validation/SourceEditValidation";
import { TextField } from "@mui/material";

interface ParamsProps {
    mode: Mode;
}

export const PostbackGoalName = observer(() => {
    const { mode } = useParams<ParamsProps>();
    const { postback, postbackIndex } = usePostback();
    const { source, changeSource } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().sourceEditValidation;
    const errorText: string = "Поле не может быть пустым";
    const fieldName: string = `postbackGoalName${postbackIndex}`;

    const changePostbackGoalName = (event: ChangeEvent<{ value: string; name: string }>): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));
        const newPostback: SourcePostback = JSON.parse(JSON.stringify(postback));

        newPostback.goalName = event.target.value;
        newSource.postbacks.splice(postbackIndex, 1, newPostback);

        changeSource(newSource);
        setPostbackGoalNameValidation(event.target.name);
    };

    const setPostbackGoalNameValidation = (name: string): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.postbacks[name] = false;
        changeValidation(newValidation);
    };

    return (
        <div className="container-fluid pl-0">
            <TextField
                fullWidth
                label="Имя цели"
                variant="outlined"
                autoComplete="off"
                name={fieldName}
                value={postback.goalName}
                error={validation.postbacks[fieldName]}
                helperText={validation.postbacks[fieldName] && errorText}
                onChange={action(changePostbackGoalName)}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: mode === Mode.VIEW }}
            />
        </div>
    );
});
