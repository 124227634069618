import { makeAutoObservable } from "mobx";
import { Domain } from "../entities/DomainEdit";

export interface DomainEditValidationInterface {
    validation: DomainValidation;
    setDefualtValidation(): void;
    checkValidation(user: Domain): boolean;
    changeValidation(newValidation: DomainValidation): void;
}

export interface DomainValidation {
    domain: boolean;
}

const DEFAULT_VALIDATION: DomainValidation = {
    domain: false,
};

export class DomainEditValidation implements DomainEditValidationInterface {
    public validation: DomainValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: DomainValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (domain: Domain): boolean => {
        !domain.domain ? (this.validation.domain = true) : (this.validation.domain = false);

        return Object.values(this.validation).includes(true);
    };
}
