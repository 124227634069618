import { useState, ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSingInValidation } from "./SignIn";
import { AuthData } from "../../../entities/Auth";
import { errorText, AuthValidation } from "./useValidation.hook";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { TextField, InputAdornment, IconButton } from "@mui/material";

export const Password = observer(() => {
    const [showPass, setShowPass] = useState<boolean>(false);
    const { authData, changeAuthData } = useStores().authStore;
    const { validation, changeValidation } = useSingInValidation();

    const handleChange = (): void => setShowPass(!showPass);

    const changePassword = (event: ChangeEvent<HTMLInputElement>): void => {
        const dataCopy: AuthData = JSON.parse(JSON.stringify(authData));

        dataCopy.password = event.target.value.replace(/\s/g, "");

        changeAuthData(dataCopy);
        setPasswordValidation();
    };

    const setPasswordValidation = (): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));
        newValidation.password = false;
        newValidation.wrongData = false;
        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <TextField
                fullWidth
                placeholder="Пароль"
                value={authData.password}
                type={showPass ? "text" : "password"}
                error={validation.password || validation.wrongData}
                helperText={validation.password && errorText.password}
                onChange={action(changePassword)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleChange}>
                                {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                    style: {
                        padding: 0,
                    },
                }}
            />
        </div>
    );
});
