import { action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { usePostback, useIndicesState } from ".";
import { Mode } from "../../../../module/Enum/Mode";
import { useValidations } from "../../../../validation";
import { Source } from "../../../../entities/SourceEdit";
import { SourceValidation } from "../../../../validation/SourceEditValidation";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface ParamsProps {
    mode: Mode;
}

export const PostbackDeleteButton = observer(() => {
    const { postbackIndex } = usePostback();
    const { mode } = useParams<ParamsProps>();
    const { source, changeSource } = useStores().sourceEditStore;
    const { postbackIndices, setPostbackIndices } = useIndicesState();
    const { validation, changeValidation } = useValidations().sourceEditValidation;

    const deletePostback = (): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));

        newSource.postbacks.splice(postbackIndex, 1);

        deleteIndex();
        changeSource(newSource);
        deletePostbacksValidation();
    };

    const deleteIndex = (): void => {
        const copyPostbackIndices: number[] = JSON.parse(JSON.stringify(postbackIndices));
        const index: number = copyPostbackIndices.indexOf(postbackIndex);

        if (index > -1) copyPostbackIndices.splice(index, 1);

        setPostbackIndices(copyPostbackIndices);
    };

    const deletePostbacksValidation = (): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.postbacks = {};
        changeValidation(newValidation);
    };

    return (
        <div>
            <Button color="error" disabled={mode === Mode.VIEW} onClick={action(deletePostback)}>
                <DeleteForeverOutlinedIcon />
            </Button>
        </div>
    );
});
