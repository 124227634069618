import { action } from "mobx";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import { useMode } from "../UserEdit";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useHistory } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { UserValidation } from "../../../validation/UserEditValidation";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export const UserEditSaveButton = observer(() => {
    const history = useHistory();
    const { mode } = useMode();
    const { enqueueSnackbar } = useSnackbar();
    const { findUsers } = useStores().usersStore;
    const { user, saveUser } = useStores().userEditStore;
    const { editCurrentUser } = useStores().currentUserStore;
    const { validation, changeValidation, checkValidation } = useValidations().userEditValidation;

    const changePath = (id: number | undefined): void => {
        id && history.push(`/spa/users/edit/${id}`);
    };

    const setEmailValidation = (): void => {
        const newValidation: UserValidation = JSON.parse(JSON.stringify(validation));
        newValidation.doubleEmail = true;
        changeValidation(newValidation);
    };

    const save = (): void => {
        if (checkValidation(user)) {
            enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 });
        } else {
            mode === Mode.CURRENT
                ? editCurrentUser(user)
                      .then((response) => {
                          if (response)
                              enqueueSnackbar("Пользователь сохранен", { variant: "success", autoHideDuration: 1500 });
                      })
                      .catch((error: AxiosError) => {
                          if (error.response?.status === 409) {
                              setEmailValidation();
                              enqueueSnackbar("Этот email уже используется", {
                                  variant: "error",
                                  autoHideDuration: 1500,
                              });
                          } else {
                              enqueueSnackbar("Неудача", { variant: "error", autoHideDuration: 1500 });
                          }
                      })
                : saveUser()
                      .then((response) => {
                          if (response) {
                              changePath(response.id);
                              if (mode === Mode.ADD) findUsers();
                              enqueueSnackbar("Пользователь сохранен", { variant: "success", autoHideDuration: 1500 });
                          }
                      })
                      .catch((error: AxiosError) => {
                          if (error.response?.status === 409) {
                              setEmailValidation();
                              enqueueSnackbar("Этот email уже используется", {
                                  variant: "error",
                                  autoHideDuration: 1500,
                              });
                          } else {
                              enqueueSnackbar("Неудача", { variant: "error", autoHideDuration: 1500 });
                          }
                      });
        }
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(save)}>
            Сохранить
        </Button>
    );
});
