import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { observer } from "mobx-react";
import { SourcesPagination } from "./";
import { useStores } from "../../stores";
import { SourcesForm } from "./SourcesForm";
import { SourcesTable } from "./SourcesTable";
import { ActionButtons } from "./ActionButtons";
import { SourceItem } from "../../entities/Sources";

interface SelectedSourcesContextInterface {
    selectedSources: SourceItem[];
    setSelectedSources: Dispatch<SetStateAction<SourceItem[]>>;
}

export const Sources = observer(() => {
    const [selectedSources, setSelectedSources] = useState<SourceItem[]>([]);
    const { criteria, findSources, cleanUpSources } = useStores().sourcesStore;

    useEffect(() => {
        findSources();
    }, [criteria, findSources]);

    useEffect(() => {
        setSelectedSources([]);
    }, [criteria]);

    useEffect(() => {
        return cleanUpSources;
    }, [cleanUpSources]);

    return (
        <SelectedSourcesContext.Provider value={{ selectedSources, setSelectedSources }}>
            <div className="container-fluid">
                <div className="ml-4">
                    <h1>Источники</h1>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <SourcesForm />
                    <ActionButtons />
                </div>
                <SourcesTable />
                <SourcesPagination />
            </div>
        </SelectedSourcesContext.Provider>
    );
});

const SelectedSourcesContext = createContext<SelectedSourcesContextInterface>({} as SelectedSourcesContextInterface);

export const useSelectedSources = (): SelectedSourcesContextInterface => {
    return useContext(SelectedSourcesContext);
};
