import { AxiosResponse } from "axios";
import { DomainsData } from "../entities/Domains";
import { makeAutoObservable, runInAction } from "mobx";
import { DomainsApi } from "../services/api/DomainsApi";

export interface DomainsStroreInterface {
    domainsData: DomainsData;
    findDomains(): Promise<DomainsData>;
    removeDomain(domainId: number): Promise<AxiosResponse<null>>;
}

export interface Domain {
    id: number;
    domain: string;
    isDefault: boolean;
}

const { getDomains, deleteDomain } = DomainsApi;

export class DomainsStore implements DomainsStroreInterface {
    public domainsData: DomainsData = {
        items: [],
    };

    constructor() {
        makeAutoObservable(this);
    }

    public findDomains = async (): Promise<DomainsData> => {
        await getDomains().then((response: AxiosResponse<DomainsData>) => {
            runInAction(() => {
                this.domainsData = response.data;
            });
        });
        return this.domainsData;
    };

    public removeDomain = async (domainId: number): Promise<AxiosResponse<null>> => {
        return await deleteDomain(domainId).then((response: AxiosResponse<null>) => {
            return runInAction(() => {
                this.findDomains();
                return response;
            });
        });
    };
}
