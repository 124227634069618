import { observer } from "mobx-react";
import { TeamAddButton, TeamEditButton, TeamDeleteButton } from ".";
import "../../../style/actionButtons.css";

export const ActionButtons = observer(() => (
    <div className="d-flex ml-4 pt-3">
        <TeamAddButton />
        <TeamEditButton />
        <TeamDeleteButton />
    </div>
));
