import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { Campaign } from "../../../../../entities/CampaignEdit";
import { Button } from "@mui/material";

const hoursArray: number[] = new Array(24).fill(1).map((number: number, index: number) => index);

export const ScheduleSelectAllButton = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const selectAllHours = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        newCampaign.postbackSettings.schedule = hoursArray;

        changeCampaign(newCampaign);
    };

    return (
        <Button variant="text" onClick={action(selectAllHours)}>
            Выбрать все
        </Button>
    );
});
