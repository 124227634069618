import { useRouteMatch, Link } from "react-router-dom";
import { Fab, Tooltip } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

interface Props {
    campaignId: number;
}

export const CampaignCloneButton = ({ campaignId }: Props) => {
    const { url } = useRouteMatch();

    return (
        <div className="mr-2">
            <Link to={`${url}/clone/${campaignId}`} className="link">
                <Tooltip title="Клонировать" placement="bottom">
                    <Fab size="small" color="secondary">
                        <FileCopyIcon fontSize="small" />
                    </Fab>
                </Tooltip>
            </Link>
        </div>
    );
};
