export const ScheduleDescription = () => (
    <div className="mt-2">
        <div className="d-flex m-0">
            [<div id="square-green"></div>] - Активные часы для отправки постбэков
        </div>
        <div className="d-flex m-0">
            [<div id="square-grey"></div>] - Неактивные часы для отправки постбэков
        </div>
    </div>
);
