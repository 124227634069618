import { useEffect, useContext, createContext } from "react";
import { observer } from "mobx-react";
import { RuleLanding } from "./RuleLanding";
import { useStores } from "../../../../stores";
import { ConditionsTable } from "./ConditionsTable";
import { RoutingRule } from "../../../../entities/CampaignEdit";
import { DestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { RuleDirection, RuleUrl, RuleOffer, ConditionAddButton, RuleDeleteButton, RuleAddButton } from "./";

interface RoutingRuleContextInterface {
    rule: RoutingRule;
    ruleIndex: number;
}

const RoutingRuleContext = createContext<RoutingRuleContextInterface>({} as RoutingRuleContextInterface);

export const CampaignRoutingRules = observer(() => {
    const { getCountries } = useStores().countriesStore;
    const { routingRules } = useStores().campaignEditStore.campaign;

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    return (
        <div className="mt-5">
            <h5>Таргетирование трафика</h5>
            {routingRules.map((rule: RoutingRule, ruleIndex: number) => (
                <RoutingRuleContext.Provider value={{ rule, ruleIndex }} key={ruleIndex}>
                    <div className="d-flex justify-content-around align-items-start mt-3">
                        <div className="container-fluid pl-0">
                            {rule.destination.type === DestinationType.OFFER && <RuleLanding />}
                        </div>
                        <div className="container-fluid"></div>
                        <div className="rule-percent ml-4 mr-4"></div>
                        <div className="rule-delete-button"></div>
                    </div>
                    <div className="d-flex justify-content-around align-items-start mt-2">
                        <div className="container-fluid pl-0">
                            <RuleDirection />
                        </div>
                        {rule.destination.type === DestinationType.URL ? (
                            <div className="container-fluid url__field">
                                <RuleUrl />
                            </div>
                        ) : (
                            <div className="container-fluid">
                                <RuleOffer />
                            </div>
                        )}
                        <ConditionAddButton />

                        <RuleDeleteButton />
                    </div>
                    <ConditionsTable />
                </RoutingRuleContext.Provider>
            ))}
            <div className="mt-3">
                <RuleAddButton />
            </div>
        </div>
    );
});

export const useRoutingRule = (): RoutingRuleContextInterface => {
    return useContext(RoutingRuleContext);
};
