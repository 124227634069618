import { useState } from "react";
import { observer } from "mobx-react";
import { useTeamsIds } from "../Teams";
import { useStores } from "../../../stores";
import { AlertModal, WarningModal } from ".";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const TeamDeleteButton = observer(() => {
    const { teamsIds, setTeamsIds } = useTeamsIds();
    const { teamsData, removeTeams } = useStores().teamsStore;
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const conditionsForDisabled: boolean = teamsIds.length === 0 || teamsIds.length === teamsData.items.length;

    const openWarningModal = (): void => setOpenWarning(true);

    const closeWarningModal = (): void => setOpenWarning(false);

    const openAlertModal = (): void => setOpenAlert(true);

    const closeAlertModal = (): void => setOpenAlert(false);

    const deleteTeam = (): void => {
        teamsIds.forEach((id: number) => {
            removeTeams(id).catch((error) => {
                if (error.response?.status === 409) {
                    openAlertModal();
                }
            });
        });
        setTeamsIds([]);
        closeWarningModal();
    };

    return (
        <>
            <Button
                size="small"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                disabled={conditionsForDisabled}
                onClick={openWarningModal}
            >
                Удалить
            </Button>

            {openAlert && <AlertModal closeAlertModal={closeAlertModal} />}
            {openWarning && <WarningModal deleteTeam={deleteTeam} closeWarningModal={closeWarningModal} />}
        </>
    );
});
