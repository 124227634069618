import { CampaignLinks } from "./CampaignLinks";
import { CampaignGoalsLimits } from "./CampaignGoalsLimits";
import { CampaignInformation } from "./CampaignInformation";
import { CampaignPaymentModel } from "./CampaignPaymentModel";
import { CampaignRoutingRules } from "./CampaignRoutingRules";
import { CampaignDefaultRules } from "./CampaignDefaultRules";
import { CampaignPostbackLimits } from "./CampaignPostbackLimits";

export const CampaignEditBody = () => (
    <div className="modal-body modal-overflow">
        <div className="row">
            <div className="col modal__band">
                <CampaignInformation />
            </div>
            <div className="col">
                <CampaignLinks />
            </div>
        </div>

        <CampaignPaymentModel />

        <CampaignDefaultRules />

        <div className="row mt-5">
            <div className="col modal__band">
                <CampaignPostbackLimits />
            </div>
            <div className="col">
                <CampaignGoalsLimits />
            </div>
        </div>

        <CampaignRoutingRules />
    </div>
);
