import { action } from "mobx";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useSingInValidation } from "./SignIn";
import { AuthValidation } from "./useValidation.hook";
import { Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

export const SignInButton = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { authData, signIn } = useStores().authStore;
    const { findCurrentUser } = useStores().currentUserStore;
    const { validation, changeValidation, checkValidation } = useSingInValidation();

    const setWrongDataValidation = (): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));
        newValidation.wrongData = true;
        changeValidation(newValidation);
    };

    const enter = (): void => {
        checkValidation(authData)
            ? enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 1500 })
            : signIn()
                  .then((response) => {
                      findCurrentUser();
                  })
                  .catch((error: AxiosError) => {
                      if (error.response?.status === 401 || error.response?.status === 409) {
                          setWrongDataValidation();
                      } else {
                          enqueueSnackbar("Упс..", { variant: "error", autoHideDuration: 1500 });
                      }
                  });
    };

    return (
        <Button variant="contained" startIcon={<SendIcon />} onClick={action(enter)}>
            Войти
        </Button>
    );
});
