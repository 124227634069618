import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { StatisticsFilter } from "./StatisticsFilter";
import { GroupByData } from "../../../entities/Statistics";
import { DateFilters } from "../../../module/Enum/DateFilters";
import { useDropdown, useStatisticsContext } from "../Statistics";
import { CampaignSearchItem } from "../../../entities/CampaignSearch";
import { StatisticsDate, StatisticsGroupings, CampaignSearch, StatisticsFilterButton } from ".";

interface FormCriteriaContextInterface {
    criteriaEdit: boolean;
    formCriteria: FormCriteria;
    setCriteriaEdit: Dispatch<SetStateAction<boolean>>;
    setFormCriteria: Dispatch<SetStateAction<FormCriteria>>;
}

interface DateFilterContextInterface {
    dateFilter: DateFilters | undefined;
    setDateFilter: Dispatch<SetStateAction<DateFilters | undefined>>;
}

interface SelectedСampaignsContextInterface {
    selectedСampaigns: CampaignSearchItem[];
    setSelectedСampaigns: Dispatch<SetStateAction<CampaignSearchItem[]>>;
}

interface GroupByValidationContextInterface {
    groupByValidation: boolean;
    checkValidation(): boolean;
    setGroupByValidation: Dispatch<SetStateAction<boolean>>;
}

export interface FormCriteria {
    campaignIds: number[];
    dateFrom: Date;
    dateTo: Date;
    groupBy: GroupByData[];
}

export const StatisticsForm = () => {
    const { dropdownNotice } = useDropdown();
    const { criteria } = useStatisticsContext();
    const [criteriaEdit, setCriteriaEdit] = useState<boolean>(false);
    const [groupByValidation, setGroupByValidation] = useState<boolean>(false);
    const [selectedСampaigns, setSelectedСampaigns] = useState<CampaignSearchItem[]>([]);
    const [dateFilter, setDateFilter] = useState<DateFilters | undefined>(DateFilters.TODAY);
    const [formCriteria, setFormCriteria] = useState<FormCriteria>({
        campaignIds: [],
        dateFrom: new Date(),
        dateTo: new Date(),
        groupBy: [],
    });

    const checkValidation = (): boolean => {
        if (formCriteria.groupBy.length === 0) {
            setGroupByValidation(true);
            return true;
        } else {
            setGroupByValidation(false);
            return false;
        }
    };

    useEffect(() => {
        const newFormCriteria: FormCriteria = {
            campaignIds: JSON.parse(JSON.stringify(criteria.campaignIds)),
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            groupBy: JSON.parse(JSON.stringify(criteria.groupBy)),
        };

        setFormCriteria(newFormCriteria);
    }, [criteria.campaignIds, criteria.dateFrom, criteria.dateTo, criteria.groupBy]);

    return (
        <FormCriteriaContext.Provider value={{ criteriaEdit, formCriteria, setCriteriaEdit, setFormCriteria }}>
            <SelectedСampaignsContext.Provider value={{ selectedСampaigns, setSelectedСampaigns }}>
                <DateFilterContext.Provider value={{ dateFilter, setDateFilter }}>
                    <GroupByValidationContext.Provider
                        value={{ groupByValidation, setGroupByValidation, checkValidation }}
                    >
                        <form className="pt-4 pb-4">
                            <div className="d-flex align-items-start ml-4">
                                <div>
                                    <StatisticsDate />
                                </div>

                                <div className="groopings ml-4">
                                    <StatisticsGroupings />
                                </div>

                                {!dropdownNotice && (
                                    <div className="w-25 ml-4">
                                        <CampaignSearch />
                                    </div>
                                )}
                            </div>

                            <div className="filter d-flex mt-4 ml-4">
                                <StatisticsFilter />
                            </div>

                            <div className="ml-4 mt-4">
                                <StatisticsFilterButton />
                            </div>
                        </form>
                    </GroupByValidationContext.Provider>
                </DateFilterContext.Provider>
            </SelectedСampaignsContext.Provider>
        </FormCriteriaContext.Provider>
    );
};

const DateFilterContext = createContext<DateFilterContextInterface>({} as DateFilterContextInterface);
const FormCriteriaContext = createContext<FormCriteriaContextInterface>({} as FormCriteriaContextInterface);
const GroupByValidationContext = createContext<GroupByValidationContextInterface>(
    {} as GroupByValidationContextInterface
);
const SelectedСampaignsContext = createContext<SelectedСampaignsContextInterface>(
    {} as SelectedСampaignsContextInterface
);

export const useDateFilter = (): DateFilterContextInterface => {
    return useContext(DateFilterContext);
};

export const useFormCriteria = (): FormCriteriaContextInterface => {
    return useContext(FormCriteriaContext);
};

export const useGroupByValidation = (): GroupByValidationContextInterface => {
    return useContext(GroupByValidationContext);
};

export const useSelectedСampaigns = (): SelectedСampaignsContextInterface => {
    return useContext(SelectedСampaignsContext);
};
