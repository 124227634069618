import { FilterList, FilterSaveButton } from "./";
import "../../../../style/filters.css";

export const StatisticsFilter = () => (
    <>
        <div className="w-100">
            <FilterList />
        </div>
        <div className="d-flex align-items-end ml-4">
            <FilterSaveButton />
        </div>
    </>
);
