export enum SortField {
    DATE = "date",
    HOUR = "hour",
    GOAL = "goal",
    LANDING = "landing",
    CAMPAIGN = "campaign",
    MANAGER = "manager",
    SOURCE = "source",
    OFFER = "offer",
    URL = "url",
    BROWSER_FAMILY = "browserFamily",
    OPERATION_SYSTEM_FAMILY = "operationSystemFamily",
    DEVICE = "device",
    COUNTRY = "country",
    CLICK_COUNT = "clickCount",
    LEAD_COUNT = "leadCount",
    LANDING_CLICK_COUNT = "landingClickCount",
    CVR = "cvr",
    REVENUE = "revenue",
    COST = "cost",
}

export enum DefaultField {
    CLICK_COUNT = "clickCount",
    LEAD_COUNT = "leadCount",
    POSTBACK_COUNT = "postbackCount",
    LANDING_CLICK_COUNT = "landingClickCount",
    REVENUE = "revenue",
    COST = "cost",
    CVR = "cvr",
}

interface ConvertField {
    [key: string]: string;
}

export const convertField: ConvertField = {
    cvr: "CVR",
    url: "URL",
    goal: "Цель",
    cost: "Cost",
    date: "Дата",
    hour: "Часы",
    offer: "Оффер",
    country: "Страна",
    leadCount: "Лиды",
    landing: "Лендинг",
    source: "Источник",
    revenue: "Revenue",
    manager: "Менеджер",
    clickCount: "Клики",
    device: "Устройство",
    campaign: "Кампания",
    browserFamily: "Браузер",
    operationSystemFamily: "ОС",
    postbackCount: "Исходящие постбэки",
    landingClickCount: "Landing clicks",
};
