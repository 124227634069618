import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../../stores";
import { Filter } from "../../../../entities/Filters";
import { Modal, Button } from "@mui/material";

interface Props {
    filterItem?: Filter;
    closeWarningModal(): void;
}

export const Warning = observer(({ filterItem, closeWarningModal }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { removeFilter } = useStores().filtersStore;

    const deleteFilter = (): void => {
        filterItem &&
            removeFilter(filterItem).then((response) => {
                if (response) {
                    closeWarningModal();
                    enqueueSnackbar("Фильтр удален", { variant: "success", autoHideDuration: 1500 });
                }
            });
    };

    return (
        <div className="modal fade">
            <Modal open onClose={closeWarningModal}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Предупреждение</h5>
                        </div>
                        <div className="modal-body">Вы действительны хотите удалить «{filterItem?.title}»?</div>
                        <div className="modal-footer">
                            <Button variant="outlined" onClick={closeWarningModal}>
                                Нет
                            </Button>
                            <Button variant="contained" onClick={action(deleteFilter)}>
                                Да
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
});
