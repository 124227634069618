import { AxiosResponse } from "axios";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { Campaign, PaymentModel } from "../../entities/CampaignEdit";
import { GetCampaignsRequest, CampaignData } from "../../entities/Campaigns";
import { CampaignLinks, CampaignLinksCriteria } from "../../entities/CampaignLinks";
import { CampaignSearchCriteria, CampaignSearchData } from "../../entities/CampaignSearch";

interface CampaignsApiInterface {
    deleteCampaign(campaignId: number): Promise<AxiosResponse<{}>>;
    postCampaign(campaign: Campaign): Promise<AxiosResponse<Campaign>>;
    getPaymentModel(campaignId: string): Promise<AxiosResponse<PaymentModel>>;
    getCampaign(campaignId: number | string): Promise<AxiosResponse<Campaign>>;
    getCampaigns(criteria: GetCampaignsRequest): Promise<AxiosResponse<CampaignData>>;
    putCampaign(campaignId: number, newCampaign: Campaign): Promise<AxiosResponse<Campaign>>;
    getCampaignSearch(criteria: CampaignSearchCriteria): Promise<AxiosResponse<CampaignSearchData>>;
    putPaymentModel(campaignId: number, newPaymentModel: PaymentModel): Promise<AxiosResponse<PaymentModel>>;
    getCampaignLinks(campaignId: number, criteria: CampaignLinksCriteria): Promise<AxiosResponse<CampaignLinks>>;
}

export const CampaignsApi: CampaignsApiInterface = {
    getCampaigns: async (criteria: GetCampaignsRequest): Promise<AxiosResponse<CampaignData>> =>
        await httpClient.get<CampaignData>("/api/v1/campaign", { params: criteria }),

    getCampaign: async (campaignId: number | string): Promise<AxiosResponse<Campaign>> =>
        await httpClient.get<Campaign>(`/api/v1/campaign/${campaignId}`),

    postCampaign: async (campaign: Campaign): Promise<AxiosResponse<Campaign>> =>
        await httpClient.post<Campaign>("/api/v1/campaign", campaign),

    putCampaign: async (campaignId: number, newCampaign: Campaign): Promise<AxiosResponse<Campaign>> =>
        await httpClient.put<Campaign>(`/api/v1/campaign/${campaignId}`, newCampaign),

    deleteCampaign: async (campaignId: number): Promise<AxiosResponse<{}>> =>
        await httpClient.delete<{}>(`/api/v1/campaign/${campaignId}`),

    getPaymentModel: async (campaignId: string): Promise<AxiosResponse<PaymentModel>> =>
        await httpClient.get<PaymentModel>(`/api/v1/campaign/${campaignId}/payment-model`),

    putPaymentModel: async (campaignId: number, newPaymentModel: PaymentModel): Promise<AxiosResponse<PaymentModel>> =>
        await httpClient.put<PaymentModel>(`/api/v1/campaign/${campaignId}/payment-model`, newPaymentModel),

    getCampaignSearch: async (criteria: CampaignSearchCriteria): Promise<AxiosResponse<CampaignSearchData>> =>
        await httpClient.get<CampaignSearchData>(`/api/v1/campaign/search`, { params: criteria }),

    getCampaignLinks: async (
        campaignId: number,
        criteria: CampaignLinksCriteria
    ): Promise<AxiosResponse<CampaignLinks>> =>
        await httpClient.get<CampaignLinks>(`/api/v1/campaign/${campaignId}/links`, { params: criteria }),
};
