import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { Goal } from "../../../../entities/CampaignEdit";
import { GoalName, GoalLimitValue, GoalIsEnabled, GoalDeleteButton, GoalAddButton } from "./";

export const CampaignGoalsLimits = observer(() => {
    const { goals } = useStores().campaignEditStore.campaign;
    const { validation } = useValidations().campaignEditValidation;

    return (
        <div>
            <h5>Лимиты по целям</h5>
            {goals.map((goal: Goal, index: number) => (
                <div key={index} className="d-flex justify-content-around align-items-start mt-3">
                    <div className="container-fluid pl-0">
                        <GoalName goal={goal} goalIndex={index} />
                    </div>
                    <div className="container-fluid">
                        <GoalLimitValue goal={goal} goalIndex={index} />
                    </div>
                    <div className="container-fluid">
                        <GoalIsEnabled goal={goal} goalIndex={index} />
                    </div>

                    <GoalDeleteButton goalIndex={index} />
                </div>
            ))}

            {validation.postbackSettings && (
                <div className="limit-validation">Сумма лимитов по целям не должна превышать глобальный лимит</div>
            )}

            <div className="mt-3">
                <GoalAddButton />
            </div>
        </div>
    );
});
