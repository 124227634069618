import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { WarningModalOnUnsavedData } from "../../WarningModalOnUnsavedData";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
    closeDomainEdit(): void;
}

export const DomainEditCloseButton = observer(({ closeDomainEdit }: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const { isChangedData } = useStores().domainEditStore;

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    return (
        <div>
            <IconButton onClick={!isChangedData ? openWarningModal : closeDomainEdit}>
                <CloseIcon />
            </IconButton>

            {open && (
                <WarningModalOnUnsavedData closeModalEdit={closeDomainEdit} closeWarningModal={closeWarningModal} />
            )}
        </div>
    );
});
