import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { httpClient } from "../module/AxiosRequest/AxiosRequest";

export interface CountriesStoreInterface {
    countries: Country[];
    getCountries(): Promise<Country[]>;
}

export interface CountriesData {
    items: Country[];
}

export interface Country {
    id: number;
    iso: string;
    name: string;
}

export class CountriesStore implements CountriesStoreInterface {
    public countries: Country[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public getCountries = async (): Promise<Country[]> => {
        await httpClient.get<CountriesData>(`/api/v1/geo/countries`).then((response: AxiosResponse<CountriesData>) => {
            runInAction(() => {
                this.countries = response.data.items;
            });
        });

        return this.countries;
    };
}
