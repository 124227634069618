import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { User } from "../../../entities/UserEdit";
import { useValidations } from "../../../validation";
import { UserValidation } from "../../../validation/UserEditValidation";
import { TextField } from "@mui/material";

export const UserSurname = observer(() => {
    const { user, changeUser } = useStores().userEditStore;
    const { validation, changeValidation } = useValidations().userEditValidation;

    const changeSurname = (event: ChangeEvent<HTMLInputElement>): void => {
        const newUser: User = JSON.parse(JSON.stringify(user));

        newUser.fullName.lastName = event.target.value.replace(/^\s/, "");

        changeUser(newUser);
        setSurnameValidation();
    };

    const setSurnameValidation = (): void => {
        const newValidation: UserValidation = JSON.parse(JSON.stringify(validation));

        newValidation.lastName = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Фамилия</h5>
            <TextField
                fullWidth
                autoComplete="off"
                error={validation.lastName}
                value={user.fullName.lastName}
                helperText={validation.lastName && "Поле не может быть пустым"}
                onChange={action(changeSurname)}
            />
        </div>
    );
});
