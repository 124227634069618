import { useState, useEffect, MouseEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { SourceParameter } from "../../../../entities/SourceParameters";
import { TypeGrouping } from "../../../../module/Enum/Grouping/Grouping";
import { SortDirection } from "../../../../module/Enum/Sort/SortDirection";
import { DefaultField, convertField, SortField } from "../../../../module/Enum/Statistics/SortField";
import {
    DefaultSort,
    ParameterSort,
    ReportRequest,
    DefaultGroupBy,
    ParameterGroupBy,
} from "../../../../entities/Report";
import { Menu, Button, MenuItem } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

export const SortAddButton = observer(() => {
    const { items } = useStores().sourceParametersStore.sourceParameters;
    const { reportRequest, changeReportRequest } = useStores().reportStore;
    const [anchorElSort, setAnchorElSort] = useState<null | HTMLElement>(null);
    const [sortList, setSortList] = useState<(DefaultSort | ParameterSort)[]>([]);

    useEffect(() => {
        const groupBySortList: (DefaultSort | ParameterSort)[] = reportRequest.groupBy.map(
            (group: DefaultGroupBy | ParameterGroupBy) =>
                group.type === TypeGrouping.DEFAULT
                    ? {
                          type: group.type,
                          field: group.field as unknown as SortField,
                          direction: SortDirection.ASC,
                      }
                    : {
                          type: group.type,
                          parameter: group.parameter,
                          direction: SortDirection.ASC,
                      }
        );

        const defaultSortList: DefaultSort[] = Object.values(DefaultField).map((field: DefaultField) => ({
            type: TypeGrouping.DEFAULT,
            field: field as unknown as SortField,
            direction: SortDirection.ASC,
        }));

        const newSortList: (DefaultSort | ParameterSort)[] = [...groupBySortList, ...defaultSortList].reduce(
            (unique: (DefaultSort | ParameterSort)[], sort: DefaultSort | ParameterSort) => {
                const isFound = reportRequest.sort.some((item: DefaultSort | ParameterSort) => {
                    if (item.type === TypeGrouping.DEFAULT && sort.type === TypeGrouping.DEFAULT) {
                        return item.field === sort.field;
                    }
                    if (item.type === TypeGrouping.PARAMETER && sort.type === TypeGrouping.PARAMETER) {
                        return item.parameter === sort.parameter;
                    }
                    return item.type === sort.type;
                });
                if (!isFound) unique.push(sort);

                return unique;
            },
            []
        );

        setSortList(newSortList);
    }, [reportRequest.groupBy, reportRequest.sort]);

    const openMenu = (event: MouseEvent<HTMLButtonElement>): void => setAnchorElSort(event.currentTarget);

    const closeMenu = (): void => setAnchorElSort(null);

    const addSort = (item: DefaultSort | ParameterSort): void => {
        const newReportRequest: ReportRequest = JSON.parse(JSON.stringify(reportRequest));

        newReportRequest.sort.push(item);

        changeReportRequest(newReportRequest);
        closeMenu();
    };

    return (
        <div>
            <Button
                size="small"
                color="success"
                id="sort-button"
                variant="outlined"
                aria-haspopup="true"
                aria-controls="sort-menu"
                disabled={sortList.length === 0}
                startIcon={<AddBoxOutlinedIcon />}
                aria-expanded={Boolean(anchorElSort) ? "true" : undefined}
                onClick={openMenu}
            >
                Добавить
            </Button>
            <Menu
                keepMounted
                id="sort-menu"
                anchorEl={anchorElSort}
                open={Boolean(anchorElSort)}
                MenuListProps={{
                    "aria-labelledby": "sort-button",
                }}
                onClose={closeMenu}
            >
                {sortList.map((item: DefaultSort | ParameterSort, index: number) => {
                    const subParametersFilter: string[] = items
                        .filter(
                            (parameter: SourceParameter) => parameter.parameter === (item as ParameterSort).parameter
                        )
                        .map((parameter: SourceParameter) => parameter.name);

                    return (
                        <MenuItem
                            key={index}
                            value={convertField[(item as DefaultSort).field] || subParametersFilter}
                            onClick={action(() => addSort(item))}
                        >
                            {convertField[(item as DefaultSort).field] || subParametersFilter}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
});
