import { Mode } from "../../module/Enum/Mode";
import { UserEdit } from "../../components/UserEdit";
import { Landings } from "../../components/Landings";
import { LandingEdit } from "../../components/LandingEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
    landingId: string;
}

export const LandingsRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode, landingId } = useParams<ParamsProps>();

    const closeLandingEdit = (): void => {
        history.push("/spa/landings");
    };

    switch (mode) {
        case Mode.ADD:
            content = <LandingEdit closeLandingEdit={closeLandingEdit} />;
            break;

        case Mode.EDIT:
            content = <LandingEdit landingId={landingId} closeLandingEdit={closeLandingEdit} />;
            break;

        case Mode.VIEW:
            content = <LandingEdit landingId={landingId} closeLandingEdit={closeLandingEdit} />;
            break;

        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeLandingEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Landings />
            {content}
        </>
    );
};
