import { useSelectedSources } from "../Sources";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const SourceEditButton = () => {
    const { url } = useRouteMatch();
    const { selectedSources } = useSelectedSources();
    const [id] = selectedSources.map((source) => source.id);

    return (
        <div className="mr-2">
            <Link to={`${url}/edit/${id}`} className={selectedSources.length !== 1 ? "link_disabled" : "link"}>
                <Button
                    size="small"
                    variant="contained"
                    startIcon={<EditIcon />}
                    disabled={selectedSources.length !== 1}
                >
                    Изменить
                </Button>
            </Link>
        </div>
    );
};
