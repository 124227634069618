import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Campaign } from "../../../../entities/CampaignEdit";
import { TextField, InputAdornment } from "@mui/material";

export const PostbackLimitPercent = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const changePostbackLimitPercent = (event: ChangeEvent<HTMLInputElement>): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));

        if (Number(event.target.value) <= 100)
            newCampaign.postbackSettings.probabilityPercent = Number(event.target.value);

        changeCampaign(newCampaign);
    };

    return (
        <TextField
            fullWidth
            label="Процент"
            value={campaign.postbackSettings.probabilityPercent}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onChange={action(changePostbackLimitPercent)}
        />
    );
});
