import { useSelectedUsers } from "../Users";
import { useRouteMatch, Link } from "react-router-dom";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export const UserEditButton = () => {
    const { url } = useRouteMatch();
    const { selectedUsers } = useSelectedUsers();
    const [id] = selectedUsers.map((user) => user.id);

    return (
        <div className="mr-2">
            <Link to={`${url}/edit/${id}`} className={selectedUsers.length !== 1 ? "link_disabled" : "link"}>
                <Button size="small" variant="contained" startIcon={<EditIcon />} disabled={selectedUsers.length !== 1}>
                    Изменить
                </Button>
            </Link>
        </div>
    );
};
