import { useState, useEffect, useContext, createContext, Dispatch, SetStateAction } from "react";
import { UsersPagination } from ".";
import { observer } from "mobx-react";
import { UsersForm } from "./UsersForm";
import { useStores } from "../../stores";
import { UsersTable } from "./UsersTable";
import { User } from "../../entities/Users";
import { ActionButtons } from "./ActionButtons";

interface SelectedUsersContextInterface {
    selectedUsers: User[];
    setSelectedUsers: Dispatch<SetStateAction<User[]>>;
}

export const Users = observer(() => {
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const { criteria, findUsers, setDefaultCriteria } = useStores().usersStore;

    useEffect(() => {
        findUsers();
    }, [criteria, findUsers]);

    useEffect(() => {
        setSelectedUsers([]);
    }, [criteria]);

    useEffect(() => {
        return setDefaultCriteria;
    }, [setDefaultCriteria]);

    return (
        <SelectedUsersContext.Provider value={{ selectedUsers, setSelectedUsers }}>
            <div className="container-fluid">
                <div className="ml-4">
                    <h1>Пользователи</h1>
                </div>
            </div>
            <div className="container-fluid bg-light">
                <div className="sticky pb-3">
                    <UsersForm />
                    <ActionButtons />
                </div>
                <UsersTable />
                <UsersPagination />
            </div>
        </SelectedUsersContext.Provider>
    );
});

const SelectedUsersContext = createContext<SelectedUsersContextInterface>({} as SelectedUsersContextInterface);

export const useSelectedUsers = (): SelectedUsersContextInterface => {
    return useContext(SelectedUsersContext);
};
