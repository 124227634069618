import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Button } from "@mui/material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

export const ReturnButton = observer(() => {
    const { setForgotPassword } = useStores().authStore;

    return (
        <Button variant="contained" startIcon={<KeyboardReturnIcon />} onClick={action(() => setForgotPassword(false))}>
            Назад
        </Button>
    );
});
