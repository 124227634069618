import { DomainEditTitle, DomainEditCloseButton } from ".";

interface Props {
    closeDomainEdit(): void;
}

export const DomainEditHeader = ({ closeDomainEdit }: Props) => (
    <div className="modal-header">
        <DomainEditTitle />
        <DomainEditCloseButton closeDomainEdit={closeDomainEdit} />
    </div>
);
