import { AxiosResponse } from "axios";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { СonvertedStatisticsCriteria, StatisticsData } from "../../entities/Statistics";

interface StatisticsApiInterface {
    getStatistics(criteria: СonvertedStatisticsCriteria): Promise<AxiosResponse<StatisticsData>>;
}

export const StatisticsApi: StatisticsApiInterface = {
    getStatistics: async (criteria: СonvertedStatisticsCriteria): Promise<AxiosResponse<StatisticsData>> =>
        await httpClient.get<StatisticsData>(`/api/v1/statistic`, { params: criteria }),
};
