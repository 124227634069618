import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Team } from "../../../entities/TeamEdit";
import { useValidations } from "../../../validation";
import { TeamValidation } from "../../../validation/TeamEditValidation";
import { TextField } from "@mui/material";

export const TeamTitle = observer(() => {
    const { team, changeTeam } = useStores().teamEditStore;
    const { validation, changeValidation } = useValidations().teamEditValidation;

    const changeTitle = (event: ChangeEvent<HTMLInputElement>): void => {
        const newTeam: Team = JSON.parse(JSON.stringify(team));

        newTeam.title = event.target.value;

        setTitleValidation();
        changeTeam(newTeam);
    };

    const setTitleValidation = (): void => {
        const newValidation: TeamValidation = JSON.parse(JSON.stringify(validation));

        newValidation.title = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Наименование команды</h5>
            <TextField
                fullWidth
                value={team.title}
                error={validation.title}
                helperText={validation.title && "Поле не может быть пустым"}
                onChange={action(changeTitle)}
            />
        </div>
    );
});
