import { Button } from "@mui/material";

interface Props {
    closeSaveModal(): void;
}

export const CanсelButton = ({ closeSaveModal }: Props) => (
    <Button variant="outlined" onClick={closeSaveModal}>
        Отмена
    </Button>
);
