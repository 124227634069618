import { AxiosResponse } from "axios";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { FilterRequest, FiltersData, Filter } from "../../entities/Filters";

interface FiltersApiInterface {
    getFilters(): Promise<AxiosResponse<FiltersData>>;
    deleteFilter(filterId: number): Promise<AxiosResponse<{}>>;
    postFilter(filter: FilterRequest): Promise<AxiosResponse<Filter>>;
}

export const FiltersApi: FiltersApiInterface = {
    getFilters: async (): Promise<AxiosResponse<FiltersData>> =>
        await httpClient.get<FiltersData>(`/api/v1/statistic/filter`),

    postFilter: async (filter: FilterRequest): Promise<AxiosResponse<Filter>> =>
        await httpClient.post<Filter>(`/api/v1/statistic/filter`, filter),

    deleteFilter: async (filterId: number): Promise<AxiosResponse<{}>> =>
        await httpClient.delete<{}>(`/api/v1/statistic/filter/${filterId}`),
};
