import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useHistory } from "react-router-dom";
import { useValidations } from "../../../validation";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

export const SourceSaveButton = observer(() => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { source, saveSource } = useStores().sourceEditStore;
    const { checkValidation } = useValidations().sourceEditValidation;

    const changePath = (id: number | undefined): void => {
        id && history.push(`/spa/sources/edit/${id}`);
    };

    const save = (): void => {
        checkValidation(source)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveSource().then((response) => {
                  if (response) {
                      changePath(response.id);
                      enqueueSnackbar("Источник сохранен", { variant: "success", autoHideDuration: 1500 });
                  }
              });
    };
    return (
        <Button color="primary" variant="contained" startIcon={<SaveIcon />} onClick={action(save)}>
            Сохранить
        </Button>
    );
});
