import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useValidations } from "../../../../validation";
import { CampaignValidation } from "../../../../validation/CampaignEditValidation";
import { Campaign, PercentageRoutingRule } from "../../../../entities/CampaignEdit";
import { DestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Button, Tooltip } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const RuleAddButton = observer(() => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;

    const addRule = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = {
            destination: {
                type: DestinationType.OFFER,
                offerId: -1,
                landingId: null,
            },
            percent: 0,
        };

        newCampaign.percentageRoutingRules.push(newRule);

        changeCampaign(newCampaign);
        setRulesLengthValidation();
    };

    const setRulesLengthValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));

        newValidation.percentageRoutingRulesLength = false;

        changeValidation(newValidation);
    };

    return (
        <>
            <Button
                variant="outlined"
                color={validation.percentageRoutingRulesLength ? "error" : "success"}
                startIcon={<AddBoxOutlinedIcon />}
                onClick={action(addRule)}
            >
                Добавить правило
            </Button>

            {validation.percentageRoutingRulesLength && (
                <Tooltip title="Необходимо добавить правило" className="rules__tooltip">
                    <ErrorOutlineOutlinedIcon fontSize="small" color="error" />
                </Tooltip>
            )}
        </>
    );
});
