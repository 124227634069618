import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useReportData, usePagination } from "../";
import { DefaultGrouping } from "../../../module/Enum/Grouping/Grouping";
import { ReportData, ReportItem, DefaultGroupBy, ParameterGroupBy } from "../../../entities/Report";

interface Props {
    calculationCvr(cvr: number, deltaCvr: number): any;
    calculationCount(value: number, deltaValue: number): any;
    calculationToFixed(value: number, deltaValue: number): any;
}

export const TableBody = observer(({ calculationCvr, calculationCount, calculationToFixed }: Props) => {
    const { pagination } = usePagination();
    const { groupBy } = useStores().reportStore.reportRequest;
    const { items } = useReportData().reportData as ReportData;
    const [reportItems, setReportItems] = useState<ReportItem[]>([]);

    useEffect(() => {
        const firstIndex: number = (pagination.currentPage - 1) * pagination.itemsPerPage;
        const lastIndex: number = pagination.currentPage * pagination.itemsPerPage;
        const newReportItems: ReportItem[] = items.slice(firstIndex, lastIndex);

        setReportItems(newReportItems);
    }, [items, pagination]);

    return (
        <tbody>
            {reportItems.map((item: ReportItem, index: number) => (
                <tr
                    key={index}
                    className={item.group.offset === 0 && groupBy.length > 1 ? "table-active table__text-weight" : ""}
                >
                    {groupBy.map((group: DefaultGroupBy | ParameterGroupBy, gIndex: number) =>
                        gIndex === item.group.offset ? (
                            <td key={gIndex}>
                                {(group as DefaultGroupBy).field === DefaultGrouping.DATE
                                    ? item.group.rowValue.split("-").reverse().join(".")
                                    : item.group.rowValue}
                            </td>
                        ) : (
                            <td key={gIndex}></td>
                        )
                    )}

                    <td>
                        {item.clickCount} {calculationCount(item.clickCount, item.deltaClickCount)}
                    </td>
                    <td>
                        {item.leadCount} {calculationCount(item.leadCount, item.deltaLeadCount)}
                    </td>
                    <td>
                        {item.postbackCount} {calculationCount(item.postbackCount, item.deltaPostbackCount)}
                    </td>
                    <td>
                        {item.landingClickCount} {calculationCount(item.landingClickCount, item.deltaLandingClickCount)}
                    </td>
                    <td>
                        {item.revenue.toFixed(2)} {calculationToFixed(item.revenue, item.deltaRevenue)}
                    </td>
                    <td>
                        {item.cost.toFixed(2)} {calculationToFixed(item.cost, item.deltaCost)}
                    </td>
                    <td>
                        {item.cvr.toFixed(2)}% {calculationCvr(item.cvr, item.deltaCvr)}
                    </td>
                </tr>
            ))}
        </tbody>
    );
});
