import { TeamEditTitle, TeamEditCloseButton } from ".";

interface Props {
    closeTeamEdit(): void;
}

export const TeamEditHeader = ({ closeTeamEdit }: Props) => (
    <div className="modal-header">
        <TeamEditTitle />
        <TeamEditCloseButton closeTeamEdit={closeTeamEdit} />
    </div>
);
