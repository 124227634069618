import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { errorText, AuthValidation } from "./useValidation.hook";
import { PasswordResetInitializeData } from "../../../entities/Auth";
import { usePasswordResetValidation } from "./InitializePasswordReset";
import { TextField } from "@mui/material";

export const Email = observer(() => {
    const { validation, changeValidation } = usePasswordResetValidation();
    const { passwordResetInitializeData, changePasswordResetInitializeData } = useStores().authStore;

    const changeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
        const dataCopy: PasswordResetInitializeData = JSON.parse(JSON.stringify(passwordResetInitializeData));

        dataCopy.email = event.target.value.replace(/^\s/, "");

        setEmailValidation();
        changePasswordResetInitializeData(dataCopy);
    };

    const setEmailValidation = (): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));
        newValidation.email = false;
        newValidation.nonExistentMail = false;
        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <TextField
                fullWidth
                placeholder="Введите вашу почту"
                value={passwordResetInitializeData.email}
                error={validation.email || validation.nonExistentMail}
                helperText={
                    (validation.email && errorText.email) || (validation.nonExistentMail && errorText.nonExistentMail)
                }
                onChange={action(changeEmail)}
            />
        </div>
    );
});
