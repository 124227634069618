import { AxiosResponse } from "axios";
import { UsersCriteria, UsersData } from "../../entities/Users";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { User, UserRequest, CurrentUser, CurrentUserRequest } from "../../entities/UserEdit";

interface UsersApiInterface {
    deleteUser(userId: number): Promise<AxiosResponse>;
    getCurrentUser(): Promise<AxiosResponse<CurrentUser>>;
    postUser(newUser: UserRequest): Promise<AxiosResponse<User>>;
    getUser(userId: string | number): Promise<AxiosResponse<User>>;
    getUsers(criteria: UsersCriteria): Promise<AxiosResponse<UsersData>>;
    putUser(userId: number, newUser: UserRequest): Promise<AxiosResponse<User>>;
    putCurrentUser(newCurrentUser: CurrentUserRequest): Promise<AxiosResponse<CurrentUser>>;
}

export const UsersApi: UsersApiInterface = {
    getCurrentUser: async (): Promise<AxiosResponse<CurrentUser>> =>
        await httpClient.get<CurrentUser>(`/api/v1/user/current`),

    putCurrentUser: async (newCurrentUser: CurrentUserRequest): Promise<AxiosResponse<CurrentUser>> =>
        await httpClient.put<CurrentUser>(`/api/v1/user/current`, newCurrentUser),

    getUsers: async (criteria: UsersCriteria): Promise<AxiosResponse<UsersData>> =>
        await httpClient.get<UsersData>(`/api/v1/user`, { params: criteria }),

    getUser: async (userId: string | number): Promise<AxiosResponse<User>> =>
        await httpClient.get<User>(`/api/v1/user/${userId}`),

    postUser: async (newUser: UserRequest): Promise<AxiosResponse<User>> =>
        await httpClient.post<User>(`/api/v1/user`, newUser),

    putUser: async (userId: number, newUser: UserRequest): Promise<AxiosResponse<User>> =>
        await httpClient.put<User>(`/api/v1/user/${userId}`, newUser),

    deleteUser: async (userId: number): Promise<AxiosResponse> => await httpClient.delete(`/api/v1/user/${userId}`),
};
