import { TableHead, TableBody, TableFooter } from "./";

export const ReportTable = () => {
    const calculationCvr = (cvr: number, deltaCvr: number): any => {
        const total: number = cvr - deltaCvr;

        switch (true) {
            case total > 0:
                return <span className="table__text-green">(+{total.toFixed(2)}%)</span>;

            case total < 0:
                return <span className="table__text-red">({total.toFixed(2)}%)</span>;

            default:
                return <span>({total.toFixed(2)}%)</span>;
        }
    };

    const calculationCount = (value: number, deltaValue: number): any => {
        const total: number = value - deltaValue;

        switch (true) {
            case total > 0:
                return <span className="table__text-green">(+{total})</span>;

            case total < 0:
                return <span className="table__text-red">({total})</span>;

            default:
                return <span>({total})</span>;
        }
    };

    const calculationToFixed = (value: number, deltaValue: number): any => {
        const total: number = value - deltaValue;

        switch (true) {
            case total > 0:
                return <span className="table__text-green">(+{total.toFixed(2)})</span>;

            case total < 0:
                return <span className="table__text-red">({total.toFixed(2)})</span>;

            default:
                return <span>({total.toFixed(2)})</span>;
        }
    };

    return (
        <div className="table-responsive">
            <table className="table table-hover">
                <TableHead />

                <TableBody
                    calculationCvr={calculationCvr}
                    calculationCount={calculationCount}
                    calculationToFixed={calculationToFixed}
                />

                <TableFooter
                    calculationCvr={calculationCvr}
                    calculationCount={calculationCount}
                    calculationToFixed={calculationToFixed}
                />
            </table>
        </div>
    );
};
