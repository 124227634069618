import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { CampaignsApi } from "../services/api/CampaignsApi";
import { CampaignLinks, CampaignLinksCriteria } from "../entities/CampaignLinks";

export interface CampaignLinksStoreInterface {
    campaignLinks: CampaignLinks;
    criteria: CampaignLinksCriteria;
    cleanUpCampaignLinks(): void;
    changeLinksCriteria(name: string, value: string): void;
    findCampaignLinks(campaignId: number): Promise<CampaignLinks>;
}

const DEFAULT_CRITERIA: CampaignLinksCriteria = {
    domainId: "-1",
    goalName: "-1",
};

const DEFAULT_LINKS: CampaignLinks = {
    click: "",
    postback: "",
    landing: "",
};

const { getCampaignLinks } = CampaignsApi;

export class CampaignLinksStore implements CampaignLinksStoreInterface {
    public campaignLinks: CampaignLinks = DEFAULT_LINKS;
    public criteria: CampaignLinksCriteria = DEFAULT_CRITERIA;

    constructor() {
        makeAutoObservable(this);
    }

    public cleanUpCampaignLinks = (): void => {
        this.criteria = DEFAULT_CRITERIA;
        this.campaignLinks = DEFAULT_LINKS;
    };

    public changeLinksCriteria = (name: string, value: string): void => {
        this.criteria[name] = value;
    };

    public findCampaignLinks = async (campaignId: number): Promise<CampaignLinks> => {
        await getCampaignLinks(campaignId, this.checkCriteria()).then((response: AxiosResponse<CampaignLinks>) => {
            runInAction(() => {
                this.campaignLinks = response.data;
            });
        });
        return this.campaignLinks;
    };

    private checkCriteria = (): CampaignLinksCriteria => {
        const newCriteria: CampaignLinksCriteria = { ...this.criteria };
        if (newCriteria.domainId === "-1") delete newCriteria.domainId;
        if (newCriteria.goalName === "-1") delete newCriteria.goalName;
        return newCriteria;
    };
}
