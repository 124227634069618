import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSelectedOffers } from "../Offers";
import { OfferItem } from "../../../entities/Offers";
import { Checkbox } from "@mui/material";

export const TableBody = observer(() => {
    const { items } = useStores().offersStore.offersData;
    const { selectedOffers, setSelectedOffers } = useSelectedOffers();

    const handleChangeId = (selectedOffer: OfferItem): void => {
        let selectedOffersCopy: OfferItem[] = JSON.parse(JSON.stringify(selectedOffers));

        const isOfferExist = (): boolean => {
            return selectedOffersCopy.map((item: OfferItem) => item.id).includes(selectedOffer.id);
        };

        isOfferExist()
            ? (selectedOffersCopy = selectedOffersCopy.filter((item: OfferItem) => item.id !== selectedOffer.id))
            : selectedOffersCopy.push(selectedOffer);

        setSelectedOffers(selectedOffersCopy);
    };

    return (
        <tbody>
            {items.map((item: OfferItem) => (
                <React.Fragment key={item.id}>
                    <tr onClick={() => handleChangeId(item)}>
                        <td className="align-middle">
                            <Checkbox
                                size="small"
                                checked={selectedOffers.map((item: OfferItem) => item.id).includes(item.id)}
                            />
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">{item.title}</td>
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
});
