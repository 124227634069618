import { useState, useEffect, useCallback } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useUserRoles } from "../../hooks/useUserRoles";
import { Roles } from "../../module/Enum/Roles/Roles";

interface MenuItem {
    title: string;
    src: string;
}

interface MenuSRC {
    TEAMS?: string;
    DOMAINS?: string;
    USERS?: string;
    SOURCES: string;
    OFFERS: string;
    LANDINGS: string;
    CAMPAIGNS: string;
    STATISTICS: string;
    DELTA: string;
}

interface ConvertTitle {
    [key: string]: string;
}

const menuSRC: MenuSRC = {
    TEAMS: "/spa/teams",
    DOMAINS: "/spa/domains",
    USERS: "/spa/users",
    SOURCES: "/spa/sources",
    OFFERS: "/spa/offers",
    LANDINGS: "/spa/landings",
    CAMPAIGNS: "/spa/campaigns",
    STATISTICS: "/spa/statistics",
    DELTA: "/spa/delta",
};

const convertTitle: ConvertTitle = {
    "/spa/teams": "Команды",
    "/spa/domains": "Домены",
    "/spa/users": "Пользователи",
    "/spa/sources": "Источники",
    "/spa/offers": "Офферы",
    "/spa/landings": "Лендинги",
    "/spa/campaigns": "Кампании",
    "/spa/statistics": "Статистика",
    "/spa/delta": "Дельта",
};

export const Navbar = observer(() => {
    const { role } = useUserRoles();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

    const createMenuItems = useCallback((): MenuItem[] => {
        const newMenuSRC: MenuSRC = JSON.parse(JSON.stringify(menuSRC));

        switch (role) {
            case Roles.ROLE_USER:
                delete newMenuSRC.TEAMS;
                delete newMenuSRC.USERS;
                delete newMenuSRC.DOMAINS;
                break;

            case Roles.ROLE_MEDIA_BUYER:
                delete newMenuSRC.TEAMS;
                delete newMenuSRC.USERS;
                delete newMenuSRC.DOMAINS;
                break;

            case Roles.ROLE_TEAMLEAD:
                delete newMenuSRC.DOMAINS;
                break;
        }

        return Object.values(newMenuSRC).map((src: string) => {
            return {
                title: convertTitle[src],
                src: src,
            };
        });
    }, [role]);

    useEffect(() => {
        if (role !== undefined) {
            setMenuItems(createMenuItems());
        }
    }, [role, createMenuItems]);

    return (
        <>
            <Link to="/spa/campaigns" className="navbar-brand">
                Honey Tracker
            </Link>

            <ul className="navbar-nav mr-auto mt-lg-0">
                {menuItems.map((item: MenuItem) => (
                    <li key={item.src} className="nav-item">
                        <Link className="nav-link" to={item.src}>
                            {item.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
});
