import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { SourceParameter } from "../../../entities/SourceParameters";
import { DefaultGroupBy, ParameterGroupBy } from "../../../entities/Report";
import { DefaultField, convertField } from "../../../module/Enum/Statistics/SortField";

export const TableHead = observer(() => {
    const { groupBy } = useStores().reportStore.reportRequest;
    const { items } = useStores().sourceParametersStore.sourceParameters;

    return (
        <thead>
            <tr>
                {groupBy.map((group: DefaultGroupBy | ParameterGroupBy, index: number) => (
                    <th key={index} id={String(index)}>
                        {convertField[(group as DefaultGroupBy).field] ||
                            items
                                .filter(
                                    (parameter: SourceParameter) =>
                                        parameter.parameter === (group as ParameterGroupBy).parameter
                                )
                                .map((parameter: SourceParameter) => parameter.name)}
                    </th>
                ))}

                {Object.values(DefaultField).map((field: DefaultField, index: number) => (
                    <th key={index}>{convertField[field]}</th>
                ))}
            </tr>
        </thead>
    );
});
