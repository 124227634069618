import { action } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useStores } from "../../../../stores";
import { Mode } from "../../../../module/Enum/Mode";
import { useValidations } from "../../../../validation";
import { Source } from "../../../../entities/SourceEdit";
import { SourceValidation } from "../../../../validation/SourceEditValidation";
import { Button } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

interface Props {
    parameterIndex: number;
}

interface ParamsProps {
    mode: Mode;
}

export const ParameterDeleteButton = observer(({ parameterIndex }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { source, changeSource } = useStores().sourceEditStore;
    const { validation, changeValidation } = useValidations().sourceEditValidation;

    const deleteParameter = (): void => {
        const newSource: Source = JSON.parse(JSON.stringify(source));

        newSource.parameters.splice(parameterIndex, 1);

        changeSource(newSource);
        deleteParametersValidation();
    };

    const deleteParametersValidation = (): void => {
        const newValidation: SourceValidation = JSON.parse(JSON.stringify(validation));
        newValidation.parameters = {};
        changeValidation(newValidation);
    };

    return (
        <div>
            <Button color="error" disabled={mode === Mode.VIEW} onClick={action(deleteParameter)}>
                <DeleteForeverOutlinedIcon />
            </Button>
        </div>
    );
});
