import { useContext, createContext } from "react";
import { observer } from "mobx-react";
import { RuleLanding } from "./RuleLanding";
import { useStores } from "../../../../stores";
import { PercentageRoutingRule } from "../../../../entities/CampaignEdit";
import { DestinationType } from "../../../../module/Enum/CampaignEdit/CampaignEdit";
import { RuleAddButton, RuleDirection, RuleUrl, RuleOffer, RulePercent, RuleDeleteButton } from ".";

interface DefaultRuleContextInterface {
    ruleIndex: number;
    rule: PercentageRoutingRule;
}

const DefaultRuleContext = createContext<DefaultRuleContextInterface>({} as DefaultRuleContextInterface);

export const CampaignDefaultRules = observer(() => {
    const { percentageRoutingRules } = useStores().campaignEditStore.campaign;

    return (
        <div className="mt-5">
            <h5>Правила по умолчанию</h5>
            {percentageRoutingRules.map((rule: PercentageRoutingRule, ruleIndex: number) => (
                <DefaultRuleContext.Provider key={ruleIndex} value={{ rule, ruleIndex }}>
                    <div className="d-flex justify-content-around align-items-start mt-3">
                        <div className="container-fluid pl-0">
                            {rule.destination.type === DestinationType.OFFER && <RuleLanding />}
                        </div>
                        <div className="container-fluid"></div>
                        <div className="rule-percent ml-4 mr-4"></div>
                        <div className="rule-delete-button"></div>
                    </div>
                    <div className="d-flex justify-content-around align-items-start mt-2">
                        <div className="container-fluid pl-0">
                            <RuleDirection />
                        </div>
                        {rule.destination.type === DestinationType.URL ? (
                            <div className="container-fluid url__field">
                                <RuleUrl />
                            </div>
                        ) : (
                            <div className="container-fluid">
                                <RuleOffer />
                            </div>
                        )}
                        <div className="rule-percent ml-4 mr-4">
                            <RulePercent />
                        </div>
                        <div className="rule-delete-button">
                            <RuleDeleteButton />
                        </div>
                    </div>
                </DefaultRuleContext.Provider>
            ))}
            <div className="position-relative mt-3">
                <RuleAddButton />
            </div>
        </div>
    );
});

export const useDefaultRule = (): DefaultRuleContextInterface => {
    return useContext(DefaultRuleContext);
};
