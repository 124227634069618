import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useSelectedUsers } from "../Users";
import { useStores } from "../../../stores";
import { User } from "../../../entities/Users";
import { Team } from "../../../entities/Teams";
import { Roles, convertRolesName } from "../../../module/Enum/Roles/Roles";
import { Checkbox } from "@mui/material";

export const TableBody = observer(() => {
    const { items } = useStores().usersStore.usersData;
    const { teamsData, findTeams } = useStores().teamsStore;
    const { selectedUsers, setSelectedUsers } = useSelectedUsers();

    useEffect(() => {
        findTeams();
    }, [findTeams]);

    const handleChangeUser = (user: User): void => {
        let usersCopy: User[] = JSON.parse(JSON.stringify(selectedUsers));

        usersCopy.map((user: User) => user.id).includes(user.id)
            ? (usersCopy = usersCopy.filter((userCopy: User) => userCopy.id !== user.id))
            : usersCopy.push(user);

        setSelectedUsers(usersCopy);
    };

    return (
        <tbody>
            {items.map((item: User) => (
                <React.Fragment key={item.id}>
                    <tr onClick={() => handleChangeUser(item)}>
                        <td className="align-middle">
                            <Checkbox
                                size="small"
                                checked={selectedUsers.map((user: User) => user.id).includes(item.id)}
                            />
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">
                            {item.fullName.lastName} {item.fullName.firstName}
                        </td>
                        <td className="align-middle">{item.email}</td>
                        <td className="align-middle">
                            {teamsData.items.filter((team: Team) => team.id === item.teamId).map((team) => team.title)}
                        </td>
                        <td className="align-middle">
                            <div className="d-flex">
                                {item.roles
                                    .slice()
                                    .sort()
                                    .reverse()
                                    .map((role: Roles, index: number) => (
                                        <div key={index} className="role">
                                            {convertRolesName[role]}
                                        </div>
                                    ))}
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
});
