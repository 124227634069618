import { makeAutoObservable } from "mobx";
import { NewUser, User } from "../entities/UserEdit";

export interface UserEditValidationInterface {
    validation: UserValidation;
    setDefualtValidation(): void;
    checkValidation(user: User | NewUser): boolean;
    changeValidation(newValidation: UserValidation): void;
}

export interface UserValidation {
    email: boolean;
    doubleEmail: boolean;
    roles: boolean;
    teamId: boolean;
    password: boolean;
    firstName: boolean;
    lastName: boolean;
}

const DEFAULT_VALIDATION: UserValidation = {
    email: false,
    doubleEmail: false,
    roles: false,
    teamId: false,
    password: false,
    firstName: false,
    lastName: false,
};

export class UserEditValidation implements UserEditValidationInterface {
    public validation: UserValidation = DEFAULT_VALIDATION;

    constructor() {
        makeAutoObservable(this);
    }

    public setDefualtValidation = (): void => {
        this.validation = DEFAULT_VALIDATION;
    };

    public changeValidation = (newValidation: UserValidation): void => {
        this.validation = newValidation;
    };

    public checkValidation = (user: User | NewUser): boolean => {
        const regEmail: RegExp = /\S+@\S+\.\S+/;

        !regEmail.test(user.email) ? (this.validation.email = true) : (this.validation.email = false);
        !user.fullName.lastName ? (this.validation.lastName = true) : (this.validation.lastName = false);
        !user.fullName.firstName ? (this.validation.firstName = true) : (this.validation.firstName = false);
        user.teamId === -1 ? (this.validation.teamId = true) : (this.validation.teamId = false);
        user.roles.length === 0 ? (this.validation.roles = true) : (this.validation.roles = false);
        if (user.id === undefined && user.password !== undefined)
            !user.password ? (this.validation.password = true) : (this.validation.password = false);

        return Object.values(this.validation).includes(true);
    };
}
