import { AxiosResponse } from "axios";
import { httpClient } from "../../module/AxiosRequest/AxiosRequest";
import { ReportRequest, ReportId, ReportData } from "../../entities/Report";

interface ReportApiInterface {
    getReport(id: string): Promise<AxiosResponse<ReportData>>;
    postReport(request: ReportRequest): Promise<AxiosResponse<ReportId>>;
}

export const ReportApi: ReportApiInterface = {
    getReport: async (id: string): Promise<AxiosResponse<ReportData>> =>
        await httpClient.get<ReportData>(`/api/v1/report/${id}`),

    postReport: async (request: ReportRequest): Promise<AxiosResponse<ReportId>> =>
        await httpClient.post<ReportId>(`/api/v1/report`, request),
};
