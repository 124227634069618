import { useState } from "react";
import { SearchUserModal } from "./";
import { IconButton, Tooltip } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

interface Props {
    addUser(userId: number): void;
}

export const UserAddButton = ({ addUser }: Props) => {
    const [open, setOpen] = useState<boolean>(false);

    const openSearchUserModal = (): void => setOpen(true);

    const closeSearchUserModal = (): void => setOpen(false);

    return (
        <>
            <Tooltip title="Добавить пользователя">
                <IconButton color="primary" size="medium" onClick={openSearchUserModal}>
                    <AddBoxOutlinedIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            {open && <SearchUserModal addUser={addUser} closeSearchUserModal={closeSearchUserModal} />}
        </>
    );
};
