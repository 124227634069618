import { ChangeEvent } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Domain } from "../../../entities/DomainEdit";
import { useValidations } from "../../../validation";
import { DomainValidation } from "../../../validation/DomainEditValidation";
import { TextField } from "@mui/material";

export const DomainTitle = observer(() => {
    const { domain, changeDomain } = useStores().domainEditStore;
    const { validation, changeValidation } = useValidations().domainEditValidation;

    const changeDomainTitle = (event: ChangeEvent<HTMLInputElement>): void => {
        const newDomain: Domain = JSON.parse(JSON.stringify(domain));

        newDomain.domain = event.target.value;

        changeDomain(newDomain);
        setDomainTitleValidation();
    };

    const setDomainTitleValidation = (): void => {
        const newValidation: DomainValidation = JSON.parse(JSON.stringify(validation));

        newValidation.domain = false;

        changeValidation(newValidation);
    };

    return (
        <div className="field">
            <h5>Домен</h5>
            <TextField
                fullWidth
                value={domain.domain}
                error={validation.domain}
                helperText={validation.domain && "Поле не может быть пустым"}
                onChange={action(changeDomainTitle)}
            />
        </div>
    );
});
