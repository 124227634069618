import { observer } from "mobx-react";
import { useTeamsIds } from "../Teams";
import { useStores } from "../../../stores";
import { Team } from "../../../entities/Teams";
import { Checkbox } from "@mui/material";

enum Field {
    ID = "id",
    TITLE = "title",
    LEADER = "leader",
}

interface ConvertField {
    id: string;
    title: string;
    leader: string;
    [key: string]: string;
}

const convertField: ConvertField = {
    id: "#",
    title: "Наименование",
    leader: "Лидер команды",
};

export const TableHead = observer(() => {
    const { teamsIds, setTeamsIds } = useTeamsIds();
    const { items } = useStores().teamsStore.teamsData;

    const handleChangeAllId = (): void => {
        const booleanArray: boolean[] = [];
        const teamsIdsCopy: number[] = JSON.parse(JSON.stringify(teamsIds));

        items.forEach((item: Team): void => {
            booleanArray.push(teamsIdsCopy.includes(item.id));
            if (!teamsIdsCopy.includes(item.id)) teamsIdsCopy.push(item.id);
        });
        !booleanArray.includes(false) ? setTeamsIds([]) : setTeamsIds(teamsIdsCopy);
    };

    return (
        <thead>
            <tr>
                <th className="table__th-width pb-1" onClick={handleChangeAllId}>
                    <Checkbox
                        size="small"
                        color="primary"
                        checked={items.length !== 0 && teamsIds.length === items.length}
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </th>
                {Object.values(Field).map((value: Field, index: number) => (
                    <th key={value} className={index === 0 ? "table__th-width" : ""}>
                        <div className="table__text-position">{convertField[value]}</div>
                    </th>
                ))}
            </tr>
        </thead>
    );
});
