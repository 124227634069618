import axios, { AxiosInstance } from "axios";
import { RootStore } from "../../stores/index";

export const httpClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

export const getToken = (jwtToken: string): void => {
    httpClient.defaults.headers.common["Authorization"] = `Bearer ${jwtToken}`;
};

httpClient.interceptors.response.use(
    (config) => {
        return config;
    },
    (error) => {
        if (error.response.status === 401) {
            RootStore.authStore.setIsAuthorized(false);
            delete httpClient.defaults.headers.common["Authorization"];
        }
        return Promise.reject(error);
    }
);
