import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useSelectedSources } from "../Sources";
import { SourceItem } from "../../../entities/Sources";
import { Checkbox } from "@mui/material";

export const TableBody = observer(() => {
    const { items } = useStores().sourcesStore.sourcesData;
    const { selectedSources, setSelectedSources } = useSelectedSources();

    const handleChangeId = (selectedSource: SourceItem): void => {
        let selectedSourcesCopy: SourceItem[] = JSON.parse(JSON.stringify(selectedSources));

        const isSourceExist = (): boolean => {
            return selectedSourcesCopy.map((item: SourceItem) => item.id).includes(selectedSource.id);
        };

        isSourceExist()
            ? (selectedSourcesCopy = selectedSourcesCopy.filter((item: SourceItem) => item.id !== selectedSource.id))
            : selectedSourcesCopy.push(selectedSource);

        setSelectedSources(selectedSourcesCopy);
    };

    return (
        <tbody>
            {items.map((item: SourceItem) => (
                <React.Fragment key={item.id}>
                    <tr onClick={() => handleChangeId(item)}>
                        <td className="align-middle">
                            <Checkbox
                                size="small"
                                checked={selectedSources.map((item: SourceItem) => item.id).includes(item.id)}
                            />
                        </td>
                        <td className="align-middle">{item.id}</td>
                        <td className="align-middle">{item.title}</td>
                    </tr>
                </React.Fragment>
            ))}
        </tbody>
    );
});
