import { TableHead, TableBody } from ".";
import "../../../style/table.css";

export const SourcesTable = () => (
    <div className="table-responsive">
        <table className="table table-hover">
            <TableHead />
            <TableBody />
        </table>
    </div>
);
