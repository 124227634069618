import { useEffect } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Goal } from "../../../../entities/CampaignEdit";
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

export const Goals = observer(() => {
    const { goals } = useStores().campaignEditStore.campaign;
    const { criteria, changeLinksCriteria } = useStores().campaignLinksStore;

    useEffect(() => {
        const criteriaGoalId: string = "-1";
        const criteriaGoalName: string = "goalName";

        changeLinksCriteria(criteriaGoalName, criteriaGoalId);
    }, [changeLinksCriteria]);

    const changeCriteriaGoalName = (event: SelectChangeEvent) => {
        const goalId: string = event.target.value;
        const goalName: string = event.target.name;

        changeLinksCriteria(goalName, goalId);
    };

    return (
        <>
            {goals.length > 0 && (
                <>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>Цель</InputLabel>
                        <Select
                            label="Цель"
                            name="goalName"
                            value={criteria.goalName}
                            onChange={action(changeCriteriaGoalName)}
                        >
                            <MenuItem value="-1">Не выбрана</MenuItem>
                            {goals.map((goal: Goal) => (
                                <MenuItem key={goal.name} value={goal.name}>
                                    {goal.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}
        </>
    );
});
