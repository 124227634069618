import { usePostback, useIndicesState } from ".";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const PostbackParametersShowButton = () => {
    const { postbackIndex } = usePostback();
    const { postbackIndices, setPostbackIndices } = useIndicesState();

    const showParameters = (): void => {
        const copyPostbackIndices: number[] = JSON.parse(JSON.stringify(postbackIndices));
        const index: number = copyPostbackIndices.indexOf(postbackIndex);

        index > -1 ? copyPostbackIndices.splice(index, 1) : copyPostbackIndices.push(postbackIndex);

        setPostbackIndices(copyPostbackIndices);
    };

    return (
        <Button
            color="inherit"
            variant="outlined"
            className="w-50 ml-2 mr-2"
            startIcon={postbackIndices.includes(postbackIndex) ? <VisibilityIcon /> : <VisibilityOffIcon />}
            onClick={showParameters}
        >
            Параметры
        </Button>
    );
};
