import { AxiosResponse } from "axios";
import { UsersApi } from "../services/api/UsersApi";
import { makeAutoObservable, runInAction } from "mobx";
import { User, NewUser, CurrentUser, CurrentUserRequest } from "../entities/UserEdit";

export interface CurrentUserStoreInterface {
    currentUser: CurrentUser;
    cleanUpCurrentUser(): void;
    findCurrentUser(): Promise<CurrentUser>;
    editCurrentUser(user: User | NewUser): Promise<AxiosResponse<CurrentUser>>;
}

const DEFAULT_CURRENT_USER: CurrentUser = {
    id: -1,
    teamId: -1,
    roles: [],
    email: "",
    fullName: {
        firstName: "",
        lastName: "",
    },
    dateTimezone: "Europe/Moscow",
};

const { getCurrentUser, putCurrentUser } = UsersApi;

export class CurrentUserStore implements CurrentUserStoreInterface {
    public currentUser: CurrentUser = DEFAULT_CURRENT_USER;

    constructor() {
        makeAutoObservable(this);
    }

    public cleanUpCurrentUser = (): void => {
        this.currentUser = DEFAULT_CURRENT_USER;
    };

    public findCurrentUser = async (): Promise<CurrentUser> => {
        await getCurrentUser().then((response: AxiosResponse<CurrentUser>) => {
            runInAction(() => {
                this.currentUser = response.data;
            });
        });
        return this.currentUser;
    };

    public editCurrentUser = async (user: User | NewUser): Promise<AxiosResponse<CurrentUser>> => {
        return await putCurrentUser(this.createCurrentUserRequest(user)).then(
            (response: AxiosResponse<CurrentUser>) => {
                return runInAction(() => {
                    getCurrentUser();
                    return response;
                });
            }
        );
    };

    private createCurrentUserRequest = (user: User | NewUser): CurrentUserRequest => {
        const userRequest: CurrentUserRequest = {
            fullName: user.fullName,
            email: user.email,
            password: user.password,
            dateTimezone: user.dateTimezone,
        };
        if (!userRequest.password) delete userRequest.password;
        return userRequest;
    };
}
