import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../module/Enum/Mode";
import { useValidations } from "../../validation";
import { LandingEditBody } from "./LandingEditBody";
import { LandingEditHeader } from "./LandingEditHeader";
import { LandingEditFooter } from "./LandingEditFooter";
import { Modal } from "@mui/material";
import "../../style/landingEdit.css";

interface Props {
    landingId?: string | undefined;
    closeLandingEdit(): void;
}

interface ParamsProps {
    mode: Mode;
}

export const LandingEdit = observer(({ landingId, closeLandingEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { setDefualtValidation } = useValidations().landingEditValidation;
    const { findLanding, cleanUpLendingEdit } = useStores().landingEditStore;

    useEffect(() => {
        switch (mode) {
            case Mode.EDIT:
                landingId && findLanding(landingId);
                break;
            case Mode.VIEW:
                landingId && findLanding(landingId);
                break;
            default:
                cleanUpLendingEdit();
                break;
        }
    }, [landingId, mode, findLanding, cleanUpLendingEdit]);

    useEffect(() => {
        return () => {
            cleanUpLendingEdit();
            setDefualtValidation();
        };
    }, [cleanUpLendingEdit, setDefualtValidation]);

    return (
        <form className="modal" tabIndex={-1} role="dialog" aria-hidden="true" autoComplete="off">
            <Modal open>
                <div role="document" className="modal-dialog modal-dialog-centered modal_width">
                    <div className="modal-content">
                        <LandingEditHeader closeLandingEdit={closeLandingEdit} />
                        <LandingEditBody />
                        {mode !== Mode.VIEW && <LandingEditFooter closeLandingEdit={closeLandingEdit} />}
                    </div>
                </div>
            </Modal>
        </form>
    );
});
