import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { SignIn } from "./SignIn";
import { InitializePasswordReset } from "./InitializePasswordReset";

export const Authorization = observer(() => {
    const { forgotPassword, cleanUpAuth } = useStores().authStore;

    useEffect(() => {
        return cleanUpAuth;
    }, [cleanUpAuth]);

    return forgotPassword ? <InitializePasswordReset /> : <SignIn />;
});
