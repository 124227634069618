import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { AuthValidation } from "./useValidation.hook";
import { usePasswordResetValidation } from "./InitializePasswordReset";
import { Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

export const SendButton = observer(() => {
    const { enqueueSnackbar } = useSnackbar();
    const { passwordResetInitializeData, initializePasswordReset } = useStores().authStore;
    const { validation, changeValidation, checkValidation } = usePasswordResetValidation();

    const setNonExistentMailValidation = (): void => {
        const newValidation: AuthValidation = JSON.parse(JSON.stringify(validation));
        newValidation.nonExistentMail = true;
        changeValidation(newValidation);
    };

    const send = (): void => {
        checkValidation(passwordResetInitializeData)
            ? enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 1500 })
            : initializePasswordReset()
                  .then((response) => {
                      enqueueSnackbar("На указанную почту отправлены инструкции по восстановлению пароля", {
                          variant: "success",
                          autoHideDuration: 5000,
                      });
                  })
                  .catch((error) => {
                      if (error.response?.status === 409) {
                          setNonExistentMailValidation();
                      } else {
                          enqueueSnackbar("Упс..", { variant: "error", autoHideDuration: 1500 });
                      }
                  });
    };

    return (
        <Button variant="contained" startIcon={<EmailIcon />} onClick={action(send)}>
            Отправить
        </Button>
    );
});
