import { useState, useEffect, SyntheticEvent } from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { useDefaultRule } from "../CampaignDefaultRules";
import { useValidations } from "../../../../../validation";
import { Landing, LandingsCriteria } from "../../../../../entities/Landings";
import { CampaignValidation } from "../../../../../validation/CampaignEditValidation";
import { DestinationType } from "../../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Campaign, PercentageRoutingRule, RuleDestinationTypeOffer } from "../../../../../entities/CampaignEdit";
import { Autocomplete, FormControl, TextField } from "@mui/material";

export const LandingSelect = observer(() => {
    const [inputValue, setInputValue] = useState<string>("");
    const [landing, setLanding] = useState<Landing | null>(null);
    const { rule, ruleIndex } = useDefaultRule();
    const { campaign, changeCampaign } = useStores().campaignEditStore;
    const { findLanding, cleanUpLendingEdit } = useStores().landingEditStore;
    const { validation, changeValidation } = useValidations().campaignEditValidation;
    const { criteria, landingsData, findLandings, changeCriteria, setDefaultCriteria } = useStores().landingsStore;
    const fieldName: string = `percentageRuleLanding${ruleIndex}`;

    useEffect(() => {
        findLandings();
    }, [criteria, findLandings]);

    useEffect(() => {
        if (rule.destination.type === DestinationType.OFFER) {
            rule.destination.landingId === null || rule.destination.landingId === -1
                ? setLanding(null)
                : findLanding(rule.destination.landingId).then((response) => {
                      const convertedLanding: Landing = {
                          id: response.id,
                          title: response.title,
                          url: response.url,
                          allowedActions: [],
                      };
                      setLanding(convertedLanding);
                  });
        }
    }, [rule.destination, findLanding]);

    useEffect(() => {
        return () => {
            cleanUpLendingEdit();
            setDefaultCriteria();
        };
    }, [cleanUpLendingEdit, setDefaultCriteria]);

    const changeLandingId = (event: SyntheticEvent<Element, Event>, newValue: Landing | null): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newRule: PercentageRoutingRule = JSON.parse(JSON.stringify(rule));

        (newRule.destination as RuleDestinationTypeOffer).landingId = newValue !== null ? newValue.id : -1;
        newCampaign.percentageRoutingRules.splice(ruleIndex, 1, newRule);

        changeCampaign(newCampaign);
    };

    const changeInputValue = (event: SyntheticEvent<Element, Event>, newInputValue: string | undefined): void => {
        const newCriretia: LandingsCriteria = JSON.parse(JSON.stringify(criteria));
        const inputValue: string = newInputValue !== undefined ? newInputValue : "";

        if (inputValue.length < 1 || inputValue.length > 2) {
            newCriretia.search = inputValue;
            changeCriteria(newCriretia);
        }

        setInputValue(inputValue);
        setPercentageRuleLandingValidation();
    };

    const setPercentageRuleLandingValidation = (): void => {
        const newValidation: CampaignValidation = JSON.parse(JSON.stringify(validation));
        newValidation.percentageRoutingRules[fieldName] = false;
        changeValidation(newValidation);
    };

    return (
        <FormControl fullWidth className="field mt-2" error={validation.percentageRoutingRules[fieldName]}>
            <Autocomplete
                fullWidth
                id="landing"
                value={landing}
                inputValue={inputValue}
                options={landingsData.items}
                getOptionLabel={(option) => option.title}
                onChange={(event, value) => runInAction(() => changeLandingId(event, value))}
                onInputChange={changeInputValue}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Введите минимум 3 символа"
                        error={validation.percentageRoutingRules[fieldName]}
                        helperText={validation.percentageRoutingRules[fieldName] && "Необходимо выбрать лендинг"}
                    />
                )}
            />
        </FormControl>
    );
});
