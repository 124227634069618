import { observer } from "mobx-react";
import { useSelectedSources } from "../";
import { useStores } from "../../../stores";
import { useUserRoles } from "../../../hooks/useUserRoles";
import { SourceAddButton, SourceEditButton, SourceViewButton, SourceCloneButton, SourceDeleteButton } from ".";
import "../../../style/actionButtons.css";

export const ActionButtons = observer(() => {
    const { selectedSources } = useSelectedSources();
    const { email } = useStores().currentUserStore.currentUser;
    const { isMediaBuyer } = useUserRoles();

    const displayEditButton = (): JSX.Element => {
        if (selectedSources.length === 1 && isMediaBuyer()) {
            const [userName] = selectedSources.map((item) => item.userName);

            const isUserNameEqual = (): boolean => {
                return email === userName;
            };

            return isUserNameEqual() ? <SourceEditButton /> : <SourceViewButton />;
        } else {
            return <SourceEditButton />;
        }
    };

    const displayDeleteButton = (): JSX.Element | null => {
        return isMediaBuyer() ? null : <SourceDeleteButton />;
    };

    return (
        <div className="d-flex ml-4 pt-3">
            <SourceAddButton />
            {displayEditButton()}
            <SourceCloneButton />
            {displayDeleteButton()}
        </div>
    );
});
