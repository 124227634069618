import { useSnackbar } from "notistack";
import { useStatisticsContext } from "../Statistics";
import { StatisticsCriteria } from "../../../entities/Statistics";
import { SortField } from "../../../module/Enum/Statistics/SortField";
import { TypeGrouping } from "../../../module/Enum/Grouping/Grouping";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { useFormCriteria, useGroupByValidation } from "./StatisticsForm";
import { Button } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

export const StatisticsFilterButton = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { formCriteria } = useFormCriteria();
    const { checkValidation } = useGroupByValidation();
    const { criteria, setCriteria } = useStatisticsContext();

    const filterStatistics = (): void => {
        const newCriteria: StatisticsCriteria = { ...criteria, ...formCriteria };

        if (checkValidation()) {
            enqueueSnackbar("Не все поля корректно заполнены", { variant: "error", autoHideDuration: 2000 });
        } else {
            newCriteria.page = 1;
            newCriteria.sort =
                newCriteria.groupBy[0].type === TypeGrouping.DEFAULT
                    ? {
                          type: TypeGrouping.DEFAULT,
                          field: newCriteria.groupBy[0].value as SortField,
                          direction: SortDirection.ASC,
                      }
                    : {
                          type: TypeGrouping.PARAMETER,
                          parameter: newCriteria.groupBy[0].value as string,
                          direction: SortDirection.ASC,
                      };

            setCriteria(newCriteria);
        }
    };

    return (
        <Button variant="contained" startIcon={<FilterAltIcon />} onClick={filterStatistics}>
            Фильтровать
        </Button>
    );
};
