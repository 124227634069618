import { Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { TeamsRoute } from "./TeamsRoute";
import { UsersRoute } from "./UsersRoute";
import { OffersRoute } from "./OffersRoute";
import { ReportRoute } from "./ReportRoute";
import { DomainsRoute } from "./DomainsRoute";
import { SourcesRoute } from "./SourcesRoute";
import { LandingsRoute } from "./LandingsRoute";
import { CampaignsRoute } from "./CampaignsRoute";
import { StatisticsRoute } from "./StatisticsRoute";
import { useUserRoles } from "../../hooks/useUserRoles";

export const TrackerRoutes = observer(() => {
    const { isUser, isMediaBuyer } = useUserRoles();

    const checkingUserAccess = (component: JSX.Element): JSX.Element => {
        return isUser() || isMediaBuyer() ? <Redirect to="/spa/campaigns" /> : component;
    };

    return (
        <Switch>
            <Route path="/spa/delta/:mode?" component={ReportRoute} />
            <Route path="/spa/statistics/:mode?" component={StatisticsRoute} />
            <Route path="/spa/offers/:mode?/:offerId?" component={OffersRoute} />
            <Route path="/spa/sources/:mode?/:sourceId?" component={SourcesRoute} />
            <Route path="/spa/landings/:mode?/:landingId?" component={LandingsRoute} />
            <Route path="/spa/campaigns/:mode?/:campaignId?" component={CampaignsRoute} />
            <Route path="/spa/teams/:mode?/:teamId?" render={() => checkingUserAccess(<TeamsRoute />)} />
            <Route path="/spa/users/:mode?/:userId?" render={() => checkingUserAccess(<UsersRoute />)} />
            <Route path="/spa/domains/:mode?/:domainId?" render={() => checkingUserAccess(<DomainsRoute />)} />
            <Route path="*" render={() => <Redirect to="/spa/campaigns" />} />
        </Switch>
    );
});
