import { useState } from "react";
import { WarningModal } from ".";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useLandingsIds } from "../Landings";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export const LandingDeleteButton = observer(() => {
    const [open, setOpen] = useState<boolean>(false);
    const { removeLanding } = useStores().landingsStore;
    const { landingsIds, setLandingsIds } = useLandingsIds();

    const openWarningModal = (): void => setOpen(true);

    const closeWarningModal = (): void => setOpen(false);

    const deleteLanding = (): void => {
        landingsIds.forEach((id: number) => {
            removeLanding(id);
        });
        setLandingsIds([]);
        closeWarningModal();
    };

    return (
        <>
            <Button
                size="small"
                color="error"
                variant="contained"
                startIcon={<DeleteIcon />}
                disabled={landingsIds.length === 0}
                onClick={openWarningModal}
            >
                Удалить
            </Button>

            {open && <WarningModal deleteLanding={deleteLanding} closeWarningModal={closeWarningModal} />}
        </>
    );
});
