import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Campaign, Goal } from "../../../../entities/CampaignEdit";
import { FormControlLabel, Switch } from "@mui/material";

interface Props {
    goal: Goal;
    goalIndex: number;
}

export const GoalIsEnabled = observer(({ goal, goalIndex }: Props) => {
    const { campaign, changeCampaign } = useStores().campaignEditStore;

    const changeGoalIsEnabled = (): void => {
        const newCampaign: Campaign = JSON.parse(JSON.stringify(campaign));
        const newGoal: Goal = JSON.parse(JSON.stringify(goal));

        newGoal.isEnabled = !newGoal.isEnabled;
        newCampaign.goals.splice(goalIndex, 1, newGoal);

        changeCampaign(newCampaign);
    };

    return (
        <FormControlLabel
            className="goal-enable"
            label={goal.isEnabled ? "Активна" : "Не активна"}
            control={<Switch color="success" checked={goal.isEnabled} />}
            onChange={action(changeGoalIsEnabled)}
        />
    );
});
