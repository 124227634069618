import { action } from "mobx";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { CampaignCriteria } from "../../entities/Campaigns";
import { Pagination, FormControl, Select, MenuItem, SelectChangeEvent } from "@mui/material";

export const CampaignsPagination = observer(() => {
    const { campaignData, criteria, changeCriteria } = useStores().campaignsStore;

    const changePage = (event: object, currentPage: number): void => {
        const newCriteria: CampaignCriteria = { ...criteria };
        newCriteria.page = currentPage;
        changeCriteria(newCriteria);
    };

    const changeItemsPerPage = (event: SelectChangeEvent<number>): void => {
        const newCriteria: CampaignCriteria = { ...criteria };
        newCriteria.page = 1;
        newCriteria.itemsPerPage = Number(event.target.value);
        changeCriteria(newCriteria);
    };

    return (
        <div className="d-flex align-items-center">
            <Pagination
                shape="rounded"
                className="pagination mr-2 mb-4"
                page={criteria.page}
                count={Math.ceil(campaignData.totalItems / criteria.itemsPerPage) || 1}
                onChange={action(changePage)}
            />

            <label className="mr-3 mb-4">Кол-во строк</label>

            <FormControl variant="standard" className="mb-4">
                <Select value={criteria.itemsPerPage} onChange={action(changeItemsPerPage)}>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
});
