import { action } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useStores } from "../../../stores";
import { useParams } from "react-router-dom";
import { Mode } from "../../../module/Enum/Mode";
import { useValidations } from "../../../validation";
import { LandingsCriteria } from "../../../entities/Landings";
import { SortField } from "../../../module/Enum/Landings/SortField";
import { SortDirection } from "../../../module/Enum/Sort/SortDirection";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";

interface Props {
    closeLandingEdit(): void;
}

interface ParamsProps {
    mode: Mode;
}

export const LandingEditSaveAndCloseButton = observer(({ closeLandingEdit }: Props) => {
    const { mode } = useParams<ParamsProps>();
    const { enqueueSnackbar } = useSnackbar();
    const { landing, saveLanding } = useStores().landingEditStore;
    const { criteria, changeCriteria } = useStores().landingsStore;
    const { checkValidation } = useValidations().landingEditValidation;

    const filterLandingsTable = (): void => {
        const newCriteria: LandingsCriteria = { ...criteria };
        newCriteria.page = 1;
        newCriteria.sort = {
            direction: SortDirection.DESC,
            field: SortField.ID,
        };
        changeCriteria(newCriteria);
    };

    const saveAndClose = (): void => {
        checkValidation(landing)
            ? enqueueSnackbar("Не все поля кореектно заполнены", { variant: "error", autoHideDuration: 1500 })
            : saveLanding()
                  .then((response) => {
                      if (response) {
                          if (mode === Mode.ADD) filterLandingsTable();
                          closeLandingEdit();
                          enqueueSnackbar("Лендинг сохранен", { variant: "success", autoHideDuration: 1500 });
                      }
                  })
                  .catch((error) => {
                      if (error.response?.status === 403) {
                          enqueueSnackbar("У вас нет доступа к редактированию", {
                              variant: "error",
                              autoHideDuration: 1500,
                          });
                      }
                  });
    };

    return (
        <Button variant="contained" startIcon={<SaveIcon />} onClick={action(saveAndClose)}>
            Сохранить и выйти
        </Button>
    );
});
