import { TableHead, TableBody, TableFooter } from "./";
import "../../../style/table.css";

export const CampaignsTable = () => (
    <div className="table-responsive">
        <table className="table table-hover">
            <TableHead />
            <TableBody />
            <TableFooter />
        </table>
    </div>
);
