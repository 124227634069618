import { UserEditTitle, UserEditCloseButton } from ".";

interface Props {
    closeUserEdit(): void;
}

export const UserEditHeader = ({ closeUserEdit }: Props) => (
    <div className="modal-header">
        <UserEditTitle />
        <UserEditCloseButton closeUserEdit={closeUserEdit} />
    </div>
);
