import { Mode } from "../../module/Enum/Mode";
import { Report } from "../../components/Report";
import { UserEdit } from "../../components/UserEdit";
import { useHistory, useParams } from "react-router-dom";

interface ParamsProps {
    mode: Mode;
}

export const ReportRoute = () => {
    let content = null;
    const history = useHistory();
    const { mode } = useParams<ParamsProps>();

    const closeOfferEdit = (): void => {
        history.push("/spa/delta");
    };

    switch (mode) {
        case Mode.CURRENT:
            content = <UserEdit mode={mode} closeUserEdit={closeOfferEdit} />;
            break;

        default:
            content = null;
            break;
    }

    return (
        <>
            <Report />
            {content}
        </>
    );
};
