import { observer } from "mobx-react";
import { useCondition } from "./";
import { useRoutingRule } from "../";
import { useValidations } from "../../../../../validation";
import { convertConditionType } from "../../../../../module/Enum/CampaignEdit/CampaignEdit";
import { Tooltip } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

export const ConditionTitle = observer(() => {
    const { ruleIndex } = useRoutingRule();
    const { condition, conditionIndex } = useCondition();
    const { routingRuleConditions } = useValidations().campaignEditValidation.validation;
    const fieldName: string = `expression${ruleIndex}${conditionIndex}`;

    return (
        <>
            {routingRuleConditions[fieldName] && (
                <Tooltip className="condition__tooltip" title={"Необходимо добавить, либо исключить условие"}>
                    <ErrorOutlineOutlinedIcon color="error" fontSize="small" />
                </Tooltip>
            )}
            <small>
                <strong>{convertConditionType[condition.type]}</strong>
            </small>
        </>
    );
});
