import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useStores } from "../../stores";
import { StatisticsCriteria, StatisticsData } from "../../entities/Statistics";
import { DEFAULT_STATISTICS_CRITERIA, DEFAULT_STATISTICS_DATA } from "../../stores/StatisticsStore";

interface StatisticsHook {
    criteria: StatisticsCriteria;
    statisticsData: StatisticsData;
    setCriteria: Dispatch<SetStateAction<StatisticsCriteria>>;
}

export const useStatistics = (campaignId?: number, dropdownNotice?: boolean): StatisticsHook => {
    const { findStatistics } = useStores().statisticsStore;
    const [statisticsData, setStatisticsData] = useState<StatisticsData>(DEFAULT_STATISTICS_DATA);
    const [criteria, setCriteria] = useState<StatisticsCriteria>({
        ...DEFAULT_STATISTICS_CRITERIA,
        campaignIds: dropdownNotice && campaignId !== undefined ? [campaignId] : [],
    });

    useEffect(() => {
        findStatistics(criteria).then((response: StatisticsData) => {
            setStatisticsData(response);
        });
    }, [criteria, findStatistics]);

    return {
        criteria,
        setCriteria,
        statisticsData,
    };
};
