import { useEffect, useState } from "react";
import { useStores } from "../stores";
import { Roles } from "../module/Enum/Roles/Roles";

interface UserRoles {
    role: Roles | undefined;
    isUser(): boolean;
    isMediaBuyer(): boolean;
    isTeamLead(): boolean;
    isAdministrator(): boolean;
}

export const useUserRoles = (): UserRoles => {
    const [role, setRole] = useState<Roles | undefined>();
    const { roles } = useStores().currentUserStore.currentUser;

    useEffect(() => {
        switch (true) {
            case roles.includes(Roles.ROLE_ADMINISTRATOR):
                setRole(Roles.ROLE_ADMINISTRATOR);
                break;

            case roles.includes(Roles.ROLE_TEAMLEAD):
                setRole(Roles.ROLE_TEAMLEAD);
                break;

            case roles.includes(Roles.ROLE_MEDIA_BUYER):
                setRole(Roles.ROLE_MEDIA_BUYER);
                break;

            case roles.includes(Roles.ROLE_USER):
                setRole(Roles.ROLE_USER);
                break;
        }
    }, [roles]);

    const isUser = (): boolean => {
        return role === Roles.ROLE_USER;
    };

    const isMediaBuyer = (): boolean => {
        return role === Roles.ROLE_MEDIA_BUYER;
    };

    const isTeamLead = (): boolean => {
        return role === Roles.ROLE_TEAMLEAD;
    };

    const isAdministrator = (): boolean => {
        return role === Roles.ROLE_ADMINISTRATOR;
    };

    return {
        role,
        isUser,
        isMediaBuyer,
        isTeamLead,
        isAdministrator,
    };
};
