import { AxiosResponse } from "axios";
import { makeAutoObservable, runInAction } from "mobx";
import { SourcesApi } from "../services/api/SourcesApi";
import { SourceParameters } from "../entities/SourceParameters";

export interface SourceParametersStoreInterface {
    sourceParameters: SourceParameters;
    cleanUpSourceParameters(): void;
    findSourceParameters(): Promise<SourceParameters>;
    findCampaignSourceParameters(campignId: number | string): Promise<SourceParameters>;
}

const DEFAULT_SOURCE_PARAMETERS: SourceParameters = {
    items: [],
};

const { getSourceParameters, getCampaignSourceParameters } = SourcesApi;

export class SourceParametersStore implements SourceParametersStoreInterface {
    public sourceParameters: SourceParameters = DEFAULT_SOURCE_PARAMETERS;

    constructor() {
        makeAutoObservable(this);
    }

    public cleanUpSourceParameters = (): void => {
        this.sourceParameters = DEFAULT_SOURCE_PARAMETERS;
    };

    public findSourceParameters = async (): Promise<SourceParameters> => {
        await getSourceParameters().then((response: AxiosResponse<SourceParameters>) => {
            runInAction(() => {
                this.sourceParameters = response.data;
            });
        });
        return this.sourceParameters;
    };

    public findCampaignSourceParameters = async (campignId: number | string): Promise<SourceParameters> => {
        await getCampaignSourceParameters(campignId).then((response: AxiosResponse<SourceParameters>) => {
            runInAction(() => {
                this.sourceParameters = response.data;
            });
        });
        return this.sourceParameters;
    };
}
