import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useStores } from "../../stores";
import { DEFAULT_OFFERS_CRITERIA } from "../../stores/OffersStore";
import { OffersCriteria, OffersData, OfferItem } from "../../entities/Offers";

interface OffersHook {
    criteria: OffersCriteria;
    offersList: OfferItem[];
    setCriteria: Dispatch<SetStateAction<OffersCriteria>>;
}

export const useOffers = (offerId?: number): OffersHook => {
    const { findOffers, cleanUpOffers } = useStores().offersStore;
    const [offersList, setOffersList] = useState<OfferItem[]>([]);
    const [criteria, setCriteria] = useState<OffersCriteria>(DEFAULT_OFFERS_CRITERIA);

    useEffect(() => {
        findOffers(criteria).then((response: OffersData) => {
            if (offerId && offerId !== -1) {
                const newOfferList: OfferItem[] = response.items.filter((item: OfferItem) => item.id !== offerId);
                setOffersList(newOfferList);
            } else {
                setOffersList(response.items);
            }
        });
    }, [offerId, criteria, findOffers]);

    useEffect(() => {
        return cleanUpOffers;
    }, [cleanUpOffers]);

    return {
        criteria,
        offersList,
        setCriteria,
    };
};
