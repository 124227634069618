import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useLandingsIds } from "../Landings";
import { Landing } from "../../../entities/Landings";
import { AllowedUserActions } from "../../../module/Enum/AllowedUserActions";
import { LandingAddButton, LandingEditButton, LandingViewButton, LandingDeleteButton } from ".";
import "../../../style/actionButtons.css";

interface AllowedActions {
    update: boolean;
    delete: boolean;
}

export const ActionButtons = observer(() => {
    const { landingsIds } = useLandingsIds();
    const { items } = useStores().landingsStore.landingsData;
    const [id] = landingsIds;

    const checkAllowedUserActions = (): AllowedActions => {
        const allowedActions: AllowedActions = {
            update: true,
            delete: true,
        };
        items
            .filter((item: Landing) => item.id === id)
            .forEach((item: Landing) => {
                allowedActions.update = item.allowedActions.includes(AllowedUserActions.UPDATE);
                allowedActions.delete = item.allowedActions.includes(AllowedUserActions.DELETE);
            });

        return allowedActions;
    };

    return (
        <div className="d-flex ml-4 pt-3">
            <LandingAddButton />
            {checkAllowedUserActions().update ? <LandingEditButton /> : <LandingViewButton />}
            {checkAllowedUserActions().delete && <LandingDeleteButton />}
        </div>
    );
});
