import { observer } from "mobx-react";
import { Filter } from "../../../../entities/Report";
import { convertField } from "../../../../module/Enum/Statistics/SortField";
import { TextField } from "@mui/material";

interface Props {
    filterItem: Filter;
}

export const FilterField = observer(({ filterItem }: Props) => (
    <TextField
        fullWidth
        label="Поле"
        variant="standard"
        className="pointer-events"
        value={convertField[filterItem.field]}
    />
));
