import { CampaingEditButton, CampaignCloneButton, SourceLinkCopyButton, CampaignDeleteButton } from "./";
import "../../../style/actionButtons.css";

interface Props {
    campaignId: number;
}

export const ActionButtons = ({ campaignId }: Props) => (
    <div className="d-flex justify-content-center">
        <CampaingEditButton campaignId={campaignId} />
        <CampaignCloneButton campaignId={campaignId} />
        <SourceLinkCopyButton campaignId={campaignId} />
        <CampaignDeleteButton campaignId={campaignId} />
    </div>
);
